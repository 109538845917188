import styled from 'styled-components';


export const ButtonS = styled.button`
  background: ${(props) =>
    props.primary ? '#B91C1C' : '#203577'
};
  color: ${(props) =>
    props.primary ? 'white' : 'white'
};
  cursor: pointer;
  border: none;
  margin-top: 10px;
  font-size: 1em;
  font-weight: 600;
  padding: 0.9rem;
  border-radius: 1px;
  width:${(props) => props.width ? 'props.width' : '100%'};
  // margin-right:${(props) => props.marginRight ? 'props.marginRight' : '0px'}
  // margin-left:${(props) => props.marginLeft ? 'props.marginLeft' : '0px'}
  &:hover{
    background-color: #2f91ed;
    
  }
    `

export const Button2 = styled.button`
    color: #595959;
    background-color: white;
    border:${(props) => props.border ? 'props.border' : '1px solid #F0F2F5'};
    cursor: pointer;
    font-weight: 600;
    font-size: 1em;
    width:${(props) => props.width ? 'props.width' : '100%'};
    padding: 0.9rem;
    
    &:hover{
    background-color: #c9c9c9;
    
  }   
`

export const ButtonL = styled.button`
  width:${(props) => props.width ? 'props.width' : '160px'};
  padding:${(props) => props.padding ? `${props.padding}` : '0px'};
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin:${(props) => props.margin ? 'props.margin' : '20px'};
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  //border-radius: 10px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background-image: linear-gradient(
      to right,
      #243B85,
      #3b54a3,
      #516bbd,
      #738dde
    );
    box-shadow: 0 2px 15px 0 rgba(47, 72, 156, 0.75);
    
    :hover{
    background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  }
  
  :hover:focus {
  outline: none;
  }
`
export const ButtonR = styled.button`
  //z-Index: 10;
  width:${(props) => props.width ? 'props.width' : '160px'};
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  //border-radius: 10px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
   background-image: linear-gradient(
      to right,
      #eb3941,
      #f15e64,
      #e14e53,
      #e2373f
    );
    box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
    
    :hover{
     background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  }
  
  :hover:focus {
  outline: none;
  }
`
