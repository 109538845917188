import React, {useState} from "react";
import {CenteredCol} from "../../../../../components/Flex";
import {Hr, KeyWordWrapper, StyledCol, Title} from "../../../style";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import {searchProjectsAsync} from "../../../../../services/api";
import {getIndexOfProjectLanguage} from "../../../../../utils/language";
import {SEARCH_TYPES} from "../../../index";

export default function ProjectsSection({onMenuClick, inputString, sectorIds, range}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });
    const [sort, setSort] = useState({
        rowPerPage: 99999999999,
        sortBy: "createdAt,ASC",
    });

    React.useEffect(() => {
        setLoading(true);
        let data = {
            name: null,
            businesSectorIds: null
        }
        data.name = inputString;
        if (sectorIds.length > 0) {
            data.businesSectorIds = sectorIds
        }
        if (range[0]) {
            data.priceFrom = range[0]
            data.priceTo = range[1]
        }
        let parsedFilter = `?page=${page.currentPage}&sort=${sort.sortBy}&size=${sort.rowPerPage}`
        searchProjectsAsync(data, parsedFilter).then(response => {
            setProjects(response?.data?.content);
            setLoading(false);
        }).catch(_ => {
            setLoading(false);
        });
    }, [inputString, sectorIds, range, page.currentPage, sort.rowPerPage, sort.sortBy])

    if (loading) {
        return (
            <CenteredCol>
                <CircularProgress/>
            </CenteredCol>
        );
    }
    if (projects?.length === 0)
        return <></>;
    return (
        <StyledCol>
            <Title>{t('searchAutocomplete.projects')}</Title>
            <Hr/>
            {projects?.map((value, index) => {
                return (<KeyWordWrapper
                    onClick={() => onMenuClick(SEARCH_TYPES.project, value?.id, value)}
                    key={index}>{value?.projectLanguages[getIndexOfProjectLanguage(value, 1)]?.name}</KeyWordWrapper>)
            })}
        </StyledCol>
    );
}
