import React, {useEffect, useState} from "react";
import {NormalText} from "../../components/Text";
import './style.css'
import {CenteredCol, CenteredRow, FlexCol, FlexRow} from "../../components/Flex";
import styled from "styled-components";
import {
    getAllCities,
    getAllCountries,
    getUserById,
    getUserProfileImageAsync, updateBrokerDto, updateDachDto,
    updateEntrepreneurDto,
    updateIndividualDto, updateInvesstorDto, updateMediaDto, updateMentorDto, updatePksDto,
    updatePksNewDto, updateRRADto, updateSpecialDto,
    updateUser,
    uploadUserImage,
    changePassword
} from "../../services/api";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import UserPhoto from "../../assets/images/user.svg"
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Spinner, SpinnerWrapper} from "../../components/Spinner";
import {useSnackbar} from "notistack";
import Pksnew from "./pksnew";
import Entrepreneur from "./entrepreneur";
import {PageContainer, ProjectContainer} from "../../components/Containers";
import {Switch} from "@mui/material";
import {ButtonL} from "../../components/Button";
import Individual from "./individual";
import Broker from "./broker";
import Investor from "./investor";
import Mentor from "./mentor";
import Dach from "./dach";
import RRA from "./rra";
import Mediji from "./mediji";
import Pks from "./pks";
import Special from "./special";
import {right} from "glamor";

const CardWrapper = styled(FlexCol)`
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 10px;
    max-width: 400px;
    max-height: 600px;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) 0px 16px 32px -4px;
    border-radius: 16px;
    position: relative;
    z-index: 0;
    padding: 80px 24px;
`;
const CardWrapperRight = styled(CardWrapper)`
    max-width: 600px;
    max-height: 600px;
    height: 100%;
    overflow: auto;
    padding: 60px 25px;
`;


const StyledSpinner = styled(Spinner)`
    position: relative;
    left: auto;
    right: auto;
    top: auto;
`;

const SubText = styled.p`
    margin: 16px auto 20px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: (145, 158, 171, 0.32);
    display: block;
    text-align: center;
    max-width: 200px;
    display:flex;
    flex-wrap:wrap;
`

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    margin: auto;
    border-radius: 50%;
    padding: 0px;
    border: 1px dashed rgba(145, 158, 171, 0.32);
    margin-bottom: 2rem;
`

export default function ProfileEditPage() {
    const {t} = useTranslation();

    const [userInfo, setUserInfo] = useState()
    const imageRef = React.useRef();
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [cityName, setCityName] = useState()
    const [imgFile, setImgFile] = useState(undefined);
    const [userImage, setUserImage] = useState();
    const [loadingImage, setLoadingImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const userId = JSON.parse(localStorage.getItem('user'))
    const [passwordChange, setPasswordChange] = useState({
        "confirmOldPassword":"",
        "newPassword":"",
        "userId": JSON.parse(localStorage.getItem('user')).id
    })

    const [formDataImage, setFormDataImage] = useState()

    const [checked, setChecked] = useState(true);

    function getFileExtension(fileName) {
        return fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
    }

    const handleFileChange = (event) => {
        if (event.target && event.target.files[0]) {
            setImgFile(event.target.files[0]);
            let formData = new FormData();
            formData.append("userId", userInfo.id);
            formData.append("extension", getFileExtension(event.target.files[0]?.name));
            formData.append("picture", event.target.files[0]);
            setFormDataImage(formData)
            setUserImage(URL.createObjectURL(event.target.files[0]))

        }
    }

    const handleChangePassword = (e) => {
        const {id, value} = e.target
        setPasswordChange(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChange = (e) => {
        const {id, value} = e.target
        if (id === "cityName") {
            setCityName(value)
            setUserInfo(prevStat => ({
                ...prevStat, cityName: value,
                cityId: undefined
            }))
        } else {
            setUserInfo(prevState => ({
                ...prevState,
                [id]: value
            }))
        }
    }


    const handleChangeCity = (e, newValue) => {
        if (newValue !== null) {
            setUserInfo(prevState => ({
                ...prevState,
                city: {...prevState.city, id: newValue.id, name: newValue.name}
            }))
            setUserInfo(prevState => ({...prevState, cityId: newValue.id}))
        }

    }
    const handleChangeCountry = (e, newValue) => {
        if (newValue !== null) {
            setUserInfo(prevState => ({
                ...prevState,
                country: {...prevState.country, id: newValue.id, name: newValue.name}
            }))
            setUserInfo(prevState => ({...prevState, countryId: newValue.id}))
        }

    }

   const handleSubmitPassword = (passwordChange) => {
        if(passwordChange.newPassword.length < 7){
            enqueueSnackbar((t('newPasswordWrong')), {
                variant: 'error'
            })
        }else{
            return changePassword(passwordChange).then(()=> {
                setLoading(false)
                enqueueSnackbar((t('profileUpdated')), {
                    variant: 'success'
                })
            }).catch((reason) => {
                setLoading(false)
                if(reason.data.status === "Incorrect old password"){
                    enqueueSnackbar((t('oldPasswordWrong')), {
                        variant: 'error'
                    })
                }else{
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                }
            })
        }
   }

    const handleSubmit = (userInfo) => {
        setLoading(true)
        if(imgFile !== undefined) {
            uploadUserImage(formDataImage).then((res) => {
                setLoading(false)
            }).catch(reason => {
                setLoading(false)
                console.error(reason, t('uploadFail'));
            });
        }
        switch (userId?.roleId) {
            case 1: {
                return updateIndividualDto(userInfo).then(()=> {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 2: {
                return updateEntrepreneurDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 3: {
                return updateInvesstorDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 4: {
                return updateBrokerDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 5: {
                return updateMentorDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 6: {
                return updateDachDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 7: {
                return updateRRADto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 8: {
                return updateMediaDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 9: {
                return updatePksDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 10: {
                return updateSpecialDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 11: {
                return updateUser(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            case 12: {
                return updatePksNewDto(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
            default:
                return updateUser(userInfo).then(() => {
                    setLoading(false)
                    enqueueSnackbar((t('profileUpdated')), {
                        variant: 'success'
                    })
                }).catch(() => {
                    setLoading(false)
                    enqueueSnackbar((t('porfilUpdatedFail')), {
                        variant: 'error'
                    })
                })
            }
        }

    useEffect(() => {
        loadUserImage(userId?.id);
        getUserById(userId?.id).then(({data}) => {
            setUserInfo(data)
            setCityName(data?.city?.name)
        }).catch(error => {
            console.error('error', error)
        })
    }, [userId?.id])

    async function loadUserImage(userId) {
        setLoadingImage(true);
        getUserProfileImageAsync(userId).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(() => {
            setLoadingImage(false);

        });
    }

    useEffect(() => {
        getAllCountries().then(res => {
            setCountries(res?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllCities().then(response => {
            setCities(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, [])

    return (
        <CenteredCol>
            <ProjectContainer style={{width: '70%', marginBottom: '2rem'}}>
                <h3 style={{color: "gray"}}>{t('editProfileTitle')}</h3>
            </ProjectContainer>
            <PageContainer>
                <CenteredRow style={{flexWrap: 'wrap', width: '100%'}}>
                    {loading ? <SpinnerWrapper><StyledSpinner/></SpinnerWrapper> : <>
                        <CardWrapper >
                            <Circle>
                                <div className="profile-pic">
                                    <label className="-label" htmlFor="file">
                                        <span className="glyphicon glyphicon-camera"></span>
                                        <span>{t('edit')}</span>
                                    </label>
                                    <input id="file" style={{display: 'none'}} type="file" onChange={handleFileChange}/>
                                    {loadingImage ? <CircularProgress/> :
                                        <img src={userImage || UserPhoto} ref={imageRef}
                                             id="output"
                                             width="200"/>}
                                </div>
                            </Circle>

                            <SubText>{t('picFormats')}</SubText>
                            {/*<FlexRow>*/}
                            {/*    <NormalText>{t('visibility')}</NormalText>*/}
                            {/*    <Switch*/}
                            {/*        checked={checked}*/}
                            {/*        onChange={handleChangeSwitch}*/}
                            {/*        inputProps={{'aria-label': 'controlled'}}*/}
                            {/*    />*/}
                            {/*</FlexRow>*/}
                        </CardWrapper>

                        <CardWrapperRight>
                            <FlexCol style={{width: '100%', gap: 25}}>
                                <h4 style={{textAlign: 'center', color: "gray"}}>{t('basicInfo')}</h4>
                                <FlexRow style={{gap: 15}}>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        label={t('firstName')}
                                        value={userInfo?.name || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                    <TextField
                                        fullWidth
                                        id="lastName"
                                        label={t('lastName')}
                                        value={userInfo?.lastName || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                </FlexRow>
                                <FlexRow style={{gap: 15}}>
                                   <TextField
                                       fullWidth
                                       id="company"
                                       label={t('company')}
                                       value={userInfo?.company || ''}
                                       onChange={handleChange}
                                       variant="outlined"
                                   />
                                   <TextField
                                       fullWidth
                                       id="phone"
                                       label={t('phoneNumber')}
                                       value={userInfo?.phone || ''}
                                       onChange={handleChange}
                                       variant="outlined"
                                   />
                               </FlexRow>
                                <FlexRow style={{gap: 15}}>
                                    <Autocomplete
                                        fullWidth
                                        value={userInfo?.country || ''}
                                        options={countries}
                                        onChange={handleChangeCountry}
                                        getOptionLabel={(option) => option?.name || ''}
                                        style={{minWidth: 'auto'}}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label={t('country')}/>}
                                    />
                                    {userInfo?.country?.id == 4 ? <Autocomplete
                                            fullWidth
                                            value={userInfo?.city || ''}
                                            options={cities}
                                            onChange={handleChangeCity}
                                            getOptionLabel={(option) => option?.name || ''}
                                            style={{minWidth: 'auto'}}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('city')}/>}
                                        /> :
                                        <TextField
                                            fullWidth
                                            id="cityName"
                                            label={t('city')}
                                            onChange={handleChange}
                                            value={cityName || ''}
                                            variant="outlined"
                                        />
                                    }
                                </FlexRow>

                                <TextField
                                    id="about"
                                    label={t('aboutSelfText')}
                                    multiline
                                    maxRows={4}
                                    value={userInfo?.about || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                />

                                <h4 style={{textAlign: 'center', color: "gray"}}>{t('passwordChange')}</h4>
                                <FlexRow style={{gap: 15}}>
                                    <TextField
                                        fullWidth
                                        id="confirmOldPassword"
                                        label={t('confirmOldPassword')}
                                        value={passwordChange?.confirmOldPassword || ''}
                                        onChange={handleChangePassword}
                                        variant="outlined"
                                        type="password"
                                    />
                                    <TextField
                                        fullWidth
                                        id="newPassword"
                                        label={t('newPassword')}
                                        value={passwordChange?.newPassword || ''}
                                        onChange={handleChangePassword}
                                        variant="outlined"
                                        type="password"
                                    />
                                </FlexRow>
                                <FlexRow style={{gap: 15, marginLeft: "auto"}}  >
                                    <ButtonL onClick={() => handleSubmitPassword(passwordChange)}>{t('save')}</ButtonL>
                                </FlexRow>

                                {userInfo?.roleId !== 11 && <h4 style={{textAlign: 'center', color: "gray"}}>{t('specificInfo')}</h4>}
                                {userInfo?.roleId === 1 && <Individual userInfo={userInfo} setUserInfo={setUserInfo}/>}
                                {userInfo?.roleId === 2 && <Entrepreneur userInfo={userInfo} setUserInfo={setUserInfo} countries={countries}/>}
                                {userInfo?.roleId === 3 && <Investor userInfo={userInfo} setUserInfo={setUserInfo} />}
                                {userInfo?.roleId === 4 && <Broker userInfo={userInfo} setUserInfo={setUserInfo} />}
                                {userInfo?.roleId === 5 && <Mentor userInfo={userInfo} setUserInfo={setUserInfo} countries={countries}/>}
                                {userInfo?.roleId === 6 && <Dach userInfo={userInfo} setUserInfo={setUserInfo}/>}
                                {userInfo?.roleId === 7 && <RRA userInfo={userInfo} setUserInfo={setUserInfo}/>}
                                {userInfo?.roleId === 8 && <Mediji userInfo={userInfo} setUserInfo={setUserInfo} countries={countries}/>}
                                {userInfo?.roleId === 9 && <Pks userInfo={userInfo} setUserInfo={setUserInfo}/>}
                                {userInfo?.roleId === 10 && <Special userInfo={userInfo} setUserInfo={setUserInfo}/>}
                                {userInfo?.roleId === 12 && <Pksnew userInfo={userInfo} setUserInfo={setUserInfo}/>}
                            </FlexCol>
                        </CardWrapperRight>
                    </>}
                </CenteredRow>
                {loading ? <SpinnerWrapper><Spinner/></SpinnerWrapper> :
                    <CenteredRow style={{gap: '1rem', marginTop: '2rem'}}>
                        <ButtonL onClick={() => handleSubmit(userInfo)}>{t('save')}</ButtonL>
                    </CenteredRow>
                }
            </PageContainer>

        </CenteredCol>
    )
}

