import React from "react"
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {NormalText} from "../Text";

import styled from "styled-components";

const Container = styled.div`
    display: flex;
    width: 100%;

    @media only screen and (max-width: 600px) {
        height: 550px;
      }
`

const Image = styled.img`
    object-fit: cover;
    max-height: 400px;
    height: 100%;
`
export default function CaroseulComponent({img, object, index}) {
    return(
        <Carousel infiniteLoop useKeyboardArrows animationHandler="fade" swipeable={false} autoPlay showThumbs={false}>
            {Object.values(img).map((pic, i) => (
                <Container key={i}>
                    <Image src={pic && URL.createObjectURL(pic)} />
                    {/*{i === 0 && <NormalText className="legend">{object.pagePlaceLanguages[index]?.imageHoverText}</NormalText>}*/}
                    {/*{i === 1 && <NormalText className="legend">{object.pagePlaceLanguages[index]?.imageHoverText2}</NormalText>}*/}
                    {/*{i === 2 && <NormalText className="legend">{object.pagePlaceLanguages[index]?.imageHoverText3}</NormalText>}*/}

                </Container>
            ))}
        </Carousel>
        )

}
