import React from "react"
import styled from "styled-components";
import {FlexRow} from "../Flex";
import NoVideoImage from "../../assets/images/City/no_video.svg";
import {useTranslation} from "react-i18next";

const Section = styled.div`
  padding: 2rem 0;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Frame = styled.iframe`
    max-width: 640px;
    width: 100%;
    height: 480px;
    border: none;
     @media screen and (max-width: 768px) {
     display: flex;
     min-height:280px;
     height: 100%;
     }
`

const Heading = styled.h1`
  width: 100%;
  margin-bottom: 24px;
  font-size: 26px;
  line-height: 1.1;
  text-align: center;
  font-weight: 600;
  background-color: #15AFFA;
  color: #FFF;
  padding: 1rem 0 1rem 0;
`;

const NoDataImage = styled.img`
    width: 100%;
    height: auto;
`;

const NoDataContainer = styled(FlexRow)`
    height: 400px;
`;

export default function SectionSeven({object}) {
    const {t} = useTranslation();
    let iFrameUrl = null;
    try {
        new URL(object?.videoLink);
        iFrameUrl = object?.videoLink.replace("watch?v=", "embed/");
    } catch {
    }
    return (
        <Section>
            <Container>
                <Heading>{t('cityPageVideoHeader')}</Heading>
                {
                    iFrameUrl ? <Frame src={iFrameUrl}/> :
                        <NoDataContainer><NoDataImage src={NoVideoImage}/></NoDataContainer>
                }
            </Container>

        </Section>
    )
}
