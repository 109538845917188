import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import SlikaOriginal from '../../assets/images/pticaf_s.png';
import SlikaCompresed from '../../assets/images/pticaf_s-min.svg';
import LoginModal from "../LoginModal";
import RegistratonModal from "../RegistrationModal";
import {ButtonL, ButtonR} from "../Button";

import {useTranslation} from 'react-i18next';
import useProgressiveImg from "../ProgressiveImage";
import {useSelector} from "react-redux";
import SuccessfulModal from "../SuccessfulModal";

const Banner = styled.div`
 display:flex;
 
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
  min-height: calc(80vh); 

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
 
`
const ButtonWraper = styled.div`
margin-top: 25rem;
@media screen and (max-height: 600px) {
    margin-top: 15rem;
}
 display: flex;
  flex-direction:row; 
  align-items:center;
   justify-content:center;
    flex-wrap:wrap;
 
`

export default function BannerPage() {

    const {user} = useSelector((state) => state.user)
    const [showModalLogin, setShowModalLogin] = useState(false);
    const [showModalRegistration, setShowModalRegistration] = useState(false);


    const handleLogin = () => {
        setShowModalLogin(true);
    }

    const handleRegistration = () => {
        setShowModalRegistration(true);
    }


    const {t} = useTranslation();

    const [windowSize, setWindowSize] = useState({width: 0, height: 0});
    useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);
    const [src, {blur}] = useProgressiveImg(`${SlikaCompresed}`, `${SlikaOriginal}`);


    return (
        <>
            {showModalLogin && <LoginModal
                showModal={showModalLogin}
                setShowModal={setShowModalLogin}
            />}
            {showModalRegistration && <RegistratonModal
                showModalR={showModalRegistration}
                setShowModalR={setShowModalRegistration}
            />}
            <Banner style={{
                backgroundImage: `url(${src})`,
                filter: blur ? "blur(20px)" : "none",
                transition: blur ? "none" : "filter 0.3s ease-out"
            }}>
                {windowSize?.width < 740 &&
                <h1 style={{marginTop: '50px', fontSize: '48px', color: 'white'}}>{t('linkUp')}</h1>}
                {windowSize?.width >= 740 && <h1 style={{fontSize: '48px', color: 'white'}}>{t('linkUp')}</h1>}
                {!Boolean(user?.user) && <ButtonWraper>
                    <ButtonL m f onClick={handleLogin}> {t('login')}</ButtonL>
                    <ButtonR bg f onClick={handleRegistration}>{t('register')}</ButtonR>

                </ButtonWraper>}
            </Banner>
        </>
    )

}
