import {CenteredCol, CenteredRow, FlexRow} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {getAllSearchUsersByRoleId} from "../../services/api";
import {Spinner, SpinnerWrapper} from "../../components/Spinner";
import {useLocation} from "react-router-dom";
import UserCard from "./card";
import Sidebar from "../../components/Sidebar";
import {useTranslation} from "react-i18next";
import Pagination from "../../components/Pagination";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 4rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF;
    
    @media (max-width: 648px) {
        padding: 0.2rem;
    }
`

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function UsersDefaultPage() {
    let query = useQuery();
    const [allUsers, setAllUsers] = useState([{}])
    const [loadingUsers, setLoading] = useState(true)
    const [windowSize, setWindowSize] = useState(null);
    const {t} = useTranslation();

    const [filter, setFilter] = useState({
        rowPerPage: 10,
        sortBy: "createdAt,ASC",
    });
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });

    React.useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);
    let id = query.get('userType')
    let menuType = query.get('menuType')

    const handleClickPage = (event, value) => {
        setPage({...page, currentPage: parseInt(value) - 1});
    };

    useEffect(() => {
        let parsedFilter = `?page=${page.currentPage}&sort=${filter.sortBy}&size=${filter.rowPerPage}`
        getAllSearchUsersByRoleId(id,parsedFilter).then(response => {
            setAllUsers(response?.data)
            setPage({...page, totalPages: response?.data.totalPages, totalElements: response.data.totalElements})
            setLoading(false)
        }).catch(() => {
                setLoading(false)
            }
        )
    }, [ page.currentPage])

    return (
        <Container>
            {windowSize?.width < 740 && <Sidebar isMobile={true}/>}
            {loadingUsers ? <SpinnerWrapper><Spinner/></SpinnerWrapper> :

                <CenteredCol style={{margin: '2rem', backgroundColor: "#F7FAFD", padding: '1rem', borderRadius: '5px'}}>
                    <h1 style={{
                        marginTop: '1.5rem',
                        color: "#5c5c5c"
                    }}>{t('numberOfUsers')} {page.totalElements}</h1>
                    <CenteredRow style={{gap: '1rem', flexWrap: "wrap", marginTop: '2rem'}}>
                        {allUsers?.content.map(user => (
                            <UserCard user={user} key={user.id} id={id} menuType={menuType}/>
                        ))}
                    </CenteredRow>
                     <Pagination
                        totalPages={page.totalPages}
                        handleClickPage={handleClickPage}
                        currentPage={parseInt(page.currentPage) + 1}/>
                </CenteredCol>
            }
        </Container>
    )
}
