import * as React from 'react';
import {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import styled from "styled-components";
import {FlexRow} from "../../components/Flex";
import {useTranslation} from "react-i18next";
import {getIndexOfProjectLanguage, LANGUAGES} from "../../utils/language";
import {useSelector} from "react-redux";

const StyledH3 = styled.h3`
    margin-bottom: 1.5rem;
`;

const StyledRow = styled(FlexRow)`
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
`;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    maxHeight: '80%',
    overflow: 'auto',
    p: 4,
};

export default function ModalRRA({open, setOpen, project}) {

    const {user} = useSelector((state) => state.user)
    const handleClose = () => setOpen(false);
    const {t, i18n} = useTranslation();
    const [projectLanguageIndex, setProjectLanguageIndex] = useState(1);
    React.useEffect(() => {
        let index = getIndexOfProjectLanguage(project, getLanguageIndex());
        if (index === -1) {
            index = 1;
        }
        setProjectLanguageIndex(index)
    }, [i18n.language])

    function getLanguageIndex() {
        switch (i18n.language) {
            case "cir":
                return LANGUAGES.serbianCyr;
            case "lat":
                return LANGUAGES.serbian;
            case "en":
                return LANGUAGES.english;
            case "de":
                return LANGUAGES.german;
            default:
                return LANGUAGES.serbian;
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <StyledH3>{t('projectPreview.previw')}</StyledH3>
                        {Boolean(user) ?
                            <>
                                <StyledRow>
                                    <Typography>{t('rraForms.municipality')}</Typography>
                                    <Typography>{project?.city?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.investName')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0]?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.businessSectors')}</Typography>
                                    <Typography>{project?.businessActivity?.businessSector?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.groupInSector')}</Typography>
                                    <Typography>{project?.businessSector?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.investDescription')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0].investmentDescription}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.investValue')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0].estimatedValue}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.existingDocumentation')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0]?.documentationDescription}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.potencionalStatusOfInvestment')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0]?.ownershipStatus}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.rightOfUse')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0]?.rightOfUse}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.existingInfrastructure')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0]?.existingInfrastructure}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.trafficConnection')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0]?.trafficConnection}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('rraForms.otherInformation')}</Typography>
                                    <Typography>{project?.rraProjectLanguages[0]?.otherInformation}</Typography>
                                </StyledRow>
                            </>
                            :
                            <p>Molimo Vas priajvite se na Atlas kako bi ste videli detalje. Hvala!</p>}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
