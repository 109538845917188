import axios from "axios";
import {clearSession} from "../utils/session";

export const BASE_URL = "https://dijaspora.pks.rs:8080/";
export const WEBSOCKET_URL = BASE_URL + "websocket";


let tokens = {
    accessToken: null,
    refreshToken: null,
    user: null
};

export const customAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json; charset=UTF-8',
        'Accept-Language': '1'
    }
});

// Add a request interceptor
customAxios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

customAxios.interceptors.response.use(
    response => response,
    error => {
        console.error("eror", error)
        // Do something with response error
        if (typeof error === 'undefined') {
            // request cancelled
            // when backend server is not available at all
        } else if (typeof error.response === 'undefined') {
            // when request is timeout
        } else if (error.response.data.error === "Forbidden") {
            clearSession()
            window.location.reload()
        } else if (error.response.data.message === "Token is not valid!") {
            clearSession()
            window.location.reload()
        } else if (error.response.status === 403) {
            // if (!error.response.data.path === "/users/me")
            //     localStorage.clear()
        }

        return Promise.reject(error.response);
    }
);

export function loadAxiosData() {
    const tokensSerialized = localStorage.getItem('token')
    if (tokensSerialized) {
        tokens = {
            ...tokens,
            accessToken: tokensSerialized
        };
    }
    const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : undefined;
    if (user) {
        tokens = {
            ...tokens,
            user: user
        };
    }
}

loadAxiosData();
