import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import SlikaOriginal from '../../assets/images/search.jpg';
import SlikaCompresed from '../../assets/images/pticaf_s-min.svg';
import {useTranslation} from "react-i18next";
import useProgressiveImg from "../../components/ProgressiveImage";
import {CenteredCol, CenteredRow, FlexRow} from "../../components/Flex";
import {InputS} from "../../components/Input";
import SearchAutocomplete from "./components/autocomplete";
import {useHistory} from "react-router-dom";
import {ALL_SEARCH_RESULTS, EVENT_PAGE, PROJECT_PAGE, RRA_PAGE, USER_PAGE} from "../../config/routes";
import {getSearchPageNumbers} from "../../services/api";
import {useSnackbar} from "notistack";
import {Button} from "@mui/material";
import FilterButton from "./filter";


const Banner = styled.div`
 display:flex;
 
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
  min-height: calc(80vh); 

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

`
const FlexDivCol = styled(CenteredCol)`
    padding: 0.5rem;
    width: 170px;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    border-color: rgba(107,114,128, 0.05);
    transition-duration: .2s;
    :hover {
        background-color: rgba(255,255,255,0.05);
        cursor: pointer;
    }
`

const FlexDivRow = styled(FlexRow)`
    justify-content: center;
    flex-wrap: wrap;

    
`

const P = styled.span`
    font-size: ${(props) => props.fontSize ? props.fontSize : '24px'};
    color: #fff;
    
        @media screen and (max-height: 710px) {
        text-align: center;
}
`

export const SEARCH_TYPES = {
    tag: 1,
    user: 2,
    project: 3,
    rra: 4,
    event: 5
}

export default function Home() {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchInput, setSearchInput] = React.useState("");
    const searchRef = React.useRef();
    const {t, i18n} = useTranslation();
    const [src, {blur}] = useProgressiveImg(`${SlikaCompresed}`, `${SlikaOriginal}`);
    const [searchNumbers, setSearchNumber] = useState()
    const [filterParams, setFilterParams] = useState({
        cityIds: [],
        businessSectorIds: [],
        range: [],
    });
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        getSearchPageNumbers().then(response => setSearchNumber(response.data)).catch(() => enqueueSnackbar(t('searchPage.errorGetingNumbers'), {
            variant: 'error'
        }))
    }, []);

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    })

    function handleClickOutside(event) {
        if (anchorEl?.current && !anchorEl?.current?.contains(event.target)) {
            setAnchorEl(null);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onMenuClick = (menuType, id) => {
        if (menuType === 2) {
            history.push({
                pathname: USER_PAGE,
                search: "?menuType=" + menuType + "&id=" + id
            });
        } else if (menuType === 3) {
            history.push({
                pathname: PROJECT_PAGE,
                search: "?menuType=" + menuType + "&id=" + id,
                // state: {projectForPreview: value}
            })
        } else if (menuType === 4) {
            history.push({
                pathname: RRA_PAGE,
                search: "?menuType=" + menuType + "&id=" + id,
                // state: {projectForPreview: value}
            });
        } else if (menuType === 5) {
            history.push({
                pathname: EVENT_PAGE,
                search: "?menuType=" + menuType + "&id=" + id
            });
        }
    }
    const handleSearchInputChange = (event) => {
        setSearchInput(event?.target?.value);
        if (event?.target?.value.length >= 4) {
            setAnchorEl(searchRef.current)
        } else {
            setAnchorEl(null)
        }
    }

    const handleApplyFilter = (cityIds, sectorIds, roleTypes, range) => {

        setFilterParams({
            cityIds: cityIds,
            businessSectorIds: sectorIds,
            roleTypes: roleTypes,
            range: range
        })
    }

    const handleSearchClick = ()=>{
        let obj = {};
        obj.params={
            input: searchInput,
            cityIds: filterParams.cityIds,
            sectorIds: filterParams.businessSectorIds,
            range: filterParams.range,
        }
        history.push({
            pathname: ALL_SEARCH_RESULTS,
            state: obj
        })
    }

    return (
        <Banner style={{
            backgroundImage: `url(${src})`,
            filter: blur ? "blur(20px)" : "none",
            transition: blur ? "none" : "filter 0.3s ease-out"
        }}>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <div style={{
                gap: '4rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around'
            }}>
                <P fontSize="48px">{t('linkUp')}</P>
                <P>{t('exploreTheBusinessAtlasOfSerbiaAndTheDiaspora')}</P>
                <CenteredRow style={{width: '100%'}}>
                    <FilterButton handleApply={handleApplyFilter} searchInput={searchInput}/>
                    <InputS
                        style={{marginTop: 0, width: '80%'}}
                        ref={searchRef}
                        onChange={handleSearchInputChange}
                        marginTop="1.5rem" width="550px" type="text"
                        placeholder={t('searchPlaceholder')}/>
                    <Button variant="contained"
                            sx={{height: '45px'}}
                            onClick={handleSearchClick}>
                        {t("searchEnt")}
                    </Button>
                </CenteredRow>
                <FlexDivRow>
                    <FlexDivCol>
                        <div style={{minHeight: '45px', display: 'flex', textAlign: 'center'}}>
                            <P fontSize="16px">{t('investor').toUpperCase()}</P>
                        </div>

                        <P style={{fontSize: '36px'}}>{searchNumbers?.investors}</P>
                    </FlexDivCol>
                    <FlexDivCol>
                        <div style={{minHeight: '45px', display: 'flex', textAlign: 'center'}}>
                            <P style={{wordBreak: i18n.language === 'de' ? 'break-all' : 'normal'}} fontSize="16px">{t('investmentOpportunities').toUpperCase()}</P>
                        </div>
                        <P style={{fontSize: '36px'}}>{searchNumbers?.investOpportunities}</P>
                    </FlexDivCol>
                    <FlexDivCol>
                        <div style={{minHeight: '45px', display: 'flex', textAlign: 'center'}}>
                            <P fontSize="16px">{t('brokerAndRepresentatives').toUpperCase()}</P>
                        </div>
                        <P style={{fontSize: '36px'}}>{searchNumbers?.brokers}</P>
                    </FlexDivCol>
                    <FlexDivCol>
                        <div style={{minHeight: '45px', display: 'flex', textAlign: 'center'}}>
                            <P fontSize="16px">{t('msp').toUpperCase()}</P>
                        </div>
                        <P style={{fontSize: '36px'}}>{searchNumbers?.entrepreneurs}</P>
                    </FlexDivCol>
                    <FlexDivCol>
                        <div style={{minHeight: '45px', display: 'flex', textAlign: 'center'}}>
                            <P fontSize="16px">{t('mentor').toUpperCase()}</P>
                        </div>
                        <P style={{fontSize: '36px'}}>{searchNumbers?.mentors}</P>
                    </FlexDivCol>
                    <FlexDivCol>
                        <div style={{minHeight: '45px', display: 'flex', textAlign: 'center'}}>
                            <P fontSize="16px">{t('searchMentorship').toUpperCase()}</P>
                        </div>
                        <P style={{fontSize: '36px'}}>{searchNumbers?.mentored}</P>
                    </FlexDivCol>
                    <FlexDivCol>
                        <div style={{minHeight: '45px', display: 'flex', textAlign: 'center'}}>
                            <P fontSize="16px">{t('membership').toUpperCase()}</P>
                        </div>
                        <P style={{fontSize: '36px'}}>{searchNumbers?.participants}</P>
                    </FlexDivCol>
                </FlexDivRow>
            </div>
            <SearchAutocomplete
                cityIds={filterParams.cityIds}
                sectorIds={filterParams.businessSectorIds}
                range={filterParams.range}
                inputString={searchInput}
                handleClose={handleClose}
                anchorEl={anchorEl}
                width={searchRef?.current?.offsetWidth}
                onMenuClick={onMenuClick}/>
        </Banner>
    )
}
