import MessageCard from "../../components/MessageCard";
import React, {useEffect, useState} from "react";
import {CenteredRow, FlexCol} from "../../components/Flex";
import {getAllActiveNotifications, getAllUserPosts, insertNewPost} from "../../services/api";
import styled from "styled-components";
import {Spinner} from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import NewPost from "./components";
import {useSelector} from "react-redux";

const StyledSpinner = styled(Spinner)`
    top: auto;
    left: auto;
    bottom: auto;
`;

const StyledRow = styled(CenteredRow)`
    width: 100%;
    height: 500px;
`;

export default function Profile() {
    const [allPosts, setAllPosts] = useState([]);
    const {user} = useSelector((state) => state.user)
    const [postValue, setPostValue] = useState({
        title:"",
        description:"",
        isImportant:false
    });
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0
    });

    useEffect(() => {
        setLoading(true);
        let parsedPage = `?page=${page.currentPage}`
        getAllUserPosts(parsedPage).then(response => {
            setLoading(false);
            setAllPosts(response?.data?.content.sort((x, y) => {
                if (x?.isImportant)
                    return -1;
                if (y?.isImportant)
                    return 1;
                return -1

            }));
        }).catch(reason => {
            console.error("getAllNotifications", reason)
            setLoading(false);
        });
    }, [page.currentPage]);

    const handleClickPage = (event, value) => {
        setPage({...page, currentPage: parseInt(value) - 1});
    };

    const handleInputChange = (event) => {
        const {value,name} = event.target
        if(name === "isImportant"){
            setPostValue({...postValue,"isImportant":!postValue.isImportant})
        }else{
            setPostValue({...postValue,[name]:value})
        }
    }
    const sendNewPost = () => {
        if(postValue.description.length>1){
            insertNewPost(postValue).then(r => {
                let arr=[...allPosts]
                arr.unshift(r.data)
                setAllPosts(arr)
                setPostValue({
                    title:"",
                    description:"",
                    isImportant:false
                })
            }).catch(reason => console.error(reason))
        }
        else {

        }
    }

    return (
        <>
            {!Boolean.user && <NewPost postValue={postValue} sendNewPost={sendNewPost} handleInputChange={handleInputChange}/>}
            <FlexCol style={{width:'100%'}}>
                {loading ? <StyledRow>
                    <StyledSpinner/>
                </StyledRow> : allPosts?.map((value, index) => {
                    return (<MessageCard key={index} notification={value}/>)
                })}
                {allPosts.length > 10 && <Pagination
                    totalPages={page.totalPages}
                    handleClickPage={handleClickPage}
                    currentPage={parseInt(page.currentPage) + 1}/>}
            </FlexCol>
        </>
    )
}
