import React, {useContext, useEffect, useState} from "react"
import SectionOne from "../../components/RegionPageSections/SectionOne";
import styled from "styled-components";
import SectionTwo from "../../components/RegionPageSections/SectionTwo";
import SectionThree from "../../components/RegionPageSections/SectionThree";
import SectionFour from "../../components/RegionPageSections/SectionFour";
import {useHistory} from "react-router-dom";
import {LanguageContext} from "../../context/context";
import {Spinner, SpinnerWrapper} from "../../components/Spinner";
import {getPagePlaceBigRegionName, getPagePlaceSymbol} from "../../services/api";
import {REGIONS_PAGE} from "../../config/routes";

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 600;
  color: primary;
  text-align: center;
`;

const Emblem = styled.img`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 130px;
    background-color: #fff;   
`
export default function RegionPage() {
    const history = useHistory();
    const query = new URLSearchParams(window.location.search);
    const name = query.get('name')
    const lang = useContext(LanguageContext);
    const [pagePlaceObj, setPagePlaceObj] = useState()
    //let objState = useLocation()
    const [loading, setLoading] = useState(true)
    const [symbol, setSymbol] = useState()

    useEffect(() => {
        if (!name) {
            history.push(REGIONS_PAGE);
        } else {
            getPagePlaceBigRegionName(name).then(response => {
                setPagePlaceObj(response.data);
                setLoading(false)
            }).catch(() => {
                history.push(REGIONS_PAGE);
            });
        }
    }, [name])
    useEffect(() => {

        switch (lang[0]) {
            case 'lat':
                setIndex(0)
                setIndexName('reachTextDescriptionLat')
                break;
            case 'cir':
                setIndex(1)
                setIndexName('reachTextDescriptionCir')
                break;
            case 'en':
                setIndex(2)
                setIndexName('reachTextDescriptionEn')
                break;
            case 'de':
                setIndex(3)
                setIndexName('reachTextDescriptionDe')
                break;
            default:
                setIndex(0)
                break;
        }
        if (pagePlaceObj) {
            handleGetSymbol(pagePlaceObj.id);

        }
    }, [lang, pagePlaceObj])

    async function handleGetSymbol(id) {
        getPagePlaceSymbol(id)
            .then(response => {
                setSymbol(response?.data);
            })
            .catch(error => (
                console.log(error, 'errrrrrrrrrrrr')))
    }


    const [index, setIndex] = useState(0)
    const [indexName, setIndexName] = useState('reachTextDescriptionLat')

    return (
        <>

            {loading ? <SpinnerWrapper><Spinner/></SpinnerWrapper> :
                <>
                    <Emblem src={symbol && URL.createObjectURL(symbol)}/>
                    <Heading>{pagePlaceObj?.bigRegion?.name.toUpperCase()}</Heading>
                    <SectionOne object={pagePlaceObj} index={index}/>
                    <SectionTwo object={pagePlaceObj} index={index}/>
                    <SectionThree object={pagePlaceObj} index={index}/>
                    <SectionFour object={pagePlaceObj} index={index}/>
                </>
            }
        </>
    )
}
