import React, {useState} from "react";
import {NormalText} from "../Text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {TextArea} from "./stepThree";
import {getAllMentorTypes} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import styled from "styled-components";
import {useSnackbar} from "notistack";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    `
export default function MentorRegistration({user, changeUserParam, countries, businessActivities}) {
    const {t} = useTranslation();
    const [mentorTypes, setMentorTypes] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    React.useEffect(() => {
        getAllMentorTypes().then(response => {
            setMentorTypes(response?.data)
        }).catch(reason => {
            enqueueSnackbar((t("mentorGetError")), {
                variant: 'error'
            })
        });
    }, []);
    return (
        <>
            <NormalText>{t('registration.mentor.question1')}</NormalText>
            <Autocomplete
                multiple
                value={user?.countryIds}
                defaultValue={user?.countryIds}
                options={countries}
                onChange={(event, newValue) => {
                    changeUserParam("countryIds", newValue)
                }}
                getOptionLabel={(option) => option?.name}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
            />
            <NormalText>{t('registration.mentor.question2')}</NormalText>
            <TextArea value={user?.description} onChange={(e) => {
                changeUserParam("description", e?.target?.value)
            }} type="text"/>
            <NormalText>{t('registration.mentor.question3')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    native
                    value={user?.mentorTypeId || ''}
                    onChange={(e) => {
                        changeUserParam("mentorTypeId", e.target.value)
                    }
                    }>
                    {mentorTypes?.map((value, index) => (
                        <option key={index} value={value?.id}>{value?.name}</option>
                    ))}
                </Select>
            </FormControl>
            <NormalText>{t('registration.mentor.question4')}</NormalText>
            <Row>
            <Autocomplete
                multiple
                value={user?.businessActivityList}
                defaultValue={user?.businessActivityList}
                options={businessActivities}
                onChange={(event, newValue) => {
                    changeUserParam("businessActivityList", newValue)
                }}
                getOptionLabel={(option) => option?.name}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
            />
            <Tooltip title={t('clasificationToolTipText')} aria-label="add" style={{ height: "100%" }}>
                    <HelpIcon />
                </Tooltip>
            </Row>
            <NormalText>{t('registration.mentor.question5')}</NormalText>
            <TextArea value={user?.description1} onChange={(e) => {
                changeUserParam("description1", e?.target?.value)
            }} type="text"/>
            <NormalText>{t('registration.mentor.question6')}</NormalText>
            <TextArea value={user?.companyExamples} onChange={(e) => {
                changeUserParam("companyExamples", e?.target?.value)
            }} type="text"/>
        </>
    );
}