import faker from 'faker';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {formatDistanceToNow, set, sub} from 'date-fns';
import {Icon} from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import {useTranslation} from "react-i18next";


// material
import {alpha} from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Tooltip,
    Typography
} from '@mui/material';

// components
import MenuPopover from '../MenuPopover';
import {getAllActiveNotifications, getAllInactiveNotifications, readNotifications} from "../../services/api";
import {useSelector} from "react-redux";
import SockJS from "sockjs-client";
import {WEBSOCKET_URL} from "../../services/customAxios";
import {Stomp} from "@stomp/stompjs";

// ----------------------------------------------------------------------


const NOTIFICATIONS = [
    {
        id: faker.datatype.uuid(),
        title: 'Nova poruka od Marko Nikolić',
        description: 'Poslao sam Vam pdf kao po dogovoru',
        avatar: faker.internet.avatar(),
        type: 'chat_message',
        createdAt: set(new Date(), {hours: 10, minutes: 30}),
        isUnRead: true
    },
    {
        id: faker.datatype.uuid(),
        title: "Marina Davidović",
        description: 'vam je poslao/la zahtev za povezivanje',
        avatar: faker.internet.avatar(),
        type: 'connection',
        createdAt: sub(new Date(), {hours: 3, minutes: 30}),
        isUnRead: true
    },
    {
        id: faker.datatype.uuid(),
        title: 'Zahtev za povezivanje',
        description: 'od Danijela S.',
        avatar: faker.internet.avatar(),
        type: 'chat_message',
        createdAt: sub(new Date(), {days: 1, hours: 3, minutes: 30}),
        isUnRead: false
    },
    {
        id: faker.datatype.uuid(),
        title: 'Zahtev za povezivanje',
        description: 'od Nenad Marković',
        avatar: faker.internet.avatar(),
        type: 'connection',
        createdAt: sub(new Date(), {days: 2, hours: 3, minutes: 30}),
        isUnRead: false
    },
    {
        id: faker.datatype.uuid(),
        title: 'Zahtev za povezivanje',
        description: 'od Jana Milošević',
        avatar: faker.internet.avatar(),
        type: 'connection',
        createdAt: sub(new Date(), {days: 3, hours: 3, minutes: 30}),
        isUnRead: false
    }
];

function renderContent(notification) {

    const title = (
        <Typography variant="subtitle2">
            {`${notification.user.name} ${notification.user.lastName}`}
            <Typography component="span" variant="body2" sx={{color: 'text.secondary'}}>
                &nbsp; {notification.notificationType.message}
            </Typography>
        </Typography>
    );

    if (notification.type === 'connection') {
        return {
            avatar: <img alt={notification.title} src={notification.avatar}/>,
            title
        };
    }
    if (notification.type === 'mail') {
        return {
            avatar: <img alt={notification.title} src={notification.avatar}/>,
            title
        };
    }
    if (notification.type === 'chat_message') {
        return {
            avatar: <img alt={notification.title} src={notification.avatar}/>,
            title
        };
    }
    return {
        avatar: <img alt={notification.title} src={notification.avatar}/>,
        title
    };
}

NotificationItem.propTypes = {
    notification: PropTypes.object.isRequired
};

function NotificationItem({notification}) {
    const {avatar, title} = renderContent(notification);

    return (
        <ListItemButton
            to="#"
            disableGutters
            component={RouterLink}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...(!notification.isRead && {
                    bgcolor: 'action.selected'
                })
            }}
        >
            <ListItemAvatar>
                <Avatar sx={{objectFit: 'contain', bgcolor: 'background.neutral'}}>{avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.disabled'
                        }}
                    >
                        <Box component={Icon} icon={clockFill} sx={{mr: 0.5, width: 16, height: 16}}/>
                        {formatDistanceToNow(new Date(notification.createdAt))}
                    </Typography>
                }
            />
        </ListItemButton>
    );
}

let stompClient = null;

export default function NotificationsPopover() {

    const {t} = useTranslation();
    const {user} = useSelector((state) => state.user)
    let userId = user?.id;
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [activeNotifications, setActiveNotifications] = useState([]);
    const [inactiveNotifications, setInactiveNotifications] = useState([]);
    const totalUnRead = activeNotifications.filter((item) => item.isRead === false).length;
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0
    });

    useEffect(() => {
        let parsedPage = `?page=${page.currentPage}`
        getAllActiveNotifications(parsedPage).then(response => {
            setActiveNotifications(response.data.content)
        }).catch(reason => {

        })
    }, [page.currentPage])

    useEffect(() => {
        let parsedPage = `?page=${page.currentPage}`
        getAllInactiveNotifications(parsedPage).then(response => {
            setInactiveNotifications(response.data.content)
        }).catch(reason => {

        })
    }, [page.currentPage])

    useEffect(() => {
        const socket = new SockJS(WEBSOCKET_URL);
        stompClient = Stomp.over(socket);
        stompClient.connect({}, () => {
            stompClient.subscribe(`/user/${userId}/queue/notifications`, (response) => {
                let arr = [...activeNotifications];
                arr.push(JSON.parse(response.body));
                setActiveNotifications(arr)
            });
        });
    }, [userId])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMarkAllAsRead = () => {
        let arr = [];
        let readed = [...inactiveNotifications]
        activeNotifications.forEach((notification) => {
                arr.push(notification.id)
                readed.push({...notification, isRead: true});
            }
        );
        let obj = {
            notificationIds: arr
        }
        readNotifications(obj).then(_ => {
            setInactiveNotifications(readed);
            setActiveNotifications([])
        }).catch(response => {
            console.error("ERROR", response)
        })
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                size="large"
                color={open ? 'primary' : 'default'}
                onClick={handleOpen}
                sx={{
                    mr: 2,
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
                    })
                }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Icon icon={bellFill} width={20} height={20}/>
                </Badge>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{width: 360}}
            >
                <Box sx={{display: 'flex', alignItems: 'center', py: 2, px: 2.5}}>
                    <Box sx={{flexGrow: 1}}>
                        <Typography variant="subtitle1">{t('notifications')}</Typography>
                        <Typography variant="body2" sx={{color: 'text.secondary'}}>
                            {t('numberOfNotifications')}{totalUnRead}
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title=" Mark all as read">
                            <IconButton color="primary" onClick={handleMarkAllAsRead}>
                                <Icon icon={doneAllFill} width={20} height={20}/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                <Divider/>

                <Box sx={{height: {xs: 340, sm: 'auto'}}}>
                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                                {t('newNotifications')}
                            </ListSubheader>
                        }>
                        {activeNotifications.map((notification) => (
                            <NotificationItem key={notification.id} notification={notification}/>
                        ))}
                    </List>
                    {inactiveNotifications.length > 1 &&
                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                                {t('previousNotifications')}
                            </ListSubheader>
                        }>
                        {inactiveNotifications.map((notification) => (
                            <NotificationItem key={notification.id} notification={notification}/>
                        ))}
                    </List>}
                </Box>

                <Divider/>

                {inactiveNotifications.length > 1 &&
                <Box sx={{p: 1}}>
                    <Button fullWidth disableRipple component={RouterLink} to="#">
                        {t('seeMore')}
                    </Button>
                </Box>}
            </MenuPopover>
        </>
    );
}
