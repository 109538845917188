import * as React from 'react';
import {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import styled from "styled-components";
import {FlexRow} from "../../components/Flex";
import {useTranslation} from "react-i18next";
import {getIndexOfProjectLanguage, LANGUAGES} from "../../utils/language";
import {useSelector} from "react-redux";

const StyledH3 = styled.h3`
    margin-bottom: 1.5rem;
`;

const StyledRow = styled(FlexRow)`
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
`;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    maxHeight: '80%',
    overflow: 'auto',
    p: 4,
};

export default function TransitionsModal({open, setOpen, project}) {

    const {user} = useSelector((state) => state.user)
    const handleClose = () => setOpen(false);
    const {t, i18n} = useTranslation();
    const [projectLanguageIndex, setProjectLanguageIndex] = useState(localStorage.getItem("language") === null ? "lat" : localStorage.getItem("language"));

    React.useEffect(() => {
        let index = getIndexOfProjectLanguage(project, getLanguageIndex());
        if (index === -1) {
            index = 1;
        }
        setProjectLanguageIndex(index)
    }, [i18n.language])

    function getLanguageIndex() {
        switch (i18n.language) {
            case "cir":
                return LANGUAGES.serbianCyr;
            case "lat":
                return LANGUAGES.serbian;
            case "en":
                return LANGUAGES.english;
            case "de":
                return LANGUAGES.german;
            default:
                return LANGUAGES.serbian;
        }
    }

    function getTextForCountries() {
        let countries = "";
        for (let i = 0; i < project?.countries?.length; i++) {
            countries += project?.countries[i]?.name;
            if (i < project?.countries?.length - 1)
                countries += ", ";
        }
        return countries;
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {/*<Container>*/}
                        <StyledH3>{t('projectPreview.previw')}</StyledH3>
                        {Boolean(user) ?
                            <>
                                <StyledRow>
                                    <Typography>{t('projectPreview.nameAndSurname')}</Typography>
                                    <Typography>{project?.user?.name} {project?.user?.lastName}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.notes')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.note}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.projectName')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.municipality')}</Typography>
                                    <Typography>{project?.municipality?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.sectorOfActivity')}</Typography>
                                    <Typography>{project?.businessSector?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.projectGoal')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.projectGoal}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.projectDescription')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.description}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.realizationYear')}</Typography>
                                    <Typography>{project?.startYear}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.projectDuration')}</Typography>
                                    <Typography>{project?.duration}</Typography>
                                </StyledRow>
                                {/*<StyledRow>*/}
                                {/*    <Typography>{t('projectPreview.projectType')}</Typography>*/}
                                {/*    <Typography>*/}
                                {/*        {project.isPrivate === true ? t('projectPreview.private') : t('projectPreview.public')}</Typography>*/}
                                {/*</StyledRow>*/}
                                <StyledRow>
                                    <Typography>{t('projectPreview.projectValue')}</Typography>
                                    <Typography>{project?.totalValue}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.investingDescription')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.investDescription}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.financing')}</Typography>
                                    <Typography>{`${t('projectPreview.ownResources')} ${project?.ownFunds}%; ${t('projectPreview.otherResourcesDesc')}
                                    ${project?.amountOther}%; ${t('projectPreview.amountInvestor')} ${project?.amountInvestor}%`}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.ownResources')}</Typography>
                                    <Typography>{project?.ownFunds}%</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.otherResources')}</Typography>
                                    <Typography>{project?.amountOther}%</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.advantage')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.advantage}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.descriptionOfTheInternalMarket')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.marketDescription}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.currentPosition')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.position}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.targetGroup')}</Typography>
                                    <Typography>{project?.projectLanguages[0]?.targetGroup}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.exportMarket')}</Typography>
                                    <Typography>{getTextForCountries()}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.workingName')}</Typography>
                                    <Typography>{project?.firm?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.shortWorkingName')}</Typography>
                                    <Typography>{project?.firm?.shortName}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.JMBG')}</Typography>
                                    <Typography>{project?.firm?.identificationNumber}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.dateOfCreation')}</Typography>
                                    <Typography>{project?.firm?.establishmentDate}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.location')}</Typography>
                                    <Typography>{project?.firm?.headOffice}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.delationName')}</Typography>
                                    <Typography>{project?.firm?.businessActivity?.name}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.numOfEmployeers')}</Typography>
                                    <Typography>{project?.firm?.employeesNumber}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.wokringStandards')}</Typography>
                                    <Typography>{project?.firm?.standards}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.workingCertifications')}</Typography>
                                    <Typography>{project?.firm?.certificates}</Typography>
                                </StyledRow>
                                <StyledRow>
                                    <Typography>{t('projectPreview.webAddress')}</Typography>
                                    <Typography>{project?.firm?.webAddress}</Typography>
                                </StyledRow>
                                {/*</Container>*/}
                            </>
                            :
                            <p>{t('plsSignUpToSeeDetails')}</p>}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
