import React from 'react';
import styled from "styled-components";

import KmIcon from '../../assets/images/City/km.svg';
import PeoplesIcon from '../../assets/images/City/peoples.svg';
import SalaryIcon from '../../assets/images/City/salary.svg';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {useTranslation} from "react-i18next";


const Section = styled.div`
  padding: 2rem 0;
`
const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 3rem;
`;


const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


const Text = styled.p`
    text-align: justify;
    margin-top: 1rem;
    text-align: justify;
    text-justify: inter-word;
    color: #575757;
    
     @media screen and (max-width: 414px) {
        text-align: center;
     }
`;

const TextBold = styled.p`
    text-align: justify;
    font-weight: 600;
    text-justify: inter-word;
    color: #575757;
`;


const Image = styled.img`
    width: ${props => props.width ? props.width : '60px'};
    height: ${props => props.height ? props.height : '60px'};
    margin-right: 1rem;
    
     @media screen and (max-width: 494px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`
export default function SectionThree({object}) {
    const {t} = useTranslation();
    const [focus, setFocus] = React.useState(false);
    return(
        <Section>
            <Container>
                <InfoRow>
                        <InfoRow>

                            <Image src={KmIcon} alt="km" />
                            <InfoColumn>
                                <CountUp start={focus ? 0 : null} end={object?.area} duration={4} redraw={true} separator=".">
                                    {({countUpRef}) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) {
                                                setFocus(true);
                                            }
                                        }}>
                                            <TextBold ref={countUpRef}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <Text>{t('area')}<sup>2</sup></Text>
                            </InfoColumn>
                        </InfoRow>
                        <InfoRow>
                            <Image src={PeoplesIcon} alt="peoples" />
                            <InfoColumn>
                                <CountUp start={focus ? 0 : null} end={object?.numberOfCitizens} duration={4}
                                         redraw={true}  separator=".">
                                    {({countUpRef}) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) {
                                                setFocus(true);
                                            }
                                        }}>
                                            <TextBold ref={countUpRef}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <Text>{t('numberOfPopulation')}</Text>
                                </InfoColumn>
                        </InfoRow>
                        <InfoRow>
                            <Image src={SalaryIcon} alt="salary" />
                            <InfoColumn>
                                <CountUp start={focus ? 0 : null} end={object?.averageSalary} duration={4}
                                         redraw={true}  separator=".">
                                    {({countUpRef}) => (
                                        <VisibilitySensor onChange={(isVisible) => {
                                            if (isVisible) {
                                                setFocus(true);
                                            }
                                        }}>
                                            <TextBold ref={countUpRef}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                                <Text>{t('averageSalary')}</Text>
                            </InfoColumn>
                        </InfoRow>
                </InfoRow>
            </Container>

        </Section>
    )
}
