import styled from "styled-components";

export const TextArea = styled.textarea`
    padding: 15px 20px;
    background: #fff;
    color: #333;
    display: flex;
    font-size: 16px;
    width: auto;
    border: 1px solid #cfd1d0;
    resize: vertical;
    min-height: 90px;
    max-height: 250px;
    margin-bottom: 1.5rem;
`
