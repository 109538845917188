import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {Cards} from "./Text";
import {FlexRow} from "../../../components/Flex";
import CardComponents from "../ProjectDescriptionPage/CardComponent";
import React, {useState} from "react";
import FeedBack from "../../../components/FeedBack";

const Banner = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%;
  background-color: #243b85;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const StyledContainer = styled(FlexRow)`
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledH4 = styled.h4`
  cursor: pointer;
`;

export default function RraProjectDescription() {
    const {t} = useTranslation();
    const cardsText = Cards(t);
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('rraProjectDescription.title')}</h1>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginBottom: "5%"}}>
                <Paper elevation={3} style={{padding: "5%"}}>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('rraProjectDescription.paragraph1.text1')}
                        <b style={{color: '#243b85'}}>{t('rraProjectDescription.paragraph1.text2')}</b>
                        {t('rraProjectDescription.paragraph1.text3')}
                        <b style={{color: '#243b85'}}>{t('rraProjectDescription.paragraph1.text4')}</b>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('rraProjectDescription.paragraph2.text1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraProjectDescription.paragraph2.text2')}</b>
                        {t('rraProjectDescription.paragraph2.text3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraProjectDescription.paragraph3.text1')}</b>
                        {t('rraProjectDescription.paragraph3.text2')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('rraProjectDescription.paragraph4.text')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b onClick={() => setShowModalSendMail(true)} style={{color: '#243b85'}}>{t('rraProjectDescription.paragraph6.bold')}</b>
                        {t('rraProjectDescription.paragraph6.text')}

                        <a style={{textDecoration: "none"}} href={"/"}><StyledH4>{t('rraProjectDescription.paragraph7.bold')}</StyledH4></a>
                        <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                            {t('rraProjectDescription.paragraph5.text')}
                        </p>
                    </p>
                    <StyledContainer>
                        {cardsText?.map((card, index) => <CardComponents key={index} image={card.image} text={card.text}
                                                                         head={card.head}/>)}
                    </StyledContainer>
                    <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>
                </Paper>
            </Container>
        </div>

    )
}
