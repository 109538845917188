import React, {useEffect, useState} from "react";
import MobileSidebar from "./mobile";
import NormalSidebar from "./normal";

export default function Sidebar({handleMenuChange, selectedMenu, isFromStatic, isPublicRoute}) {

    const [showModalRegion, setShowModalRegion] = useState(false);
    const [windowSize, setWindowSize] = React.useState(null);
    const currentPath = window.location.pathname
    const handleModal = () => {
        setShowModalRegion(true);
    }

    useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    if(isFromStatic){
        if(windowSize?.width < 900){
            return <MobileSidebar handleMenuChange={handleMenuChange} isPublicRoute={isPublicRoute}/>
        }
        return <></>
    }
    return (
        <>
            {windowSize?.width < 900 ?
                <MobileSidebar handleMenuChange={handleMenuChange}/> :
                <NormalSidebar
                    setShowModalRegion={setShowModalRegion}
                    showModalRegion={showModalRegion}
                    handleModal={handleModal}
                    currentPath={currentPath}
                    handleMenuChange={handleMenuChange}
                    selectedMenu={selectedMenu}
                />}
        </>
    )

}
