import React from "react";
import FileChooserWithText from "../../../../../components/FileChooserWithText";
import {NormalText} from "../../../../../components/Text";
import {InputDiv, InputS} from "../../../../../components/Input";
import {FlexCol} from "../../../../../components/Flex";
import styled from "styled-components";

const Wrapper = styled(FlexCol)`
    
`;

export default function ImageChooserWithInput({chooserId, src, chooserText, loading, handleFileChange, text, inputId, inputValue, onInputChange}) {
    return (
        <Wrapper>
            <FileChooserWithText
                id={chooserId}
                src={src}
                text={chooserText}
                loading={loading}
                handleFileChange={handleFileChange}/>
            <NormalText>{text}</NormalText>
            <InputDiv>
                <InputS id={inputId}
                        value={inputValue}
                        onChange={onInputChange}/>
            </InputDiv>
        </Wrapper>
    );
}

export function ImageChooserWithoutInput({chooserId, src, chooserText, loading, handleFileChange}) {
    return (
        <Wrapper>
            <FileChooserWithText
                id={chooserId}
                src={src}
                text={chooserText}
                loading={loading}
                handleFileChange={handleFileChange}/>
        </Wrapper>
    );
}
