import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllBusinessActivities, getAllMentorTypes, getBrokerType} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Mentor({userInfo, setUserInfo, countries}) {
    const {t} = useTranslation();
    const [businessActivities, setBusinessActivities] = useState([]);
    const [mentorType, setMentorsType] = useState([]);

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChangeActivities = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState, businessActivities: newValue
        }))
    }

    const handleChangeMentorType = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState, mentorType: newValue
        }))
    }

    const handleChangeCountriesExport = (event, newValue) => {
        setUserInfo(prevState => ({
            ...prevState,
            countries: newValue
        }))
    }

    useEffect(() => {
        getAllBusinessActivities().then(response => {
            setBusinessActivities(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllMentorTypes().then(response => {
            setMentorsType(response.data)
        }).catch(error => {
            console.log(error, "BrokerTypeError")
        })
    }, []);

    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.mentor.question1')}</NormalText>
                <Autocomplete
                    multiple
                    id="countries"
                    options={countries}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(event, newValue) => {
                        handleChangeCountriesExport(event, newValue)
                    }}
                    defaultValue={userInfo?.countries}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                        />)}
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.mentor.question2')}</NormalText>
                <TextField
                    fullWidth
                    id="description"
                    multiline
                    maxRows={4}
                    value={userInfo?.description || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.mentor.question3')}</NormalText>
                <FormControl fullWidth variant="outlined">
                    <Select
                        native
                        id="mentorTypeId"
                        value={userInfo?.mentorTypeId || ''}
                        onChange={handleChange}>
                        {mentorType?.map((value, index) => (
                            <option key={index} value={value?.id}>{value?.name}</option>
                        ))}
                    </Select>
                </FormControl>
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.investor.question2')}</NormalText>
                <Autocomplete
                    multiple
                    value={userInfo?.businessActivities|| ''}
                    options={businessActivities}
                    onChange={(e, newValue) => {
                        handleChangeActivities(e, newValue)
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    style={{minWidth: 'auto'}}
                    renderInput={(params) => <TextField {...params} variant="outlined"/>}
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.mentor.question5')}</NormalText>
                <TextField
                    fullWidth
                    id="description1"
                    multiline
                    maxRows={4}
                    value={userInfo?.description1 || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.mentor.question6')}</NormalText>
                <TextField
                    fullWidth
                    id="companyExamples"
                    multiline
                    maxRows={4}
                    value={userInfo?.companyExamples || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

