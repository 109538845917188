import * as React from 'react';
import {useEffect} from 'react';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {getAllBusinessSector, getAllCities, getFilter} from "../../services/api";
import {useTranslation} from "react-i18next";
import {ALL_SEARCH_RESULTS} from "../../config/routes";
import {useHistory} from "react-router-dom";

const ITEM_HEIGHT = 78;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function valuetext(value) {
    return `${value}°C`;
}

export default function FilterButton({handleApply, searchInput}) {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <SearchDialog open={open} setOpen={setOpen} handleApply={handleApply} searchInput={searchInput}/>
            <Button onClick={handleClickOpen} sx={{height: '45px'}} variant="contained" startIcon={<FilterListIcon/>}>
                {t("filter")}
            </Button>
        </>

    );
}


function SearchDialog({open, setOpen,handleApply, searchInput}) {
    const {t} = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const allRroles =  [
        {id: 1, name: t('roles.individual')},
        {id: 2, name: t('roles.entrepreneur')},
        {id: 3, name: t('roles.investor')},
        {id: 4, name: t('roles.broker')},
        {id: 5, name: t('roles.mentor')},
        {id: 6, name: t('roles.dach')},
        {id: 7, name: t('roles.rra')},
        {id: 8, name: t('roles.media')},
        {id: 9, name: t('roles.pks')},
        {id: 10, name: t('roles.vulnerableGroup')},
        {id: 12, name: t('roles.privrednaKomora')},
        {id: 13, name: t('roles.enterprise')},
        {id: 14, name: t('roles.other')}
    ]

    const [value, setValue] = React.useState([0, 999999999999999999999999999999999999999]);
    const [sectors, setSectors] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    const [sectorName, setSectorName] = React.useState([]);
    const [cityName, setCityName] = React.useState([]);
    const [roleName, setRoleName] = React.useState([]);
    const [projectValue, setProjectValue] = React.useState();

    const handleChangeSector = (event) => {
        const {
            target: {value},
        } = event;

        setSectorName(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeCity = (event) => {
        const {
            target: {value},
        } = event;

        setCityName(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeRole = (event) => {
        const {
            target: {value},
        } = event;

        setRoleName(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApplyClick=()=>{
        handleApply(getIds(cities,cityName),getIds(sectors,sectorName),getIds(allRroles, roleName),value)
        handleClose();
    }

    const handleApplyAndGo = () => {
        let obj = {};
        obj.params={
            input: searchInput,
            cityIds: getIds(cities,cityName),
            sectorIds: getIds(sectors,sectorName),
            roleTypes: getIds(allRroles, roleName),
            range: value,
        }

        history.push({
            pathname: ALL_SEARCH_RESULTS,
            state: obj
        })
    }

    useEffect(() => {
        getAllBusinessSector().then(r => setSectors(r.data)).catch(e => console.log('nisu dohvaceni biznis sektori'))
        getAllCities().then(r => setCities(r.data)).catch(e => console.log('nisu dohvaceni gradovi'))
        getAllCities().then(r => setCities(r.data)).catch(e => console.log('nisu dohvaceni gradovi'))
        getFilter().then(r => setProjectValue(r.data)).catch(e => console.log('nisu dohvacene vrednosti projekta'))
    }, []);

    return (
        <div>
            <Dialog
                PaperProps={{
                    style: {
                        padding: 10
                    },
                }}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {t("detailSearch")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("bussinesSector")}
                    </DialogContentText>
                    <FormControl sx={{mt: 1, width: 300}}>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={sectorName}
                            onChange={handleChangeSector}
                            input={<OutlinedInput/>}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {sectors.map((sector) => (
                                <MenuItem key={sector.id} value={sector.name}>
                                    <Checkbox checked={sectorName.indexOf(sector.name) > -1}/>
                                    <ListItemText primary={sector.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        {t("cities")}
                    </DialogContentText>
                    <FormControl sx={{mt: 1, width: 300}}>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={cityName}
                            onChange={handleChangeCity}
                            input={<OutlinedInput/>}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {cities.map((city) => (
                                <MenuItem key={city.id} name={city.id} value={city.name}>
                                    <Checkbox checked={cityName.indexOf(city.name) > -1}/>
                                    <ListItemText primary={city.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        {t("users")}
                    </DialogContentText>
                    <FormControl sx={{mt: 1, width: 300}}>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={roleName}
                            onChange={handleChangeRole}
                            input={<OutlinedInput/>}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {allRroles.map((role) => (
                                <MenuItem key={role.id} name={role.id} value={role.name}>
                                    <Checkbox checked={roleName.indexOf(role.name) > -1}/>
                                    <ListItemText primary={role.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        {t("priceRange")}
                    </DialogContentText>
                    <Slider
                        getAriaLabel={() => 'Price range'}
                        value={value}
                        min={projectValue?.lowestValue}
                        step={1000}
                        max={projectValue?.biggestValue}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleApplyClick} autoFocus>
                        {t("applyFilter")}
                    </Button>
                    <Button onClick={handleApplyAndGo} autoFocus>
                        {t("applyFilterAndGo")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
function getIds(array,names){
    let ids=[];
    if(!array)
        return ids
    if(array.length===0)
        return ids
    if(!names)
        return ids
    if(names.length===0)
        return ids
    names.forEach(city=>{
        array.forEach(obj=>{
            if(city===obj.name)
                ids.push(obj.id)
        })
    })
    return ids;
}
