import {CenteredRow, FlexCol, FlexRow} from "../../components/Flex";
import {NormalText} from "../../components/Text";
import TextField from "@material-ui/core/TextField";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {CheckBoxS} from "../../components/Checkbox";

export default function Entrepreneur({userInfo, t, lng}) {

    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexRow style={{gap: 15}}>
                <TextField
                    fullWidth
                    id="company"
                    label={t('registration.entrepreneur.question')}
                    multiline
                    disabled={true}
                    maxRows={4}
                    value={userInfo?.company || ''}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    id="identificationNumber"
                    label={t('registration.entrepreneur.question1')}
                    multiline
                    maxRows={4}
                    disabled={true}
                    value={userInfo?.identificationNumber || ''}
                    variant="outlined"
                />
            </FlexRow>

            <FlexRow style={{gap: 15}}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        fullWidth
                        id="companyTypeId"
                        label={t('registration.entrepreneur.question1')}
                        multiline
                        maxRows={4}
                        disabled={true}
                        value={userInfo?.companyTypeId || ''}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        fullWidth
                        id="companySizeId"
                        label={t('registration.entrepreneur.question1')}
                        multiline
                        maxRows={4}
                        disabled={true}
                        value={userInfo?.companySizeId || ''}
                        variant="outlined"
                    />
                </FormControl>
            </FlexRow>


            <TextField
                fullWidth
                id="companySizeId"
                label={t('bussinesSector')}
                multiline
                maxRows={4}
                disabled={true}
                value={userInfo?.businessSector || ''}
                variant="outlined"
            />

            <NormalText style={{textAlign: 'center'}}>{t('interestedToConnect')}</NormalText>
            <CenteredRow style={{gap: 10}}>
                <CheckBoxS disabled={true} id="mentor" defaultChecked={userInfo?.mentor} type="checkbox"
                           disabled={true}/>
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS id="broker" defaultChecked={userInfo?.broker} type="checkbox"
                           disabled={true}/>
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS id="investor" defaultChecked={userInfo?.investor} type="checkbox"
                           disabled={true}/>
                <NormalText>{t('withInvestor')}</NormalText>
            </CenteredRow>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('poslovniPlanovi')}</NormalText>
                <TextField
                    fullWidth
                    id="plans"
                    multiline
                    maxRows={4}
                    value={userInfo?.plans || ''}
                    disabled={true}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.entrepreneur.question4')}</NormalText>
                <TextField
                    fullWidth
                    id="countries"
                    multiline
                    maxRows={4}
                    value={userInfo?.countries}
                    disabled={true}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText>{t('registration.entrepreneur.question5')}</NormalText>
                <TextField
                    fullWidth
                    id="exportChances"
                    multiline
                    maxRows={4}
                    value={userInfo?.exportChances || ''}
                    disabled={true}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}
