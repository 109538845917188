import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {getPdf, getProjectById} from "../../services/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CenteredCol, CenteredRow, FlexCol} from "../../components/Flex";
import {NormalText} from "../../components/Text";
import Button from "@mui/material/Button";
import ConnectModal from "../../pages/UserPage/connectModal";
import {ButtonL} from "../../components/Button";
import {useTranslation} from "react-i18next";
import TransitionsModal from "../UserPage/modalPreview";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import LanguagePdfSelect from "../../components/LanguagePdfSelect";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 4rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF;
    
    @media (max-width: 648px) {
        padding: 0.2rem;
    }
`
const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 300px;
    width: 400px;
    padding: 2rem;
    background-color: #fff;
    
    &:hover{
        cursor: pointer;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
    
     @media (max-width: 648px) {
        width: 100%;
        
    }    
`
const BootstrapButton = styled(Button)({
    width: '140px',
})

const BText = styled.p`
    font-weight: 700;
    padding: 0;
`

const Text = styled(NormalText)`
    width: 100%;
    line-height: 20px; //for not webkit browser
    max-height: 120px; //for not webkit browser
    overflow: hidden;
    height: 80px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ProjectPage() {
    let query = useQuery();
    const {t} = useTranslation();
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [project, setProject] = useState();
    const [open, setOpen] = useState(false);
    const [openConnect, setOpenConnect] = useState(false);
    const [pojectInfo, setPojectInfo] = useState()
    const [projectForDownload, setProjectForDownload] = useState()
    const [showLang, setShowLang] = useState()
    const {enqueueSnackbar} = useSnackbar();
    const {user} = useSelector((state) => state.user)

    const handleDownloadPdf = (lan) => {
        if (user) {
            getPdf(projectForDownload, lan).then((response) => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                const url = window.URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(url);
            }).catch(() => {
                enqueueSnackbar(t('pdfNotExist'), {
                    variant: 'error'
                });
            })
        } else {
            enqueueSnackbar(t('youHaveToBeLoggedIn'), {
                variant: 'error'
            });
        }
    }

    useEffect(() => {
        setLoadingProjects(true)
        getProjectById(query.get("id")).then(response => {
            setProject(response.data)
            setLoadingProjects(false)
        }).catch(error => {
            setLoadingProjects(false)
        })
    }, []);

    function handleConnect() {
        setOpenConnect(true)
    }

    return (
        <Container>
            <LanguagePdfSelect t={t} show={showLang} handlePreviewProjectClick={handleDownloadPdf}
                               handleClose={() => setShowLang(false)} />
            <ConnectModal openConnect={openConnect} setOpenConnect={setOpenConnect} project={project}/>
            <TransitionsModal open={open} setOpen={setOpen} project={pojectInfo}/>
            <CenteredCol>
                {loadingProjects ? <CircularProgress/> : <>
                    <h1>{project?.projectLanguages[0]?.name}</h1>
                    <h4>{project?.businessSector?.name}</h4>
                </>}
                {Boolean(user) && <ButtonL onClick={handleConnect} variant="contained">{t('linkUpButton')}</ButtonL>}
            </CenteredCol>

            <div style={{margin: '2rem', backgroundColor: "#F7FAFD", padding: '1rem', borderRadius: '5px'}}>
                <CenteredRow style={{gap: '1rem', flexWrap: "wrap"}}>
                    <Card>
                        <FlexCol style={{marginTop: '2rem'}}>
                            <CenteredRow><BText>{project?.projectLanguages[0]?.name}</BText></CenteredRow>
                            <Text style={{marginTop: '2rem'}}>{project?.projectLanguages[0]?.description}</Text>
                        </FlexCol>
                        <CenteredRow style={{gap: '0.5rem', marginTop: '1rem'}}>
                            <BootstrapButton
                                stlye={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                onClick={()=>{
                                    setProjectForDownload(project.id);
                                    setShowLang(true);
                                }} variant="contained">{t('details')}</BootstrapButton>
                        </CenteredRow>
                    </Card>
                </CenteredRow>
            </div>
        </Container>
    )
}
