import React from "react"
import {makeStyles} from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "10px",
        maxWidth: "350px"
    },
    title: {
        fontSize: 14,
    },
}));

export default function ContactPage({streat, address, phone, fax, email, website, head}) {
    const classes = useStyles();
    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardActionArea>
                    <CardContent>
                        <Typography variant="h4" className={classes.title} color="textSecondary" gutterBottom>
                            {head}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: '#243b85'}}>
                            {streat}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: '#243b85'}}>
                            {address}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: '#243b85'}}>
                            {phone}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: '#243b85'}}>
                            {fax}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: '#243b85'}}>
                            {email}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: '#243b85'}}>
                            {website}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    );
}