import styled, {keyframes} from 'styled-components'
import PrivrednaKomora from '../../assets/images/privrednakomora.svg'
import PrivrednaKomoraNem from '../../assets/images/privrednakomora_nem.PNG'
import PrivrednaKomoraEng from '../../assets/images/privrednakomora_eng.PNG'
import Austrian from '../../assets/images/ada.png'
import ICMPD from '../../assets/images/icmpd.svg'
import React from "react";

const Image = styled.img`
    //width: 175px;
    height: 60px;
`
const Container = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    background: #F9FAFB;
    justify-content: space-between;
    padding: 1.5rem 6em 1.5rem 6em;
    box-sizing: border-box;
    position: relative;
    //margin: 200px auto;
    overflow: hidden;
`

const moving = keyframes` 
  0% { left: 0; }
  100% { left: -20%; }
`

const ContainerInner = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  animation: ${moving} 20s linear infinite;
  &:hover{
    animation-play-state: paused;
  }
`
const Orb = styled.div`
    height: 90px;
    display: inline-block;
    margin: 0 24.7px;
    float: left;
    transition: all .2s ease-out;
    
    &:hover {
        transform: scale(1.2);
        opacity: .5;
        cursor: pointer;
    }
`

export default function Footer() {
    return (
        <>
            <Container>
                <ContainerInner>
                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomora} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomoraEng} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>

                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomoraNem} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomora} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomoraEng} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>

                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomoraNem} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomora} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomoraEng} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>

                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomoraNem} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomora} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomoraEng} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>

                    <Orb>
                        <a href="https://pks.rs/"><Image src={PrivrednaKomoraNem} alt="privredna-komora" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.entwicklung.at/en/"><Image src={Austrian} alt="austrian" /></a>
                    </Orb>
                    <Orb>
                        <a href="https://www.icmpd.org/"><Image src={ICMPD} alt="icmpd" /></a>
                    </Orb>
                </ContainerInner>
            </Container>
        </>
    )
}
