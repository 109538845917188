export function validateFirstStep(user, stringRole, enqueueSnackbar, t) {
    if (!RegExp(
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(user.email)) {
        enqueueSnackbar(t('invalidEmail'), {
            variant: 'error'
        });
        return false;
    }
    if (user.password == null || user.password?.length < 8) {
        enqueueSnackbar(t('password8Characters'), {
            variant: 'error'
        });
        return false;
    }
    if (user.password !== user?.repeatPassword) {
        enqueueSnackbar(t('passwordNotMatch'), {
            variant: 'error'
        });
        return false;
    }
    if (stringRole == null || stringRole?.length < 1) {
        enqueueSnackbar(t('selectAcountType'), {
            variant: 'error'
        });
        return false;
    }
    return true;
}

export function validateSecondStep(user, enqueueSnackbar, t) {
    if (user.city == null && (user.cityString == null || user?.cityString?.length < 1)) {
        enqueueSnackbar(t('cityRequired'), {
            variant: 'error'
        });
        return false;
    }else{
        if(user.city != null)
            user.cityString = user.city.name;
    }
    if (user.country === "" || user.country === null) {
        enqueueSnackbar(t('countryRequired'), {
            variant: 'error'
        });
        return false;
    } else {
        user.countryString = user.country.name;
        user.countryId = user.country.id;
    }
    if (user.name == null || user?.name?.length < 1) {
        enqueueSnackbar(t('firstNameRequired'), {
            variant: 'error'
        });
        return false;
    }
    if (user.lastName == null || user?.lastName?.length < 1) {
        enqueueSnackbar(t('lastNameRequired'), {
            variant: 'error'
        });
        return false;
    }
    if (user.company == null || user?.company?.length < 1 && user?.roleId !== 1) {
        enqueueSnackbar(t('companyNameRequired'), {
            variant: 'error'
        });
        return false;
    }
    return true;
}

export function validateThirdStep(user, enqueueSnackbar, t) {
    switch (user?.roleId) {
        case 1:
            if (user?.businessSectorId == null || user?.businessSectorId?.length < 1) {
                enqueueSnackbar(t('businessSectorRequired'), {
                    variant: 'error'
                });
                return false;
            }
            break;
        case 2:
            if (user?.countryIds == null || user?.countryIds?.length < 1) {
                enqueueSnackbar(t('marketRequired'), {
                    variant: 'error'
                });
                return false;
            }
            if (user?.companyTypeId == null || user?.companyTypeId?.length < 1) {
                enqueueSnackbar(t('firmTypeRequired'), {
                    variant: 'error'
                });
                return false;
            }
            if (user?.businessSectorId == null || user?.businessSectorId?.length < 1) {
                enqueueSnackbar(t('businessSectorRequired'), {
                    variant: 'error'
                });
                return false;
            }
            if (user?.companySizeId == null || user?.companySizeId?.length < 1) {
                enqueueSnackbar(t('firmSizeRequired'), {
                    variant: 'error'
                });
                return false;
            }
            break;
        case 3:
            if (user?.businessActivityList == null || user?.businessActivityList?.length < 1) {
                enqueueSnackbar(t('businessActivityInvestorRequired'), {
                        variant: 'error'
                    }
                )
                ;
                return false;
            }
            if (user?.investorBusinessSectors == null || user?.investorBusinessSectors?.length < 1) {
                enqueueSnackbar(t('businessSectorRequired'), {
                        variant: 'error'
                    }
                )
                ;
                return false;
            }
            if (user?.investmentsIds == null || user?.investmentsIds?.length < 1) {
                enqueueSnackbar(t('investmentsTypeRequired'), {
                        variant: 'error'
                    }
                )
                ;
                return false;
            }
            break;
        case 4:
            if (user?.businessActivityList == null || user?.businessActivityList?.length < 1) {
                enqueueSnackbar(t('businessSectorRequired'), {
                        variant: 'error'
                    }
                )
                ;
                return false;
            }
            break;
        case 5:
            if (user?.businessActivityList == null || user?.businessActivityList?.length < 1) {
                enqueueSnackbar(t('businessSectorRequired'), {
                        variant: 'error'
                    }
                )
                ;
                return false;
            }
            if (user?.countryIds == null || user?.countryIds?.length < 1) {
                enqueueSnackbar(t('countryRequired'), {
                        variant: 'error'
                    }
                )
                ;
                return false;
            }
            if (user?.mentorTypeId == null || user?.mentorTypeId?.length < 1) {
                enqueueSnackbar(t('sectorRequired'), {
                        variant: 'error'
                    }
                )
                ;
                return false;
            }
            break;
        case 6:
            if (user?.dachGroupId == null || user?.dachGroupId?.length < 1) {
                enqueueSnackbar(t('dachGroupRequired'), {
                        variant: 'error'
                    }
                );
                return false;
            }
            break;
        case 7:
            break
        case 8:
            if (user?.countryIds == null || user?.countryIds?.length < 1) {
                enqueueSnackbar(t('mediaCountriesRequired'), {
                        variant: 'error'
                    }
                );
                return false;
            }
            break;
        case 9:
            if (user?.pksGroupId == null || user?.pksGroupId?.length < 1) {
                enqueueSnackbar(t('dachGroupRequired'), {
                        variant: 'error'
                    }
                );
                return false;
            }
            break;
        case 10:
            if (user?.vulnerableGroupTypeId == null || user?.vulnerableGroupTypeId?.length < 1) {
                enqueueSnackbar(t('dachGroupRequired'), {
                        variant: 'error'
                    }
                );
                return false;
            }
            break;
        default:
            return true;
    }
    return true;
}
