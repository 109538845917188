import StepOneImage from "../../../assets/images/project_creation_page/1.png";
import StepTwoImage from "../../../assets/images/project_creation_page/2_1.png";
import StepThreeImage from "../../../assets/images/project_creation_page/3.png";
import StepFourImage from "../../../assets/images/project_creation_page/4.png";
import StepFiveImage from "../../../assets/images/project_creation_page/5.png";
import StepSixImage from "../../../assets/images/project_creation_page/6.png";
import StepSevenImage from "../../../assets/images/project_creation_page/7.png";
import StepEightImage from "../../../assets/images/project_creation_page/8.png";
import StepNineImage from "../../../assets/images/project_creation_page/9.png";

export const Cards = (t) => {
    return (
        [
            {
                image: StepOneImage,
                text: t('projectWizard.card1.text'),
                head: t('projectWizard.card1.header'),
            },
            {
                image: StepTwoImage,
                text: t('projectWizard.card2.text'),
                head: t('projectWizard.card2.header'),
            },
            {
                image: StepThreeImage,
                text: t('projectWizard.card3.text'),
                head: t('projectWizard.card3.header'),
            },
            {
                image: StepFourImage,
                text: t('projectWizard.card4.text'),
                head: t('projectWizard.card4.header'),
            },
            {
                image: StepFiveImage,
                text: t('projectWizard.card5.text'),
                head: t('projectWizard.card5.header'),
            },
            {
                image: StepSixImage,
                text: t('projectWizard.card6.text'),
                head: t('projectWizard.card6.header'),
            },
            {
                image: StepSevenImage,
                text: t('projectWizard.card7.text'),
                head: t('projectWizard.card7.header'),
            },
            {
                image: StepEightImage,
                text: t('projectWizard.card8.text'),
                head: t('projectWizard.card8.header'),
            },
            {
                image: StepNineImage,
                text: t('projectWizard.card9.text'),
                head: t('projectWizard.card9.header'),
            }
        ]
    )
}