import {CenteredCol, FlexCol} from "../../components/Flex";
import {NormalText} from "../../components/Text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {TextArea} from "../../components/RegistrationModal/stepThree";
import React, {useEffect, useState} from "react";
import {organizationUnitType} from "../../services/api";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function Pksnew({userInfo, setUserInfo}){
    const [pksGroups, setPksGroups] = useState([{}]);
    const {t} = useTranslation();

    const handleChangeOrganizationType = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState,
            organizationalUnitTypeId: newValue.id
        }))
    }

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    useEffect(() => {
        organizationUnitType().then(({data}) => {
            setPksGroups(data)
        }).catch(error => {
            console.log(error, 'error')
        })
    }, [])

    return(
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question1')}</NormalText>
                <FormControl fullWidth variant="outlined">
                    <Select
                        native
                        id="organizationalUnitTypeId"
                        value={userInfo?.organizationalUnitTypeId || ''}
                        onChange={handleChange}>
                        {pksGroups?.map((value, index) => (
                            <option key={index} value={parseInt(value?.id)}>{value?.name}</option>
                        ))}
                    </Select>
                </FormControl>
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question2')}</NormalText>
                <TextField
                    id="organizationalUnitName"
                    multiline
                    maxRows={4}
                    value={userInfo?.organizationalUnitName || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question3')}</NormalText>
                <TextField
                    id="existingExperiental"
                    multiline
                    maxRows={4}
                    value={userInfo?.existingExperiental || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question4')}</NormalText>
                <TextField
                    id="existingDesiredCooperation"
                    multiline
                    maxRows={4}
                    value={userInfo?.existingDesiredCooperation || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question5')}</NormalText>
                <TextField
                    id="leadingInvestors"
                    multiline
                    maxRows={4}
                    value={userInfo?.leadingInvestors || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}
