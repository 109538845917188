import React from "react";
import {BigText, NormalText} from "../Text";
import {InputDiv, InputS} from "../Input";
import {ButtonL} from "../Button";
import {useTranslation} from "react-i18next";

export default function ResetPasswordSection({setUsername, handleMailReset}) {
    const { t } = useTranslation();
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <BigText style={{marginBottom: '10rem'}}>{t('forgotPassword')}</BigText>
            </div>
            <NormalText>{t('email')}</NormalText>
            <InputDiv>
                <InputS
                    id="username"
                    label="username"
                    name="username"
                    autoComplete="username"
                    onChange={(e) => setUsername(e.target.value)}/>
            </InputDiv>
            <ButtonL width="100%" onClick={() => handleMailReset()}>{t('sendReactivationMail')}</ButtonL>
        </>
    );
}
