import React, {useState} from "react";
import {NormalText} from "../Text";
import {Row, TextArea} from "./stepThree";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {getAllVulnerableGroupTypes} from "../../services/api";
import {CheckBoxS} from "../Checkbox";
import {useTranslation} from "react-i18next";

export default function RanjiveGrupeRegistration({changeUserParam, user}) {
    const {t} = useTranslation();
    const [vulnerableGroupTypes, setVulnerableGroupTypes] = useState([]);
    React.useEffect(() => {
        getAllVulnerableGroupTypes().then(response => {
            setVulnerableGroupTypes(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, []);
    return (
        <>
            <NormalText>{t('registration.vulnerableGroups.question1')}</NormalText>
            <TextArea value={user?.associationName} onChange={(e) => {
                changeUserParam("associationName", e?.target?.value)
            }} type="text"/>
            <NormalText>{t('registration.vulnerableGroups.question3')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    native
                    value={user.pksGroupId}
                    onChange={(e) => {
                        changeUserParam("vulnerableGroupTypeId", e.target.value)
                    }}>
                    <option aria-label="None" value=""/>
                    {vulnerableGroupTypes?.map((value, index) => (
                        <option key={index} value={value?.id}>{value?.name}</option>
                    ))}
                </Select>
            </FormControl>
            <NormalText>{t('interestedToConnect')}</NormalText>
            <Row>
                <CheckBoxS value={user?.mentor} type="checkbox" onChange={() => {
                    changeUserParam("mentor", !user?.mentor)
                }}/>
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS value={user?.broker} type="checkbox" onChange={() => {
                    changeUserParam("broker", !user?.broker)
                }}/>
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS value={user?.investor} type="checkbox" onChange={() => {
                    changeUserParam("investor", !user?.investor)
                }}/>
                <NormalText>{t('withInvestor')}</NormalText>
            </Row>
            <NormalText>{t('poslovniPlanovi')}</NormalText>
            <TextArea value={user?.description} type="text" onChange={(e) => {
                changeUserParam("description", e.target.value)
            }}/>
        </>
    );
}
