import React, {useContext, useEffect, useState} from "react"
import styled from "styled-components";
import {getPagePlaceCity, getPagePlaceImage, getPagePlaceSymbol} from "../../services/api";
import {Spinner, SpinnerWrapper} from "../../components/Spinner";
import CaroseulComponent from "../../components/CaroseulComponent";
import {LanguageContext} from "../../context/context";
import SectionOne from "../../components/CityPageSections/SectionOne";
import SectionTwo from "../../components/CityPageSections/SectionTwo";
import SectionThree from "../../components/CityPageSections/SectionThree";
import SectionFour from "../../components/CityPageSections/SectionFour";
import SectionFive from "../../components/CityPageSections/SectionFive";
import SectionSix from "../../components/CityPageSections/SectionSix";
import SectionSeven from "../../components/CityPageSections/SectionSeven";
import {useHistory} from "react-router-dom";
import {CITIES_PAGE} from "../../config/routes";

const Emblem = styled.img`
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    height: 150px;
    z-index: 1;
    transform: translateY(50%); 
`

export default function CityPage() {
    const history = useHistory();
    const query = new URLSearchParams(window.location.search);
    const name = query.get('name')
    const lang = useContext(LanguageContext);
    const [pagePlaceObj, setPagePlaceObj] = useState()
    const [loading, setLoading] = useState(true)
    const [symbol, setSymbol] = useState()
    const [bannerImage, setBannerImage] = useState()
    const [haveImage, setHaveImage] = useState(false)
    useEffect(() => {
        if (!name) {
            history.push(CITIES_PAGE);
        } else {
            getPagePlaceCity(name).then(response => {
                setPagePlaceObj(response.data);
                setLoading(false)
            }).catch(() => {
                history.push(CITIES_PAGE);
            });
        }

    }, [name])

    useEffect(() => {

        switch (lang[0]) {
            case 'lat':
                setIndex(0)
                setIndexName('reachTextDescriptionLat')
                break;
            case 'cir':
                setIndex(1)
                setIndexName('reachTextDescriptionCir')
                break;
            case 'en':
                setIndex(2)
                setIndexName('reachTextDescriptionEn')
                break;
            case 'de':
                setIndex(3)
                setIndexName('reachTextDescriptionDe')
                break;
            default:
                setIndex(0)
                break;
        }
        if (pagePlaceObj) {
            handleGetSymbol(pagePlaceObj.id);

            if (pagePlaceObj?.placeImagePath?.length > 0) {
                handleBannerImage(pagePlaceObj.placeImagePath);
            }
            if (pagePlaceObj.placeImagePath2?.length > 0) {
                handleBannerImage2(pagePlaceObj.placeImagePath2);
            }
            if (pagePlaceObj.placeImagePath3?.length > 0) {
                handleBannerImage3(pagePlaceObj.placeImagePath3);

            }

        }
    }, [lang, pagePlaceObj])

    async function handleGetSymbol(id) {
        getPagePlaceSymbol(id)
            .then(response => {
                setSymbol(response?.data);
            })
            .catch(error => (
                console.error(error, 'getPagePlaceSymbol')))
    }

    async function handleBannerImage(image) {
        const data = {
            imagePath: image.replace("places_images/", "")
        }
        getPagePlaceImage(data)
            .then(response => {
                setBannerImage((prevState) => ({...prevState, img: response.data}));
                setHaveImage(true)
            })
            .catch(error => (
                console.error(error)))

    }

    async function handleBannerImage2(image) {
        const data2 = {
            imagePath: image.replace("places_images/", "")
        }
        getPagePlaceImage(data2)
            .then(response => {
                setBannerImage((prevState) => ({...prevState, img2: response.data}));
                setHaveImage(true)
            })
            .catch(error => (
                console.error(error)))

    }

    async function handleBannerImage3(image) {
        const data3 = {
            imagePath: image.replace("places_images/", "")
        }
        getPagePlaceImage(data3)
            .then(response => {
                setBannerImage((prevState) => ({...prevState, img3: response.data}));
                setHaveImage(true)
            })
            .catch(error => (
                console.error(error)))

    }

    const [index, setIndex] = useState(0)
    const [indexName, setIndexName] = useState('reachTextDescriptionLat')

    return (
        <div>
            {loading ? <SpinnerWrapper><Spinner/></SpinnerWrapper> :
                <div style={{position: "relative", margin: "auto"}}>
                    <Emblem src={symbol && URL.createObjectURL(symbol)}/>
                    {haveImage && <CaroseulComponent img={bannerImage} object={pagePlaceObj} index={index}/>}
                    <SectionOne object={pagePlaceObj} index={index}/>
                    <SectionTwo object={pagePlaceObj} index={index}/>
                    <SectionThree object={pagePlaceObj} index={index}/>
                    <SectionFour object={pagePlaceObj} index={index}/>
                    <SectionFive object={pagePlaceObj} index={index}/>
                    <SectionSix object={pagePlaceObj} index={index}/>
                    <SectionSeven object={pagePlaceObj} index={index}/>
                </div>
            }
        </div>

    )
}

