import {DialogTitle, InputAdornment} from "@material-ui/core";
import {Dialog, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {FlexRow} from "../../../style/Flex";
import {
    getAllBusinessActivities,
    getAllCities, getAllSuccessfulInvestments,
    getInvestImageAsync,
    sendSuccessfullInvestment,
    updateSuccessfullInvestment
} from "../../../services/api";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ImageChooserWithoutInput} from "../AdminAddPage/components/ImageChooser";
import {uploadSuccessInvestmentImage} from "../AdminAddPage/util";
import {ButtonS} from "../../../components/Button";
import {Spinner, SpinnerWrapper} from "../../../components/Spinner";
import {CenteredRow} from "../../../components/Flex";
import {setLanguageIndex} from "../../../helper/language";

const initialState = {
    amount: 0,
    businessActivityId: 0,
    cityId: 0,
    description: "",
    id: null,
    name: "",
    successfulInvestmentImagePath: null,
    successfulInvestmentImagePath2: null,
    successfulInvestmentImagePath3: null,
    videoLink: ""
}
export default function AddInvestmentDialog({setSuccessfulInvestmentList, successfulInvestmentsList, show, handleClose, result}) {
    const {t, i18n} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const [businessActivity, setBusinessActivity] = useState([])
    const [investObject, setInvestObject] = useState(initialState)
    const [cities, setCities] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [imageFile, setImageFile] = useState();
    const [imageFile1, setImageFile1] = useState();
    const [imageFile2, setImageFile2] = useState();
    const [imageLoading, setImageLoading] = useState(false);
    const [image1Loading, setImage1Loading] = useState(false);
    const [image2Loading, setImage2Loading] = useState(false);

    useEffect(() => {
        if (result) {
            setPagePlaceFromResponse(result)
            let index = setLanguageIndex(i18n.language)
            let obj = {...result}
            obj.name = result.successfulInvestmentLanguages[index].name
            obj.description = result.successfulInvestmentLanguages[index].description
            setInvestObject(obj)
        } else {
            setInvestObject(initialState)
        }
    }, [])
    useEffect(() => {
        getAllBusinessActivities().then(res => {
            setBusinessActivity(res.data)
        }).catch(err =>
            console.error(err))
        getAllCities().then(r => setCities(r.data)).catch(e => console.log('nisu dohvaceni gradovi'))
    }, [])

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setInvestObject({
            ...investObject,
            [name]: value
        })
    }

    const handleChangeCity = (e, newValue) => {
        if (newValue !== null) {
            setInvestObject(prevState => ({
                ...prevState,
                city: {...prevState.city, id: newValue.id, name: newValue.name}
            }))
            setInvestObject(prevState => ({...prevState, cityId: newValue.id}))
        }

    }

    async function getInvestImage(imagePath, onSuccess, onUnSuccess) {
        getInvestImageAsync(imagePath).then(value => {
            onSuccess(value?.data);
        }).catch(reason => {
            console.error("getInvestImage", reason)
            onUnSuccess();
        });
    }

    async function setPagePlaceFromResponse(data) {
        setInvestObject(data);
        setImageFile(undefined);
        setImageFile1(undefined);
        setImageFile2(undefined);
        if (data?.successfulInvestmentImagePath) {
            setImageLoading(true);
            await getInvestImage(data?.successfulInvestmentImagePath,
                (s) => {
                    setImageFile(s);
                    setImageLoading(false);
                },
                () => {
                    setImageLoading(false);
                });
        }
        if (data?.successfulInvestmentImagePath2) {
            setImage1Loading(true);
            await getInvestImage(data?.successfulInvestmentImagePath2,
                (s) => {
                    setImageFile1(s);
                    setImage1Loading(false);
                },
                () => {
                    setImage1Loading(false);
                });
        }
        if (data?.successfulInvestmentImagePath3) {
            setImage2Loading(true);
            await getInvestImage(data?.successfulInvestmentImagePath3,
                (s) => {
                    setImageFile2(s);
                    setImage2Loading(false);
                },
                () => {
                    setImage2Loading(false);
                }).then(_ => console.log("uspesnogetInvestImage"))
                .catch(e => {
                    console.err('getInvestImage')
                });
        }
    }

    const handleChangeBusinessActivity = (e, newValue) => {
        if (newValue !== null) {
            setInvestObject(prevState => ({
                ...prevState,
                businessActivity: {...prevState.businessActivity, id: newValue.id, name: newValue.name}
            }))
            setInvestObject(prevState => ({...prevState, businessActivityId: newValue.id}))
        }

    }

    const handleFileChange = (event, index) => {
        if (event.target && event.target.files[0]) {
            switch (index) {
                case 0:
                    setImageFile(event.target.files[0]);
                    break;
                case 1:
                    setImageFile1(event.target.files[0]);
                    break;
                case 2:
                    setImageFile2(event.target.files[0]);
                    break;
                default:
                    console.log("err")
                    break;
            }
        }
    }

    const handleSubmit = () => {
        setSubmitting(true)
        if (!result) {
            console.log('usao')
            sendSuccessfullInvestment(investObject).then(async response => {
                let successfulInvestmentId = response?.data?.id;
                if (imageFile) {
                    await uploadSuccessInvestmentImage(successfulInvestmentId, imageFile, enqueueSnackbar, 1)
                        .then(r => console.log(r)).catch(e => console.error(e));
                }
                if (imageFile1)
                    await uploadSuccessInvestmentImage(successfulInvestmentId, imageFile1, enqueueSnackbar, 2)
                        .then(r => console.log(r)).catch(e => console.error(e));
                ;
                if (imageFile2)
                    await uploadSuccessInvestmentImage(successfulInvestmentId, imageFile2, enqueueSnackbar, 3)
                        .then(r => console.log(r)).catch(e => console.error(e));
                ;
                setSubmitting(false);
                enqueueSnackbar(t("successAddedInvestment"), {
                    variant: 'success'
                });
                window.location.reload()
                handleClose()
            }).catch(e => {
                setSubmitting(false);
                console.error(e)
                handleClose()
            })
        } else {
            updateSuccessfullInvestment(investObject).then(async _ => {
                if (imageFile && imageFile?.name)
                    await uploadSuccessInvestmentImage(result.id, imageFile, enqueueSnackbar, 1);
                if (imageFile1 && imageFile1?.name)
                    await uploadSuccessInvestmentImage(result.id, imageFile1, enqueueSnackbar, 2);
                if (imageFile2 && imageFile2?.name)
                    await uploadSuccessInvestmentImage(result.id, imageFile2, enqueueSnackbar, 3);
                setSubmitting(false);
                enqueueSnackbar(t("successAddedInvestment"), {
                    variant: 'success'
                });
                const findId = successfulInvestmentsList.findIndex(item => {
                    return item.id === result.id
                })
                if(findId !== -1){
                    setSuccessfulInvestmentList[findId] = investObject
                }
                window.location.reload()
                handleClose()
            }).catch(reason => {
                console.error(reason)
                setSubmitting(false);
                handleClose()
            });
        }
    }

    return (
        <Dialog
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1000px",
                        maxHeight: '70%'
                    },
                },
            }}
            open={show}
            onClose={handleClose}>
            <DialogTitle>{t('page.successInvestment.create')}</DialogTitle>
            <FlexRow style={{
                padding: 10,
                justifyContent: 'center',
                alignItems: 'cemter'
            }}>

                <TextField
                    style={{marginRight: 10, minWidth: 300}}
                    label={t('page.successInvestment.nameOfInvestment')}
                    value={investObject.name}
                    name="name"
                    onChange={handleInputChange}/>

                <TextField
                    label={t('projectsView.financeInfo.amount')}
                    value={investObject.amount}
                    name="amount"
                    style={{
                        minWidth: 200,
                        marginRight: 10
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                    }}
                    onChange={handleInputChange}/>
                <Autocomplete
                    name="cityId"
                    options={cities}
                    value={investObject?.city || ''}
                    onChange={handleChangeCity}
                    getOptionLabel={(option) => option?.name || ''}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
                />
            </FlexRow>
            <FlexRow style={{
                padding: 10
            }}>
                <Autocomplete
                    name="businessActivityId"
                    options={businessActivity}
                    value={investObject?.businessActivity || ''}
                    onChange={handleChangeBusinessActivity}
                    getOptionLabel={(option) => option?.name || ''}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
                />
            </FlexRow>

            <FlexRow style={{
                padding: 10
            }}>
                <TextField
                    fullWidth
                    label={t('description')}
                    name="description"
                    value={investObject.description}
                    onChange={handleInputChange}/>
            </FlexRow>
            <FlexRow style={{
                padding: 10,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <ImageChooserWithoutInput
                    chooserId="imageFile"
                    src={imageFile}
                    chooserText={t('chooseImage')}
                    loading={imageLoading}
                    handleFileChange={(e) => handleFileChange(e, 0)}
                />
                <ImageChooserWithoutInput
                    chooserId="imageFile1"
                    src={imageFile1}
                    chooserText={t('chooseImage')}
                    loading={image1Loading}
                    handleFileChange={(e) => handleFileChange(e, 1)}
                />
                <ImageChooserWithoutInput
                    chooserId="imageFile2"
                    src={imageFile2}
                    chooserText={t('chooseImage')}
                    loading={image2Loading}
                    handleFileChange={(e) => handleFileChange(e, 2)}
                />
            </FlexRow>

            <FlexRow style={{
                padding: 10
            }}>
                <TextField
                    fullWidth
                    label={t('videoLink')}
                    value={investObject.videoLink}
                    name="videoLink"
                    onChange={handleInputChange}/>
            </FlexRow>
            <CenteredRow>
                <ButtonS style={{width: 100, marginBottom: 10}} onClick={handleSubmit}> {t('save')}</ButtonS>
                {submitting && <SpinnerWrapper><Spinner/></SpinnerWrapper>}
            </CenteredRow>
        </Dialog>
    );
}
