import React from "react";
import styled from "styled-components";

const Container = styled.label`
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 150px;
    border: 1px solid black;
    cursor: pointer;
`;

const Image = styled.img`
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 150px;
`;

const HiddenInput = styled.input`
    visibility: hidden;
    display: none;
`;

const StyledSpinner = styled.div`
    height: 60px;
    width: 60px;
    border: 3px solid transparent;
    border-top-color: #045394;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    &:before, &:after{
        content:'';
        position: absolute;
        border: 3px solid transparent;
        border-radius: 50%;
    }
    &:before{
        border-top-color: #de3333;
        top: -12px;
        left: -12px;
        right: -12px;
        bottom: -12px;
        animation: spin 3s linear infinite;
    }
    &:after{
        border-top-color: #ffffff;
        top: 6px;
        left: 6px;
        right: 6px;
        bottom: 6px;
        animation: spin 4s linear infinite;
    }
    @keyframes spin{
        0% {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}
    }
`;

export default function FileChooserWithText({id, src, text, handleFileChange, loading}) {
    if (src) {
        src = URL.createObjectURL(src)
    }
    return (
        <>
            <Container htmlFor={id}>
                {loading ? <StyledSpinner/> : (src ? <Image src={src} alt={text}/> : <p>{text}</p>)}
            </Container>
            <HiddenInput id={id} type="file" onChange={handleFileChange} accept="image/*"/>
        </>
    );
}