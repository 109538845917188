import {sidebar_menu} from "../../helper/sidebar";

export function getUserInfo() {
    return localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
}

export function getUserToken() {
    return localStorage.getItem("token")
        ? localStorage.getItem("token")
        : null;
}

export function isInProgressShowed() {
    return !!localStorage.getItem("inProgress");
}

export function setSessionLanguage(language) {
    localStorage.setItem("language", language)
}

export function getSavedLanguage() {
    return localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "lat";
}

export function clearSession() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
}

export function setPwaToInstalled() {
    localStorage.setItem("pwaInstalled", true);
}

export function isPwaInstalled() {
    return !!localStorage.getItem("pwaInstalled");
}

export function setMenuItem(item) {
    localStorage.setItem("menu_item", item);
}

export function getMenuItem() {
    return localStorage.getItem("menu_item")
        ? parseInt(localStorage.getItem("menu_item"))
        : sidebar_menu.home;
}