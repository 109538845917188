import {FlexCol, FlexRow} from "../../../components/Flex";
import {InputS} from "../../../components/Input";
import React from "react";
import {useTranslation} from "react-i18next";
import {Image} from "../../../components/Header";
import Sendicon from "../../../assets/send.svg";
import styled from "styled-components";
import EnergySavingsLeafOutlinedIcon from '@icons/material/LeafIcon';
import EnergySavingsLeafIcon from '@icons/material/LeafIcon';
import {Checkbox, FormControlLabel} from "@mui/material";

const StyledRow = styled(FlexRow)`
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 7px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
    padding: 1rem;
    background-color: white;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
`;

const StyledCol = styled(FlexCol)`
    width:100%;
    padding:10px;
`;

const TextArea = styled.textarea`
    width:100%;
    min-height:100px;
    resize: vertical;
    max-height:300px;
    margin-top:10px;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 0px;
    -webkit-appearance: none;
    color: #99A3BA;
    border: 1px solid #CDD9ED;
    background: #fff;
    transition: border .3s ease;
    &::placeholder {
        color: #8c8c8c;
    }
    &:focus {
        outline: none;
        border-color: #275EFE;
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
        
    }
`;

const Button=styled(FlexRow)`
    background-color:#243b85;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    cursor:pointer;
    height:40px;
    width:60px;
    justify-self: center;
    align-self: center;
`;

export default function NewPost({postValue, handleInputChange, sendNewPost}) {
    const {t} = useTranslation();
    return (
        <StyledRow style={{zIndex: 1}}>
            <StyledCol>
                <InputS name="title" value={postValue.title} placeholder={t('newPostTitlePlaceholder')} onChange={handleInputChange}/>
                <TextArea name="description" value={postValue.description} placeholder={t('newPostTextPlaceholder')} onChange={handleInputChange}/>

                <FlexRow>
                    <FormControlLabel style={{flex: 4}} control={<Checkbox name="isImportant" value={postValue.isImportant}
                                                         onChange={handleInputChange}
                                                       checked={postValue?.isImportant}
                                                         sx={{
                                                             color: "#545252",
                                                             '&.Mui-checked': {
                                                                 color: "#287428",
                                                             },
                                                         }}
                                                         icon={<EnergySavingsLeafOutlinedIcon />}
                                                         checkedIcon={<EnergySavingsLeafIcon />}
                    />} label={t('ekoPost')} />
                    <Button style={{flex: 1, margin: "10px 0px 0px 10px"}} onClick={sendNewPost} >
                        <Image style={{height: '20px'}} marginRight="0" src={Sendicon}/>
                    </Button>
                </FlexRow>

            </StyledCol>
        </StyledRow>
    );
}