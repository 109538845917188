import React, {useState} from 'react'
import {useHistory} from "react-router-dom";
import styled from 'styled-components'
// import Language from '../../assets/images/lang.svg'
import Language from '../../assets/images/ptica_no_background.png'
import LoginModal from "../LoginModal";
import RegistratonModal from "../RegistrationModal";
import FeedBack from "../FeedBack";
// import {useAuthDispatch} from "../../context";
import MobileMenu from './mobile';
import NormalMenu from './normal';
import {LOGIN_ROUTE, PROFILE_ROUTE, ROOT_PATH} from "../../config/routes";
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../redux/user";

export const Head = styled.div`
    background: 'white';
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 2rem;
    align-items: center;
    height: 10vh;
    
    @media only screen and (max-width: 1050px) {
        padding: 0;
    }
`
export const Image = styled.img`
    width: 25px;
    height: 25px;
    margin-right: ${props => props.marginRight ? props.marginRight : '1.5rem'};
    cursor: pointer;
`
export const WrapperCenter = styled.div`
        display:flex;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;
`
export const P = styled.p`
    font-weight: 500;
    color: grey;
    margin-right: 1.5rem;
    cursor: pointer;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    &:hover{
        background: gray;
    }
`

export const Button = styled.button`
    background: none;
    color: grey;
    font-weight: 700;
    width: auto;
    border: none;
    padding: 15px;
    
    &:hover{
        cursor: pointer;
        background-color: #dbdbdb;
        color: '#fff';
    }
`
export const Button2 = styled.button`
    color: white;
    background: ${({bg}) => (bg ? '#DC2626' : '#243b85')};
    margin-right: ${({m}) => (m ? '1.5rem' : '0px')};
    font-weight: ${({f}) => (f ? '400' : '700')};
    width: auto;
    border: none;
    padding: 15px;
    
    &:hover{
        cursor: pointer;
        background-color: #2f91ed;
    }
`

export default function Header({isPwa}) {
    const dispatch = useDispatch();
    const {user} = useSelector((state) => state.user)
    const history = useHistory();

    const [windowSize, setWindowSize] = React.useState(null);

    const handleImageClick = () => {
        if (user) {
            history.push(ROOT_PATH)
        } else {
            history.push(LOGIN_ROUTE);
        }
    }

    const handleProfileClick = () => {
        history.push(PROFILE_ROUTE);
    }

    const [showModalLogin, setShowModalLogin] = useState(false);
    const [showModalRegistration, setShowModalRegistration] = useState(false);
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    const handleLogin = () => {
        setShowModalLogin(true);
    }

    const handleRegistration = () => {
        setShowModalRegistration(true);
    }

    const handleLogout = () => {
        dispatch(logOut());
    }

    React.useEffect(() => {
        if (isPwa) {
            if (Boolean(user)) {
                setShowModalLogin(false)
            } else {
                setShowModalLogin(true)
            }
        }
        if (Boolean(user)) {
            setShowModalLogin(false)
            setShowModalRegistration(false)
            setShowModalSendMail(false)
        }
    }, [isPwa, user])

    React.useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    let forRender;
    if (windowSize?.width < 740) {
        forRender = <MobileMenu
            setShowModalSendMail={setShowModalSendMail}
            user={user}
            language={Language}
            handleProfileClick={handleProfileClick}
            handleImageClick={handleImageClick}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
            handleRegistration={handleRegistration}/>
    } else {
        forRender = <NormalMenu
            setShowModalSendMail={setShowModalSendMail}
            user={user}
            Language={Language}
            handleProfileClick={handleProfileClick}
            handleImageClick={handleImageClick}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
            handleRegistration={handleRegistration}/>
    }

    return (
        <>
            <LoginModal
                //loading={modalBtnLoading}
                showModal={showModalLogin}
                setShowModal={setShowModalLogin}
            />
            <RegistratonModal
                showModalR={showModalRegistration}
                setShowModalR={setShowModalRegistration}
                // setState={setState}
                // state={state}
            />
            <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>
            {forRender}
        </>
    )
}

