import React from "react"
import styled from "styled-components";
import NoMapImage from "./../../assets/images/City/no_map.svg"
import {FlexRow} from "../Flex";

const Section = styled.div`
  padding: 2rem 0;
`
const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  `;

const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

const Frame = styled.iframe`
    width: 100%;
    height: 500px;
    padding: 2rem;
    border: none;
    @media screen and (max-width: 768px) {
        padding: 0rem;
    }
`
const TextWrapper = styled.div`
    max-width: 540px;
    height: 450px;
    padding: 2rem;
    border: 1px solid #E9E9E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    overflow: auto;
 
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    padding-bottom: 65px;
  }
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 26px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#f7f8fa' : '#1c2237')};
`;

const Text = styled.p`
    text-align: justify;
    text-justify: inter-word;
    color: #575757;
`

const NoDataImage = styled.img`
    width: 100%;
    height: auto;
`;

const NoDataContainer = styled(FlexRow)`
    height: 400px;
`;

export default function SectionOne({object, index}) {
    let iFrameUrl = null;
    try {
        iFrameUrl = new URL(object?.googleMapsIFrame);
    } catch {
    }
    return (
        <Section>
            <Container>
                <InfoRow>
                    <InfoColumn>
                        <TextWrapper>
                            <Heading>{object?.city?.name}</Heading>
                            <Text dangerouslySetInnerHTML={{__html: object.pagePlaceLanguages[index]?.cityOrDistrictDescription}} />
                        </TextWrapper>
                    </InfoColumn>
                    <InfoColumn>
                        {
                            iFrameUrl ? <Frame src={iFrameUrl}/> :
                                <NoDataContainer><NoDataImage src={NoMapImage}/></NoDataContainer>
                        }
                    </InfoColumn>
                </InfoRow>
            </Container>
        </Section>
    )
}
