import React from "react";
import {NormalText} from "../Text";
import {TextArea} from "./stepThree";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useTranslation} from "react-i18next";
import {InputS} from "../Input";

export default function MedijiRegistration({user, changeUserParam, countries}) {
    const {t} = useTranslation();
    return (
        <>
            <NormalText>{t('registration.media.question1')}</NormalText>
            <Autocomplete
                multiple
                value={user?.countryIds}
                defaultValue={user?.countryIds}
                options={countries}
                onChange={(event, newValue) => {
                    changeUserParam("countryIds", newValue)
                }}
                getOptionLabel={(option) => option?.name}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
            />
            <NormalText>{t('registration.media.question2')}</NormalText>
            <InputS min={0} value={user?.followersNumber} type="number" onChange={(e) => {
                changeUserParam("followersNumber", e.target.value)
            }}/>
            <NormalText>{t('registration.media.question3')}</NormalText>
            <TextArea value={user?.explanation} type="text" onChange={(e) => {
                changeUserParam("explanation", e.target.value)
            }}/>
            <NormalText>{t('registration.media.question4')}</NormalText>
            <TextArea value={user?.showExample} type="text" onChange={(e) => {
                changeUserParam("showExample", e.target.value)
            }}/>
        </>
    );
}