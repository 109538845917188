import {FlexCol, FlexRow, FlexRowSpace} from "../../components/Flex";
import {Box, CircularProgress} from "@mui/material";
import NoImage from "../../assets/images/noImage.svg";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {NormalText} from "../../components/Text";
import Button from "@mui/material/Button";
import {getInvestImageAsync} from "../../services/api";
import Label from "../admin/UsersApprove/Label";
import {useTranslation} from "react-i18next";
import {ButtonL} from "../../style/Button";
import {CenteredRow, Flex} from "../../style/Flex";

export default function InvestmentCard({invest, setOpen, setInvestDetails}) {
    const {t} = useTranslation()
    const [loadingImage, setLoadingImage] = useState(false);
    const [investmentImages, setInvestImages] = useState();

    useEffect(() => {

        if (invest?.successfulInvestmentImagePath) {
            getInvestImage()
        }
    }, [])


    async function getInvestImage() {
        setLoadingImage(true)
        getInvestImageAsync(invest.successfulInvestmentImagePath).then(value => {
            if(value.status === 200){
                setInvestImages(URL.createObjectURL(value.data))
                setLoadingImage(false)
            } else {
                setInvestImages(undefined)
                setLoadingImage(false)
            }
        }).catch(reason => {
            setLoadingImage(false)
        });
    }

    const handleDetails = () => {
        setOpen(true)
        setInvestDetails(invest)
    }
    return (
        <Card>
            <FlexCol style={{marginTop: '1rem'}}>
                {loadingImage ? <CircularProgress/>
                    : <FlexRow>
                        <ProfileImage src={investmentImages !== undefined ? investmentImages : NoImage}/>
                        <FlexCol style={{flex: 1, width: '100%'}}>
                            <FlexRowSpace>
                                <BText style={{alignSelf: 'center'}}>{invest.city.name}</BText>
                                <Label
                                    style={{marginTop: -2}}
                                    variant="ghost"
                                    color={(invest?.status === 'A' && 'success') || 'error'}>
                                    {(invest?.status === 'A' && t('active'))
                                    || t('passive')}
                                </Label>
                            </FlexRowSpace>
                            <Text style={{alignSelf: 'center'}}>{t('amount')}: {invest?.amount} EUR</Text>
                        </FlexCol>
                    </FlexRow>
                }
                <Box sx={{height: 80}}>
                    <Text style={{marginTop: '2rem', alignSelf: 'center'}}>{invest.businessActivity.name}</Text>
                </Box>
                <CenteredRow>
                    <Button sx={{maxWidth: 90}} variant="contained" onClick={handleDetails}>
                        <Text style={{fontSize: 11, color: 'white', margin: 0, padding: 0}}>{t('details')}</Text>
                        </Button>
                </CenteredRow>
            </FlexCol>
        </Card>
    )
}

const ProfileImage = styled.img`
  margin-right: 10px;
  border-radius: 5px;
  height: 75px;
  width: 75px;
  object-fit: cover;
  border: 1px solid lightGrey;
`

const Card = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 250px;
  width: 350px;
  padding: 1rem;
  background-color: #fff;

  @media (max-width: 648px) {
    width: 100%;

  }
`


const BText = styled.p`
  font-weight: 700;
  padding: 0;
`

const Text = styled(NormalText)`
  width: 100%;
  line-height: 20px; //for not webkit browser
  max-height: 40px; //for not webkit browser
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
