import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Section = styled.div`
  padding: 2rem 0;
  background-color: rgb(21,175,250, 0.1);
`
const Container = styled.div`
  z-index: 1;
  width: 100%;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
const InfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;


const Text = styled.p`
    color: #575757;
    text-align: justify;

`;


const Heading = styled.h1`
  margin-bottom: 24px;
  text-align: center;
  font-size: 26px;
  line-height: 1.1;
  font-weight: 600;
  color: #15AFFA;
`;

const Card = styled.div`
    width: 250px;
    height: 300px;
    background: #FFF;
    margin: 1rem;
    overflow: auto;
    border-radius: 5px;
    border: 0.2px solid #E9E9E9;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    padding: 1rem 1rem 1rem 1rem;
`

export default function SectionFour({object, index}) {
    const {t} = useTranslation();
    return(
        <Section>
            <Container>
                <Heading>{t('generalInvestments').toUpperCase()}</Heading>
                <InfoRow>
                    {object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage && <Card>
                        <Text style={{textAlign: 'initial'}}
                              dangerouslySetInnerHTML={{__html: object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage}}/>
                    </Card>}
                    {object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage2 && <Card>
                        <Text style={{textAlign: 'initial'}}
                              dangerouslySetInnerHTML={{__html: object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage2}}/>
                    </Card>}
                    {object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage3 && <Card>
                        <Text style={{textAlign: 'initial'}}
                              dangerouslySetInnerHTML={{__html: object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage3}}/>
                    </Card>}
                    {object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage4 &&<Card>
                        <Text style={{textAlign: 'initial'}}
                              dangerouslySetInnerHTML={{__html: object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage4}}/>
                    </Card> }
                    {object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage5 &&<Card>
                        <Text style={{textAlign: 'initial'}}
                              dangerouslySetInnerHTML={{__html: object.pagePlaceLanguages[index]?.reachTextInvestmentAdvantage5}}/>
                    </Card> }

                </InfoRow>
            </Container>
        </Section>
    )
}
