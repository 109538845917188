import {CenteredRow, FlexCol, FlexRow} from "../../components/Flex";
import {NormalText} from "../../components/Text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getAllBusinessSector, getAllCompanySizes, getAllCompanyTypes} from "../../services/api";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {CheckBoxS} from "../../components/Checkbox";

export default function Entrepreneur({userInfo, setUserInfo, countries}) {
    const {t} = useTranslation();
    const [companyTypes, setCompanyTypes] = useState([]);
    const [companySizes, setCompanySizes] = useState([]);
    const [businessSectors, setBusinessSectors] = useState([]);

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChangeCountriesExport = (event, newValue) => {
        setUserInfo(prevState => ({
            ...prevState,
            countries: newValue
        }))
    }

    const handleChangeBusinessSector = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState, businessSector: newValue
        }))
    }

    const handleChangeCheckBox = (e) => {
        const {id, checked} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: checked
        }))
    }

    useEffect(() => {
        getAllCompanyTypes().then(response => {
            setCompanyTypes(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllCompanySizes().then(response => {
            setCompanySizes(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllBusinessSector().then(response => {
            setBusinessSectors(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, []);

    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexRow style={{gap: 15}}>
                <TextField
                    fullWidth
                    id="company"
                    label={t('registration.entrepreneur.question')}
                    multiline
                    maxRows={4}
                    value={userInfo?.company || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    id="identificationNumber"
                    label={t('registration.entrepreneur.question1')}
                    multiline
                    maxRows={4}
                    value={userInfo?.identificationNumber || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexRow>

            <FlexRow style={{gap: 15}}>
                <FormControl fullWidth variant="outlined">
                    {/*<InputLabel>{t('registration.entrepreneur.question2')}</InputLabel>*/}
                    <Select
                        native
                        id="companyTypeId"
                        value={userInfo?.companyTypeId}
                        onChange={handleChange}>
                        {companyTypes?.map((value, index) => (
                            <option key={index} value={value?.id}>{value?.name}</option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                    {/*<InputLabel>{t('registration.entrepreneur.question3')}</InputLabel>*/}
                    <Select
                        native
                        id="companySizeId"
                        value={userInfo?.companySizeId}
                        onChange={handleChange}>
                        {companySizes?.map((value, index) => (
                            <option key={index} value={value?.id}>{value?.name}</option>
                        ))}
                    </Select>
                </FormControl>
            </FlexRow>


            <Autocomplete
                value={userInfo?.businessSector || ''}
                options={businessSectors}
                onChange={(e, newValue) => {
                    handleChangeBusinessSector(e, newValue)
                }}
                getOptionLabel={(option) => option?.name || ''}
                style={{minWidth: 'auto'}}
                renderInput={(params) => <TextField {...params} variant="outlined" label={t('bussinesSector')}/>}
            />

            <NormalText style={{textAlign: 'center'}}>{t('interestedToConnect')}</NormalText>
            <CenteredRow style={{gap: 10}}>
                <CheckBoxS id="mentor" defaultChecked={userInfo?.mentor} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS id="broker" defaultChecked={userInfo?.broker} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS id="investor" defaultChecked={userInfo?.investor} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withInvestor')}</NormalText>
            </CenteredRow>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('poslovniPlanovi')}</NormalText>
                <TextField
                    fullWidth
                    id="plans"
                    multiline
                    maxRows={4}
                    value={userInfo?.plans || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.entrepreneur.question4')}</NormalText>
                <Autocomplete
                    multiple
                    id="countries"
                    options={countries}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(event, newValue) => {
                        handleChangeCountriesExport(event, newValue)
                    }}
                    defaultValue={userInfo?.countries}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                        />)}
                />
            </FlexCol>
            <FlexCol>
                <NormalText>{t('registration.entrepreneur.question5')}</NormalText>
                <TextField
                    fullWidth
                    id="exportChances"
                    multiline
                    maxRows={4}
                    value={userInfo?.exportChances || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}
// nazivfire, maticnibroj tippravnog lica, velicina predujzeca, poslovni sektor, da li ste zainteresoavni za pvoesizavanje, navedite ukratko, navedite trziste, da li je vase preduzece
