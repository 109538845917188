import React, {useState} from "react";
import {CenteredCol} from "../../../../../components/Flex";
import {Hr, KeyWordWrapper, StyledCol, Title} from "../../../style";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import {postParams} from "../../../../../services/api";
import {SEARCH_TYPES} from "../../../index";

export default function UsersSection({onMenuClick, inputString, cityIds}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    React.useEffect(() => {
        setLoading(true);
        let data = {}
        data.type = inputString;
        if (cityIds.length > 0) {
            data.cityIds = cityIds
        }
        postParams(data).then(response => {
                setResults(response.data)
                setLoading(false)
            }
        ).catch(error => {
            console.log(error, 'getParams')
            setLoading(false)
        })
    }, [inputString, cityIds])

    if (loading) {
        return (
            <CenteredCol>
                <CircularProgress/>
            </CenteredCol>
        );
    }

    if (results?.length === 0)
        return <></>;

    return (
        <StyledCol>
            <Title>{t('searchAutocomplete.users')}</Title>
            <Hr/>
            {results?.map((value, index) => {
                if (value?.companyName !== null)
                    return (<KeyWordWrapper
                        onClick={() => onMenuClick(SEARCH_TYPES.user, value?.userId)}
                        key={index}>{value?.companyName}</KeyWordWrapper>)

                else return (<KeyWordWrapper
                    onClick={() => onMenuClick(SEARCH_TYPES.user, value?.userId)}
                    key={index}>{value?.name} {value?.lastName}</KeyWordWrapper>)
            })}
        </StyledCol>
    );
}
