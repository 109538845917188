import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {SnackbarProvider} from "notistack";
import {LanguageProvider} from "./context/context";
import './i18n';
import {store} from "./store/reduxStore"
import {Spinner, SpinnerWrapper} from "./components/Spinner";
import {Provider} from "react-redux";
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<SpinnerWrapper><Spinner/></SpinnerWrapper>}>
            <LanguageProvider>
                <SnackbarProvider maxSnack={3}>
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </SnackbarProvider>
            </LanguageProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.register()
