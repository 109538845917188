import styled from "styled-components";
import {FlexCol, FlexRow} from "../../components/Flex";
import Popper from "@material-ui/core/Popper";

export const Title = styled(FlexRow)`
    padding: 5px 20px 5px 20px;
    font-weight: bold;
`;

export const StyledCol = styled(FlexCol)`
    padding: 20px 30px 0 20px;
`;

export const Hr = styled.hr`
    padding: 0 20px 0 20px;
    border-top: 10px #8c8b8b;
    margin-bottom: 10px;
`

export const Container = styled(FlexCol)`
    background-color: white;
    z-index: 10;
`;

export const KeyWordWrapper = styled(FlexRow)`
    color: black;
    cursor: pointer;
    padding: 5px;
    &:hover{
        background: grey;
    }
`;

export const StyledPopper = styled(Popper)`
    width: ${({width}) => width ? `${width}px` : "50%"};
    max-height: 400px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;