import styled from 'styled-components'
import React, {useState} from 'react';
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import FeedBack from "../../../components/FeedBack";

const Banner = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    background-color: #243b85;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export default function Crowdfunding() {
    const {t} = useTranslation();
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('dijasporaCroudFounding.header')}</h1>

            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dijasporaCroudFounding.head1')}
                    </p>
                    <h4><b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.head2')}</b></h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <a href={""} style={{textDecoration: "none"}}><b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.b1')}</b></a>
                        {t('dijasporaCroudFounding.t1')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.head3')}</b></h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.b2')}</b>
                        {t('dijasporaCroudFounding.t3')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.head4')}</b></h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.b3')}</b>
                        {t('dijasporaCroudFounding.t5')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.head5')}</b></h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.b4')}</b>
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dijasporaCroudFounding.t7')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <a style={{textDecoration: "none"}} href={"/all-search-results"}>
                            <b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.b5')}</b></a>
                        {t('dijasporaCroudFounding.t9')}
                    </p>

                    {/* <p style={{ textAlign: "justify", textJustify: "inter-word", marginBottom: "1%" }}>
                        <b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.b5')}</b>
                        {t('dijasporaCroudFounding.t9')}
                        <b style={{color: '#243b85'}}>{t('dijasporaCroudFounding.b6')}</b>
                    </p> */}

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b onClick={() => setShowModalSendMail(true)} style={{color: '#243b85'}}>{t('dijasporaCroudFounding.b7')}</b>
                    </p>
                    <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>
                </Paper>
            </Container>


        </div>
    )
}
