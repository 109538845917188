import React, {useEffect, useState} from 'react'
import {filter} from 'lodash';
import {styled} from '@mui/material/styles';
// material
import {
    Card,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
// components
import Label from './Label';
import {useSnackbar} from "notistack";
import SearchNotFound from './SearchNotFound';
import UserListHead from '../UsersApprove/UserListHead';
import {getUserApprovment, updateUserStatus} from "../../../services/api";
import UserImage from "./userImage";
import {useTranslation} from "react-i18next";
import {Icon} from "@iconify/react";
import {Spinner, SpinnerWrapper} from "../../../components/Spinner";
import accept from '@iconify/icons-eva/checkmark-outline';
import reject from '@iconify/icons-eva/close-outline';

import {CenteredRow} from "../../../components/Flex";
//


const RootStyle = styled('div')({
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden'
});


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


export default function UsersApprove() {
    const [usersCount, setUsersCount] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [userList, setUserList] = useState([])
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const TABLE_HEAD = [
        {id: 'name', label: t('userApprove.fullName'), alignRight: false},
        {id: 'company', label: t('userApprove.company'), alignRight: false},
        {id: 'role', label: t('userApprove.role'), alignRight: false},
        {id: 'isVerified', label: t('userApprove.verified'), alignRight: false},
        {id: 'status', label: t('userApprove.status'), alignRight: false},
        {id: ''}
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = userList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

    const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    useEffect(() => {
        getUserApprovment().then(response => {
            setUserList(response.data.content)
            let usersList = response.data.content;
            let processing = usersList.filter(e => e.userStatus.id === 0);
            let approved = usersList.filter(e => e.userStatus.id === 2);
            let rejected = usersList.filter(e => e.userStatus.id === 3);
            setUsersCount([processing.length, approved.length, rejected.length])
            setLoading(false)
        })
    }, []);

    const changeUserStatus = (userId, index, status) => {
        updateUserStatus(userId, status).then(() => {
            let arr = userList;
            let user = arr[index];
            user.userStatus.id = status;
            arr[index] = user
            setUserList([])
            setUserList(arr)
        }).catch(() => {
            enqueueSnackbar(t('userApprove.statusChangeError'), {
                variant: 'error'
            })
        })
    }

    return (
        <>

            <Card>
                {/*<UserListToolbar*/}
                {/*    numSelected={selected.length}*/}
                {/*    filterName={filterName}*/}
                {/*    onFilterName={handleFilterByName}*/}
                {/*/>*/}

                <RootStyle>
                    <CenteredRow style={{margin: '2rem 0', gap: '1rem'}}>
                        <label>{t('onWaiting')}: {usersCount[0]}</label>
                        <label>{t('numOfAccepted')}: {usersCount[1]}</label>
                        <label>{t('numOfRejected')}: {usersCount[2]}</label>
                    </CenteredRow>
                    <TableContainer sx={{minWidth: 800}}>
                        {loading ? <SpinnerWrapper><Spinner/></SpinnerWrapper> : <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={userList.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => {
                                    const isItemSelected = selected.indexOf(user.id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={user?.id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                {/*<Checkbox*/}
                                                {/*    checked={isItemSelected}*/}
                                                {/*    onChange={(event) => handleClick(event, user.id)}*/}
                                                {/*/>*/}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <UserImage id={user.id} name={user.name}/>
                                                    <Typography variant="subtitle2" noWrap>
                                                        {user?.name} {user?.lastName}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">{user?.company}</TableCell>
                                            <TableCell align="left">{user?.role.name}</TableCell>
                                            <TableCell
                                                align="left">{user?.userStatus.id === 2 ? t('userApprove.yes') : t('userApprove.no')}</TableCell>
                                            <TableCell align="left">
                                                <Label
                                                    variant="ghost"
                                                    color={(user?.userStatus?.id === 2 && 'success') || (user?.userStatus?.id === 0 && 'primary') || 'error'}>
                                                    {(user?.userStatus?.id === 2 && t('userApprove.approved'))
                                                    || (user?.userStatus?.id === 0 && t('userApprove.inProgress')) || t('userApprove.rejected')}
                                                </Label>
                                            </TableCell>

                                            <TableCell align="right">
                                                <IconButton component="span"
                                                            onClick={() => changeUserStatus(user?.id, index, 2)}>
                                                    <Icon icon={accept} width={24} height={24}/>
                                                </IconButton>
                                                <IconButton component="span"
                                                            onClick={() => changeUserStatus(user?.id, index, 3)}>
                                                    <Icon icon={reject} width={24} height={24}/>
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 53 * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                            <SearchNotFound searchQuery={filterName}/>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                        }
                    </TableContainer>
                </RootStyle>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={userList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
}
