import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    getAllBusinessActivities,
    getAllBusinessSector,
    getAllCities,
    getAllCountries,
    getFInancingTypes,
    getProjectTypes
} from "../../services/api";

export const fetchAllCities = createAsyncThunk("fetchCities", async () => {
    return await getAllCities();
})

export const fetchAllCountries = createAsyncThunk("fetchCountries", async () => {
    return await getAllCountries();
})

export const fetchAllSectors = createAsyncThunk("fetchSectors", async () => {
    return await getAllBusinessSector();
})

export const fetchAllActivites = createAsyncThunk("fetchAllActivites", async () => {
    return await getAllBusinessActivities();
})

export const fetchProjectsType = createAsyncThunk("fetchProjectsTypes", async () => {
    return await getProjectTypes();
})

export const fetchFinanceType = createAsyncThunk("fetchFinanceTypes", async () => {
    return await getFInancingTypes();
})

const initialState = {cityList: [], countryList: [], businessSectorList: [], businessActivityList: [], projectTypes: [], financeTypes: [],loading: false, error: {}}

export const citiesSlice = createSlice({
    name: 'city',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllCities.pending]: (state) => {

            state.cityList = []
            state.loading = true;
        },
        [fetchAllCities.fulfilled]: (state, {payload}) => {
            state.cityList = payload.data;
            state.loading = false;
        },
        [fetchAllCities.rejected]: (state, {payload}) => {
            state.error = payload;
            state.loading = false;
        },
        [fetchAllCountries.pending]: (state) => {

            state.countryList = []
            state.loading = true;
        },
        [fetchAllCountries.fulfilled]: (state, {payload}) => {
            state.countryList = payload.data;
            state.loading = false;
        },
        [fetchAllCountries.rejected]: (state, {payload}) => {
            state.error = payload;
            state.loading = false;
        },
        [fetchAllSectors.pending]: (state) => {
            state.loading = true;
            state.businessSectorList = []
        },
        [fetchAllSectors.fulfilled]: (state, {payload}) => {
            state.businessSectorList = payload.data;
            state.loading = false;
        },
        [fetchAllSectors.rejected]: (state, {payload}) => {
            state.error = payload;
            state.loading = false;
        },
        [fetchAllActivites.pending]: (state) => {
            state.loading = true;
            state.businessActivityList = []
        },
        [fetchAllActivites.fulfilled]: (state, {payload}) => {
            state.businessActivityList = payload.data;
            state.loading = false;
        },
        [fetchAllActivites.rejected]: (state, {payload}) => {
            state.error = payload;
            state.loading = false;
        },
        [fetchProjectsType.pending]: (state) => {
            state.loading = true;
            state.projectTypes = []
        },
        [fetchProjectsType.fulfilled]: (state, {payload}) => {
            state.projectTypes = payload.data;
            state.loading = false;
        },
        [fetchProjectsType.rejected]: (state, {payload}) => {
            state.error = payload;
            state.loading = false;
        },
        [fetchFinanceType.pending]: (state) => {
            state.loading = true;
            state.financeTypes = []
        },
        [fetchFinanceType.fulfilled]: (state, {payload}) => {
            state.financeTypes = payload.data;
            state.loading = false;
        },
        [fetchFinanceType.rejected]: (state, {payload}) => {
            state.error = payload;
            state.loading = false;
        },

    },
})

export const citiesSliceReducer = citiesSlice.reducer
export const citiesSelector = (state) => state.city;
// updateRegistration: (state, action) => { state.regist = {...state.regist, [action.pramaName]: action.payload }},
