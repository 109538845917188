import React from "react";
import {FlexRow} from "../Flex";
import styled from "styled-components"
import {useTranslation} from "react-i18next";
import Avatar from '@mui/material/Avatar';
import PticaImage from "../../assets/images/ptica_no_background.png"
import {Typography} from "@mui/material";
import usePWA, {isPwaBrowser} from "../../utils/pwa";

const Container = styled(FlexRow)`
    position: fixed;
    bottom: 0;
    background: black;
    width: 100%;
    z-index: 10;
`;

const Wrapper = styled(FlexRow)`
    position: relative;
    width: 100%;
    align-items: center;
`;

const CloseButton = styled(FlexRow)`
    position: absolute;
    right: 10px;
    color: white;
    cursor: pointer;
    padding: 5px;
    &:hover{
        color: red;
        background: white;
    }
`;

const InstallButton = styled(FlexRow)`
    color: white;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    background: #203577;
    border-radius: 5px;
    margin-right: 50px;
    &:hover{
        color: white;
        background: #2f91ed;
    }
`;

const Icon = styled(Avatar)`
    padding-left: 10px;
`;

const Text = styled(Typography)`
    color: white;
`;

export default function PwaInstaller({closeDialog}) {
    const {isStandalone, isInstallPromptSupported, promptInstall} = usePWA();
    const {t} = useTranslation();

    const handleInstallClick = async () => {
        const didInstall = await promptInstall()
        if (didInstall) {
            closeDialog(true);
        }
    }

    if (isPwaBrowser())
        return <></>

    return (
        <Container>
            <Wrapper>
                <Icon variant="square" src={PticaImage}/>
                <Text>{t('pwaInstaller.description')}</Text>
                <InstallButton onClick={handleInstallClick}>{t('pwaInstaller.btnText')}</InstallButton>
                <CloseButton onClick={closeDialog}>X</CloseButton>
            </Wrapper>
        </Container>
    );
}