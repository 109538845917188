import {upladPlacesImage, upladPlacesSymbol, uploadInvestmentImage} from "../../../services/api";

export function getLanguageIndex(languages, id) {
    if (languages === null)
        return -1;
    if (languages?.length === 0)
        return -1;
    let index = -1;
    for (let i = 0; i < languages?.length; i++) {
        if (languages[i]?.language?.id === id) {
            index = i;
            break;
        }
    }
    return index;
}

export const languageObject = (id) => {
    return (
        {
            language: {
                id: id
            },
            reachTextIndustry: "",
            reachTextAgriculture: "",
            reachTextTourism: "",
            reachTextForestry: "",
            villageRenewal: "",
            reachTextInvestmentAdvantage: "",
            reachTextInvestmentAdvantage2: "",
            reachTextInvestmentAdvantage3: "",
            reachTextInvestmentAdvantage4: "",
            reachTextInvestmentAdvantage5: "",
            supportType: "",
            imageHoverText: "",
            imageHoverText2: "",
            imageHoverText3: "",
            cityOrDistrictDescription: ""
        }
    );
}

export async function uploadPagePlaceImage(placeId, imgFile, enqueueSnackbar, index) {
    let formData = new FormData();
    formData.append("placeId", placeId);
    formData.append("extension", getFileExtension(imgFile?.name));
    formData.append("imageNumber", index)
    formData.append("picture", imgFile);
    upladPlacesImage(formData).then(() => {
    }).catch(reason => {
        console.error(reason);
        enqueueSnackbar('Izabran format slike nije podrzan!', {
            variant: 'error'
        });
    })
}

export async function uploadSuccessInvestmentImage(successfulInvestmentId, imgFile, enqueueSnackbar, index) {
    let formData = new FormData();
    formData.append("successfulInvestmentId", successfulInvestmentId);
    formData.append("extension", getFileExtension(imgFile?.name));
    formData.append("imageNumber", index)
    formData.append("picture", imgFile);
    uploadInvestmentImage(formData).then(() => {
    }).catch(reason => {
        console.error(reason);
        enqueueSnackbar('Izabran format slike nije podrzan!', {
            variant: 'error'
        });
    })
}


export async function uploadPagePlaceSymbol(symbolFile, placeId, enqueueSnackbar) {
    let formDataS = new FormData();
    formDataS.append("placeId", placeId);
    formDataS.append("extension", getFileExtension(symbolFile?.name));
    formDataS.append("picture", symbolFile);
    upladPlacesSymbol(formDataS).then(() => {
    }).catch(reason => {
        console.error(reason);
        enqueueSnackbar('Izabran format slike nije podrzan!', {
            variant: 'error'
        });
    });
}


function getFileExtension(fileName) {
    return fileName?.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
}
