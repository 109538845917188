import React, {useEffect} from "react";
import {getUserProfileImageAsync} from "../../services/api";
import Avatar from '@mui/material/Avatar';
import CircularProgress from "@material-ui/core/CircularProgress";

export default function User({user}) {
    const [userImage, setUserImage] = React.useState();
    const [loadingImage, setLoadingImage] = React.useState(false);
    useEffect(() => {
        setLoadingImage(true)
        getUserProfileImageAsync(user?.userId).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(() => {
            setLoadingImage(false);

        });
    }, [user?.userId]);

    return (
        <>
            {loadingImage ? <CircularProgress/> :
                <Avatar alt="Remy Sharp" src={userImage}/>
            }
        </>
    )
}
