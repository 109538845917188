import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {getRating, getUserById, getUserProfileImageAsync} from "../../services/api";
import {CenteredCol, CenteredRow, FlexCol, FlexRow} from "../../components/Flex";
import {PageContainer} from "../../components/Containers";
import {Spinner, SpinnerWrapper} from "../../components/Spinner";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserPhoto from "../../assets/images/user.svg";
import TextField from "@material-ui/core/TextField";
import {useLocation} from "react-router-dom";
import styled from "styled-components";
import Pksnew from "./pksnew";
import Individual from "./individual";
import Entrepreneur from "./entrepreneur";
import Investor from "./investor";
import Broker from "./broker";
import Mentor from "./mentor";
import Dach from "./dach";
import RRA from "./rra";
import Mediji from "./mediji";
import Pks from "./pks";
import Special from "./special";
import ReactStars from "react-stars";


const CardWrapper = styled(FlexCol)`
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 10px;
    max-width: 400px;
    max-height: 600px;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) 0px 16px 32px -4px;
    border-radius: 16px;
    position: relative;
    z-index: 0;
    padding: 80px 24px;
`;
const CardWrapperRight = styled(CardWrapper)`
    max-width: 600px;
    max-height: 600px;
    height: 100%;
    overflow: auto;
    padding: 60px 25px;
`;


const StyledSpinner = styled(Spinner)`
    position: relative;
    left: auto;
    right: auto;
    top: auto;
`;

const SubText = styled.p`
    margin: 16px auto 20px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: (145, 158, 171, 0.32);
    display: block;
    text-align: center;
    max-width: 200px;
    display:flex;
    flex-wrap:wrap;
`

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    margin: auto;
    border-radius: 50%;
    padding: 0px;
    border: 1px dashed rgba(145, 158, 171, 0.32);
    margin-bottom: 2rem;
`

export function Stars({user}) {

    const [rating, setRating] = useState(0);

    useEffect(() => {
        getRating(user?.id).then((res) => {
            setRating(res.data);
        })
    }, [user])

    return (
        <>

            <CenteredRow><ReactStars
                edit={false}
                value={rating}
                count={5}
                size={34}
                color2={'#ffd700'}/></CenteredRow>
        </>
    )
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function UserProfile() {
    const {t} = useTranslation();
    let query = useQuery();

    const [userInfo, setUserInfo] = useState()
    const imageRef = React.useRef();
    const [userImage, setUserImage] = useState();
    const [loadingImage, setLoadingImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lng, setLng] = useState('lat')
    let lngName;

    useEffect(() => {
        setLng(localStorage.getItem('language') || 'lat')
    }, [])

    useEffect(() => {
        loadUserImage(parseInt(query.get("id")));
        getUserById(parseInt(query.get("id"))).then(({data}) => {
            setUserInfo(data)
        }).catch(error => {
            console.log('error', error)
        })
    }, [])

    async function loadUserImage(userId) {
        setLoadingImage(true);
        getUserProfileImageAsync(userId).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(() => {
            setLoadingImage(false);
        });
    }

    switch (lng) {
        case 'cir':
            lngName = 'nameCir'
            break;
        case 'en':
            lngName = 'nameEn'
            break;
        case 'de':
            lngName = 'nameDe'
            break;
        default:
            lngName = 'name'
    }

    return (
        <CenteredCol>
            <PageContainer>
                <CenteredRow style={{flexWrap: 'wrap', width: '100%'}}>
                    {loading ? <SpinnerWrapper><StyledSpinner/></SpinnerWrapper> : <>
                        <CardWrapper>
                            <Circle>
                                <div className="profile-pic">
                                    {loadingImage ? <CircularProgress/> :
                                        <img src={userImage || UserPhoto} ref={imageRef}
                                             id="output"
                                             width="200"/>}
                                </div>
                            </Circle>
                            <Stars user={userInfo} />
                        </CardWrapper>

                        <CardWrapperRight>
                            <FlexCol style={{width: '100%', gap: 25}}>
                                <h4 style={{textAlign: 'center', color: "gray"}}>{t('basicInfo')}</h4>
                                <FlexRow style={{gap: 15}}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        id="name"
                                        label={t('firstName')}
                                        value={userInfo?.name || ''}
                                        variant="outlined"
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        id="lastName"
                                        label={t('lastName')}
                                        value={userInfo?.lastName || ''}
                                        variant="outlined"
                                    />
                                </FlexRow>
                                <FlexRow style={{gap: 15}}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        id="company"
                                        label={t('company')}
                                        value={userInfo?.company || ''}
                                        variant="outlined"
                                    />
                                    <TextField
                                        fullWidth
                                        id="phone"
                                        disabled={true}
                                        label={t('phoneNumber')}
                                        value={userInfo?.phone || ''}
                                        variant="outlined"
                                    />
                                </FlexRow>
                                <FlexRow style={{gap: 15}}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        label={t('country')}
                                        value={userInfo?.country?.name || ''}
                                        variant="outlined"
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        id="cityName"
                                        label={t('city')}
                                        value={userInfo?.city?.name || ''}
                                        variant="outlined"
                                    />
                                </FlexRow>

                                <TextField
                                    id="about"
                                    label={t('aboutSelfText')}
                                    multiline
                                    disabled={true}
                                    maxRows={4}
                                    value={userInfo?.about || ''}
                                    variant="outlined"
                                />
                                {/*{userInfo?.roleId !== 11 &&*/}
                                {/*<h4 style={{textAlign: 'center', color: "gray"}}>{t('specificInfo')}</h4>}*/}
                                {userInfo?.roleId === 1 && <Individual userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 2 && <Entrepreneur userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 3 && <Investor userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 4 && <Broker userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 5 && <Mentor userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 6 && <Dach userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 7 && <RRA userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 8 && <Mediji userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 9 && <Pks userInfo={userInfo} t={t} lng={lngName}/>}
                                {userInfo?.roleId === 10 && <Special userInfo={userInfo} setUserInfo={setUserInfo}/>}
                                {userInfo?.roleId === 12 && <Pksnew userInfo={userInfo} t={t} lng={lngName}/>}
                            </FlexCol>
                        </CardWrapperRight>
                    </>}
                </CenteredRow>
            </PageContainer>

        </CenteredCol>
    )
}
