import {CenteredCol, FlexCol} from "../../components/Flex";
import {NormalText} from "../../components/Text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {TextArea} from "../../components/RegistrationModal/stepThree";
import React, {useEffect, useState} from "react";
import {organizationUnitType} from "../../services/api";
import {useTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function Pksnew({userInfo, t, lng}){

    return(
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question1')}</NormalText>
                <TextField
                    disabled={true}
                    id="organizationalUnitTypeId"
                    multiline
                    maxRows={4}
                    value={userInfo?.organizationalUnitType.name || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question2')}</NormalText>
                <TextField
                    disabled={true}
                    id="organizationalUnitName"
                    multiline
                    maxRows={4}
                    value={userInfo?.organizationalUnitName || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question3')}</NormalText>
                <TextField
                    disabled={true}
                    id="existingExperiental"
                    multiline
                    maxRows={4}
                    value={userInfo?.existingExperiental || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question4')}</NormalText>
                <TextField
                    disabled={true}
                    id="existingDesiredCooperation"
                    multiline
                    maxRows={4}
                    value={userInfo?.existingDesiredCooperation || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.privrednaKomora.question5')}</NormalText>
                <TextField
                    disabled={true}
                    id="leadingInvestors"
                    multiline
                    maxRows={4}
                    value={userInfo?.leadingInvestors || ''}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}
