import styled from 'styled-components'
import {CenteredRow} from "../Flex";
import {Color} from "../Colors";

export const HomeButtonWraper = styled(CenteredRow)`
    margin-top: 25rem;
    flex-wrap:wrap;
    
    @media screen and (max-height: 600px) {
        margin-top: 15rem;
    } 
`

export const ButtonL = styled.button`
  width:${(props) => props.width ? 'props.width' : '160px'};
  padding:${(props) => props.padding ? `${props.padding}` : '0px'};
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin:${(props) => props.margin ? 'props.margin' : '20px'};
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  //border-radius: 10px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background-image: linear-gradient(
      to right,
      #243B85,
      #3b54a3,
      #516bbd,
      #738dde
    );
    box-shadow: 0 2px 15px 0 rgba(47, 72, 156, 0.75);
    
    :hover{
    background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  }
  
  :hover:focus {
  outline: none;
  }
`
export const ButtonR = styled.button`
  width:${(props) => props.width ? 'props.width' : '160px'};
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  //border-radius: 10px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
   background-image: linear-gradient(
      to right,
      #eb3941,
      #f15e64,
      #e14e53,
      #e2373f
    );
    box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
    
    :hover{
     background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  }
  
  :hover:focus {
  outline: none;
  }
`

export const ButtonLostPw = styled.button`
    cursor: pointer; 
    background: none; 
    border: none; 
    text-decoration:underline;
    color: ${Color.blue};
`
export const Button1 = styled.button`
    background: none;
    color: grey;
    font-weight: 700;
    width: auto;
    border: none;
    padding: 15px;
    
    &:hover{
        cursor: pointer;
        background-color: #dbdbdb;
        color: '#fff';
    }
`
export const Button2 = styled.button`
     color: #fff;
    background: ${({bg}) => (bg ? '#DC2626' : '#243b85')};
    margin-right: ${({m}) => (m ? '1.5rem' : '0px')};
    font-weight: ${({f}) => (f ? '400' : '700')};
    width: auto;
    border: none;
    padding: 15px;
    
    &:hover{
        cursor: pointer;
        background-color: #2f91ed;
    }
`
export const BtnSearch = styled.button`
    color: #fff;
    
    margin-right: ${({m}) => (m ? '1.5rem' : '0px')};
    font-weight: ${({f}) => (f ? '400' : '700')};
    width: auto;
    border: none;
    padding: 15px;
    background-image: linear-gradient(
      to right,
      #eb3941,
      #f15e64,
      #e14e53,
      #e2373f
    );
    box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
     cursor: pointer;
    :hover{
     background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  }
  
  :hover:focus {
  outline: none;
  }
  
`

export const HeaderLogoutBtn = styled.button`
    background: none;
    color: grey;
    font-weight: 700;
    width: auto;
    border: none;
    padding: 15px;
    
    &:hover{
        cursor: pointer;
        outline: 1px solid #dbdbdb;
        border-radius: 10px;
        color: '#fff';
    }
`

