import {CenteredCol, CenteredRow, FlexCol} from "../../components/Flex";
import Pagination from "../../components/Pagination";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {NormalText} from "../../components/Text";
import {getRRAPdf, searchRRAProjectsAsync, getDocument} from "../../services/api";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import ConnectModal from "../UserPage/connectModal";
import Button from "@mui/material/Button";
import {useLocation} from "react-router-dom";
import LanguagePdfSelect from "../../components/LanguagePdfSelect";
import {useTranslation} from "react-i18next";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 4rem;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 80vh;
  width: 100%;
  background-color: #FFF;

  @media (max-width: 648px) {
    padding: 0.2rem;
  }
`

const Card = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 400px;
  width: 400px;
  padding: 2rem;
  background-color: #fff;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  @media (max-width: 648px) {
    width: 100%;
  }
`

const BText = styled.p`
  font-weight: 700;
  padding: 0;
  line-height: 24px; //for not webkit browser
  max-height: 120px; //for not webkit browser
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Text = styled(NormalText)`
  width: 100%;
  line-height: 20px; //for not webkit browser
  max-height: 120px; //for not webkit browser
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`

const BootstrapButton = styled(Button)({
    width: '150px',
})

async function getAllRraProjects(input, sectorIds, params, cityIds) {
    let data = {
        type: null,
        businesSectorIds: null
    }
    data.name = input;
    if (sectorIds.length > 0) {
        data.businesSectorIds = sectorIds
    }
    if (cityIds.length > 0) {
        data.cityIds = cityIds
    }
    return await searchRRAProjectsAsync(data, params);
}


const getLangIndex = (lang) => {
    switch (lang) {
        case "cir": return 1;
        case "en": return 2;
        case "de": return 3;
        default: return 0;
    }
}

const getBuissnisSectorByLang = (lang, project, t) => {
    switch (lang) {
        case "cir": return <BText>{project?.businessSector?.nameCir == null ? t('noName') : project?.businessSector.nameCir}</BText>;
        case "en": return <BText>{project?.businessSector?.nameEn == null ? t('noName') : project?.businessSector.nameEn}</BText>;
        case "de": return <BText>{project?.businessSector?.nameDe == null ? t('noName') : project?.businessSector.nameDe}</BText>;
        default: return <BText>{project?.businessSector?.name == null ? t('noName') : project?.businessSector.name}</BText>;
    }
}

const getCityNameByLang = (lang, project, t) => {
    switch (lang) {
        case "cir": return <Text>{project?.municipality?.name === "" ? t('noCityName') : project?.municipality?.nameCir}</Text>;
        case "en": return <Text>{project?.municipality?.name === "" ? t('noCityName') : project?.municipality?.nameEn}</Text>;
        case "de": return <Text>{project?.municipality?.name === "" ? t('noCityName') : project?.municipality?.nameDe}</Text>;
        default: return <Text>{project?.municipality?.name === "" ? t('noCityName') : project?.municipality?.name}</Text>;
    }
}

export default function AllSearchedRRAProjects({t}) {

    const {i18n} = useTranslation()
    const location = useLocation();
    const [rraProjects, setRraProjects] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {user} = useSelector((state) => state.user)
    const [openConnect, setOpenConnect] = useState(false);
    const [project, setproject] = useState(false);
    const [projectForDownload, setProjectForDownload] = useState(0);
    const [projectForDownloadDocuments, setProjectForDownloadDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showLang, setShowLang] = useState(false);
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });
    const [sort, setSort] = useState({
        rowPerPage: 10,
        sortBy: "createdAt,ASC",
    });

    useEffect(() => {
        let params = location.state.params;
        let filter = {
            input: null,
            cityIds: [],
            sectorIds: [],
            range: [0, 9999999999999999999999]
        }
        if (params) {
            filter = {
                input: params.input,
                cityIds: params.cityIds,
                sectorIds: params.sectorIds,
                range: params.range
            }
        }
        setLoading(true);
        let parsedFilter = `?page=${page.currentPage}&sort=${sort.sortBy}&size=${sort.rowPerPage}`
        getAllRraProjects(filter.input, filter.sectorIds, parsedFilter, filter.cityIds).then(value => {
            setRraProjects(value.data.content)
            setPage({...page, totalPages: value?.data.totalPages})
        }).catch(error => {
            console.error(error, 'err')
            setLoading(false)
        });
    }, [location.state.params, page.currentPage, sort.rowPerPage, sort.sortBy]);

    const handleClickPage = (event, value) => {
        setPage({...page, currentPage: parseInt(value) - 1});
    };



    const handleConnect = (project) => {
        setproject(project)
        setOpenConnect(true)
    }

    function handlePreviewRraProjectClick(lan) {
        if (user) {
            getRRAPdf(projectForDownload, lan).then((response) => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                const url = window.URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(url);

                projectForDownloadDocuments.map((documentForDownload) => {
                    getDocument(documentForDownload.fileName).then((response1) =>{
                        const url1 = window.URL.createObjectURL(new Blob([response1.data]));
                        const link1 = document.createElement('a');
                        link1.href = url1;
                        link1.setAttribute('download', documentForDownload.fileName); //or any other extension
                        document.body.appendChild(link1);
                        link1.click();
                    })
                })
            }).catch(_ => {
                enqueueSnackbar(t('pdfNotExist'), {
                    variant: 'error'
                });
            })
        } else {
            enqueueSnackbar(t('youHaveToBeLoggedIn'), {
                variant: 'error'
            });
        }
        setShowLang(false);
    }

    return (
        <Container>
            <LanguagePdfSelect t={t} show={showLang}  handlePreviewProjectClick={handlePreviewRraProjectClick}
                               handleClose={()=>{setShowLang(false)}}/>
            <ConnectModal openConnect={openConnect} setOpenConnect={setOpenConnect} project={project}/>
            <CenteredCol>
                {rraProjects.length > 0 ?
                    <CenteredRow style={{gap: '1rem', flexWrap: "wrap"}}>
                        {rraProjects.map(project => (
                            <>
                                <Card>
                                    <CenteredRow>
                                        {/*<Image style={{height: '60px', width: 'auto'}} src={Logo}/>*/}
                                        <CenteredCol style={{height: '100px'}}>
                                            <BText
                                                style={{fontSize: '20px'}}>{project?.rraProjectLanguages[getLangIndex(i18n.language)]?.name === "" ? t('noName') : project?.rraProjectLanguages[getLangIndex(i18n.language)]?.name}</BText>
                                            {/*<Text>{project?.city?.name === "" ? t('noName') : project?.city?.name}</Text>*/}
                                            {getCityNameByLang(i18n.language, project, t)}
                                        </CenteredCol>
                                    </CenteredRow>
                                    <FlexCol style={{height: '190px', marginTop: '0.5rem'}}>
                                        {/*<BText>{project?.businessActivity?.name}</BText>*/}
                                        {getBuissnisSectorByLang(i18n.language, project, t)}
                                        <Text>{project?.rraProjectLanguages[getLangIndex(i18n.language)]?.investmentDescription === "" ? t('noDescription') : project?.rraProjectLanguages[getLangIndex(i18n.language)]?.investmentDescription}</Text>
                                    </FlexCol>
                                    <CenteredRow style={{marginTop: '.5rem', gap: '.5rem'}}>
                                        <BootstrapButton onClick={() => {
                                            handleConnect(project)
                                        }}
                                                         variant="contained">{t('linkUpButton')}</BootstrapButton>
                                        <BootstrapButton onClick={() => {
                                            setProjectForDownload(project.id);
                                            setProjectForDownloadDocuments(project.documents);
                                            setShowLang(true)
                                        }}
                                                         variant="contained">{t('seePdf')}</BootstrapButton>
                                        {/*<Text>{project?.projectLanguages[0].description === "" ? "Nema opis" : project?.projectLanguages[0]?.description}</Text>*/}
                                    </CenteredRow>
                                </Card>
                            </>
                        ))}

                    </CenteredRow> :
                    <p>{t('noResults')}</p>}
            </CenteredCol>
            {rraProjects.length !== 0 && <Pagination
                totalPages={page.totalPages}
                handleClickPage={handleClickPage}
                currentPage={parseInt(page.currentPage) + 1}/>
            }
        </Container>
    )
}
