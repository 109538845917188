import React, {lazy} from 'react'
import {sidebar_menu} from "../../helper/sidebar";
import {Container} from "../../components/Container";
import AddSuccessfulInvestment from "../admin/AddSuccessfulInvestment";

const ProfilePage = lazy(() => import('../Profile'))
const MessagePage = lazy(() => import('../MessagesPage'))
const InteractionsPage = lazy(() => import('../InteractionsPage'))
const MyProjectsPage = lazy(() => import('../Projects'))
const MyPagesPage = lazy(() => import('../admin/AdminAddPage'))
const AdminApprovePage = lazy(() => import('../admin/Approve'))
const MyEventsPage = lazy(() => import('../Events'))

export default function MainPage({selectedMenu}) {
    let forRender;
    switch (selectedMenu) {
        case sidebar_menu.home:
            forRender = <ProfilePage/>;
            break;
        case sidebar_menu.messages:
            forRender = <MessagePage/>
            break;
        case sidebar_menu.interactions:
            forRender = <InteractionsPage/>
            break;
        case sidebar_menu.myProjects:
            forRender = <MyProjectsPage/>
            break;
        case sidebar_menu.myPages:
            forRender = <MyPagesPage/>
            break;
        case sidebar_menu.adminApprove:
            forRender = <AdminApprovePage/>
            break;
        case sidebar_menu.myEvents:
            forRender = <MyEventsPage/>
            break;
        case sidebar_menu.addSuccessful:
            forRender = <AddSuccessfulInvestment/>
            break;
        default:
            forRender = <ProfilePage/>;
            break;
    }
    return (
        <Container>
            {forRender}
        </Container>
    )
}
