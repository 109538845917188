import React from "react";
import {NormalText} from "../Text";
import {TextArea} from "./stepThree";
import {useTranslation} from "react-i18next";
import {InputS} from "../Input";

export default function RraRegistration({user, changeUserParam}) {
    const {t} = useTranslation();
    return (
        <>
            <NormalText>{t('registration.rra.question')}</NormalText>
            <InputS value={user?.rraname} onChange={(e) => {
                changeUserParam("rraname", e.target.value)
            }} />
            <NormalText>{t('registration.rra.question1')}</NormalText>
            <TextArea value={user?.supportMechanism} type="text" onChange={(e) => {
                changeUserParam("supportMechanism", e.target.value)
            }}/>
            <NormalText>{t('registration.rra.question2')}</NormalText>
            <TextArea value={user?.description} type="text" onChange={(e) => {
                changeUserParam("description", e.target.value)
            }}/>
        </>
    );
}