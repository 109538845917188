import Image from '../../../assets/grboviGrada/farmers3.jpg'
import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import FeedBack from "../../../components/FeedBack";

const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
 height: calc(100vh - 250px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;

`

export default function FarmerPage() {
    const {t} = useTranslation();
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('famers.header')}</h1>

            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('famers.description1')}
                    </p>
                    <h4>
                        <b style={{color: '#243b85'}}>{t('famers.b1')}</b> {t('famers.description2')}
                    </h4>
                    <ol style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%", marginLeft: "5%"}}>
                        <li>{t('famers.li1')}</li>
                        <li>{t('famers.li2')}</li>
                        <li>{t('famers.li3')}</li>
                        <li>{t('famers.li4')}</li>
                        <li>{t('famers.li5')}</li>
                        <li>{t('famers.li6')}</li>
                    </ol>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('famers.b2')}</b>{t('famers.description3')}
                    </p>
                    <h4>
                        <b style={{color: '#243b85'}}>{t('famers.b3')}</b> {t('famers.description4')}
                    </h4>
                    <ul style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%", marginLeft: "5%"}}>
                        <li>{t('famers.li7')}</li>
                        <li>{t('famers.li8')}</li>
                        <li>{t('famers.li9')}</li>
                    </ul>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('famers.b4')}</b> {t('famers.description5')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('famers.b5')}</b> {t('famers.description6')}
                    </p>
                    <a href={"/"} style={{textDecoration: "none"}}><h4><b style={{color: '#243b85'}}>{t('famers.registration')}</b></h4></a>
                    <h4><b onClick={() => setShowModalSendMail(true)} style={{color: '#243b85'}}>{t('famers.b6')}</b>{t('famers.description7')}</h4>
                    <h4><b style={{color: '#243b85'}}>{t('famers.search')}</b></h4>
                    <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>

                </Paper>
            </Container>


        </div>
    )
}
