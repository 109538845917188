import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllBusinessActivities, getAllDachgroups, getAllMentorTypes, getBrokerType} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function RRA({userInfo, setUserInfo, countries}) {
    const {t} = useTranslation();

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }


    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.rra.question')}</NormalText>
                <TextField
                    fullWidth
                    id="rraName"
                    multiline
                    value={userInfo?.rraName || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.rra.question1')}</NormalText>
                <TextField
                    fullWidth
                    id="supportMechanism"
                    multiline
                    maxRows={4}
                    value={userInfo?.supportMechanism || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.rra.question2')}</NormalText>
                <TextField
                    fullWidth
                    id="description"
                    multiline
                    maxRows={4}
                    value={userInfo?.description || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

