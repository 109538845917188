import styled from "styled-components";
import React, {useEffect, useState} from 'react';
import {getCitiesForPagePlace, getPagePlaceCity} from "../../services/api";
import {CITY_PAGE_ROUTE} from "../../config/routes";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {CenteredRow, FlexCol} from "../../components/Flex";
import {InputDiv, InputS} from "../../components/Input";
import {NormalText} from "../../components/Text";
import {useTranslation} from "react-i18next";

const Section = styled(FlexCol)`
    align-items: center;
    width: 100%;
    height: auto;
    overflow: auto;
    flex-wrap: wrap;
   
`
const Container = styled(CenteredRow)`
    height: 50px;
    width: 220px;
    margin: 0.4rem;
`
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E9E9E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
     height: 50px;
    width: 200px;
    border-radius: 5px;
    cursor: pointer;
    transition: width 0.2s;
   
    &:hover {
     background-color: #2f91ed;
     width: 210px;
    }
`

const Wrapper2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E9E9E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
     height: 50px;
    width: 200px;
    border-radius: 5px;
    background-color: #d6d6d6;
    transition: width 0.2s;
`

const Text = styled.p`
    text-align: justify;
    text-justify: inter-word;
    color:black;
`

const Heading = styled.h1`
    width: 100%;
    color: #fff;
    background-color:  #243b85;
    margin-bottom: 2rem;
    font-size: 26px;
    line-height: 1.1;
    text-align: center;
    font-weight: 600;
    margin-top: 2rem;
`;


const WrappedRow = styled(CenteredRow)`
    flex-wrap: wrap;
    
`;


const StyledCol = styled(FlexCol)`
    margin: 10px 10px 50px 10px;
`;

export default function CitiesPage() {
    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState("")
    const [filteredCities, setFilteredCities] = useState([]);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const handleCheckIfExist = (cityName) => {
        getPagePlaceCity(cityName)
            .then(() => {
                history.push({
                    pathname: CITY_PAGE_ROUTE,
                    search: "?name=" + cityName
                })
            }).catch(() => {
            enqueueSnackbar("Za odabrani grad ne postoji stranica", {variant: "info"})
            //enqueueSnackbar(t('cityPageNotFoundInfo'), {variant: "info"})
        });
    }
    useEffect(() => {
        getCitiesForPagePlace().then(response => {
                setFilteredCities(response?.data);
            }
        ).catch(error => {
            console.error(error, 'getPagePlaceCity')
        })
    }, [])

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value)
        // let arr = filterItems(filteredCities, event?.target?.value);
        // setFilteredCities(arr);
    }

    return (
        <div style={{minHeight: 'calc(100vh - 250px)'}}>
            <Heading>{t('searchCityHeader')}</Heading>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Section>
                <StyledCol>
                    <NormalText style={{textAlign: 'center'}}>{t('search')}</NormalText>
                    <InputDiv>
                        <InputS onChange={handleInputChange}/>
                    </InputDiv>
                </StyledCol>
                <WrappedRow>
                    {filteredCities?.filter(city => {
                            if (searchTerm === "") {
                                return city
                            } else if (city.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return city
                            }
                        }
                    ).map(item => (
                        <Container>
                            {item.hasPagePlace === true ? <Wrapper onClick={() => handleCheckIfExist(item?.name)}>
                                <Text>{item?.name}</Text>
                            </Wrapper> : <Wrapper2><Text>{item?.name}</Text></Wrapper2>}
                        </Container>
                    ))}
                </WrappedRow>
            </Section>
        </div>
    )
}


