import {Redirect} from "react-router-dom";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {ROOT_PATH} from "../../config/routes";
import {PageContainer, ProjectContainer} from "../../components/Containers";
import {CenteredCol} from "../../style/Flex";
import {CenteredRow, FlexCol, FlexRow} from "../../components/Flex";
import {Spinner} from "../../components/Spinner";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ButtonL} from "../../components/Button";
import ReactStars from 'react-stars'
import {TextArea} from "../../components/TextArea";
import {getUserById, postUserReview} from "../../services/api";
import {Typography} from "@mui/material";
import Users from "../UserPage/users";

const Container = styled(CenteredCol)`
    jusitfy-content: initial;
`

const CardWrapper = styled(FlexCol)`
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 10px;
    max-width: 400px;
    max-height: 600px;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) 0px 16px 32px -4px;
    border-radius: 16px;
    position: relative;
    z-index: 0;
    padding: 80px 24px;
`;

const CardWrapperRight = styled(CardWrapper)`
    max-width: 600px;
    max-height: 600px;
    height: 100%;
    overflow: auto;
    padding: 60px 25px;
`;


const StyledSpinner = styled(Spinner)`
    position: relative;
    left: auto;
    right: auto;
    top: auto;
`;

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    margin: auto;
    border-radius: 50%;
    padding: 0px;
    border: 1px dashed rgba(145, 158, 171, 0.32);
    margin-bottom: 2rem;
`

export default function ReviewPage() {
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();
    const searchParams = new URLSearchParams(window.location.search);
    const [needRedirect, setNeedRedirect] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [userRating, setUserRating] = useState(0);
    const [userComment, setUserComment] = useState("");
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastName: "",
        pictureUrl: ""
    })
    const imageRef = React.useRef();

    useEffect(() => {
        //TODO Add api call to chaeck is token valid on BE, and add redirect to home page
        if (searchParams.get('token').length < 5) {
            enqueueSnackbar(t('tokenIsNotValid'), {
                variant: 'error'
            });
            setNeedRedirect(true);
        }
        if (!searchParams.get('user')) {
            enqueueSnackbar(t('userNotFoundError'), {variant: "error"})
            setNeedRedirect(true);
        } else {
            getUserById(searchParams.get('user')).then(({data}) => {
                setUserInfo(data)
            }).catch(reason => {
                enqueueSnackbar(t('userNotFoundError'), {variant: "error"})
                setNeedRedirect(true);
            })
        }
    }, []);

    if (needRedirect) {
        return <Redirect to={ROOT_PATH}/>
    }

    const ratingChanged = (newRating) => {
        setUserRating(newRating)
    }

    const submitReview = () => {
        if (!userInfo) {
            enqueueSnackbar(t('userNotFoundError'), {variant: "error"})
            return;
        }
        setSubmitting(true);
        postUserReview({
            comment: userComment,
            grade: userRating,
            ratedForId: userInfo.id,
            token: searchParams.get('token')
        }).then(r => {
            enqueueSnackbar(t('reviewSuccess'), {variant: "success"})
            setSubmitting(false);
            return <Redirect to={ROOT_PATH}/>
        }).catch(reason => {
            enqueueSnackbar(t('reviewError'), {variant: "error"})
            setSubmitting(false);
        })
    }

    return (
        <Container>
            <ProjectContainer style={{width: '70%', marginBottom: '2rem'}}>
                <h3 style={{color: "gray"}}>{t('reviewUserTitle')}</h3>
            </ProjectContainer>
            <PageContainer>
                <CenteredRow style={{flexWrap: 'wrap', width: '100%'}}>
                    <CardWrapper>
                        <Circle>
                            <div className="profile-pic">
                                <Users
                                    style={{
                                        width: "165px",
                                        height: "165px"
                                    }}
                                    user={userInfo}/>
                            </div>
                        </Circle>
                        {/*<FlexRow>*/}
                        {/*    <Typography>*/}
                        {/*        {userInfo.name} {userInfo.lastName}*/}
                        {/*    </Typography>*/}
                        {/*</FlexRow>*/}
                    </CardWrapper>

                    <CardWrapperRight>
                        <FlexCol style={{width: '100%', gap: 25}}>
                            <h4 style={{textAlign: 'center', color: "gray"}}>{t('userRate')}</h4>
                            <FlexCol>
                                <h4 style={{fontWeight: 300, textAlign: 'center', color: "gray"}}>1
                                    - {t('reviewPage.rateOne')}</h4>
                                <h4 style={{fontWeight: 300, textAlign: 'center', color: "gray"}}>2
                                    - {t('reviewPage.rateTwo')}</h4>
                                <h4 style={{fontWeight: 300, textAlign: 'center', color: "gray"}}>3
                                    - {t('reviewPage.rateThree')}</h4>
                                <h4 style={{fontWeight: 300, textAlign: 'center', color: "gray"}}>4
                                    - {t('reviewPage.rateFour')}</h4>
                                <h4 style={{fontWeight: 300, textAlign: 'center', color: "gray"}}>5
                                    - {t('reviewPage.rateFIve')}</h4>
                            </FlexCol>
                            <FlexRow style={{gap: 15, justifyContent: "center"}}>
                                <ReactStars
                                    value={userRating}
                                    count={5}
                                    onChange={ratingChanged}
                                    size={30}
                                    color2={'#ffd700'}/>
                            </FlexRow>

                            <h4 style={{textAlign: 'center', color: "gray"}}>{t('userComment')}</h4>
                            <FlexRow style={{gap: 15}}>
                                <TextArea
                                    style={{
                                        width: "100%"
                                    }}
                                    value={userComment}
                                    onChange={(e) => setUserComment(e.target.value)}
                                />
                            </FlexRow>
                            <FlexRow style={{gap: 15, justifyContent: "center"}}>
                                <ButtonL onClick={submitReview} disabled={submitting}>
                                    {submitting ? <CircularProgress style={{color: "white"}}/> : t('save')}
                                </ButtonL>
                            </FlexRow>
                        </FlexCol>
                    </CardWrapperRight>
                </CenteredRow>
            </PageContainer>
        </Container>
    );
}
