import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";
import {FlexCol, FlexRow} from "../../../Flex";
import styled from "styled-components";
import {
    CITIES_PAGE,
    DACH_SUPPORT,
    FORUMS_INFORMATION,
    CONFERENCE_VIENNA,
    DIASPORA_DAYS_MICRO_REGIONS,
    GRANTS_RS,
    REGIONS_PAGE,
    RRA_CORDINATION,
    SUCCESSFUL_MODEL_DDL
} from "../../../../config/routes";
import {makeStyles} from "@material-ui/styles";

const Title = styled(FlexRow)`
    padding: 20px 20px 5px 20px;
    font-weight: bold;
`;

const StyledCol = styled(FlexCol)`
    padding: 20px 30px 20px 20px;
`;

const Hr = styled.hr`
    padding: 0 20px 0 20px;
    border-top: 10px #8c8b8b;
    margin-bottom: 10px;
`
const useStyles = makeStyles({
    paper: {
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
    },
});

export default function InformationsMenu({ anchorEl, handleClose, onMenuClick, isMobile }) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            classes={{
                paper: classes.paper
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <FlexRow>
                {isMobile &&
                    <StyledCol>
                        <Title>{t('informationMenuHeader1')}</Title>
                        <Hr />
                        <MenuItem onClick={() => onMenuClick(RRA_CORDINATION)}>{t('informationMenuItem1')}</MenuItem>
                        <Title>{t('informationMenuSubHeader1')}</Title>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(FORUMS_INFORMATION)}>{t('informationMenuItem9')}</MenuItem>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(CONFERENCE_VIENNA)}>{t('informationMenuItem10')}</MenuItem>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(DIASPORA_DAYS_MICRO_REGIONS)}>{t('informationMenuItem11')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(CITIES_PAGE)}>{t('informationMenuItem2')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(REGIONS_PAGE)}>{t('informationMenuItem3')}</MenuItem>
                        {/*<MenuItem>{t('informationMenuItem4')}</MenuItem>*/}
                        <Title>{t('informationMenuHeader2')}</Title>
                        <Hr />
                        <MenuItem onClick={() => onMenuClick(SUCCESSFUL_MODEL_DDL)}>{t('informationMenuItem5')}</MenuItem>
                        <Title>{t('informationMenuSubHeader1')}</Title>
                        <MenuItem  style={{ marginLeft: "5%" }} onClick={() => onMenuClick(DACH_SUPPORT)}>{t('informationMenuItem6')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(GRANTS_RS)}>{t('informationMenuItem7')}</MenuItem>
                        <MenuItem>{t('informationMenuItem8')}</MenuItem>
                    </StyledCol>
                }
                {!isMobile &&
                    <><StyledCol>
                        <Title>{t('informationMenuHeader1')}</Title>
                        <Hr />
                        <MenuItem onClick={() => onMenuClick(RRA_CORDINATION)}>{t('informationMenuItem1')}</MenuItem>
                        <Title>{t('informationMenuSubHeader1')}</Title>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(FORUMS_INFORMATION)}>{t('informationMenuItem9')}</MenuItem>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(CONFERENCE_VIENNA)}>{t('informationMenuItem10')}</MenuItem>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(DIASPORA_DAYS_MICRO_REGIONS)}>{t('informationMenuItem11')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(CITIES_PAGE)}>{t('informationMenuItem2')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(REGIONS_PAGE)}>{t('informationMenuItem3')}</MenuItem>
                        {/*<MenuItem>{t('informationMenuItem4')}</MenuItem>*/}
                    </StyledCol>
                        <StyledCol>
                            <Title>{t('informationMenuHeader2')}</Title>
                            <Hr />
                            <MenuItem onClick={() => onMenuClick(SUCCESSFUL_MODEL_DDL)}>{t('informationMenuItem5')}</MenuItem>
                            <Title>{t('informationMenuSubHeader2')}</Title>
                            <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(DACH_SUPPORT)}>{t('informationMenuItem6')}</MenuItem>
                            <MenuItem onClick={() => onMenuClick(GRANTS_RS)}>{t('informationMenuItem7')}</MenuItem>
                            <MenuItem onClick={() => window.location = 'https://dijaspora.pks.rs/atlas/index.html'}>{t('informationMenuItem8')}</MenuItem>
                        </StyledCol></>}
            </FlexRow>
        </Menu>
    );
}