import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import React, {useState, useEffect} from "react";
import {Spinner, SpinnerWrapper} from "../../components/Spinner";
import {CenteredCol, CenteredRow} from "../../components/Flex";
import UserCard from "../UsersFindPage/card";
import Pagination from "../../components/Pagination";
import {getAllSuccessfulInvestments, searchSuccessfulInvestmentAsync} from "../../services/api";
import {useTranslation} from "react-i18next";
import InvestmentCard from "./InvestmentCard";
import SuccessfullModal from "./Modal";
import {useLocation} from "react-router-dom";

export default function SuccessfulInvestmentsPage(){
    const location = useLocation();
    const [windowSize, setWindowSize] = useState(null);
    const [loadingSuccessfulInvestments, setLoadingSuccessfulInvestments] = useState(true)
    const [allSuccessfulInvestments, setAllSuccessfulInvestments] = useState([{}])
    const [investDetails, setInvestDetails] = useState()
    const {t} = useTranslation();
    let params = location?.state;

    const [filter, setFilter] = useState({
        rowPerPage: 20,
        sortBy: "createdAt,ASC",
    });
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });

    useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    // let parsedFilter = `?page=${page.currentPage}&sort=${sort.sortBy}&size=${sort.rowPerPage}`


    useEffect(() => {
        let parsedFilter = `?page=${page.currentPage}&sort=${filter.sortBy}&size=${filter.rowPerPage}`
        if(params){
            searchSuccessfulInvestmentAsync(params, parsedFilter).then(response => {
               // setLoading(false);
                setPage({totalPages: response.data.totalPages, currentPage: response.data.pageable.pageNumber,
                    totalElements: response.data.totalElements})
                parsedFilter = `?page=${response.data.pageable.pageNumber}&sort=${filter.sortBy}&size=${filter.rowPerPage}`
                setAllSuccessfulInvestments(response.data.content)
                setLoadingSuccessfulInvestments(false)
            }).catch(_ => {
                setLoadingSuccessfulInvestments(false)
            });
        } else {
            getAllSuccessfulInvestments().then(r => {
                if(r.data.content){
                    setAllSuccessfulInvestments(r.data.content)
                    setPage({...page, totalPages: r?.data.totalPages, totalElements: r.data.totalElements})
                }
                setLoadingSuccessfulInvestments(false)
            }).catch(e => {
                console.error(e)
            })
        }

    }, [page.currentPage])

    const handleClickPage = (event, value) => {
        setPage({...page, currentPage: parseInt(value) - 1});
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);


    return(
        <Container>
            {open && <SuccessfullModal open={open} setOpen={setOpen} investDetails={investDetails}/>}
            {windowSize?.width < 740 && <Sidebar isMobile={true}/>}
            {loadingSuccessfulInvestments ? <SpinnerWrapper><Spinner/></SpinnerWrapper> :
                <CenteredCol style={{margin: '2rem', backgroundColor: "#F7FAFD", padding: '1rem',
                    borderRadius: '5px', minHeight: '50vh'}}>
                    <h1 style={{
                        marginTop: '1.5rem',
                        color: "#5c5c5c"
                    }}>{t('numOfSuccessfulInvestments')}: {page.totalElements}</h1>
                    <CenteredRow style={{gap: '1rem', flexWrap: "wrap", marginTop: '2rem'}}>
                        {allSuccessfulInvestments.map(invest => (
                           <InvestmentCard invest={invest} setOpen={setOpen} setInvestDetails={setInvestDetails}/>
                        ))}
                    </CenteredRow>
                    {page.totalElements !== 0  && <Pagination
                        totalPages={page.totalPages}
                        handleClickPage={handleClickPage}
                        currentPage={parseInt(page.currentPage) + 1}/>}
                </CenteredCol>
            }
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 4rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF;
    
    @media (max-width: 648px) {
        padding: 0.2rem;
    }
`
