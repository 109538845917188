export const LANGUAGES = {
    serbian: 1,
    serbianCyr: 2,
    english: 3,
    german: 4
}


export function getIndexOfProjectLanguage(project, languageId) {
    if (!project)
        return -1;
    let projectLanguages = project?.projectLanguages;
    if (projectLanguages?.length === 0)
        return -1
    let index = -1;
    for (let i = 0; i < projectLanguages?.length; i++) {
        if (projectLanguages[i].language?.id === languageId)
            index = i;
    }
    return index;
}

export function getIndexOfRRAProjectLanguage(project, languageId) {
    if (!project)
        return -1;
    let projectLanguages = project?.rraProjectLanguages;
    if (projectLanguages?.length === 0)
        return -1
    let index = -1;
    for (let i = 0; i < projectLanguages?.length; i++) {
        if (projectLanguages[i].language?.id === languageId)
            index = i;
    }
    return index;
}

