import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {CenteredRow, FlexCol, FlexRow} from "../../components/Flex";
import {getEventById} from "../../services/api";
import {NormalText} from "../../components/Text";
import DateRangeIcon from '@mui/icons-material/DateRange';
import TimerIcon from '@mui/icons-material/Timer';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import User from "./user";
import Button from "@mui/material/Button";
import ConnectModal from "../UserPage/connectModal";
import {useTranslation} from "react-i18next";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 4rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF;
    
    @media (max-width: 648px) {
        padding: 0.2rem;
    }
`

const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    min-height: 300px;
    width: 80%;
    padding: 2rem;
    background-color: #fff;
    
     @media (max-width: 648px) {
        width: 100%;   
    }   
`

const BText = styled.p`
    font-weight: 700;
    padding: 0;
`
const BootstrapButton = styled(Button)({
    width: '140px',
})

const Text = styled(NormalText)`
    width: 100%;
    line-height: 20px; //for not webkit browser
    max-height: 120px; //for not webkit browser
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
`
export default function EventPage() {
    const [events, setEvents] = useState()
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id')
    const {t} = useTranslation();
    const [openConnect, setOpenConnect] = useState(false)

    function handleConnect() {
        setOpenConnect(true)
    }

    useEffect(() => {
        getEventById(id).then(res => {
            setEvents(res?.data)
        }).catch(error => {
            console.error(error, 'getEventById')
        })
    }, [])

    return (
        <Container>
            <ConnectModal openConnect={openConnect} setOpenConnect={setOpenConnect} event={events}/>
            <h3 style={{textAlign: 'center', marginBottom: '1.5rem'}}>Pregled događaja</h3>
            <div style={{margin: '2rem', backgroundColor: "#F7FAFD", padding: '1rem', borderRadius: '5px'}}>


                <FlexCol>
                    {!events ? <Box sx={{display: 'flex'}}>
                            <CircularProgress/>
                        </Box> :
                        <CenteredRow style={{gap: '1rem', flexWrap: "wrap"}}>
                            <Card>
                                <FlexCol style={{alignItems: 'center'}}>
                                    {/*<Image style={{height: '60px', width: 'auto'}} src={Logo}/>*/}
                                    <FlexCol>
                                        <BText
                                            style={{
                                                fontSize: '24px',
                                                marginBottom: '1rem'
                                            }}>{events?.name === "" ? "Nema naziv" : events?.name}</BText>
                                    </FlexCol>
                                    <FlexRow style={{gap: '0.5rem'}}>
                                        <FlexRow style={{alignItems: 'center', gap: '0.5rem'}}><DateRangeIcon
                                            color="disabled"/><Text>{events?.date.substr(0, 10)}.</Text></FlexRow>
                                        <FlexRow style={{alignItems: 'center', gap: '0.5rem'}}><TimerIcon
                                            color="disabled"/><Text>{events?.durationMin} min.</Text></FlexRow>
                                    </FlexRow>
                                    <FlexRow
                                        style={{marginBottom: '0.5rem'}}><Text>{events?.description}</Text></FlexRow>
                                    <FlexRow style={{marginBottom: '0.5rem'}}><Text>Učesnici:</Text></FlexRow>
                                    {events.eventInvitationList.length !== 0 ?
                                        <FlexRow style={{gap: '0.5rem'}}>{events.eventInvitationList.map(user => (
                                            <User user={user}/>
                                        ))}</FlexRow> : <FlexRow><Text>Nema učesnika</Text></FlexRow>}
                                    <FlexRow style={{marginTop: '0.5rem'}}> <Text>Tip
                                        eventa: {events?.eventType.name}</Text></FlexRow>
                                    <FlexRow> <Text>Zoom link: <a href="#">{events?.zoomLink}</a></Text></FlexRow>
                                </FlexCol>
                                <CenteredRow style={{marginTop: '0.5rem'}}>
                                    <BootstrapButton onClick={handleConnect}
                                                     variant="contained">{t('linkUpButton')}</BootstrapButton>
                                    {/*<Text>{project?.projectLanguages[0].description === "" ? "Nema opis" : project?.projectLanguages[0]?.description}</Text>*/}
                                </CenteredRow>
                            </Card>
                        </CenteredRow>
                    }
                </FlexCol>

            </div>
        </Container>
    )
}
