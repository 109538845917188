import styled from 'styled-components'
import React, {useState} from 'react';
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import Image from '../../../assets/pticsa.png';
import FeedBack from "../../../components/FeedBack";


const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
 height: calc(100vh - 200px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
`

export default function BrokersAndRepresentatives() {
    const {t} = useTranslation();
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('brokersAndRepresentatives.header')}</h1>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('brokersAndRepresentatives.t1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('brokersAndRepresentatives.t2')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('brokersAndRepresentatives.b1-1')}</b>
                        {t('brokersAndRepresentatives.t3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('brokersAndRepresentatives.b1-2')}</b>
                        {t('brokersAndRepresentatives.t3-1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('brokersAndRepresentatives.t3-2')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('brokersAndRepresentatives.t3-3')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('brokersAndRepresentatives.t4')}
                        <a style={{textDecoration: "none"}} href={"/"}><b style={{color: '#243b85'}}>{t('brokersAndRepresentatives.b1')}</b></a>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('brokersAndRepresentatives.t5')}
                        <b onClick={() => setShowModalSendMail(true)} style={{color: '#243b85'}}>{t('brokersAndRepresentatives.b2')}</b>
                        {t('brokersAndRepresentatives.t6')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('brokersAndRepresentatives.t7')}
                    </p>
                    <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>

                </Paper>
            </Container>
        </div>
    )
}
