import * as React from 'react';
import {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {CenteredCol, FlexCol, FlexRow} from "../../components/Flex";
import {useTranslation} from "react-i18next";
import {ButtonL} from "../../components/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {useSnackbar} from "notistack";
import {getAllInteractionReasons, insertInteraction} from "../../services/api";
import {getSavedLanguage} from "../../utils/session";
import {useSelector} from "react-redux";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    maxHeight: '80%',
    overflow: 'auto',
    p: 4,
};

export default function ConnectModal({openConnect, setOpenConnect, project, user, event}) {
    const {t} = useTranslation();
    const userInfo = useSelector((state) => state.user.user)
    const [reasons, setReasons] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const handleClose = () => setOpenConnect(false);
    useEffect(() => {
        getAllInteractionReasons().then(response => {
            setReasons(response.data)
        }).catch(() => {
            enqueueSnackbar(t('connectionModal.getAllReasonsError'), {
                variant: "error"
            })
        })
    }, []);

    const [value, setValue] = React.useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleConnect = () => {
        if (!Boolean(userInfo)) {
            enqueueSnackbar(t('connectionModal.loginError'), {
                variant: "error"
            })
            setOpenConnect(false)
            return;
        }
        if (value === '') {
            enqueueSnackbar(t('connectionModal.chooseReasonError'), {
                variant: "error"
            })
            return;
        }
        let objectForSend = {};
        if (project) {
            objectForSend.projectId = project.id;
            objectForSend.userId = project.userId;
            objectForSend.linkUpReasonId = value
        } else if (event) {
            objectForSend.eventId = event.id;
            objectForSend.userId = event.userId;
            objectForSend.linkUpReasonId = value
        } else if (user) {
            objectForSend.userId = user.userList[0]?.id;
            objectForSend.linkUpReasonId = value
        } else {
            enqueueSnackbar(t('connectionModal.globalError'), {
                variant: "error"
            })
            return;
        }
        insertInteraction(objectForSend).then(_ => {
            setOpenConnect(false)
            enqueueSnackbar(t('connectionModal.successLinkUp'), {
                variant: "success"
            })
        }).catch(error => {
            enqueueSnackbar(t('connectionModal.unSuccessLinkUp'), {
                variant: "error"
            })
            console.error("insertInteraction", error)
        })
    }

    return (
        <Modal
            open={openConnect}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={openConnect}>
                <Box sx={style}>
                    <CenteredCol>
                        <Typography>{t('connectionTitleReason')}</Typography>
                        <FormControl style={{width: "80%", margin: "2rem 0"}} component="fieldset">
                            <FlexCol>
                                <RadioGroup
                                    value={value}
                                    onChange={handleChange}>
                                    {reasons.map((reason) =>
                                        <FormControlLabel value={reason.id} control={<Radio/>}
                                                          label={getLanguageReason(reason)}/>
                                    )}
                                </RadioGroup>
                            </FlexCol>
                        </FormControl>
                        <FlexRow>
                            <ButtonL onClick={handleClose}>{t('cancelButton')}</ButtonL>
                            <ButtonL onClick={handleConnect}>{t('linkUpButton')}</ButtonL>
                        </FlexRow>
                    </CenteredCol>

                </Box>
            </Fade>
        </Modal>
    );
}

function getLanguageReason(reason) {
    let language;
    switch (getSavedLanguage()) {
        case "de":
            language = reason.reasonDe;
            break;
        case "en":
            language = reason.reasonEn;
            break;
        case "cir":
            language = reason.reasonCir;
            break;
        default:
            language = reason.reason;
            break;
    }
    return language;
}
