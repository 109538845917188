import {CenteredCol, CenteredRow, Flex, FlexCol, FlexRow} from "../../components/Flex";
import UserImage from "../../assets/images/user.svg";
import React, {useEffect, useState} from "react";
import {getRating, getResults, getUserProfileImageAsync} from "../../services/api";
import styled from "styled-components";
import {NormalText} from "../../components/Text";
import {CircularProgress} from "@mui/material";
import {USER_PAGE} from "../../config/routes";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import TransitionsModal from "../UserPage/modalPreview";
import ConnectModal from "../UserPage/connectModal";
import ReactStars from "react-stars";

const ProfileImage = styled.img`
  border-radius: 50px;
  height: 50px;
  width: 50px;
  object-fit: cover;
`

const Card = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 400px;
  width: 400px;
  padding: 1rem;
  background-color: #fff;

  @media (max-width: 648px) {
    width: 100%;

  }
`


const BText = styled.p`
  font-weight: 700;
  padding: 0;
`

const Text = styled(NormalText)`
  width: 100%;
  line-height: 20px; //for not webkit browser
  max-height: 40px; //for not webkit browser
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const BootstrapButton = styled(Button)({
    width: '140px',
})

export function Rating({userid}) {

    const [rating, setRating] = useState(0);

    useEffect(() => {
        getRating(userid).then((res) => {
            setRating(res.data);
        })
    }, [userid])

    return (

        <ReactStars
            edit={false}
            value={rating}
            count={5}
            size={24}
            color2={'#ffd700'}/>

    )
}

export default function UserCard({user, menuType}) {
    const {t} = useTranslation();
    const history = useHistory()
    const [userImage, setUserImage] = useState();
    const [loadingImage, setLoadingImage] = useState(false);
    const [open, setOpen] = useState(false);
    const [pojectInfo, setPojectInfo] = useState()
    const [hideBtn, setHideBtn] = useState(false)
    const [openConnect, setOpenConnect] = useState(false);
    const [userDetails, setUserDetails] = useState()

    const handlePreviewUser = () => {
        history.push({
            pathname: USER_PAGE,
            search: "?menuType=" + menuType + "&id=" + user.id
        });
    }

    useEffect(() => {
        setLoadingImage(true);
        getUserProfileImageAsync(user?.id).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(() => {
            setLoadingImage(false);
        })
    }, [user.id])

    function handleConnect() {
        getResults(user.id).then(response => {
            setUserDetails(response.data)
            setOpenConnect(true)
        }).catch()
    }

    return (
        <Card>
            <ConnectModal openConnect={openConnect} setOpenConnect={setOpenConnect} user={userDetails}/>
            <TransitionsModal open={open} setOpen={setOpen} project={pojectInfo}/>
            <FlexCol style={{marginTop: '1rem'}}>
                {loadingImage ? <CircularProgress/>
                    : <Flex style={{justifyContent: 'center'}}><ProfileImage src={userImage || UserImage}/></Flex>
                }
                <BText style={{marginTop: '2rem', alignSelf: 'center'}}>{user?.name + ' ' + user?.lastName}</BText>
                <CenteredRow><Rating userid={user.id} /></CenteredRow>
                <CenteredRow><FlexCol style={{height: '80px'}}>
                    <Text
                        style={{marginTop: '2rem'}}>{user?.about ? user.about : "Korisnik trenutno nema kratak opis svog profila"}</Text>
                </FlexCol></CenteredRow>
                <FlexRow style={{height: '70px'}}>
                    <Text style={{marginTop: '1rem'}}>{t('city')}: {user?.city.name}</Text>
                    <Text style={{marginTop: '1rem'}}>{t('firm')}: {user?.company}</Text>
                </FlexRow>
                <CenteredRow style={{gap: '0.5rem', marginTop: '1rem'}}>
                    <BootstrapButton stlye={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                     onClick={() => handlePreviewUser()}
                                     variant="contained">{t('details')}</BootstrapButton>
                    {!hideBtn &&
                        <BootstrapButton onClick={handleConnect}
                                         variant="contained">{t('linkUpButton')}</BootstrapButton>}
                </CenteredRow>
            </FlexCol>
        </Card>
    )
}
