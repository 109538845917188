import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {getHasConnection, getResults, getUserProfileImageAsync} from "../../services/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CenteredCol, CenteredRow, FlexCol, FlexRow} from "../../components/Flex";
import {NormalText} from "../../components/Text";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TimerIcon from "@mui/icons-material/Timer";
import Users from "./users";
import TransitionsModal from "./modalPreview";
import Button from "@mui/material/Button";
import ConnectModal from "./connectModal";
import {ButtonL} from "../../components/Button";
import {useTranslation} from "react-i18next";
import ModalRRA from "./modalRRaPreview";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import AvatarGroup from '@mui/material/AvatarGroup';
import {Avatar} from "@mui/material";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 4rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF;
    
    @media (max-width: 648px) {
        padding: 0.2rem;
    }
`
const ProfileImage = styled.img`
    border-radius: 50px;
    height: 100px;
    width: 100px;
    object-fit: cover;
`

const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 300px;
    width: 400px;
    padding: 2rem;
    background-color: #fff;
    
    &:hover{
        cursor: pointer;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
    
     @media (max-width: 648px) {
        width: 100%;
        
    }    
`
const Card2 = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 300px;
    width: 400px;
    padding: 2rem;
    background-color: #fff;
    
     @media (max-width: 648px) {
        width: 100%;
        
    }    
`

const BootstrapButton = styled(Button)({
    width: '140px',
})

const BText = styled.p`
    font-weight: 700;
    padding: 0;
`

const Text = styled(NormalText)`
    width: 100%;
    line-height: 20px; //for not webkit browser
    max-height: 120px; //for not webkit browser
    overflow: hidden;
    height: 80px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`

const Btn = styled(ButtonL)`
    display: ${(props) => props.display ? 'none' : '160px'};
`

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function UserPage() {
    let query = useQuery();
    const {t} = useTranslation();
    const {user} = useSelector((state) => state.user)
    const {enqueueSnackbar} = useSnackbar();
    const [userImage, setUserImage] = React.useState();
    const [loadingImage, setLoadingImage] = React.useState(false);
    const [loadingProjects, setLoadingProjects] = React.useState(false);
    const [userDetails, setUserDetails] = useState();
    const [open, setOpen] = React.useState(false);
    const [openRra, setOpenRra] = React.useState(false);
    const [openConnect, setOpenConnect] = React.useState(false);
    const [pojectInfo, setPojectInfo] = useState()
    const [pojectInfoRRA, setPojectInfoRRA] = useState()
    const [connected, setConnected] = useState(false)
    const [hideBtn, setHideBtn] = useState(false)
    useEffect(() => {
        setLoadingProjects(true)
        getResults(query.get("id")).then(response => {
            setUserDetails(response.data)
            setLoadingProjects(false)
        }).catch(error => {
            setLoadingProjects(false)
        })
    }, []);

    useEffect(() => {
        loadUserImage(userDetails?.id);
    }, [userDetails?.id]);

    useEffect(() => {
        if (parseInt(user?.id) !== parseInt(query.get("id")) && user?.id !== undefined) {
            getHasConnection(query.get("id")).then(response => {
                setConnected(response.data)

            })
        } else setHideBtn(true)
    })

    async function loadUserImage(userId) {
        setLoadingImage(true);
        getUserProfileImageAsync(userId).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(reason => {
            setLoadingImage(false);

        });
    }

    function handlePreviewProjectClick(project) {
        setOpen(true)
        setPojectInfo(project)
    }

    function handlePreviewRRAProjectClick(project) {
        setOpenRra(true)
        setPojectInfoRRA(project)
    }

    function handleConnect() {
        setOpenConnect(true)
    }

    return (
        <Container>
            <TransitionsModal open={open} setOpen={setOpen} project={pojectInfo}/>
            <ConnectModal openConnect={openConnect} setOpenConnect={setOpenConnect} user={userDetails}/>
            <ModalRRA open={openRra} setOpen={setOpenRra} project={pojectInfoRRA}/>

            <CenteredCol>
                <FlexRow style={{gap: '1rem'}}>
                    <AvatarGroup max={9}>
                    {userDetails?.userList.map(user => (
                        <Users user={user}/>
                    ))}</AvatarGroup></FlexRow>

                {loadingProjects ? <CircularProgress/> : <>
                    {userDetails?.userList[0]?.roleId == 12 ?
                        <FlexRow><h1>{userDetails?.userList[0]?.organizationalUnitName}</h1></FlexRow>
                        :
                        <FlexRow><h1>{userDetails?.userList[0]?.company}</h1></FlexRow>
                    }
                    <NormalText>{userDetails?.userList[0]?.role?.name}</NormalText>
                </>}
                {hideBtn ? null : <>{connected ? <ButtonL disabled variant="contained">{t('connected')}</ButtonL>
                    : <ButtonL onClick={handleConnect} variant="contained">{t('linkUpButton')}</ButtonL>}</>}

            </CenteredCol>
            <div style={{margin: '2rem', backgroundColor: "#F7FAFD", padding: '1rem', borderRadius: '5px'}}>

                {userDetails?.projectList !== undefined &&
                <>
                    <h1 style={{marginTop: '1.5rem', color: "#5c5c5c"}}>{t('investOpportunity')}
                        ({userDetails?.projectList.length})</h1>
                    <FlexRow style={{gap: '1rem', flexWrap: "wrap"}}>
                        {userDetails?.projectList?.map((project, index) => (
                            <Card key={index}>
                                <FlexCol style={{marginTop: '2rem'}}>
                                    <CenteredRow><BText>{project?.projectLanguages[0]?.name}</BText></CenteredRow>
                                    <Text style={{marginTop: '2rem'}}>{project?.projectLanguages[0]?.description}</Text>
                                </FlexCol>
                                <CenteredRow style={{gap: '0.5rem', marginTop: '1rem'}}>
                                    <BootstrapButton stlye={{
                                        maxWidth: '30px',
                                        maxHeight: '30px',
                                        minWidth: '30px',
                                        minHeight: '30px'
                                    }} onClick={() => {
                                        handlePreviewProjectClick(project)
                                    }} variant="contained">{t('details')}</BootstrapButton>
                                    {!hideBtn && <BootstrapButton onClick={handleConnect}
                                                                  variant="contained">{t('linkUpButton')}</BootstrapButton>}
                                </CenteredRow>
                            </Card>
                        ))}
                    </FlexRow>
                </>
                }
                {userDetails?.rraProjectList !== undefined &&
                <>
                    <h1 style={{marginTop: '1.5rem', color: "#5c5c5c"}}>Investicione prilike RRA
                        ({userDetails?.rraProjectList.length})</h1>
                    <FlexRow style={{gap: '1rem', flexWrap: "wrap"}}>
                        {userDetails?.rraProjectList?.map((project, index) => (
                            <Card key={index}>
                                <FlexCol style={{marginTop: '2rem'}}>
                                    <CenteredRow><BText>{project?.rraProjectLanguages[0].name}</BText></CenteredRow>
                                    <Text
                                        style={{marginTop: '2rem'}}>{project?.rraProjectLanguages[0]?.documentationDescription}</Text>
                                </FlexCol>
                                <CenteredRow style={{gap: '0.5rem', marginTop: '1rem'}}>
                                    <BootstrapButton onClick={() => {
                                        handlePreviewRRAProjectClick(project)
                                    }} variant="contained">{t('details')}</BootstrapButton>
                                    <BootstrapButton onClick={handleConnect}
                                                     variant="contained">{t('linkUpButton')}</BootstrapButton>
                                </CenteredRow>
                            </Card>
                        ))}
                    </FlexRow>
                </>
                }

                {userDetails?.eventList.length !== 0 &&
                <>
                    <h1 style={{marginTop: '1.5rem', color: "#5c5c5c"}}>{t('searchAutocomplete.events')}
                        ({userDetails?.eventList.length})</h1>
                    <FlexRow style={{gap: '1rem', flexWrap: "wrap"}}>
                        {userDetails?.eventList?.map((project, index) => (
                            <Card2 key={index}>
                                <FlexCol style={{marginTop: '2rem'}}>
                                    <CenteredRow><BText>{project?.name}</BText></CenteredRow>
                                    <Text style={{marginTop: '2rem', height: '100px'}}>{project?.description}</Text>
                                    <FlexRow style={{gap: '0.5rem'}}>
                                        <FlexRow style={{alignItems: 'center', gap: '0.5rem'}}><DateRangeIcon
                                            color="disabled"/><NormalText>Datum: {project?.date.substr(0, 10)}</NormalText></FlexRow>
                                        <FlexRow style={{alignItems: 'center', gap: '0.5rem'}}><TimerIcon
                                            color="disabled"/><NormalText>Trajanje: {project?.durationMin} min.</NormalText></FlexRow>
                                    </FlexRow>
                                    <a style={{textDecoration: 'none'}} href="#" target="_blank"
                                       rel="noreferrer">{t('eventPreview.zoomLink')}</a>
                                </FlexCol>
                            </Card2>
                        ))}

                    </FlexRow>
                </>
                }
            </div>
        </Container>
    )
}
