import styled from "styled-components";

export const Flex = styled.div`
    display: flex;
`;

export const FlexCol = styled(Flex)`
    flex-direction: column;
`;

export const FlexRow = styled(Flex)`
    flex-direction: row;
`;

export const FlexRowSpace = styled(Flex)`
    flex-direction: row;
    justify-content: space-between;
`;

export const CenteredRow = styled(FlexRow)`
    justify-content: center;
    align-items: center;
`;

export const CenteredCol = styled(FlexCol)`
    justify-content: center;
    align-items: center;
`;

export const FlexAlign = styled(FlexRow)`
    align-items: center;
`;
