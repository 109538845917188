import {NormalText} from "../Text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import {getAllInvestmentTypes} from "../../services/api";
import {TextArea} from "./stepThree";
import {useTranslation} from "react-i18next";
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import styled from "styled-components";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    `

export default function InvestitorRegistration({ businessSectors, user, changeUserParam, businessActivities }) {
    const { t } = useTranslation();
    const [investmentTypes, setInvestmentTypes] = useState([]);

    React.useEffect(() => {
        getAllInvestmentTypes().then(response => {
            setInvestmentTypes(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, [])
    return (
        <>
            <NormalText>{t('registration.investor.question1')}</NormalText>
            <TextArea value={user?.investments} type="text" onChange={(e) => {
                changeUserParam("investments", e.target.value)
            }} />
            <NormalText>{t('registration.investor.question2')}</NormalText>
            <Row>
                <Autocomplete
                    multiple
                    value={user?.businessActivityList}
                    defaultValue={user?.businessActivityList}
                    options={businessActivities}
                    onChange={(event, newValue) => {
                        changeUserParam("businessActivityList", newValue)
                    }}
                    getOptionLabel={(option) => option?.name}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                />
                <Tooltip title={t('clasificationToolTipText')} aria-label="add" style={{ height: "100%" }}>
                    <HelpIcon />
                </Tooltip>
            </Row>

            <NormalText>{t('registration.investor.question4')}</NormalText>
            <Row>
                <Autocomplete
                    multiple
                    value={user?.investorBusinessSectors}
                    defaultValue={user?.investorBusinessSectors}
                    options={businessSectors}
                    onChange={(event, newValue) => {
                        changeUserParam("investorBusinessSectors", newValue)
                    }}
                    getOptionLabel={(option) => option?.name}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                />
                <Tooltip title={t('clasificationToolTipText')} aria-label="add" style={{ height: "100%" }}>
                    <HelpIcon />
                </Tooltip>
            </Row>
            <NormalText>{t('registration.investor.question3')}</NormalText>
            <Autocomplete
                multiple
                value={user?.investmentsIds}
                defaultValue={user?.investmentsIds}
                options={investmentTypes}
                onChange={(event, newValue) => {
                    changeUserParam("investmentsIds", newValue)
                }}
                getOptionLabel={(option) => option?.name}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
            />

        </>
    );
}