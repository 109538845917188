import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {FlexCol} from "../../../components/Flex";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "10px",
        maxWidth: "350px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5%',
    },
    paper: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        padding: "20px"
    },
    title: {
        fontSize: 14,
    },
    media: {
        height: 140,
    },
    image: {
        maxHeight: '600px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '200px',
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: '400px',
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: '600px',
        },
    },
    description: {
        padding: "20px",
        fontSize: "20px"
    }
}));

export default function CardComponents({image, text, head}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Card className={classes.root} onClick={handleOpen} variant="outlined">
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={image}
                        title="Contemplative Reptile"
                    />
                    <CardContent>
                        <Typography variant="h4" className={classes.title} color="textSecondary" gutterBottom>
                            {head}
                        </Typography>
                        <Typography variant="p" component="p" style={{color: '#243b85'}}>
                            {text}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <FlexCol className={classes.paper}>
                        <h2 id="transition-modal-title">{head}</h2>
                        <img className={classes.image} src={image} alt="image"/>
                        <p className={classes.description}>{text}</p>
                    </FlexCol>
                </Fade>
            </Modal>
        </>
    );
}