import React, {useState} from "react";
import styled from "styled-components";
import UserProfilePhoto from '../../assets/images/user.svg'
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {FileChooser, InputDiv, InputS} from "../Input";
import {NormalText} from "../Text";
import {useTranslation} from "react-i18next";
import {TextArea} from "./stepThree";
import {getAllCities, getAllCountries} from "../../services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`

const S = styled.span`
    font-weight: 500;
    color: grey;
    padding-bottom: 0.5rem;
`

const Image = styled.img`
    width: 100%;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 100
`

export default function StepTwo({ changeUserParam, changeUserParam2, user, imgFile, setImgFile }) {
    const { t } = useTranslation();
    const handleFileChange = (event) => {
        if (event.target && event.target.files[0]) {
            setImgFile(event.target.files[0]);
        }
    }
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    React.useEffect(() => {
        getAllCountries().then(response => {
            setCountries(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllCities().then(response => {
            setCities(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, [])

    return (
        <Container>
            <Stepper activeStep={1}>
                <Step>
                    <StepLabel />
                </Step>
                <Step>
                    <StepLabel />
                </Step>
                <Step>
                    <StepLabel />
                </Step>
            </Stepper>
            <Row style={{ marginBottom: '1.5rem' }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '2rem',
                    maxWidth: "150px"
                }}>
                    <S>{t('picture')}</S>
                    <Image src={imgFile ? URL.createObjectURL(imgFile) : UserProfilePhoto} alt="profile" />
                </div>
                <InputDiv>
                    <FileChooser htmlFor="filePicker">{t('chooseFile')}</FileChooser>
                    <InputS id="filePicker" style={{ visibility: "hidden" }} type="file" accept="image/*"
                        onChange={handleFileChange} />
                </InputDiv>
            </Row>
            <Row>
                <Col>
                    <NormalText>{t('firstName')}</NormalText>
                    <InputDiv>
                        <InputS type="text" value={user?.name}
                            onChange={(e) => changeUserParam("name", e?.target?.value)} />
                    </InputDiv>
                </Col>
                <Col>
                    <NormalText>{t('lastName')}</NormalText>
                    <InputDiv>
                        <InputS type="text" value={user?.lastName}
                            onChange={(e) => changeUserParam("lastName", e?.target?.value)} />
                    </InputDiv>
                </Col>
            </Row>
            <NormalText>{t('firm')}</NormalText>
            <InputDiv>
                <InputS type="text" value={user?.company}
                        onChange={(e) => changeUserParam("company", e?.target?.value)}/>
            </InputDiv>
            <Row style={{flexWrap: 'wrap'}}>
                <Col>
                    <NormalText>{t('country')}</NormalText>
                    {/* <InputDiv>
                        <InputS type="text" value={user?.country}
                                onChange={(e) => changeUserParam("country", e?.target?.value)}/>
                    </InputDiv> */}
                    <Autocomplete
                        value={user?.country}
                        defaultValue={user?.country}
                        options={countries}
                        onChange={(event, newValue) => {
                            changeUserParam("countryId", newValue?.id);
                            changeUserParam2("country", newValue);
                        }}
                        getOptionLabel={(option) => option?.name}
                        style={{minWidth: 'auto'}}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
                    />
                </Col>

                <Col>
                    <NormalText>{t('city')}</NormalText>
                    {
                        user?.country?.id == 4 ? <Autocomplete
                                value={user?.city}
                                defaultValue={user?.city}
                                options={cities}
                                onChange={(event, newValue) => {
                                changeUserParam("city", newValue)
                            }}
                                getOptionLabel={(option) => option?.name}
                                style={{width: 'auto'}}
                                renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
                        /> :
                            <InputDiv>
                                <InputS value={user?.cityString} type="text"
                                    onChange={(e) => changeUserParam("cityString", e?.target?.value)} />
                            </InputDiv>
                    }
                </Col>
            </Row>

            <NormalText>{t('phoneNumber')}</NormalText>
            <InputDiv>
                <InputS type="text" value={user?.phone} onChange={(e) => changeUserParam("phone", e?.target?.value)} />
            </InputDiv>
            <NormalText>{t('aboutSelfText')}</NormalText>
            <TextArea value={user?.aboutSelf} onChange={(e) => {
                changeUserParam("aboutSelf", e?.target?.value)
            }} type="text">
            </TextArea>
        </Container>
    )
}
