import React, {useState} from "react";
import styled from "styled-components";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import {getAllBusinessActivities, getAllBusinessSector, getAllCountries} from "../../services/api";
import PojedinacRegistration from "./pojedinac";
import PreduzetnikRegistration from "./preduzetnik";
import InvestitorRegistration from "./investitor";
import BrokerRegistration from "./broker";
import MentorRegistration from "./mentor";
import DachRegistration from "./dach";
import PksRegistration from "./pks";
import PrivrednaKomoraSrbije from "./privrednaKomora";
import RanjiveGrupeRegistration from "./ranjiveGrupe";
import RraRegistration from "./rra";
import MedijiRegistration from "./mediji";
import {useTranslation} from "react-i18next";
import EnterpriseRegistration from "./enterprise";
import OtherRegistration from "./other";

export const TextArea = styled.textarea`
    padding: 15px 20px;
    background: #fff;
    color: #333;
    display: flex;
    font-size: 16px;
    width: auto;
    border: 1px solid #cfd1d0;
    resize: vertical;
     min-height: 90px;
    max-height: 150px;
    margin-bottom: 1.5rem;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
`

export default function StepThree({roleString, changeUserParam, user}) {
    const [businessSectors, setBusinessSectors] = useState([]);
    const [businessActivities, setBusinessActivities] = useState([]);
    const [countries, setCountries] = useState([]);
    const {t} = useTranslation();
    React.useEffect(() => {
        getAllBusinessSector().then(response => {
            setBusinessSectors(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllCountries().then(response => {
            setCountries(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllBusinessActivities().then(response => {
            setBusinessActivities(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, [roleString])
    let view;

    switch (roleString) {
        case t('roles.individual'):
            view =
                <PojedinacRegistration
                    user={user}
                    businessSectors={businessSectors}
                    changeUserParam={changeUserParam}/>
            break;
        case t('roles.entrepreneur'):
            view =
                <PreduzetnikRegistration
                    changeUserParam={changeUserParam}
                    user={user}
                    businessSectors={businessSectors}
                    countries={countries}/>
            break;
        case t('roles.enterprise'):
            view =
                <EnterpriseRegistration
                    changeUserParam={changeUserParam}
                    user={user}
                    businessSectors={businessSectors}
                    countries={countries}/>
            break;
        case t('roles.other'):
            view =
                <OtherRegistration
                    changeUserParam={changeUserParam}
                    user={user}
                    businessSectors={businessSectors}
                    countries={countries}/>
            break;
        case t('roles.investor'):
            view =
                <InvestitorRegistration
                    businessSectors={businessSectors}
                    user={user}
                    changeUserParam={changeUserParam}
                    businessActivities={businessActivities}/>
            break;
        case t('roles.broker'):
            view = <BrokerRegistration
                businessActivities={businessActivities}
                changeUserParam={changeUserParam}
                user={user}/>
            break;
        case t('roles.mentor'):
            view = <MentorRegistration
                user={user}
                businessActivities={businessActivities}
                changeUserParam={changeUserParam}
                countries={countries}/>
            break;
        case t('roles.dach'):
            view = <DachRegistration
                user={user}
                changeUserParam={changeUserParam}/>
            break;
        case t('roles.pks'):
            view = <PksRegistration changeUserParam={changeUserParam} user={user}/>
            break;
        case t('roles.privrednaKomora'):
            view = <PrivrednaKomoraSrbije changeUserParam={changeUserParam} user={user}/>
            break;
        case t('roles.vulnerableGroup'):
            view = <RanjiveGrupeRegistration changeUserParam={changeUserParam} user={user}/>
            break;
        case t('roles.rra'):
            view = <RraRegistration changeUserParam={changeUserParam} user={user}/>
            break;
        case t('roles.media'):
            view = <MedijiRegistration changeUserParam={changeUserParam} user={user} countries={countries}/>
            break;
        default:
            view = <PojedinacRegistration
                user={user}
                businessSectors={businessSectors}
                changeUserParam={changeUserParam}/>
            break;
    }

    return (
        <Container>
            <Stepper activeStep={2}>
                <Step>
                    <StepLabel/>
                </Step>
                <Step>
                    <StepLabel/>
                </Step>
                <Step>
                    <StepLabel/>
                </Step>
            </Stepper>
            {view}
        </Container>
    )
}
