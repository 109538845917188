import Image from '../../../assets/mentor.jpg'
import Image1 from '../../../assets/mentor1.png'
import Image2 from '../../../assets/mentor2.png'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Container, Paper} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";
import React from "react";

const Banner = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    background-color: #243b85;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const useStyles = makeStyles({
    root: {
        maxWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function MentorshipPage() {
    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>

            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('mentorship.header')}</h1>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <div style={{
                marginTop: "1%",
                marginBottom: "1%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img style={{objectFit: 'cover', width: '100%', maxWidth: '800px'}} src={Image} alt="image"/>
            </div>

            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.b1')}</b>{t('mentorship.description1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.b2')}</b>{t('mentorship.description2')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.programForNumber')}1</b>
                        <br/>
                        {t('mentorship.program1')}
                        <br/>
                        <b style={{color: '#243b85'}}>{t('mentorship.detailed')}</b>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.programForNumber')}2</b>
                        <br/>
                        {t('mentorship.program2')}
                        <br/>
                        <b style={{color: '#243b85'}}>{t('mentorship.detailed')}</b>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.programForNumber')}3</b>
                        <br/>
                        {t('mentorship.program3')}
                        <br/>
                        <b style={{color: '#243b85'}}>{t('mentorship.detailed')}</b>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.programForNumber')}4</b>
                        <br/>
                        {t('mentorship.program4')}
                        <br/>
                        <b style={{color: '#243b85'}}>{t('mentorship.detailed')}</b>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.programForNumber')}5</b>
                        <br/>
                        {t('mentorship.program5')}
                        <br/>
                        <b style={{color: '#243b85'}}>{t('mentorship.detailed')}</b>
                    </p>


                    <p style={{marginTop: "1%", marginBottom: "1%"}}>
                        <img style={{width: "80%", marginLeft: "10%"}} src={Image1} alt="image"/>
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.b3')}</b>{t('mentorship.description3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.b4')}</b>{t('mentorship.description4')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.b5')}</b>{t('mentorship.description5')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.b6')}</b>{t('mentorship.description6')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.howToParticipate')}</b>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%", marginLeft: "2%"}}>
                        <ol>
                            <li>{t('mentorship.howToParticipate1')}</li>
                            <li>{t('mentorship.howToParticipate2')}</li>
                            <li>{t('mentorship.howToParticipate3')}</li>
                            <li>{t('mentorship.howToParticipate4')}</li>
                            <li>{t('mentorship.howToParticipate5')}</li>
                            <li>{t('mentorship.howToParticipate6')}</li>
                        </ol>
                    </p>

                    <p style={{marginTop: "1%", marginBottom: "1%"}}>
                        <img style={{width: "80%", marginLeft: "10%"}} src={Image2} alt="image"/>
                    </p>

                    <h4 style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('mentorship.typoTitle')}</b>
                    </h4>
                    <Grid container spacing={3} style={{padding: "2%"}}>
                        <Grid item xs={3}>

                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h4" className={classes.title} color="textSecondary"
                                                gutterBottom>
                                        {t('mentorship.typo')}
                                    </Typography>
                                    <Typography variant="h3" component="h2" style={{color: '#243b85'}}>
                                        50
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>

                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h4" className={classes.title} color="textSecondary"
                                                gutterBottom>
                                        {t('mentorship.typo2')}
                                    </Typography>
                                    <Typography variant="h3" component="h2" style={{color: '#243b85'}}>
                                        50
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>

                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h4" className={classes.title} color="textSecondary"
                                                gutterBottom>
                                        {t('mentorship.typo3')}
                                    </Typography>
                                    <Typography variant="h3" component="h2" style={{color: '#243b85'}}>
                                        50
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={3}>

                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h4" className={classes.title} color="textSecondary"
                                                gutterBottom>
                                        {t('mentorship.typo4')}
                                    </Typography>
                                    <Typography variant="h3" component="h2" style={{color: '#243b85'}}>
                                        50
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                    <h4>{t('mentorship.registration')}<a href={"/"} style={{textDecoration: "none"}}><b style={{color: '#243b85'}}>{t('mentorship.mentor')}</b></a></h4>
                    <h4>{t('mentorship.registration')}<a href={"/"} style={{textDecoration: "none"}}><b style={{color: '#243b85'}}>{t('mentorship.mentorisani')}</b></a>
                    </h4>
                    <h4>{t('mentorship.view')}<b style={{color: '#243b85'}}>{t('mentorship.archive')}</b></h4>
                    <h4>{t('mentorship.impressions')}</h4>
                </Paper>
            </Container>


        </div>
    )
}
