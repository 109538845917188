import React, {useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@material-ui/core/Box";
import AllSearchedProjects from "./AllSearchedProjects";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import AllSearchedRRAProjects from "./AllSearchedRRAProjects";
import AllSearchedEvents from "./AllSearchedEevents";
import AllSearchedUsers from "./AllSearchedUsers";
import AllSearchedLocations from "./AllSearchedLocations";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4rem;
    background-color: #F6F7FA;
    width: 100%;
    min-height: 80vh;
`

export default function AllSearchResults() {
    const {t} = useTranslation()
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example">
                <Tab label={t('investOpportunity')}/>
                <Tab label={t('rraProjects')}/>
                <Tab label={t('events')}/>
                <Tab label={t('investmentLocations')}/>
                <Tab label={t('users')}/>
            </Tabs>
            <TabPanel value={value} index={0}>
                <AllSearchedProjects t={t}/>;
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AllSearchedRRAProjects t={t}/>
            </TabPanel>
            <TabPanel value={value} index={2} >
                <AllSearchedEvents t={t}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <AllSearchedLocations t={t}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <AllSearchedUsers t={t}/>
            </TabPanel>
        </Container>
    )
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div style={{width: '100%'}}
             role="tabpanel"
             hidden={value !== index}
             {...other}
        >
            {value === index && (
                <Box sx={{p: 1}}>
                    {children}
                </Box>
            )}
        </div>
    );
}
