import React from "react";
import styled from "styled-components";
import {FlexRow} from "../Flex";

const Wrapper = styled(FlexRow)`
    padding: 10px;
`;

const Radio = styled.input`
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 10px;
`;

const Label = styled.label`
    padding-left: 10px;
`;

export default function RadioButton({id, value, name, checked, onChange, label}) {
    return (
        <Wrapper>
            <Radio
                id={id}
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <Label htmlFor={id}>{label}</Label>
        </Wrapper>
    );
}