import React from "react";
import styled from "styled-components";

import {AccountType} from "../Dropdown/accountType";
import {InputDiv, InputS} from "../Input";
import {BigText, NormalText, SmallText} from "../Text";
import {CheckBoxS} from "../Checkbox";
import {useTranslation} from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
`
const Header = styled.div`
    display: flex;
    justify-content: center; 
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
export default function StepOne({changeUserParam, user, roleString, setRoleString, onEmailBlur, selectedSktor, setSelectedSektor}) {

    const {t} = useTranslation();
    return (
        <Container>
            <Header>
                <BigText>{t('registration.stepOne.header')}</BigText>
            </Header>
            <NormalText>{t('email')}</NormalText>
            <InputDiv>
                <InputS value={user?.email} defaultValue={user?.email} onBlur={onEmailBlur}
                        onChange={(e) => changeUserParam("email", e?.target?.value)}/>
            </InputDiv>
            <NormalText>{t('password')}</NormalText>
            <InputDiv>
                <InputS type="password" value={user?.password} defaultValue={user?.email}
                        onChange={(e) => changeUserParam("password", e?.target?.value)}/>
            </InputDiv>
            <NormalText>{t('repeatPassword')}</NormalText>
            <InputDiv>
                <InputS type="password" value={user?.repeatPassword} defaultValue={user?.repeatPassword}
                        onChange={(e) => changeUserParam("repeatPassword", e?.target?.value)}/>
            </InputDiv>
            <NormalText>{t('selectSector')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    value={selectedSktor}
                    // defaultValue={selectedSktor}
                    onChange={(e) => {
                        setSelectedSektor(e.target.value)
                        setRoleString(null)
                    }}>
                    {[t('publicSector'), t('privateSector')]?.map((option, key) => {
                        return (<MenuItem key={key} value={option}>{option}</MenuItem>)
                    })}
                </Select>
            </FormControl>
            {/*<SectorType selectedSktor={selectedSktor} setSelectedSektor={setSelectedSektor}/>*/}
            <NormalText>{t('accountType')}</NormalText>
            <AccountType selectedSktor={selectedSktor} roleString={roleString} setRoleString={setRoleString}
                         changeUserParam={changeUserParam}/>
            {/*<Row>*/}
            {/*    <CheckBoxS type="checkbox"/>*/}
            {/*    <SmallText>{t('agreementForNewsletter')}.</SmallText>*/}
            {/*</Row>*/}
        </Container>
    )
}
