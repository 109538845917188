import {FlexCol} from "../../components/Flex";
import React from "react";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";

export default function Investor({userInfo, t, lng}) {

    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <TextField
                fullWidth
                disabled={true}
                id="investments"
                label={t('registration.investor.question1')}
                multiline
                maxRows={4}
                value={userInfo?.investments || ''}
                variant="outlined"
            />
            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="investments"
                    label={t('registration.investor.question2')}
                    multiline
                    maxRows={4}
                    value={userInfo?.businessActivityList || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="investments"
                    label={t('registration.investor.question4')}
                    multiline
                    maxRows={4}
                    value={userInfo?.businessSectors || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.investor.question3')}</NormalText>
                <TextField
                    fullWidth
                    disabled={true}
                    id="investmentTypes"
                    label={t('registration.investor.question3')}
                    multiline
                    maxRows={4}
                    value={userInfo?.investmentTypes || ''}
                    variant="outlined"
                />
            </FlexCol>

        </FlexCol>
    )
}

