import styled from "styled-components";
import {Color} from "../Colors";

export const P = styled.p`
     font-size: 16px;
     font-weight: 300;
     line-height: 1.5625;
     margin: 0;
     padding: 0;
     color: ${Color.midGrey};
     margin-bottom:${(props) => props.marginBottom ? '10px' : '0px'}
`

export const Head3 = styled.h4`
     margin: 0;
     padding: 0;
     color: ${Color.midGrey};
     -webkit-line-clamp: 1;
     overflow:hidden; 
    max-width:100%;
    text-overflow: ellipsis;
`

export const PConnection = styled(P)`
     font-size: 17px;
     font-weight: 500;
     line-height: 1.5625;
     margin: 0;
     padding: 0;
      display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow:hidden;
     color: ${Color.midGrey};
     margin-bottom:${(props) => props.marginBottom ? '10px' : '0px'}
`

export const Pxs = styled.p`
     font-size: 12px;
     font-weight: 300;
     line-height: 1.5625;
     margin: 0;
     padding: 0;
     display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow:hidden;
     color: ${Color.midGrey};
     margin-bottom:${(props) => props.marginBottom ? '10px' : '0px'}
`

export const PUserPost = styled(Pxs)`
     font-size: 13px;
    -webkit-line-clamp: 5;

`

export const SidebarText = styled(P)`
    font-weight: bold;
    letter-spacing: 1px;
`

export const HeaderP = styled.p`
     font-size: 24px;
     font-weight: 600;
     margin: 0;
     padding: 0;
     color: ${Color.grey};
`
