import React, {useEffect} from "react";
import Avatar from '@mui/material/Avatar';
import {getUserProfileImageAsync} from "../../../services/api";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function UserImage({id, name}) {
    const [userImage, setUserImage] = React.useState();
    const [loadingImage, setLoadingImage] = React.useState(false);
    useEffect(() => {
        setLoadingImage(true)
        getUserProfileImageAsync(id).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(() => {
            setLoadingImage(false);

        });
    }, [id]);

    return (
        <>
            {loadingImage ? <CircularProgress/> :
                <Avatar alt={name} src={userImage}/>
            }
        </>
    )
}
