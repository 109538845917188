import * as React from 'react';
import {styled} from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {FlexCol} from "../../components/Flex";
import {useTranslation} from "react-i18next";

const Container = styled('div')(({ theme }) => ({

    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    padding: '0 16',


    [theme.breakpoints.up('md')]: {
        paddingTop: 64,
        paddingBottom: 64,
    },
    [theme.breakpoints.up('sm')]: {
        paddingTop: 48,
        paddingBottom: 48,
    },
    [theme.breakpoints.up('xs')]: {
        paddingTop: 32,
        paddingBottom: 32,
    }
}));

const Title = styled(`h2`)({
    margin: '0px 0px 0.35em',
    fontSize: ' 3.125rem',
    lineHeight: 1.2,
    textAlign: 'center',
    fontWeight: 700,
})

const SubTitle = styled(`p`)({
    margin: '0px 0px 0.35em',
    fontSize: ' 1rem',
    lineHeight: 1.5,
    textAlign: 'center',
    color: 'rgb(103, 119, 136)',
    fontWeight: 500,
})

const UpTitle = styled(SubTitle)({
    textTransform: 'uppercase',
})

const SectionTitle = styled(`h5`)(({ theme }) => ({
    marginBottom: '48px',
    lineHeight: 1.334,
    fontWeight: 700,

    [theme.breakpoints.up('md')]: {
        fontSize: '1.4993rem',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.3118rem',
    },
    [theme.breakpoints.up('xs')]: {
        fontSize: '1.25rem',
    }
}));

const AccordingTitle = styled('p')({
    margin: '0px',
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 600,
})

const AaccordingDescription = styled(`p`)({
    margin:' 0px',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: 'rgb(103, 119, 136)',
})
const AccordionWrapper = styled(Accordion)({
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(30, 32, 34)',
    boxShadow: 'rgb(140 152 164 / 25%) 0px 3px 6px 0px',
    position: 'relative',
    transition:' margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflowAnchor: 'none',
    padding: '8px',
    marginBottom: '16px'
});

export default function FAQPage() {
    const {t} = useTranslation();

    return (
        <div>
            <FlexCol style={{padding: '4rem 0', background: '#F9FBFF'}}>
                <UpTitle>{t('faq')}</UpTitle>
                <Title>{t('haveAQuestion')}</Title>
                <SubTitle>{t('faqAnswers')}</SubTitle>
            </FlexCol>
            <Container>
                <div style={{marginBottom: '3rem'}}>
                    <SectionTitle>{t('faqSection1')}</SectionTitle>
                    <AccordionWrapper>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <AccordingTitle>{t('faqQuestion1')}</AccordingTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AaccordingDescription>
                                {t('faqAnswer1')}
                            </AaccordingDescription>
                        </AccordionDetails>
                    </AccordionWrapper>
                    <AccordionWrapper>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <AccordingTitle>{t('faqQuestion2')}</AccordingTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AaccordingDescription>
                                {t('faqAnswer2')}
                            </AaccordingDescription>
                        </AccordionDetails>
                    </AccordionWrapper>
                    <AccordionWrapper>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <AccordingTitle>{t('faqQuestion3')}</AccordingTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AaccordingDescription>
                                {t('faqAnswer3')}
                            </AaccordingDescription>
                        </AccordionDetails>
                    </AccordionWrapper>
                    <AccordionWrapper>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <AccordingTitle>{t('faqQuestion4')}</AccordingTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AaccordingDescription>
                                {t('faqAnswer4')}
                            </AaccordingDescription>
                        </AccordionDetails>
                    </AccordionWrapper>
                    <AccordionWrapper>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <AccordingTitle>{t('faqQuestion5')}</AccordingTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AaccordingDescription>
                                {t('faqAnswer5')}
                            </AaccordingDescription>
                        </AccordionDetails>
                    </AccordionWrapper>
                </div>
            </Container>

        </div>
    );
}

