import {CenteredRow, FlexCol} from "../../components/Flex";
import React from "react";
import {TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {CheckBoxS} from "../../components/Checkbox";

export default function Special({userInfo, lng, t}) {

    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.vulnerableGroups.question1')}</NormalText>
                <TextField
                    associationName
                    id="associationName"
                    multiline
                    maxRows={4}
                    value={userInfo?.associationName || ''}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.pks.question2')}</NormalText>
                <TextField
                    associationName
                    id="id"
                    multiline
                    maxRows={4}
                    value={userInfo?.vulnerableGroupType.id || ''}
                    variant="outlined"
                />
            </FlexCol>

            <NormalText style={{textAlign: 'center'}}>{t('interestedToConnect')}</NormalText>
            <CenteredRow style={{gap: 10}}>
                <CheckBoxS id="mentor" defaultChecked={userInfo?.mentor} type="checkbox"
                           />
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS id="broker" defaultChecked={userInfo?.broker} type="checkbox"
                           />
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS id="investor" defaultChecked={userInfo?.investor} type="checkbox"
                           />
                <NormalText>{t('withInvestor')}</NormalText>
            </CenteredRow>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('poslovniPlanovi')}</NormalText>
                <TextField
                    associationName
                    id="description"
                    multiline
                    maxRows={4}
                    value={userInfo?.description || ''}
                    variant="outlined"
                />
            </FlexCol>

        </FlexCol>
    )
}

