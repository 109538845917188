export function parseDateForMessage(date) {
    let today = new Date();
    let messageDate = new Date(date);
    if (today.getDate() === messageDate.getDate() && today.getMonth() === messageDate.getMonth() && today.getFullYear() === messageDate.getFullYear())
        return (messageDate.getHours() > 9 ? messageDate.getHours() : "0" + messageDate.getHours()) + ":"
            + (messageDate.getMinutes() > 9 ? messageDate.getMinutes() : "0" + messageDate.getMinutes())
    return (messageDate.getDate() + 1) + "." + (messageDate.getMonth() + 1) + "."
        + messageDate.getFullYear() + " "
        + (messageDate.getHours() > 9 ? messageDate.getHours() : "0" + messageDate.getHours())
        + ":" + (messageDate.getMinutes() > 9 ? messageDate.getMinutes() : "0" + messageDate.getMinutes());
}