import React, {useEffect, useState} from "react";
import {NormalText} from "../Text";
import {TextArea} from "./stepThree";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";
import {organizationUnitType} from "../../services/api";

export default function PrivrednaKomoraSrbije({changeUserParam, user}) {
    const {t} = useTranslation();
    const [pksGroups, setPksGroups] = useState([{}
    ]);
    useEffect(() => {
        organizationUnitType().then(({data}) => {
            setPksGroups(data)
        }).catch(error => {
            console.error(error, 'error')
        })
    },[])


    return (
        <>
            <NormalText>{t('registration.privrednaKomora.question1')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    native
                    value={user.organizationalUnitTypeId}
                    onChange={(e) => {
                        changeUserParam("organizationalUnitTypeId", e.target.value)
                    }}>
                    <option aria-label="None" value="" />
                    {pksGroups?.map((value, index) => (
                        <option key={index} value={value?.id}>{value?.name}</option>
                    ))}
                </Select>
            </FormControl>
            <NormalText>{t('registration.privrednaKomora.question2')}</NormalText>
            <TextArea value={user?.organizationalUnitName} onChange={(e) => {
                changeUserParam("organizationalUnitName", e?.target?.value)
            }} type="text" />

            <NormalText>{t('registration.privrednaKomora.question3')}</NormalText>
            <TextArea value={user?.existingExperiental} onChange={(e) => {
                changeUserParam("existingExperiental", e?.target?.value)
            }} type="text" />

            <NormalText>{t('registration.privrednaKomora.question4')}</NormalText>
            <TextArea value={user?.existingDesiredCooperation} onChange={(e) => {
                changeUserParam("existingDesiredCooperation", e?.target?.value)
            }} type="text" />

            <NormalText>{t('registration.privrednaKomora.question5')}</NormalText>
            <TextArea value={user?.leadingInvestors} onChange={(e) => {
                changeUserParam("leadingInvestors", e?.target?.value)
            }} type="text" />
        </>
    );
}
