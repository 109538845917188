import Image from '../../../assets/circular-economy.jpg'
import styled from 'styled-components'
import React from 'react';
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
 height: calc(100vh - 250px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
`

export default function CircularEconomy() {
    const {t} = useTranslation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('circularEconomy.header')}</h1>

            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('circularEconomy.b1')}</b>{t('circularEconomy.description1')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('circularEconomy.investOportunity')}<b
                        style={{color: '#243b85'}}>{t('circularEconomy.investOportunityB1')}</b>{t('circularEconomy.investOportunityT1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('circularEconomy.investOportunity')}<b
                        style={{color: '#243b85'}}>{t('circularEconomy.investOportunityB2')}</b>{t('circularEconomy.investOportunityT2')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('circularEconomy.investOportunity')}<b
                        style={{color: '#243b85'}}>{t('circularEconomy.investOportunityB3')}</b>{t('circularEconomy.investOportunityT3')}
                    </p>


                    <a style={{textDecoration: "none"}} href={"http://localhost:3000/all-search-results"}><h4>{t('circularEconomy.view')}<b style={{color: '#243b85'}}>{t('circularEconomy.archive')}</b></h4></a>
                    <h4>{t('circularEconomy.impressions')}</h4>
                </Paper>
            </Container>


        </div>
    )
}
