import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import CardComponents from './CardComponent'
import {useTranslation} from "react-i18next";
import {Cards} from "./Text";
import {FlexRow} from "../../../components/Flex";
import React, {useState} from "react";
import FeedBack from "../../../components/FeedBack";

const Banner = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    background-color: #243b85;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const StyledContainer = styled(FlexRow)`
    flex-wrap: wrap;
    justify-content: center;
`;

const StyledH4 = styled.h4`
    cursor: pointer;
`;

export default function ProjectDescription() {
    const {t} = useTranslation();
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    const cardsText = Cards(t);
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}> {t('projectWizard.title')}</h1>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginBottom: "5%"}}>
                <Paper elevation={3} style={{padding: "5%"}}>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('projectWizard.paragraph1.text1')}
                        <b style={{color: '#243b85'}}>{t('projectWizard.paragraph1.text2')}</b>
                        {t('projectWizard.paragraph1.text3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('projectWizard.paragraph2.text1')}</b>
                        {t('projectWizard.paragraph2.text2')}
                        <b style={{color: '#243b85'}}>{t('projectWizard.paragraph2.text3')}</b>
                        <b style={{color: '#243b85'}}>{t('projectWizard.paragraph2.text4')}</b>
                        {t('projectWizard.paragraph2.text5')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('projectWizard.paragraph3.text')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('projectWizard.paragraph4.text')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('projectWizard.paragraph5.text')}
                    </p>
                    <a style={{textDecoration: "none"}} href={"/"}><StyledH4>{t('projectWizard.paragraph6.text1')}</StyledH4></a>
                    <StyledH4 onClick={() => {setShowModalSendMail(true)}}>{t('projectWizard.paragraph6.text2')}</StyledH4>
                    <StyledContainer>
                        {cardsText?.map(card => <CardComponents image={card.image} text={card.text} head={card.head}/>)}
                    </StyledContainer>
                    <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>
                </Paper>
            </Container>


        </div>
    )
}
