import styled from "styled-components";

import InvestingPicture from '../../assets/grboviGrada/investment.jpg'
import React from "react";
import {useTranslation} from "react-i18next";

const Section = styled.div`
  padding: 4rem 0;
`
const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  `;

const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 40%;
  flex-basis: 40%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

const InfoColumn2 = styled(InfoColumn)`
   flex: 3;
   max-width: 60%;
  flex-basis: 60%;
  @media screen and (max-width: 768px) {
      max-width: 100%;
      flex: 1;
     }
`;


const TextWrapper = styled.div`
    max-width: 840px;
    height: 350px;
    padding: 2rem;
    overflow: auto;
    padding: 2rem 1rem; 
    
 
  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
    
  }
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 26px;
  line-height: 1.1;
  text-align: center;
  font-weight: 600;
  color: #15AFFA;
`;

const Text = styled.p`
    text-align: justify;
    text-justify: inter-word;
    color: #575757;
    margin-bottom: 1.5rem;

`
const Image = styled.img`
    width: ${props => props.width ? props.width : '90%'};
    height: ${props => props.height ? props.height : 'auto'};
    border: 1px solid #E9E9E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding: 0.5rem;
`

export default function SectionThree({object, index}) {
    let {t} = useTranslation();

    return(
        <Section>
            <Container>
                <Heading>{t('investmentsSupport').toUpperCase()} </Heading>
                <InfoRow>
                    <InfoColumn>
                        <Image src={InvestingPicture} alt="investing" />
                    </InfoColumn>
                    <InfoColumn2>
                        <TextWrapper>
                            <Text dangerouslySetInnerHTML={{__html:object.pagePlaceLanguages[index]?.supportType}}/>
                        </TextWrapper>
                    </InfoColumn2>

                </InfoRow>
            </Container>
        </Section>
    )
}
