import styled from "styled-components";
import {NormalText, SmallText} from "../Text";
import {Button2} from "../Button";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {getMyConnection} from "../../services/api";
import {getUserInfo} from "../../utils/session";
import Users from "../../pages/UserPage/users";
import {USER_PROFILE} from "../../config/routes";
import {useHistory} from "react-router-dom";
import {CenteredRow, Flex} from "../Flex";

const Container = styled.div`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    max-width: 250px;
    width: 100%;
    min-width: 250px;
    background-color: white;
    padding: 2rem;
    margin-bottom: 0.2rem;
    height: 90vh;
    border-top: 2px solid #F6F7FA;
    
    @media only screen and (max-width: 900px) {
        display: none
    }
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
`

const CardWrapper = styled(Flex)`
    gap: 0.5rem;
    &:hover {
        cursor: pointer;
    }
`

const NormalText3 = styled(NormalText)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
`
export default function Veze() {
    const {t} = useTranslation();
    const history = useHistory();
    const [connections, setConnections] = useState([]);

    useEffect(() => {
        getMyConnection("?size=9").then(response => {
            setConnections(response.data.content)
        }).catch(reason => {
            console.error("ERROR getMyConnection", reason)
        });
    }, []);

    const handleClick = (interaction) => {
        let userId = interaction.sentToId;
        if(getUserInfo().id === userId){
            userId = interaction.sentById;
        }
        history.push({
            pathname: USER_PROFILE,
            search: "?id=" + userId
        });
    }

    return (
        <>
            {connections.length > 0 ? <Container>
                <h3 style={{color: "gray", marginBottom: '2rem'}}>{t('myConnections.myConnections')}</h3>
                {connections.map((value, index) => {
                    return (
                        <CardWrapper key={index}>
                            <CenteredRow onClick={() => handleClick(value)}>
                                <Users user={getUserData(value)}
                                       style={{
                                           height: "40px",
                                           width: "40px",
                                           borderRadius: "10px",
                                           marginRight: "1rem"
                                       }}/>
                                <Col>
                                </Col>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                                     onClick={() => handleClick(value)}>
                                    {/*<Image src={SearchIcon} alt="search"/>*/}
                                    {/*<NormalText>{t('myConnections.look')}</NormalText>*/}
                                </div>
                            </CenteredRow>
                            {index < connections.length &&
                            <hr style={{
                                marginTop: '1rem',
                                backgroundColor: '#dbdbdb',
                                border: 'none',
                                height: '1px'
                            }}/>}
                        </CardWrapper>
                    );
                })}
                {connections.length > 9 && <Button2 style={{marginTop: '1rem'}}>{t('myConnections.lookAll')}</Button2>}
            </Container> : <></>}
        </>

    )
}

function getUserData(interaction) {
    let loggedUser = getUserInfo();
    if (loggedUser.id === interaction.sentBy.id) {
        return interaction.sentTo;
    } else {
        return interaction.sentBy;
    }
}
