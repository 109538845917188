import ImageLinkUp from '../../../assets/linkup.png';
import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import FeedBack from "../../../components/FeedBack";

const Banner = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    background-color: #243b85;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export default function RegionalDachNetwork() {
    const {t} = useTranslation();
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>

            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('dachNetwork.b1')}</h1>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <p style={{marginTop: "1%", marginBottom: "1%", marginLeft: "45%", marginRight: "45%"}}>
                <img src={ImageLinkUp} alt="LinkUp"/>
            </p>

            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%", whiteSpace: 'pre-wrap'}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dachNetwork.b1')}</b>{t('dachNetwork.a1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachNetwork.a2')}<b
                        style={{color: '#243b85'}}>{t('dachNetwork.b2')}</b>{t('dachNetwork.a3')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dachNetwork.b3')}</b><br/>
                        <ul style={{marginLeft: "1%", listStyle: "square url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==')"}}>
                            <li>{t('dachNetwork.p1')}</li>
                            <li>{t('dachNetwork.p2')}</li>
                            <li>{t('dachNetwork.p3')}</li>
                            <li>{t('dachNetwork.p4')}</li>
                            <li>{t('dachNetwork.p5')}</li>
                            <li>{t('dachNetwork.p6')}</li>
                            <li>{t('dachNetwork.p7')}</li>
                        </ul>


                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachNetwork.a4')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dachNetwork.b4')}</b><br/>
                        {t('dachNetwork.bu')}<br/>
                        {t('dachNetwork.bu2')}<br/>
                        {t('dachNetwork.bu3')}<br/>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dachNetwork.b5')}</b>{t('dachNetwork.a5')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dachNetwork.b6')}</b> {t('dachNetwork.a6')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('dachNetwork.b7')}</b>{t('dachNetwork.a7')}
                    </p>
                    <p onClick={() => setShowModalSendMail(true)}  style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachNetwork.a8')}<b style={{color: '#243b85'}}>{t('dachNetwork.b8')}</b>{t('dachNetwork.a9')}
                    </p>
                    <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>

                </Paper>
            </Container>


        </div>
    )
}
