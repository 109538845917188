import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getAllSuccessfulInvestments} from "../../../services/api"
import styled from "styled-components";
import {CenteredRow, FlexCol} from "../../../components/Flex";
import {Spinner} from "../../../components/Spinner";
import {Button2, ButtonL} from "../../../components/Button";
import {NormalText} from "../../../components/Text";
import Pagination from "../../../components/Pagination";
import AddInvestmentDialog from "./AddInvestmentDialog";
import Label from "../UsersApprove/Label";
import {setLanguageIndex} from "../../../helper/language";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
    max-width: 400px;
    width: 100%;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    
`
const Col2 = styled.div`
    display: flex;
    width: 100%;
    height: 150px;
    flex-direction: column;
    margin-right: 0.5rem;
    margin-bottom: 1.5rem;
    
`

const WrapperBox = styled.div`
    padding: 1rem;
    display: flex;
    background: white;
    max-width: 400px;
    width: 100%;
`

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
`;

const SpinnerWrapper = styled(CenteredRow)`
    height: 100px;
`;

const StyledSpinner = styled(Spinner)`
    position: relative;
    left: auto;
    right: auto;
    top: auto;
`;

export const NormalText3 = styled.p`
    width: 100%;
    line-height: 20px; //for not webkit browser
    max-height: 60px; //for not webkit browser
    overflow: hidden;
    height: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 15px;
    color: #737373;
    padding: 0;
    text-align: justify;
`

const ProjectContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 7px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
    padding: 1rem;
    background-color: white;
    margin-bottom: 1rem;
`

export default function AddSuccessfulInvestment() {
    const {t, i18n} = useTranslation()
    const [successfulInvestments, setSuccessfulInvestments] = useState([]);
    const [result, setResult] = useState();
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });
    const [investmentModalProps, setInvestmentModalProps] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(false)
    const [indexLng, setIndexLng] = useState()
    const [successfulInvestmentsList, setSuccessfulInvestmentList] = useState([])
    useEffect(() => {
        setIndexLng(setLanguageIndex(i18n.language))
        setLoading(true);
        getAllSuccessfulInvestments().then((result) => {
            setSuccessfulInvestmentList(result.data.content)
            setSuccessfulInvestments(result.data.content)
            setPage({...page, totalPages: result?.data.totalPages})
            setLoading(false);
        }).catch(error => {
            setLoading(false)
        });
    }, [])

    const handleCloseModal = () => {
        setInvestmentModalProps(false)
        setPreview(false)
    }

    const handleClickPage = (event, value) => {
        setPage({...page, currentPage: parseInt(value) - 1});

    };

    const handleChangeProjectClick = (result) => {
        setInvestmentModalProps(true)
        setResult(result)
    }

    return <>
        <ProjectContainer><h3 style={{color: "gray"}}>{t('investmentTitle')}</h3>
        </ProjectContainer>
        <FlexRow style={{gap: '1rem', marginBottom: '2rem'}}>
            <ButtonL padding="10px" margin="0"
                     onClick={() => setInvestmentModalProps(true)}>
                {t('page.successInvestment.create')}</ButtonL>
        </FlexRow>
        <div style={{
            display: 'flex',
            backgroundColor: 'white',
            flexDirection: 'column',
            width: '100%'
        }}>
            <FlexCol>
                {loading ? <SpinnerWrapper><StyledSpinner/></SpinnerWrapper> : <>
                    {!successfulInvestmentsList && successfulInvestmentsList.length < 1 ? <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexGrow: '1',
                            height: 'auto'
                        }}><CenteredRow
                            style={{
                                height: '50vh',
                                width: '100%'
                            }}><NormalText>{t('noData')}</NormalText></CenteredRow></div>
                        : <FlexRow>
                            {successfulInvestmentsList.map((result, index) => (
                                <Col key={index}>
                                    <WrapperBox>
                                        <Col2>
                                            <Row style={{marginBottom: '1rem'}}>
                                                <NormalText style={{textAlign: 'center'}}
                                                    color>{result.successfulInvestmentLanguages[indexLng].name}</NormalText>
                                                <Label
                                                    variant="ghost"
                                                    color={(result.status === "A" && 'success') || (result.status !== "A" && 'error')}>
                                                    {(result.status === "A" && t('userApprove.approved'))
                                                    || (result.status !== "A" && t('userApprove.rejected'))}
                                                </Label>
                                            </Row>
                                            <NormalText3>{t('municipalityCity')}: {result.city.name}</NormalText3>
                                            <NormalText3>{t('investmentDescription')}{result.successfulInvestmentLanguages[indexLng].description}</NormalText3>
                                        </Col2>
                                    </WrapperBox>
                                    <Row>
                                        {/*<Button2 onClick={() => {*/}
                                        {/*    handlePreviewProjectClick(result)*/}
                                        {/*}}>{t('project.preview')}</Button2>*/}
                                        <Button2
                                            onClick={() => {
                                                handleChangeProjectClick(result)
                                            }}>
                                            {t('edit')}
                                        </Button2>
                                        {/*<Button2 onClick={() => {*/}
                                        {/*    // handleDeleteProject(result, index)*/}
                                        {/*}}>{t('delete')}</Button2>*/}
                                    </Row>
                                </Col>
                            ))}
                        </FlexRow>}
                    {investmentModalProps && <AddInvestmentDialog
                        successfulInvestmentsList={successfulInvestmentsList}
                        setSuccessfulInvestmentList={setSuccessfulInvestmentList}
                        show={investmentModalProps}
                        handleClose={handleCloseModal}
                        result={result}
                        preview={preview}
                    />}
                    {successfulInvestments.length > 10 && <Pagination
                        totalPages={page.totalPages}
                        handleClickPage={handleClickPage}
                        currentPage={parseInt(page.currentPage) + 1}/>}
                </>
                }
            </FlexCol>
        </div>

    </>
}
