import {CenteredRow, FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {CheckBoxS} from "../../components/Checkbox";
import {getAllBusinessSector} from "../../services/api";

export default function Individual({userInfo, setUserInfo}) {
    const {t} = useTranslation();
    const [businessSectors, setBusinessSectors] = useState([]);

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChangeBusinessSector = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState, businessSector: newValue
        }))
    }

    const handleChangeCheckBox = (e) => {
        const {id, checked} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: checked
        }))
    }

    useEffect(() => {
        getAllBusinessSector().then(response => {
            setBusinessSectors(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, []);

    return (
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <NormalText>{t('registration.investor.question4')}</NormalText>
                <Autocomplete
                    value={userInfo?.businessSector || ''}
                    options={businessSectors}
                    id="businessSectorId"
                    onChange={(e, newValue) => {
                        handleChangeBusinessSector(e, newValue)
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    style={{minWidth: 'auto'}}
                    renderInput={(params) => <TextField {...params} variant="outlined" label={t('bussinesSector')}/>}
                />
            </FlexCol>

            <NormalText style={{textAlign: 'center'}}>{t('interestedToConnect')}</NormalText>
            <CenteredRow style={{gap: 10}}>
                <CheckBoxS id="mentor" defaultChecked={userInfo?.mentor} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS id="broker" defaultChecked={userInfo?.broker} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS id="investor" defaultChecked={userInfo?.investor} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withInvestor')}</NormalText>
            </CenteredRow>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('poslovniPlanovi')}</NormalText>
                <TextField
                    fullWidth
                    id="plans"
                    multiline
                    maxRows={4}
                    value={userInfo?.plans || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}
