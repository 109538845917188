export function getCityNameForLanguage(city, language){
    switch (language){
        case 'de':
            return isObjectEmpty(city.nameDe) ? city.name : city.nameDe
        case 'cir':
            return isObjectEmpty(city.nameCir) ? city.name :city.nameCir
        case 'en':
            return isObjectEmpty(city.nameEn) ? city.name :city.nameEn
        default:
            return city.name
    }
}

const isObjectEmpty = (obj) => {
    if(!obj) return true;
    if(obj === null) return true;
    return obj === '';

}

export function setLanguageIndex(index){
    switch (index){
        case 'de':
            return 3
        case 'cir':
            return 1
        case 'en':
            return 2
        default:
            return 0
    }
}
