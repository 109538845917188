import React, {useState} from "react";
import RegionCityModal from "../RegionCityModal";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

import {getUserInfo} from "../../utils/session";


import HomeIcon from "../../assets/sidebar/home-outline.svg"
import MessageIcon from "../../assets/sidebar/message-square-outline.svg"
import InteractionsIcon from "../../assets/sidebar/flash-outline.svg"
import ProjectsIcon from "../../assets/sidebar/project-outline.svg"
import PagesIcon from "../../assets/sidebar/pages-outline.svg"
import ApproveIcon from "../../assets/sidebar/approve-outline.svg"
import EventsIcon from "../../assets/sidebar/events-outline.svg"


import HomeIconW from "../../assets/sidebar/home-outlineW.svg"
import MessageIconW from "../../assets/sidebar/message-square-outlineW.svg"
import InteractionsIconW from "../../assets/sidebar/flash-outlineW.svg"
import ProjectsIconW from "../../assets/sidebar/project-outlineW.svg"
import PagesIconW from "../../assets/sidebar/pages-outlineW.svg"
import ApproveIconW from "../../assets/sidebar/approve-outlineW.svg"
import EventsIconW from "../../assets/sidebar/events-outlineW.svg"

import {FlexAlign} from "../Flex";
import {sidebar_menu} from "../../helper/sidebar";
import {SEARCH_RESULTS, SEARCHED_USER_PAGE} from "../../config/routes";
import {SEARCH_TYPES} from "../../pages/Search";
import {ROLES_TYPE} from "../../utils/roles";
import {useHistory} from "react-router-dom";
import InvestorDialog from "./investorDialog";

const Image = styled.img`
    height: 20px;
    width: 20px;
    margin-right: 1rem;
`

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    min-width: 250px;
    height: 90vh;
    padding-top: 2rem;
    border-top: 2px solid #F6F7FA;  
    position: sticky;
    top: 0;  
    overflow: auto;
     @media only screen and (max-width: 900px) {
        display: none
    }
`

const SidebarDiv = styled(FlexAlign)`
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
    padding: 0.5rem 2rem;
    color: grey;
    -webkit-transform:translateZ(0) scale(1,1);
    -webkit-transition:all .1s linear;
    transition:all .1s linear;
    border-right: ${({active}) => active ? "4px solid #243B85" : "none"};
    &:hover {
        background-color: #c7dcff;
    },
`

const SidebarText = styled.p`
    font-size: 16px;
     font-weight: 300;
     line-height: 1.5625;
     margin: 0;
     padding: 0;
     color: ${({active}) => active ? "#243B85" : "grey"};
     margin-bottom:${(props) => props.marginBottom ? '10px' : '0px'};
    font-weight: ${({active}) => active ? "bold" : "none"};
    letter-spacing: 0.7px;
`
const sidebarItems = (t) => {

    return [
        {
            id: sidebar_menu.home,
            text: t('homePage'),
            icon: HomeIcon,
            hoverIcon: HomeIconW,
        },
        {
            id: sidebar_menu.messages,
            text: t('messages'),
            icon: MessageIcon,
            hoverIcon: MessageIconW,
        },
        {
            id: sidebar_menu.interactions,
            text: t('interactions'),
            icon: InteractionsIcon,
            hoverIcon: InteractionsIconW,
        },
        {
            id: sidebar_menu.myProjects,
            text: t('myProjects'),
            icon: ProjectsIcon,
            hoverIcon: ProjectsIconW,
        },
        {
            id: sidebar_menu.myPages,
            text: t('myPages'),
            icon: PagesIcon,
            hoverIcon: PagesIconW,
        },
        {
            id: sidebar_menu.adminApprove,
            text: t('approve'),
            icon: ApproveIcon,
            hoverIcon: ApproveIconW,
        },
        {
            id: sidebar_menu.myEvents,
            text: t('myEvents'),
            icon: EventsIcon,
            hoverIcon: EventsIconW,
        },
        {
            id: sidebar_menu.addSuccessful,
            text: t('addSuccessful'),
            icon: ApproveIcon,
            hoverIcon: ApproveIconW,
        }
    ]
}

export default function NormalSidebar({
                                          showModalRegion,
                                          setShowModalRegion,
                                          handleModal,
                                          handleMenuChange,
                                          selectedMenu
                                      }) {
    const {t} = useTranslation();
    const history = useHistory()
    const [isAdmin, setIsAdmin] = useState(false);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    let user = JSON.parse(localStorage.getItem("user"));
    React.useEffect(() => {
        let user = getUserInfo();
        if (user !== null) {
            if (user.role?.id === 11) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    }, []);

    return (
        <SidebarContainer style={{
            position: 'sticky',
            top: 0
        }}>
            <h3 style={{color: "gray", marginLeft: '2rem', marginBottom: '1rem'}}>{t('menu')}</h3>
            {sidebarItems(t).map((item, index) => {

                if(!isAdmin && (item.id === 6 || item.id === 8))
                    return null
                else if (!(user?.role.id === 11 || user?.role.id === 12 || user?.role.id === 7) && item.id === 5)
                    return null
                else if(user?.role.id === 5  && user?.role.id === 4 && user?.role.id === 8 && item.id === 4)
                    return null
                else {
                    return <SidebarDiv key={index} onClick={() => handleMenuChange(item.id)} active={(selectedMenu === item.id)}>
                        <Image src={selectedMenu === item.id ? item.icon : item.hoverIcon}/>
                        <SidebarText active={(selectedMenu === item.id)}>{item.text}</SidebarText>
                    </SidebarDiv>
                }
            })}
            <h3 style={{
                color: "gray",
                marginLeft: '2rem',
                marginTop: '2rem',
                marginBottom: '1rem'
            }}>{t('entitySearch')}</h3>
            <SidebarDiv onClick={handleClickOpen}>
                <p>{t('investor')}</p>
            </SidebarDiv>
            <SidebarDiv onClick={()=>history.push(SEARCHED_USER_PAGE + "?menuType=" + SEARCH_TYPES.user + "&userType=" + ROLES_TYPE.broker)}>
                <p>{t('broker')}</p>
            </SidebarDiv>
            <SidebarDiv onClick={()=>history.push(SEARCHED_USER_PAGE + "?menuType=" + SEARCH_TYPES.user + "&userType=" + ROLES_TYPE.entrepreneur)}>
                <p>{t('enterpreneur')}</p>
            </SidebarDiv>
            <SidebarDiv onClick={handleModal}>
                <p>{t('localGovernments')}</p>
            </SidebarDiv>
            <SidebarDiv onClick={()=>history.push(SEARCH_RESULTS + "?menuType=" + SEARCH_TYPES.project)}>
                <p>{t('projects')}</p>
            </SidebarDiv>
            <InvestorDialog open={open} setOpen={setOpen} />
            <RegionCityModal showModalRegion={showModalRegion}
                             setShowModalRegion={setShowModalRegion}/>
        </SidebarContainer>
    )
}
