import React from 'react';
import styled from "styled-components";

import Zaposlenost1 from '../../assets/images/City/Zaposlenost1.svg'
import Zaposlenost2 from '../../assets/images/City/zaposlenost2.svg'
import Zaposlenost3 from '../../assets/images/City/zaposlenost3.svg'
import {useTranslation} from "react-i18next";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Section = styled.div`
  padding: 2rem 0;
`
const Container = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
const InfoRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
    display: flex;
    border: 1px solid red;
    width: auto;
    border-radius: 5px;
    border: 0.2px solid #E9E9E9;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    padding: 1rem 0.5rem 1rem 0.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    
    @media screen and (max-width: 640px) {
    flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0rem;
        margin-top: 0rem;
        
     }
`;

const Text = styled.p`
    text-align: justify;
    text-justify: inter-word;
    color: #575757;
    text-align: justify;

    @media screen and (max-width: 460px) {

    font-size: 14px;
    }
`;
const Text2 = styled.p`
    text-align: justify;
    text-justify: inter-word;
    color: #575757;
    
    @media screen and (max-width: 640px) {
    margin-bottom: 1rem;
    font-size: 14px;
    }
`;


const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 26px;
  line-height: 1.1;
  font-weight: 600;
  color: #606060;
`;

const Card = styled.div`
    width: 50%;
    background: #FFF;
    border-radius: 5px;
    border: 0.2px solid #E9E9E9;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    padding: 1rem 0.5rem 1rem 0.5rem;
    @media screen and (max-width: 640px) {
    width: 100%;
    }
    
`

const Image = styled.img`
    width: ${props => props.width ? props.width : '60px'};
    height: ${props => props.height ? props.height : '60px'};
    margin-right: 3rem;
    
     @media screen and (max-width: 1389px) {
    display: none;
  }
`
const Image2 = styled.img`
    width: ${props => props.width ? props.width : '60px'};
    height: ${props => props.height ? props.height : '60px'};
    margin-right: 3rem;
    @media screen and (max-width: 640px) {
    margin-right: 0;
    }
    @media screen and (max-width: 460px) {
    display: none;
    }
   
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
     @media screen and (max-width: 1389px) {
    align-items: center;
  }
`
const MiniWrapper = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
     @media screen and (max-width: 1389px) {
        justify-content: center;
        
     }
    
`

export default function SectionFive({ object }) {
    const { t } = useTranslation();
    return (
        <Section>
            <Container>
                <Card>
                    <InfoRow>

                        <Image width="auto" height="100%" src={Zaposlenost1} alt="zaposlenost 1" />

                        <Col>
                            <Heading>{t('employment')}</Heading>
                            <Text2>{t('dataYearFirst')} {object?.yearOfData}. {t('dataYearSecond')}</Text2>
                            <MiniWrapper>
                                <Row>
                                    <Image2 src={Zaposlenost2} alt="zaposlenost2" />
                                    <Col>
                                        <CountUp start={0} end={object?.numberOfEmployed} duration={4}
                                            redraw={true} separator=".">
                                            {({ countUpRef }) => (
                                                <VisibilitySensor>
                                                    <Text ref={countUpRef}/>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        <Text>{t('numberOfEmployment')}</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Image2 src={Zaposlenost3} alt="zaposlenost2" />
                                    <Col>
                                    <CountUp start={0} end={object?.numberOfUnemployed} duration={4}
                                            redraw={true} separator=".">
                                            {({ countUpRef }) => (
                                                <VisibilitySensor>
                                                    <Text ref={countUpRef}/>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        <Text>{t('numberOfUnEmployment')}</Text>
                                    </Col>
                                </Row>
                            </MiniWrapper>
                        </Col>

                    </InfoRow>
                </Card>
            </Container>
        </Section>
    )
}
