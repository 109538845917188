import StepFiveImage from "../../../assets/images/rra_project_creation/3.jpeg";
import StepOneImage from "../../../assets/images/project_creation_page/1.png";
import StepTwoImage from "../../../assets/images/rra_project_creation/4.jpeg";
import StepThreeImage from "../../../assets/images/rra_project_creation/1.jpeg";
import StepFourImage from "../../../assets/images/rra_project_creation/2.jpeg";

export const Cards = (t) => {
    return (
        [
            {
                image: StepOneImage,
                text: t('rraProjectDescription.card1.text'),
                head: t('rraProjectDescription.card1.header'),
            },
            {
                image: StepTwoImage,
                text: t('rraProjectDescription.card2.text'),
                head: t('rraProjectDescription.card2.header'),
            },
            {
                image: StepThreeImage,
                text: t('rraProjectDescription.card3.text'),
                head: t('rraProjectDescription.card3.header'),
            },
            {
                image: StepFourImage,
                text: t('rraProjectDescription.card4.text'),
                head: t('rraProjectDescription.card4.header'),
            },
            {
                image: StepFiveImage,
                text: t('rraProjectDescription.card5.text'),
                head: t('rraProjectDescription.card5.header'),
            }
        ]
    )
}