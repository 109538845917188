import React, {useEffect, useState} from "react";
import './style.css'
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {getAllBigRegions, getPagePlaceBigRegionName} from "../../services/api";
import {REGION_PAGE_ROUTE} from "../../config/routes";
import {NormalText} from "../../components/Text";
import {useTranslation} from "react-i18next";

const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
const InfoRow = styled.div`
  display: flex;
 
  flex-wrap: wrap;
  align-items: center;
  `;

const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 40%;
  flex-basis: 40%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

const InfoColumn2 = styled(InfoColumn)`
    display: flex;
    max-width: 60%;
  flex-basis: 60%;
    flex-wrap: wrap;
`
const BigDiv = styled.div`
 display: flex;
    justify-content: center;
   align-items: center;

    height: 50px;
    width: 170px;
    
    margin: 0.4rem;
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;;
    border: 1px solid #E9E9E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color:#243b85;
    box-sizing: border-box;
    height: 50px;
    width: 160px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${({hovered}) => hovered ? "#2f91ed" : "#243b85"};

    transition: width 0.2s;
    &:hover {
     background-color: #2f91ed;
     width: 170px;
    }
`

const Heading = styled.h1`
    color: #fff;
    background-color:#243b85;
    margin-bottom: 2rem;
    font-size: 26px;
    line-height: 1.1;
    text-align: center;
    font-weight: 600;
    margin-top: 2rem;
`;
export default function RegionsMapPage() {
    const [regionsList, setRegionsList] = useState([])
    const [hoveredArea, setHoveredArea] = useState("");
    const [hoveredButton, setHoveredButton] = useState("");
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const handleCheckIfExist = (event) => {
        const {id} = event.target
        getPagePlaceBigRegionName(id)
            .then(() => {
                history.push({
                    pathname: REGION_PAGE_ROUTE,
                    search: "?name=" + id

                })
            }).catch(() => {
            enqueueSnackbar("Za odabrani region ne postoji stranica", {variant: "info"})
        });
    }

    const handleButtonEnter = (event) => {
        const {id} = event?.target;
        setHoveredButton(id);
    }

    const handleButtonLeave = () => {
        setHoveredButton("");
    }

    const handleMouseEnter = (event) => {
        setHoveredArea(event?.target?.id);
    }

    const handleMouseLeave = () => {
        setHoveredArea("");
    }

    const handleCheckIfExistL = (name) => {
        getPagePlaceBigRegionName(name)
            .then(() => {
                history.push({
                    pathname: REGION_PAGE_ROUTE,
                    search: "?name=" + name
                })
            }).catch(() => {
            enqueueSnackbar(`Za ${name} region ne postoji stranica`, {variant: "info"})
        });
    }

    useEffect(() => {
        getAllBigRegions().then(response => {
                setRegionsList(response.data)
            }
        ).catch(error => {
            console.log(error, 'regionsList')
        })
    }, [])

    // const regions = regionPaths(null, handleCheckIfExist, handleMouseEnter, handleMouseLeave, hoveredButton);
    const regions = regionsList.map(region => {
        return <g id={region.id} className={parseInt(hoveredButton) === parseInt(region.id) ? "btnHovered" : ""}
                  fill="#243b85">
            <path id={region.id} onClick={e => handleCheckIfExist(e)}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  d={region.svg}/>
            <text x="280" y="50">{region.name}</text>
        </g>
    })
    const {t} = useTranslation();

    return (
        <Container>
            <Heading>{t('searchRegionHeader')}</Heading>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <InfoRow>
                <InfoColumn2>
                    {regionsList.map((value, index) => (
                        <BigDiv key={index}>
                            <Wrapper
                                id={value?.id}
                                onClick={() => handleCheckIfExistL(value?.name)}
                                onMouseEnter={handleButtonEnter}
                                onMouseLeave={handleButtonLeave}
                                hovered={parseInt(hoveredArea) === parseInt(value?.id)}>
                                <NormalText id={value?.id} onMouseEnter={handleButtonEnter}
                                            onMouseLeave={handleButtonLeave} style={{
                                    color: "#fff",
                                    textAlign: "center",
                                    padding: "10px"
                                }}>{value?.name}</NormalText>
                            </Wrapper>
                        </BigDiv>

                    ))}
                </InfoColumn2>
                <InfoColumn>
                    <div className="mapdiv">

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 681.41 997.79">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    {regions?.map((value) => {
                                        return (value);
                                    })}
                                </g>
                            </g>
                        </svg>
                    </div>
                </InfoColumn>

            </InfoRow>

        </Container>
    )
}
