export const ROLES_TYPE = {
    individual: 1,
    entrepreneur: 2,
    investor: 3,
    broker: 4,
    mentor: 5,
    dach: 6,
    rra: 7,
    media: 8,
    pks: 9,
    vulnerableGroup: 10,
    admin: 11,
    privrednaKomora: 12,
    enterpise: 13,
    other: 14
}

export const PRIVATE_ROLES = (t) => {
    return [
        {name: t('roles.individual'), id: 1},
        {name: t('roles.entrepreneur'), id: 2},
        {name: t('roles.investor'), id: 3},
        {name: t('roles.broker'), id: 4},
        {name: t('roles.mentor'), id: 5},
        {name: t('roles.dach'), id: 6},
        {name: t('roles.media'), id: 8},
        {name: t('roles.vulnerableGroup'), id: 10},
        {name: t('roles.other'), id: 14},
    ];
}

export const PUBLIC_ROLES = (t) => {
    return [
        {name: t('roles.privrednaKomora'), id: 12},
        {name: t('roles.pks'), id: 9},
        {name: t('roles.rra'), id: 7},
        {name: t('roles.media'), id: 8},
        {name: t('roles.investor'), id: 3},
        {name: t('roles.mentor'), id: 5},
        {name: t('roles.enterprise'), id: 13},
        {name: t('roles.other'), id: 14},
    ]
}
