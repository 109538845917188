import ImageInText from '../../../assets/images/investorsupport.png'
import Image from '../../../assets/investorsupport.png'
import styled from 'styled-components'
import React from 'react';
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {FlexCol} from "../../../components/Flex";

const Banner = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    background-color: #243b85;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`
export default function InvestorSupport() {
    const {t} = useTranslation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('investitorSuport.header')}</h1>

            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <h3><b style={{color: '#243b85'}}>{t('investitorSuport.head1')}</b></h3>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('investitorSuport.step')}1: </b>
                        {t('investitorSuport.t1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('investitorSuport.step')}2: </b>
                        {t('investitorSuport.t2')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('investitorSuport.step')}3: </b>
                        {t('investitorSuport.t3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('investitorSuport.step')}4: </b>
                        {t('investitorSuport.t4')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('investitorSuport.step')}5: </b>
                        {t('investitorSuport.t5')}
                    </p>


                    <FlexCol style={{alignItems: 'center', marginTop: "1%", marginBottom: "1%"}}>
                        <img style={{
                            marginBottom: '1rem',
                            objectFit: 'cover',
                            width: '100%',
                            maxWidth: '1000px',
                            height: 'auto'
                        }} src={ImageInText} alt="imageInText"/>
                        <img style={{width: '100%', height: 'auto'}} src={Image} alt="image"/>
                    </FlexCol>
                </Paper>
            </Container>


        </div>
    )
}
