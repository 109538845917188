import styled from "styled-components";
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


const Section = styled.div`
  padding: 2rem 0;
  background-color: #EFEFEF;
`
const Container = styled.div`
  padding-top: ${props => props.paddingTop ? '0' : '2rem'};
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  background-color: #FFF;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

const Wrapper = styled(Container)`
    margin-top: 2rem;
`

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.1;
  text-align: center;
  font-weight: 600;
  background-color: #15AFFA;
  color: #FFF;
  padding: 1rem 0 1rem 0;
`;

const Text = styled.p`
    text-align: justify;
    text-justify: inter-word;
    color: #575757;
    padding: 2rem 0 2rem 0;
    margin: 0 5% 0 5%;
`

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    let {t} = useTranslation();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function SectionTwo({object, index}) {
    let {t} = useTranslation();

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Section>
            <Container>
                <Heading>{t('definedDirectionsAndPriorites').toUpperCase()}</Heading>
                <div className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label={t('industry')} {...a11yProps(0)} />
                        <Tab label={t('agricultureR')} {...a11yProps(1)} />
                        <Tab label={t('tourism')} {...a11yProps(2)} />
                        <Tab label={t('renewableEnergy')} {...a11yProps(3)} />
                        <Tab label={t('villageRenewal')} {...a11yProps(4)} />

                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <Text dangerouslySetInnerHTML={{__html: object?.pagePlaceLanguages[index]?.reachTextIndustry}}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Text
                            dangerouslySetInnerHTML={{__html: object?.pagePlaceLanguages[index]?.reachTextAgriculture}}/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Text dangerouslySetInnerHTML={{__html: object?.pagePlaceLanguages[index]?.reachTextTourism}}/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Text dangerouslySetInnerHTML={{__html: object?.pagePlaceLanguages[index]?.reachTextForestry}}/>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Text dangerouslySetInnerHTML={{__html: object?.pagePlaceLanguages[index]?.villageRenewal}}/>
                    </TabPanel>
                </div>
            </Container>
            <Wrapper paddingTop>
                <Text style={{textAlign: 'center'}}>{t('investmentsLink2')} <Link
                    to={{pathname: object?.linkForInvestments}} target="_blank" rel="noreferrer">{t('link')}</Link>
                </Text>
            </Wrapper>
        </Section>

    )


}

