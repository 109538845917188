import React, {useEffect, useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {FlexRow} from "../../../components/Flex";

const StyledRow = styled(FlexRow)`
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
`;


export default function PreviewRRAModal({showModal, setShowModal, data}) {
    const {t} = useTranslation();
    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (showModal) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [showModal]);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {showModal &&
            <div>
                <Dialog
                    maxWidth="xl"
                    open={showModal}
                    onClose={handleClose}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">{t('projectPreview.previw')}</DialogTitle>
                    <DialogContent dividers={'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}>
                            <StyledRow>
                                <Typography>{t('rraForms.municipality')}</Typography>
                                <Typography>{data?.municipality}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.investName')}</Typography>
                                <Typography>{data?.name}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.businessSectors')}</Typography>
                                <Typography>{data?.businessSector}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.groupInSector')}</Typography>
                                <Typography>{data?.groupInsideSector}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.investDescription')}</Typography>
                                <Typography>{data?.description}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.investValue')}</Typography>
                                <Typography>{data?.estimatedValue}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.existingDocumentation')}</Typography>
                                <Typography>{data?.documentation}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.potencionalStatusOfInvestment')}</Typography>
                                <Typography>{data?.ownerStatus}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.rightOfUse')}</Typography>
                                <Typography>{data?.usageRights}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.existingInfrastructure')}</Typography>
                                <Typography>{data?.currentInfrastructure}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.trafficConnection')}</Typography>
                                <Typography>{data?.trafficConnectivity}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('rraForms.otherInformation')}</Typography>
                                <Typography>{data?.otherInfo}</Typography>
                            </StyledRow>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>

                    </DialogActions>
                </Dialog>
            </div>
            }
        </>

    );
}

