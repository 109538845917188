export const sidebar_menu={
    home: 1,
    messages: 2,
    interactions: 3,
    myProjects: 4,
    myPages: 5,
    adminApprove: 6,
    myEvents: 7,
    addSuccessful: 8
}
