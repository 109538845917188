import rs from "../../assets/images/rs.svg";
import en from "../../assets/images/sidebar/gb.svg";
import de from "../../assets/images/sidebar/de.svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@material-ui/core/List";
import {Box, ListItemIcon, MenuItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

export default function LanguagePdfSelect({t, show, handleClose, handlePreviewProjectClick}) {

    const LANGS = [
        {
            value: 'src',
            label: t('lngSerbianC'),
            icon: rs
        },
        {
            value: 'sr',
            label: t('lngSerbian'),
            icon: rs
        },
        {
            value: 'en',
            label: t('lngEnglish'),
            icon: en
        },
        {
            value: 'de',
            label: t('lngGerman'),
            icon: de
        }
    ];

    return (
        <Dialog
            PaperProps={{
                style: {
                    boxShadow: "none"
                },
            }}
            BackdropProps={{
                style: {
                    backgroundColor: "#00000020",
                    boxShadow: "none"
                },
            }} fullScrean onClose={handleClose} open={show} >
            <DialogTitle>{t('pickLanguage')}</DialogTitle>
            <List sx={{ pt: 0 }}>
                {LANGS.map((option) => (
                    <MenuItem
                        onClick={() => {
                            handlePreviewProjectClick(option.value)
                        }}
                        key={option.value}
                        //selected={option.value === LANGS[2].value}
                        sx={{ py: 1, px: 2.5 }}
                    >
                        <ListItemIcon>
                            <Box style={{ width: 25, height: 25}} component="img" alt={option.label} src={option.icon} />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                            {option.label}
                        </ListItemText>
                    </MenuItem>
                ))}
            </List>
        </Dialog>
    )

}