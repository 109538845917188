import React from "react";
import {NormalText} from "../Text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {CheckBoxS} from "../Checkbox";
import {TextArea} from "../TextArea";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const Row1 = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    `
export default function PojedinacRegistration({ businessSectors, user, changeUserParam }) {
    const { t } = useTranslation();
    return (
        <>
            <NormalText>{t('bussinesSector')}</NormalText>
            <Row1>
                <Autocomplete

                    options={businessSectors}
                    value={user?.businessSectorId}
                    defaultValue={user?.businessSectorId}
                    onChange={(event, newValue) => {
                        changeUserParam("businessSectorId", newValue)
                    }}
                    getOptionLabel={(option) => option?.name}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} style={{ width: '100%' }} label="" variant="outlined" />}
                />
                <Tooltip title={t('clasificationToolTipText')} aria-label="add" style={{ height: "100%" }}>
                    <HelpIcon />
                </Tooltip>
            </Row1>
            <NormalText>{t('registration.individual.question1')}</NormalText>
            <Row>
                <CheckBoxS value={user?.mentor} type="checkbox" onChange={() => {
                    changeUserParam("mentor", !user?.mentor)
                }} />
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS value={user?.broker} type="checkbox" onChange={() => {
                    changeUserParam("broker", !user?.broker)
                }} />
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS value={user?.investor} type="checkbox" onChange={() => {
                    changeUserParam("investor", !user?.investor)
                }} />
                <NormalText>{t('withInvestor')}</NormalText>
            </Row>
            <NormalText>{t('registration.individual.question2')}</NormalText>
            <TextArea value={user?.description} onChange={(e) => {
                changeUserParam("description", e.target.value)
            }} />
        </>
    );
}
