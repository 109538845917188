import React from "react";
import {Container, StyledPopper} from "../../style";
import UsersSection from "./sections/UsersSection";
import ProjectsSection from "./sections/ProjectsSection";
import EventsSection from "./sections/EventsSection";
import RRAProjectSection from "./sections/RRAProjectSection";

export default function SearchAutocomplete({
                                               anchorEl,
                                               onMenuClick,
                                               inputString,
                                               width,
                                               cityIds,
                                               sectorIds,
                                               range
                                           }) {
    return (
        <StyledPopper
            ref={anchorEl}
            width={width}
            placement="bottom"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            transition>
            <Container>
                {/*<KeyWordsSection*/}
                {/*    inputString={inputString}*/}
                {/*    onMenuClick={onMenuClick}/>*/}
                <UsersSection
                    cityIds={cityIds}
                    inputString={inputString}
                    onMenuClick={onMenuClick}/>
                <ProjectsSection
                    sectorIds={sectorIds}
                    range={range}
                    inputString={inputString}
                    onMenuClick={onMenuClick}/>
                <RRAProjectSection
                    sectorIds={sectorIds}
                    inputString={inputString}
                    onMenuClick={onMenuClick}/>
                <EventsSection
                    inputString={inputString}
                    onMenuClick={onMenuClick}/>
            </Container>
        </StyledPopper>
    );
}
