import React, {useState} from "react";
import {NormalText} from "../Text";
import {TextArea} from "./stepThree";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {getAllPksGroups} from "../../services/api";
import {useTranslation} from "react-i18next";

export default function PksRegistration({changeUserParam, user}) {
    const {t} = useTranslation();
    const [pksGroups, setPksGroups] = useState([]);
    React.useEffect(() => {
        getAllPksGroups().then(response => {
            setPksGroups(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, []);
    return (
        <>
            <NormalText>{t('registration.pks.question1')}</NormalText>
            <TextArea value={user?.associationName} onChange={(e) => {
                changeUserParam("associationName", e?.target?.value)
            }} type="text"/>
            <NormalText>{t('registration.pks.question2')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    native
                    value={user.pksGroupId}
                    onChange={(e) => {
                        changeUserParam("pksGroupId", e.target.value)
                    }}>
                    <option aria-label="None" value=""/>
                    {pksGroups?.map((value, index) => (
                        <option key={index} value={value?.id}>{value?.name}</option>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}