import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import RegPhoto from "../../assets/images/sazvezdja-01.png"
import Bg from "../../assets/images/sazvezdjebg.png"
import StepThree from "./stepThree";
import {ButtonL, ButtonR} from "../Button";
import {BigText} from "../Text";
import {checkIfEmailExist, loginUserSync, meWithToken, registration, uploadUserImage} from "../../services/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import {validateFirstStep, validateSecondStep, validateThirdStep} from "./validation";
import {useHistory} from "react-router-dom";
import {animated, useSpring} from "react-spring";
import {FlexRow} from "../Flex";
import {PROFILE_ROUTE} from "../../config/routes";
import {useTranslation} from "react-i18next";

const Background = styled.div`
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 104;
 
  width: 100%;
  height: 100%;
`

const ContainerItem = styled.div`
position: relative;
    width: 100%;
    height:100%;
    background: ${({primary}) =>
    primary ? 'white' : 'grey'};
    overflow: auto;

`

const ContainerWithBackground = styled.div`
    position: relative;
    height: 100%;
    width: 100%;  
    background: ${({primary}) =>
    primary ? 'white' : 'grey'};
    overflow: auto;
    background: no-repeat center;
    background-image: url(${Bg});
    background-size: auto 100%;
`

const Close = styled.button`
    width: 30px;
    font-size: 20px;
    color: '#000';
    position:absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
`

export const DivRopw = styled(FlexRow)`
   
     max-height: 660px;
      height: 100vh;
`

export default function RegistratonModal({showModalR, setShowModalR}) {
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const [roleString, setRoleString] = useState("")
    const [imgFile, setImgFile] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [emailExist, setEmailExist] = useState(false);
    const [windowSize, setWindowSize] = React.useState(null);
    const [selectedSktor, setSelectedSektor] = useState("Javni sektor");
    const [user, setUser] = useState(
        {
            "email": "",
            "name": "",
            "password": "",
            "lastName": "",
            "pictureUrl": "",
            "company": "",
            "country": "",
            "cityId": null,
            "cityString": "",
            "phone": "",
            "businessSectorId": null,
            "companySizeId": null,
            "companyTypeId": null,
            "investmentTypeId": null,
            "roleId": null,
            "vulnerableGroupTypeId": null,
            "mentorTypeId": 1,
            "pksGroupId": null,
            "genderId": 3,
            "representative": false,
            "description": "",
            "countryId": null,
            "countryIds": [],
            "description1": "",
            "foreignFirms": "",
            "mentor": false,
            "broker": false,
            "investor": false,
            "plans": "",
            "identificationNumber": "",
            "foreignInvestment": false,
            "investments": "",
            "businessActivityList": [],
            "investorBusinessSectors": [],
            "investmentsIds": [],
            "industries": [],
            "companyExamples": "",
            "export_chances": "",
            "associationName": "",
            "dachGroupId": null,
            "success": "",
            "supportMechanism": "",
            "followersNumber": null,
            "explanation": "",
            "showExample": "",
            "aboutSelf": "",
            "city": null,
            "organizationalUnitTypeId": null,
            "organizationalUnitName": null,
            "existingExperiental": null,
            "existingDesiredCooperation": null,
            "leadingInvestors": null,
            "rraname": ""
        }
    );

    useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    function changeUserParam(param, value) {
        setUser({...user, [param]: value});
    }

    function changeUserParam2(param, value) {
        setUser({...user, [param]: value});
    }

    function onEmailBlur() {
        let obj = {};
        obj.email = user?.email;
        checkIfEmailExist(obj).then(response => {
            if (response?.data === true) {
                enqueueSnackbar(t('emailAlreadyExist'), {
                    variant: 'error'
                });
            }
            setEmailExist(response?.data);
        }).catch(reason => {
            console.error(reason);
        });
    }

    let stepView;
    const [activeStep, setActiveStep] = useState(0);

    const nextStep = () => {
        switch (activeStep) {
            case 0:
                if (!validateFirstStep(user, roleString, enqueueSnackbar, t))
                    return;
                else {
                    if (emailExist) {
                        enqueueSnackbar(t('emailAlreadyExist'), {
                            variant: 'error'
                        });
                        return;
                    }
                }
                break;
            case 1:
                if (!validateSecondStep(user, enqueueSnackbar, t))
                    return;
                break;
            case 2:
                if (!validateThirdStep(user, enqueueSnackbar, t)) {
                    return
                }
                break;
            default:
        }
        if (activeStep < 2)
            setActiveStep((currentStep) => currentStep + 1)
        else {
            let countryIds = [];
            user?.countryIds?.forEach(value => {
                countryIds.push(value?.id)
            });
            let businessActivityList = [];
            user?.businessActivityList?.forEach(value => {
                businessActivityList.push(value?.id)
            });
            let investorBusinessSectors = [];
            user?.investorBusinessSectors?.forEach(value => {
                investorBusinessSectors.push(value?.id)
            });
            let investmentsIds = [];
            user?.investmentsIds?.forEach(value => {
                investmentsIds.push(value?.id)
            });
            let industries = [];
            user?.industries?.forEach(value => {
                industries.push(value?.id)
            });
            let parsedUser = {
                ...user,
                // company: parseInt(user.company),
                country: parseInt(user.country),
                dachGroupId: parseInt(user.dachGroupId),
                companyTypeId: parseInt(user.companyTypeId),
                businessSectorId: parseInt(user?.businessSectorId?.id),
                companySizeId: parseInt(user.companySizeId),
                countryIds: countryIds,
                businessActivityList: businessActivityList,
                investorBusinessSectors: investorBusinessSectors,
                investmentsIds: investmentsIds,
                industries: industries
            };


            setLoading(true);
            registration(parsedUser).then(value => {
                setDefaultUser();
                if (imgFile) {
                    let formData = new FormData();
                    formData.append("userId", value?.data?.id);
                    formData.append("extension", getFileExtension(imgFile?.name));
                    formData.append("picture", imgFile);
                    uploadUserImage(formData).then(() => {
                    }).catch(reason => {
                        console.error(reason);
                        enqueueSnackbar(t('pictureError'), {
                            variant: 'error'
                        });
                    });

                }
                enqueueSnackbar(t('successRegistration'), {
                    variant: 'success'
                });
                handleLogin()
            }).catch(reason => {
                setLoading(false);
                enqueueSnackbar(t('registratironError'), {
                    variant: 'error'
                });
            });
        }
    }
    const history = useHistory();

    const handleLogin = () => {
        setLoading(true);
        loginUserSync({username: user.email, password: user.password}).then(response => {
            localStorage.setItem("token", response?.data.token);

            meWithToken(response?.data.token).then(response1 => {
                localStorage.setItem("user", JSON.stringify(response1?.data));
                setLoading(false);
                setShowModalR(false);
                history.push(PROFILE_ROUTE);
                window.location.reload()
            })
        }).catch(reason => {
            setLoading(false);
            console.error("LOGIN", reason)
            enqueueSnackbar(t('usernamePasswordError'), {
                variant: 'error'
            });
        });
    };

    const prevStep = () => {
        if (activeStep !== 0)
            setActiveStep((currentStep) => currentStep - 1)
    }

    function setDefaultUser() {
        setUser({
            "email": "",
            "name": "",
            "password": "",
            "repeatPassword": "",
            "lastName": "",
            "pictureUrl": "",
            "company": "",
            "country": "",
            "cityId": null,
            "cityString": "",
            "phone": "",
            "businessSectorId": null,
            "companySizeId": null,
            "companyTypeId": null,
            "investmentTypeId": null,
            "roleId": null,
            "vulnerableGroupTypeId": null,
            "mentorTypeId": null,
            "pksGroupId": null,
            "genderId": 3,
            "brokerTypeId": null,
            "description": "",
            "countryId": null,
            "countryIds": [],
            "description1": "",
            "foreignFirms": "",
            "mentor": false,
            "broker": false,
            "investor": false,
            "plans": "",
            "identificationNumber": "",
            "foreignInvestment": false,
            "investments": "",
            "businessActivityList": [],
            "investorBusinessSectors": [],
            "investmentsIds": [],
            "export_chances": "",
            "industries": [],
            "companyExamples": "",
            "associationName": "",
            "dachGroupId": null,
            "success": "",
            "supportMechanism": "",
            "followersNumber": null,
            "explanation": "",
            "showExample": "",
            "aboutSelf": "",
            "city": {},
            "organizationalUnitTypeId": null,
            "organizationalUnitName": null,
            "existingExperiental": null,
            "existingDesiredCooperation": null,
            "leadingInvestors": null,
            "rraname": ""

        });
    }

    function getFileExtension(fileName) {
        return fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
    }

    switch (activeStep) {
        case 0:
            stepView =
                <StepOne changeUserParam={changeUserParam}
                         user={user}
                         onEmailBlur={onEmailBlur}
                         roleString={roleString}
                         setRoleString={setRoleString}
                         selectedSktor={selectedSktor}
                         setSelectedSektor={setSelectedSektor}/>
            break;
        case 1:
            stepView = <StepTwo
                imgFile={imgFile}
                setImgFile={setImgFile}
                changeUserParam={changeUserParam}
                changeUserParam2={changeUserParam2}
                user={user}/>
            break;
        case 2:
            stepView = <StepThree roleString={roleString} changeUserParam={changeUserParam} user={user}/>
            break;
        default:
            stepView = <StepOne/>
            break;
    }

    const animation = useSpring({
        config: {
            duration: 250
        },
        width: windowSize?.width > 1000 ? '80%' : '90%',
        opacity: showModalR ? 1 : 0,
        transform: showModalR ? `translateY(0%)` : `translateY(-100%)`
    });
    const keyPress = React.useCallback(
        e => {
            if (e.key === 'Escape' && showModalR) {
                setShowModalR(false);
                setDefaultUser();
                setActiveStep(0);
            }
        },
        [setShowModalR, showModalR]
    );

    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );
    let text;
    if (activeStep !== 2) {
        text = t('nextStep')
    } else {
        text = t('finish')
    }
    return (
        <>
            {showModalR && <Background>
                <animated.div style={animation}>
                    <DivRopw>
                        {windowSize?.width > 1000 &&
                        <ContainerWithBackground>
                            {<img src={RegPhoto} alt="logo" style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}/>}
                            <BigText style={{
                                fontWeight: '500',
                                textAlign: 'center',
                                color: 'white',
                                position: 'absolute',
                                top: 160,
                                left: 0,
                                right: 0,
                                width: '400px',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}>{t('register2ndPagePicture')}</BigText>
                        </ContainerWithBackground>}
                        <ContainerItem primary>
                            <Close onClick={() => {
                                setShowModalR(false);
                                setActiveStep(0);
                                setDefaultUser()
                            }}>✖</Close>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                paddingTop: '2rem',
                                paddingRight: '2rem',
                                paddingLeft: '2rem'
                            }}>
                                {loading ? <CircularProgress/> :
                                    <>
                                        {stepView}
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row', justifyContent: 'flex-end'
                                        }}>
                                            {activeStep > 0 && (

                                                <ButtonR style={{marginRight: '0.5rem'}}
                                                         onClick={prevStep}>{t('previousStep')}</ButtonR>
                                            )}
                                            <ButtonL style={{marginLeft: '0.5rem'}} onClick={nextStep}>{text}</ButtonL>
                                        </div>
                                    </>}

                            </div>
                        </ContainerItem>
                    </DivRopw>
                </animated.div>
            </Background>}
        </>
    )
}
