import React, {useEffect, useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {FlexRow} from "../../../components/Flex";
import {useSelector} from "react-redux";

const StyledRow = styled(FlexRow)`
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
`;


export default function PreviewProjectModal({showModal, setShowModal, data}) {
    const {t} = useTranslation();
    const {user} = useSelector((state) => state.user)
    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (showModal) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [showModal]);

    const handleClose = () => {
        setShowModal(false);
    };

    function getTextForCountries() {
        let countries = "";
        for (let i = 0; i < data?.countries?.length; i++) {
            countries += data?.countries[i]?.name;
            if (i < data?.countries?.length - 1)
                countries += ", ";
        }
        return countries;
    }

    return (
        <>
            {showModal &&
            <div>
                <Dialog
                    maxWidth="xl"
                    open={showModal}
                    onClose={handleClose}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">{t('projectPreview.previw')}</DialogTitle>
                    <DialogContent dividers={'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}>
                            <StyledRow>
                                <Typography>{t('projectPreview.typeOfViewer')}</Typography>
                                <Typography>Test</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.nameAndSurname')}</Typography>
                                <Typography>{user?.name} {user?.lastName}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.address')}</Typography>
                                <Typography>{user?.city?.name}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.phone')}</Typography>
                                <Typography>{user?.phone}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.email')}</Typography>
                                <Typography>{user?.email}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.notes')}</Typography>
                                <Typography>{data?.note}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.projectName')}</Typography>
                                <Typography>{data?.projectName}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.municipality')}</Typography>
                                <Typography>{data?.municipality}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.sectorOfActivity')}</Typography>
                                <Typography>{data?.businessActivity}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.projectGoal')}</Typography>
                                <Typography>{data?.projectGoal}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.projectDescription')}</Typography>
                                <Typography>{data?.description}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.realizationYear')}</Typography>
                                <Typography>{data?.startYear}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.projectDuration')}</Typography>
                                <Typography>{data?.duration}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.projectType')}</Typography>
                                <Typography>
                                    {data.isPrivate === true ? t('projectPreview.private') : t('projectPreview.public')}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.projectValue')}</Typography>
                                <Typography>{data?.totalValue}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.investingDescription')}</Typography>
                                <Typography>{data?.investDescription}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.financing')}</Typography>
                                <Typography>{`${t('projectPreview.ownResources')} ${data?.ownFunds}%; ${t('projectPreview.otherResourcesDesc')}
                            ${data?.amountOther}%; ${t('projectPreview.amountInvestor')} ${data?.amountInvestor}%`}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.ownResources')}</Typography>
                                <Typography>{data?.ownFunds}%</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.otherResources')}</Typography>
                                <Typography>{data?.amountOther}%</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.advantage')}</Typography>
                                <Typography>{data?.advantage}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.descriptionOfTheInternalMarket')}</Typography>
                                <Typography>{data?.marketDescription}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.currentPosition')}</Typography>
                                <Typography>{data?.position}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.targetGroup')}</Typography>
                                <Typography>{data?.targetGroup}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.exportMarket')}</Typography>
                                <Typography>{getTextForCountries()}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.workingName')}</Typography>
                                <Typography>{data?.firm}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.shortWorkingName')}</Typography>
                                <Typography>{data?.shortName}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.JMBG')}</Typography>
                                <Typography>{data?.identificationNumber}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.dateOfCreation')}</Typography>
                                <Typography>{data?.establishmentDate}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.location')}</Typography>
                                <Typography>{data?.headOffice}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.delationName')}</Typography>
                                <Typography>{data?.firmDelation}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.numOfEmployeers')}</Typography>
                                <Typography>{data?.employeesNumber}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.wokringStandards')}</Typography>
                                <Typography>{data?.standards}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.workingCertifications')}</Typography>
                                <Typography>{data?.certificates}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('projectPreview.webAddress')}</Typography>
                                <Typography>{data?.webAddress}</Typography>
                            </StyledRow>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>

                    </DialogActions>
                </Dialog>
            </div>
            }
        </>

    );
}
