import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 4rem;
    background-color: #F6F7FA;
    width: 100%;
`
