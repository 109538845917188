import {FlexCol} from "../../components/Flex";
import React from "react";
import {TextField} from "@mui/material";

export default function Broker({userInfo, t, lng}) {

    return (
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="businessActivityList"
                    label={t('registration.broker.question1')}
                    multiline
                    maxRows={4}
                    value={userInfo?.businessActivityList || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="brokerTypeId"
                    label={t('registration.broker.question2')}
                    multiline
                    maxRows={4}
                    value={userInfo?.brokerTypeId || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="description"
                    label={t('registration.broker.question3')}
                    multiline
                    maxRows={4}
                    value={userInfo?.description || ''}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="description"
                    label={t('registration.broker.question4')}
                    multiline
                    maxRows={4}
                    value={userInfo?.description1 || ''}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="foreignFirms"
                    label={t('registration.broker.question5')}
                    multiline
                    maxRows={4}
                    value={userInfo?.foreignFirms || ''}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

