import * as React from 'react'

export function isPwaBrowser() {
    return (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches)
}

const checkStandalone = () => {
    return (
        navigator?.standalone ||
        window?.matchMedia('(display-mode: standalone)').matches
    )
}

const fireTracking = (label) => {

};

const initialState = {
    isInstallPromptSupported: false,
    promptInstall: () => null,
    isStandalone: checkStandalone()
}

const usePWA = () => {
    const [pwaInfos, setPwaInfos] = React.useState(initialState)

    React.useEffect(() => {
        const beforeinstallpromptHandler = (e) => {
            e.preventDefault()

            const promptInstall = async () => {
                const promptRes = await e.prompt()
                if (promptRes.outcome === 'accepted') {
                    setPwaInfos({
                        ...pwaInfos,
                        isStandalone: checkStandalone()
                    })
                    return true
                }
                return false
            }

            setPwaInfos({
                isInstallPromptSupported: true,
                promptInstall,
                isStandalone: checkStandalone()
            })
        }

        const onAppInstalled = () => {
            setTimeout(() => setPwaInfos({...pwaInfos, isStandalone: checkStandalone()}), 200)
        }

        const onMatchMedia = () => {
            setPwaInfos({
                ...pwaInfos,
                isStandalone: checkStandalone()
            });
        }

        const onLoad = () => {
            let trackText;
            if (navigator && navigator.standalone) {
                trackText = "Launched: Installed (iOS)";
            } else if (matchMedia("(display-mode: standalone)").matches) {
                trackText = "Launched: Installed";
            } else {
                trackText = "Launched: Browser Tab";
            }
            fireTracking(trackText);
        }

        window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler)
        window.addEventListener('appinstalled', onAppInstalled)
        window.matchMedia('(display-mode: standalone)').addListener(onMatchMedia)
        window.addEventListener("load", onLoad);

        return () => {
            // Cleanup event listeners
            window.removeEventListener('beforeinstallprompt', beforeinstallpromptHandler)
            window.removeEventListener('appinstalled', onAppInstalled)
            window.removeEventListener('load', onLoad)
            window.matchMedia('(display-mode: standalone)').removeEventListener("change", onMatchMedia)
        }
    }, [pwaInfos])

    return pwaInfos
}

export default usePWA
