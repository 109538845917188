import Image from '../../../assets/savet.jpg';
import ImageOrganogram from '../../../assets/organograms.jpg';
import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import React from "react";

const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
 height: calc(100vh - 250px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
`

export default function BusinessAdviceForDiaspora() {
    const {t} = useTranslation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>

            <Banner>
                <h1 style={{
                    fontSize: '48px',
                    color: 'white',
                    padding: "15px",
                    backgroundColor: 'rgba(0, 0, 0, 0.55)',
                    borderRadius: '25px'
                }}>{t('businessAdvice.h1')}</h1>
                <p style={{
                    position: 'absolute',
                    right: '20px',
                    bottom: '20px',
                    padding: "15px",
                    backgroundColor: 'rgba(250, 249, 249, 0.55)',
                    borderRadius: '25px'
                }}>{t('businessAdvice.z1')}</p>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%", whiteSpace: 'pre-wrap'}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.b1')}</b>{t('businessAdvice.p1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.b2')}</b>{t('businessAdvice.p2')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.b3')}</b>{t('businessAdvice.p3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.p4')}</b>{t('businessAdvice.p5')}<b
                        style={{color: '#243b85'}}>{t('businessAdvice.b4')}</b>{t('businessAdvice.p6')}<b
                        style={{color: '#243b85'}}>{t('businessAdvice.b5')}</b>{t('businessAdvice.p7')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('businessAdvice.p8')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('businessAdvice.p9')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.b6')}</b>{t('businessAdvice.p10')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        a. <b style={{color: '#243b85'}}>{t('businessAdvice.b7')}</b>{t('businessAdvice.p11')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        b. <b style={{color: '#243b85'}}>{t('businessAdvice.b8')}</b> {t('businessAdvice.p12')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        c. <b style={{color: '#243b85'}}>{t('businessAdvice.b9')}</b>{t('businessAdvice.p13')}
                    </p>
                    <h3>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.b4')} 1</b>
                    </h3>
                    <h4>
                        <b style={{color: '#243b85'}}> &#9; &#9; &#9; &#9; &#9;• {t('businessAdvice.b10')}</b>
                    </h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.group')} 1 </b>{t('businessAdvice.p14')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.group')} 2 </b>{t('businessAdvice.p15')}
                    </p>
                    <h3>
                        <b style={{color: '#243b85'}}> {t('businessAdvice.b4')} 2</b>
                    </h3>
                    <h4>
                        <b style={{color: '#243b85'}}> &#9; &#9; &#9; &#9; &#9;• {t('businessAdvice.b11')}</b>
                    </h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.group')} 3</b> {t('businessAdvice.p16')}
                    </p>
                    <h4>
                        <b style={{color: '#243b85'}}> &#9; &#9; &#9; &#9; &#9;• {t('businessAdvice.b12')}</b>
                    </h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.group')} 4 </b> {t('businessAdvice.p17')}
                    </p>
                    <h3>

                        <b style={{color: '#243b85'}}>{t('businessAdvice.b4')} 3</b>
                    </h3>
                    <h4>
                        <b style={{color: '#243b85'}}> &#9; &#9; &#9; &#9; &#9;• {t('businessAdvice.b13')}</b>
                    </h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.group')} 5</b> {t('businessAdvice.p18')}
                    </p>
                    <h3>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.b4')} 4</b>
                    </h3>
                    <h4>
                        <b style={{color: '#243b85'}}> &#9; &#9; &#9; &#9; &#9;• {t('businessAdvice.b14')}</b>
                    </h4>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.group')} 6</b> {t('businessAdvice.p19')}
                    </p>

                    <p style={{marginTop: "1%", marginBottom: "1%"}}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <img style={{objectFit: 'cover', width: '100%', maxWidth: '900px'}} src={ImageOrganogram}
                                 alt='image'/>
                        </div>
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.b15')}</b>{t('businessAdvice.p20')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('businessAdvice.b16')}</b>{t('businessAdvice.p21')}

                    </p>

                </Paper>
            </Container>


        </div>
    )
}
