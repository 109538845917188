import React, {useContext, useRef, useState} from "react"
// material
import {alpha} from '@mui/material/styles';
import {Box, IconButton, ListItemIcon, ListItemText, MenuItem} from '@mui/material';
// components
import MenuPopover from './MenuPopover';

// ----------------------------------------------------------------------
import rs from "../../assets/images/rs.svg"
import en from "../../assets/images/sidebar/gb.svg"
import de from "../../assets/images/sidebar/de.svg"
import {setSessionLanguage} from "../../utils/session";
import {LanguageContext} from "../../context/context";
import {useTranslation} from "react-i18next";


// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const {t} = useTranslation();
    const LANGS = [
        {
            value: 'cir',
            label: t('lngSerbianC'),
            icon: rs
        },
        {
            value: 'lat',
            label: t('lngSerbian'),
            icon: rs
        },
        {
            value: 'en',
            label: t('lngEnglish'),
            icon: en
        },
        {
            value: 'de',
            label: t('lngGerman'),
            icon: de
        }
    ];
    const {i18n} = useTranslation();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [flag, setFlag] = useState(LANGS[i18n.language])



    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeLanguage = (event) => {
        setFlag(event.icon);
        i18n.changeLanguage(event.value);
        setSessionLanguage(event.value);
        handleClose()
    };

    React.useEffect(() => {
        switch (i18n.language){
            case 'de':
                setFlag(LANGS[3].icon)
                break;
            case 'cir':
                setFlag(LANGS[0].icon)
                break;
            case 'en':
                setFlag(LANGS[2].icon)
                break;
            default:
                setFlag(LANGS[1].icon)
        }
    }, [i18n.language]);


    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    marginLeft: 2,
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
                    })
                }}
            >
                <img style={{width: 25, height: 25}} src={flag} alt={LANGS[0].label} />
            </IconButton>

            <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
                <Box sx={{ py: 1 }}>
                    {LANGS.map((option) => (
                        <MenuItem
                            onClick={() => {
                                changeLanguage(option)
                            }}
                            key={option.value}
                            //selected={option.value === LANGS[2].value}
                            sx={{ py: 1, px: 2.5 }}
                        >
                            <ListItemIcon>
                                <Box style={{ width: 25, height: 25}} component="img" alt={option.label} src={option.icon} />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                                {option.label}
                            </ListItemText>
                        </MenuItem>
                    ))}
                </Box>
            </MenuPopover>
        </>
    );
}
