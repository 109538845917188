import React, {useState, useEffect} from "react";
import {NormalText} from "../Text";
import {TextArea} from "./stepThree";
import {getAllDachgroups} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";

export default function DachRegistration({user, changeUserParam}) {
    const {t} = useTranslation();
    const [dachgroups, setDachgroups] = useState([]);

    useEffect(() => {
        getAllDachgroups().then(response => {
            setDachgroups(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, []);

    return (
        <>
            <NormalText>{t('registration.dach.question1')}</NormalText>
            <TextArea value={user?.associationName} type="text" onChange={(e) => {
                changeUserParam("associationName", e.target.value)
            }}/>
            <NormalText>{t('registration.dach.question2')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    native
                    value={user.dachGroupId}
                    onChange={(e) => {
                        changeUserParam("dachGroupId", e.target.value)
                    }}>
                    <option aria-label="None" value=""/>
                    {dachgroups?.map((value, index) => (
                        <option key={index} value={value?.id}>{value?.name}</option>
                    ))}
                </Select>
            </FormControl>
            <NormalText>{t('registration.dach.question3')}</NormalText>
            <TextArea value={user?.success} type="text" onChange={(e) => {
                changeUserParam("success", e.target.value)
            }}/>
        </>
    );
}
