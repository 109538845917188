import Image from '../../../assets/dach-support.jpg'
import styled from 'styled-components'
import React from 'react';
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import ContactPage from './ContatctPage'
import {CenteredRow} from "../../../components/Flex";

const Banner = styled.div`
display:flex;
position: relative;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
background-color: #243b85;
background-size: cover;
background-position: center;
`
export default function DachSupport() {
    const {t} = useTranslation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{
                    textAlign: 'center',
                    fontSize: '48px',
                    color: 'white',
                    padding: '10px 40px 10px 40px'
                }}>{t('dachSuport.header')}</h1>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%", whiteSpace: 'pre-wrap'}}>

                    <h3><b style={{color: '#243b85'}}>{t('dachSuport.heading1')}</b></h3>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text1')}
                        <a target="_blank" href={t('dachSuport.b1.url')} rel="noreferrer"><b style={{color: '#243b85'}}>{t('dachSuport.b1.text')}</b></a>
                        {t('dachSuport.text2')}
                        <a target="_blank" href={t('dachSuport.b2.url')} rel="noreferrer"><b style={{color: '#243b85'}}>{t('dachSuport.b2.text')}</b></a>
                        {t('dachSuport.text3')}
                        <a target="_blank" href={t('dachSuport.b3.url')} rel="noreferrer"><b style={{color: '#243b85'}}>{t('dachSuport.b3.text')}</b></a>
                        {t('dachSuport.text4')}
                    </p>

                    <h3><b style={{color: '#243b85'}}>{t('dachSuport.heading2')}</b></h3>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS1')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text5')}
                        <a target="_blank" href={t('dachSuport.b4.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b4.text')}</b>
                        </a>
                        {t('dachSuport.text6')}
                        <a target="_blank" href={t('dachSuport.b5.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b5.text')}</b>
                        </a>
                        {t('dachSuport.text7')}
                        <a target="_blank" href={t('dachSuport.b6.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b6.text')}</b>
                        </a>
                        {t('dachSuport.text8')}
                        <a target="_blank" href={t('dachSuport.b7.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b7.text')}</b>
                        </a>
                        {t('dachSuport.text9')}
                        <a target="_blank" href={t('dachSuport.b8.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b8.text')}</b>
                        </a>
                        {t('dachSuport.text10')}
                        <a target="_blank" href={t('dachSuport.b9.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b9.text')}</b>
                        </a>
                        {t('dachSuport.text11')}
                        <a target="_blank" href={t('dachSuport.b3.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b10.text')}</b>
                        </a>
                        {t('dachSuport.text12')}
                        <a target="_blank" href={t('dachSuport.b11.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b11.text')}</b>
                        </a>
                        {t('dachSuport.text13')}
                        <a target="_blank" href={t('dachSuport.b12.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b12.text')}</b>
                        </a>
                        {t('dachSuport.text14')}
                        <a target="_blank" href={t('dachSuport.b13.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b13.text')}</b>
                        </a>
                        {t('dachSuport.text15')}
                        <a target="_blank" href={t('dachSuport.b14.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b14.text')}</b>
                        </a>
                        {t('dachSuport.text16')}
                        <a target="_blank" href={t('dachSuport.b15.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b15.text')}</b>
                        </a>
                        {t('dachSuport.text17')}
                        <a target="_blank" href={t('dachSuport.b16.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b16.text')}</b>
                        </a>
                        {t('dachSuport.text18')}
                        <a target="_blank" href={t('dachSuport.b17.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b17.text')}</b>
                        </a>
                        {t('dachSuport.text19')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>

                    </p>

                    <ContactPage streat={t('dachSuport.kontakt1.streat')} address={t('dachSuport.kontakt1.address')}
                                 phone={t('dachSuport.kontakt1.phone')} fax={t('dachSuport.kontakt1.fax')}
                                 email={t('dachSuport.kontakt1.email')} website={t('dachSuport.kontakt1.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text20')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS2')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text21')}
                        <a target="_blank" href={t('dachSuport.b18.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b18.text')}</b>
                        </a>
                        {t('dachSuport.text22')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt2.streat')} address={t('dachSuport.kontakt2.address')}
                                 phone={t('dachSuport.kontakt2.phone')} fax={t('dachSuport.kontakt2.fax')}
                                 email={t('dachSuport.kontakt2.email')} website={t('dachSuport.kontakt2.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text23')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS3')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text24')}
                        <a target="_blank" href={t('dachSuport.b19.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b19.text')}</b>
                        </a>
                        {t('dachSuport.text25')}
                        <a target="_blank" href={t('dachSuport.b20.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b20.text')}</b>
                        </a>
                        {t('dachSuport.text26')}
                        <a target="_blank" href={t('dachSuport.b21.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b21.text')}</b>
                        </a>
                        {t('dachSuport.text27')}
                        <a target="_blank" href={t('dachSuport.b22.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b22.text')}</b>
                        </a>
                        {t('dachSuport.text28')}
                        <a target="_blank" href={t('dachSuport.b23.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b23.text')}</b>
                        </a>
                        {t('dachSuport.text29')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt3.streat')} address={t('dachSuport.kontakt3.address')}
                                 phone={t('dachSuport.kontakt3.phone')} fax={t('dachSuport.kontakt3.fax')}
                                 email={t('dachSuport.kontakt3.email')} website={t('dachSuport.kontakt3.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text30')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS4')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text31')}
                        <a target="_blank" href={t('dachSuport.b24.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b24.text')}</b>
                        </a>
                        {t('dachSuport.text32')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt4.streat')} address={t('dachSuport.kontakt4.address')}
                                 phone={t('dachSuport.kontakt4.phone')} fax={t('dachSuport.kontakt4.fax')}
                                 email={t('dachSuport.kontakt4.email')} website={t('dachSuport.kontakt4.website')}
                                 head={''}/>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS5')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text33')}
                        <a target="_blank" href={t('dachSuport.b25.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b25.text')}</b>
                        </a>
                        {t('dachSuport.text34')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt5.streat')} address={t('dachSuport.kontakt5.address')}
                                 phone={t('dachSuport.kontakt5.phone')} fax={t('dachSuport.kontakt5.fax')}
                                 email={t('dachSuport.kontakt5.email')} website={t('dachSuport.kontakt5.website')}
                                 contact={t('dachSuport.kontakt5')} head={''}/>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS6')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text35')}
                        <a target="_blank" href={t('dachSuport.b26.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b26.text')}</b>
                        </a>
                        {t('dachSuport.text36')}
                        <a target="_blank" href={t('dachSuport.b27.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b27.text')}</b>
                        </a>
                        {t('dachSuport.text37')}
                        <a target="_blank" href={t('dachSuport.b28.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b28.text')}</b>
                        </a>
                        {t('dachSuport.text38')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt6.streat')} address={t('dachSuport.kontakt6.address')}
                                 phone={t('dachSuport.kontakt6.phone')} fax={t('dachSuport.kontakt6.fax')}
                                 email={t('dachSuport.kontakt6.email')} website={t('dachSuport.kontakt6.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text39')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS7')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text40')}
                        <a target="_blank" href={t('dachSuport.b29.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b29.text')}</b>
                        </a>
                        {t('dachSuport.text41')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt7.streat')} address={t('dachSuport.kontakt7.address')}
                                 phone={t('dachSuport.kontakt7.phone')} fax={t('dachSuport.kontakt7.fax')}
                                 email={t('dachSuport.kontakt7.email')} website={t('dachSuport.kontakt7.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text42')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS8')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text43')}
                        <a target="_blank" href={t('dachSuport.b30.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b30.text')}</b>
                        </a>
                        {t('dachSuport.text44')}
                        <a target="_blank" href={t('dachSuport.b31.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b31.text')}</b>
                        </a>
                        {t('dachSuport.text45')}
                        <a target="_blank" href={t('dachSuport.b32.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b32.text')}</b>
                        </a>
                        {t('dachSuport.text46')}
                        <a target="_blank" href={t('dachSuport.b33.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b33.text')}</b>
                        </a>
                        {t('dachSuport.text47')}
                        <a target="_blank" href={t('dachSuport.b34.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b34.text')}</b>
                        </a>
                        {t('dachSuport.text48')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt8.streat')} address={t('dachSuport.kontakt8.address')}
                                 phone={t('dachSuport.kontakt8.phone')} fax={t('dachSuport.kontakt8.fax')}
                                 email={t('dachSuport.kontakt8.email')} website={t('dachSuport.kontakt8.website')}
                                 head={''}/>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS9')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <a target="_blank" href={t('dachSuport.b35.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b35.text')}</b>
                        </a>
                        {t('dachSuport.text49')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt9.streat')} address={t('dachSuport.kontakt9.address')}
                                 phone={t('dachSuport.kontakt9.phone')} fax={t('dachSuport.kontakt9.fax')}
                                 email={t('dachSuport.kontakt9.email')} website={t('dachSuport.kontakt9.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text50')}
                    </p>

                    <h3><b style={{color: '#243b85'}}>{t('dachSuport.heading3')}</b></h3>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS10')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text51')}
                    </p>

                    <div style={{width: '100%', alignContent: 'center'}}>
                        <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS11')}</b></h4>
                        <CenteredRow>
                            <img style={{objectFit: 'cover', width: '100%', maxWidth: '800px'}} src={Image}
                                 alt="image"/>
                        </CenteredRow>

                    </div>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text52')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS12')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text53')}
                        <a target="_blank" href={t('dachSuport.b36.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b36.text')}</b>
                        </a>
                        {t('dachSuport.text54')}
                        <a target="_blank" href={t('dachSuport.b37.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b37.text')}</b>
                        </a>
                        {t('dachSuport.text55')}
                        <a target="_blank" href={t('dachSuport.b38.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b38.text')}</b>
                        </a>
                        {t('dachSuport.text56')}
                        <a target="_blank" href={t('dachSuport.b39.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b39.text')}</b>
                        </a>
                        {t('dachSuport.text57')}
                        <a target="_blank" href={t('dachSuport.b40.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b40.text')}</b>
                        </a>
                        {t('dachSuport.text58')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt10.streat')} address={t('dachSuport.kontakt10.address')}
                                 phone={t('dachSuport.kontakt10.phone')} fax={t('dachSuport.kontakt10.fax')}
                                 email={t('dachSuport.kontakt10.email')} website={t('dachSuport.kontakt10.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text59')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS13')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text60')}
                        <a target="_blank" href={t('dachSuport.b41.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b41.text')}</b>
                        </a>
                        {t('dachSuport.text61')}
                        <a target="_blank" href={t('dachSuport.b42.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b42.text')}</b>
                        </a>
                        {t('dachSuport.text62')}
                        <a target="_blank" href={t('dachSuport.b43.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b43.text')}</b>
                        </a>
                        {t('dachSuport.text63')}
                        <a target="_blank" href={t('dachSuport.b44.url')} rel="noreferrer">
                            <b style={{}}>{t('dachSuport.b44.text')}</b>
                        </a>
                        {t('dachSuport.text64')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt11.streat')} address={t('dachSuport.kontakt11.address')}
                                 phone={t('dachSuport.kontakt11.phone')} fax={t('dachSuport.kontakt11.fax')}
                                 email={t('dachSuport.kontakt11.email')} website={t('dachSuport.kontakt11.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text65')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS14')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text66')}
                        <a target="_blank" href={t('dachSuport.b45.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b45.text')}</b>
                        </a>
                        {t('dachSuport.text67')}
                        <a target="_blank" href={t('dachSuport.b46.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b46.text')}</b>
                        </a>
                        {t('dachSuport.text68')}
                        <a target="_blank" href={t('dachSuport.b47.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b47.text')}</b>
                        </a>
                        {t('dachSuport.text69')}
                        <a target="_blank" href={t('dachSuport.b48.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b48.text')}</b>
                        </a>
                        {t('dachSuport.text70')}
                        <a target="_blank" href={t('dachSuport.b49.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b49.text')}</b>
                        </a>
                        {t('dachSuport.text71')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt12.streat')} address={t('dachSuport.kontakt12.address')}
                                 phone={t('dachSuport.kontakt12.phone')} fax={t('dachSuport.kontakt12.fax')}
                                 email={t('dachSuport.kontakt12.email')} website={t('dachSuport.kontakt12.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text72')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS15')}</b></h4>

                    <p style={{
                        textAlign: "justify",
                        textJustify: "inter-word",
                        marginBottom: "1%"
                    }}>{t('dachSuport.text73')}
                        <b style={{color: '#243b85'}}>{t('dachSuport.b50.text')}</b>
                        {t('dachSuport.text74')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt13.streat')} address={t('dachSuport.kontakt13.address')}
                                 phone={t('dachSuport.kontakt13.phone')} fax={t('dachSuport.kontakt13.fax')}
                                 email={t('dachSuport.kontakt13.email')} website={t('dachSuport.kontakt13.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text75')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS16')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text76')}
                        <a target="_blank" href={t('dachSuport.b51.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b51.text')}</b>
                        </a>
                        {t('dachSuport.text77')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt14.streat')} address={t('dachSuport.kontakt14.address')}
                                 phone={t('dachSuport.kontakt14.phone')} fax={t('dachSuport.kontakt14.fax')}
                                 email={t('dachSuport.kontakt14.email')} website={t('dachSuport.kontakt14.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text78')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS17')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text79')}
                        <a target="_blank" href={t('dachSuport.b52.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b52.text')}</b>
                        </a>
                        <a target="_blank" href={t('dachSuport.b51-1.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b51-1.text')}</b>
                        </a>
                        {t('dachSuport.text80')}
                        <a target="_blank" href={t('dachSuport.b53.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b53.text')}</b>
                        </a>
                        {t('dachSuport.text80-1')}
                        <a target="_blank" href={t('dachSuport.b53-1.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b53-1.text')}</b>
                        </a>
                        {t('dachSuport.text81')}
                        <a target="_blank" href={t('dachSuport.b54.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b54.text')}</b>
                        </a>
                        {t('dachSuport.text82')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt15.streat')} address={t('dachSuport.kontakt15.address')}
                                 phone={t('dachSuport.kontakt15.phone')} fax={t('dachSuport.kontakt15.fax')}
                                 email={t('dachSuport.kontakt15.email')} website={t('dachSuport.kontakt15.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text83')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS18')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text84')}
                        <a target="_blank" href={t('dachSuport.b55.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b55.text')}</b>
                        </a>
                        {t('dachSuport.text85')}
                        <a target="_blank" href={t('dachSuport.b56.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b56.text')}</b>
                        </a>
                        {t('dachSuport.text86')}
                        <a target="_blank" href={t('dachSuport.b57.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b57.text')}</b>
                        </a>
                        {t('dachSuport.text87')}
                        <a target="_blank" href={t('dachSuport.b58.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b58.text')}</b>
                        </a>
                        {t('dachSuport.text88')}
                        <a target="_blank" href={t('dachSuport.b59.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b59.text')}</b>
                        </a>
                        {t('dachSuport.text89')}
                        <a target="_blank" href={t('dachSuport.b60.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b60.text')}</b>
                        </a>
                        {t('dachSuport.text90')}
                        <a target="_blank" href={t('dachSuport.b61.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b61.text')}</b>
                        </a>
                        {t('dachSuport.text91')}
                        <a target="_blank" href={t('dachSuport.b62.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b62.text')}</b>
                        </a>
                        {t('dachSuport.text91-1')}
                        <a target="_blank" href={t('dachSuport.b62-1.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b62-1.text')}</b>
                        </a>
                        {t('dachSuport.text92')}
                        <a target="_blank" href={t('dachSuport.b63.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b63.text')}</b>
                        </a>
                        {t('dachSuport.text93')}
                        <a target="_blank" href={t('dachSuport.b64.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b64.text')}</b>
                        </a>
                        {t('dachSuport.text94')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt16.streat')} address={t('dachSuport.kontakt16.address')}
                                 phone={t('dachSuport.kontakt16.phone')} fax={t('dachSuport.kontakt16.fax')}
                                 email={t('dachSuport.kontakt16.email')} website={t('dachSuport.kontakt16.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text95')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS19')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <a target="_blank" href={t('dachSuport.b65.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b65.text')}</b>
                        </a>
                        {t('dachSuport.text96')}
                        <a target="_blank" href={t('dachSuport.b66.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}> {t('dachSuport.b66.text')}</b>
                        </a>
                        {t('dachSuport.text97')}
                        <a target="_blank" href={t('dachSuport.b67.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b67.text')}</b>
                        </a>
                        {t('dachSuport.text97-1')}
                        <a target="_blank" href={t('dachSuport.b67-1.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b67-1.text')}</b>
                        </a>
                        {t('dachSuport.text98')}
                        <a target="_blank" href={t('dachSuport.b68.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b68.text')}</b>
                        </a>
                        {t('dachSuport.text99')}
                        <a target="_blank" href={t('dachSuport.b69.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b69.text')}</b>
                        </a>
                        {t('dachSuport.text100')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt17.streat')} address={t('dachSuport.kontakt17.address')}
                                 phone={t('dachSuport.kontakt17.phone')} fax={t('dachSuport.kontakt17.fax')}
                                 email={t('dachSuport.kontakt17.email')} website={t('dachSuport.kontakt17.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text101')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS20')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text102')}
                        <a target="_blank" href={t('dachSuport.b70.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b70.text')}</b>
                        </a>
                        {t('dachSuport.text103')}
                        <a target="_blank" href={t('dachSuport.b71.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b71.text')}</b>
                        </a>
                        {t('dachSuport.text104')}
                        <a target="_blank" href={t('dachSuport.b72.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b72.text')}</b>
                        </a>
                        {t('dachSuport.text105')}
                        <a target="_blank" href={t('dachSuport.b73.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b73.text')}</b>
                        </a>
                        {t('dachSuport.text106')}
                    </p>


                    <ContactPage streat={t('dachSuport.kontakt18.streat')} address={t('dachSuport.kontakt18.address')}
                                 phone={t('dachSuport.kontakt18.phone')} fax={t('dachSuport.kontakt18.fax')}
                                 email={t('dachSuport.kontakt18.email')} website={t('dachSuport.kontakt18.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text107')}
                    </p>

                    <h3><b style={{color: '#243b85'}}>{t('dachSuport.heading4')}</b></h3>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS21')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text108')}
                        <a target="_blank" href={t('dachSuport.b74.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b74.text')}</b>
                        </a>
                        {t('dachSuport.text109')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt19.streat')} address={t('dachSuport.kontakt19.address')}
                                 phone={t('dachSuport.kontakt19.phone')} fax={t('dachSuport.kontakt19.fax')}
                                 email={t('dachSuport.kontakt19.email')} website={t('dachSuport.kontakt19.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text110')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS22')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text111')}
                        <a target="_blank" href={t('dachSuport.b75.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b75.text')}</b>
                        </a>
                        {t('dachSuport.text112')}
                        <a target="_blank" href={t('dachSuport.b76.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b76.text')}</b>
                        </a>
                        {t('dachSuport.text113')}
                        <a target="_blank" href={t('dachSuport.b77.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b77.text')}</b>
                        </a>
                        {t('dachSuport.text114')}
                        <a target="_blank" href={t('dachSuport.b78.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b78.text')}</b>
                        </a>
                        {t('dachSuport.text115')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt20.streat')} address={t('dachSuport.kontakt20.address')}
                                 phone={t('dachSuport.kontakt20.phone')} fax={t('dachSuport.kontakt20.fax')}
                                 email={t('dachSuport.kontakt20.email')} website={t('dachSuport.kontakt20.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text116')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS23')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text117')}
                        <a target="_blank" href={t('dachSuport.b79.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b79.text')}</b>
                        </a>
                        {t('dachSuport.text118')}
                        <a target="_blank" href={t('dachSuport.b80.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b80.text')}</b>
                        </a>
                        {t('dachSuport.text119')}
                        <a target="_blank" href={t('dachSuport.b81.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b81.text')}</b>
                        </a>
                        {t('dachSuport.text120')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt21.streat')} address={t('dachSuport.kontakt21.address')}
                                 phone={t('dachSuport.kontakt21.phone')} fax={t('dachSuport.kontakt21.fax')}
                                 email={t('dachSuport.kontakt21.email')} website={t('dachSuport.kontakt21.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text121')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS24')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text122')}
                        <a target="_blank" href={t('dachSuport.b82.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b82.text')}</b>
                        </a>
                        {t('dachSuport.text123')}
                        <a target="_blank" href={t('dachSuport.b83.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b83.text')}</b>
                        </a>
                        {t('dachSuport.text124')}
                        <a target="_blank" href={t('dachSuport.b84.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b84.text')}</b>
                        </a>
                        {t('dachSuport.text125')}
                        <a target="_blank" href={t('dachSuport.b85.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b85.text')}</b>
                        </a>
                        {t('dachSuport.text126')}
                        <a target="_blank" href={t('dachSuport.b86.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b86.text')}</b>
                        </a>
                        {t('dachSuport.text127')}
                        <a target="_blank" href={t('dachSuport.b87.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b87.text')}</b>
                        </a>
                        {t('dachSuport.text128')}
                        <a target="_blank" href={t('dachSuport.b88.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b88.text')}</b>
                        </a>
                        {t('dachSuport.text128-1')}
                        <a target="_blank" href={t('dachSuport.b88-1.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b88-1.text')}</b>
                        </a>
                        {t('dachSuport.text129')}
                        <a target="_blank" href={t('dachSuport.b89.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b89.text')}</b>
                        </a>
                        {t('dachSuport.text130')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt22.streat')} address={t('dachSuport.kontakt22.address')}
                                 phone={t('dachSuport.kontakt22.phone')} fax={t('dachSuport.kontakt22.fax')}
                                 email={t('dachSuport.kontakt22.email')} website={t('dachSuport.kontakt22.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text131')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS25')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text132')}
                        <a target="_blank" href={t('dachSuport.b90.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b90.text')}</b>
                        </a>
                        {t('dachSuport.text133')}
                        <a target="_blank" href={t('dachSuport.b91.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b91.text')}</b>
                        </a>
                        {t('dachSuport.text134')}
                        <a target="_blank" href={t('dachSuport.b92.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b92.text')}</b>
                        </a>
                        {t('dachSuport.text135')}
                        <a target="_blank" href={t('dachSuport.b93.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b93.text')}</b>
                        </a>
                        {t('dachSuport.text136')}
                        <a target="_blank" href={t('dachSuport.b94.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b94.text')}</b>
                        </a>
                        {t('dachSuport.text137')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt23.streat')} address={t('dachSuport.kontakt23.address')}
                                 phone={t('dachSuport.kontakt23.phone')} fax={t('dachSuport.kontakt23.fax')}
                                 email={t('dachSuport.kontakt23.email')} website={t('dachSuport.kontakt23.website')}
                                 head={''}/>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS26')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text138')}
                        <a target="_blank" href={t('dachSuport.b95.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b95.text')}</b>
                        </a>
                        {t('dachSuport.text139')}
                        <a target="_blank" href={t('dachSuport.b916url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b96.text')}</b>
                        </a>
                        {t('dachSuport.text140')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt24.streat')} address={t('dachSuport.kontakt24.address')}
                                 phone={t('dachSuport.kontakt24.phone')} fax={t('dachSuport.kontakt24.fax')}
                                 email={t('dachSuport.kontakt24.email')} website={t('dachSuport.kontakt24.website')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text141')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS27')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text142')}
                        <a target="_blank" href={t('dachSuport.b97.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b97.text')}</b>
                        </a>
                        {t('dachSuport.text143')}
                        <a target="_blank" href={t('dachSuport.b98.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b98.text')}</b>
                        </a>
                        {t('dachSuport.text144')}
                        <a target="_blank" href={t('dachSuport.b99.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b99.text')}</b>
                        </a>
                        {t('dachSuport.text145')}
                        <a target="_blank" href={t('dachSuport.b100.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b100.text')}</b>
                        </a>
                        {t('dachSuport.text146')}
                        <a target="_blank" href={t('dachSuport.b101.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b101.text')}</b>
                        </a>
                        {t('dachSuport.text147')}
                        <a target="_blank" href={t('dachSuport.b102.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b102.text')}</b>
                        </a>
                        {t('dachSuport.text148')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS28')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <a target="_blank" href={t('dachSuport.b103.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b103.text')}</b>
                        </a>
                        {t('dachSuport.text149')}
                    </p>

                    <ContactPage streat={t('dachSuport.kontakt25.streat')} address={t('dachSuport.kontakt25.address')}
                                 phone={t('dachSuport.kontakt25.phone')} fax={t('dachSuport.kontakt25.fax')}
                                 email={t('dachSuport.kontakt25.email')} website={t('dachSuport.kontakt25.website')}
                                 contact={t('dachSuport.kontakt25')} head={''}/>

                    {/* <p style={{ textAlign: "justify", textJustify: "inter-word", marginBottom: "1%" }}>
                        {t('dachSuport.text150')}
                    </p> */}

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS29')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <a target="_blank" href={t('dachSuport.b104.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b104.text')}</b>
                        </a>
                        {t('dachSuport.text151')}
                        <a target="_blank" href={t('dachSuport.b105.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b105.text')}</b>
                        </a>
                        {t('dachSuport.text152')}
                    </p>

                    <ContactPage address={t('dachSuport.kontakt26.address')} phone={t('dachSuport.kontakt26.phone')}
                                 fax={t('dachSuport.kontakt26.fax')} email={t('dachSuport.kontakt26.email')}
                                 website={t('dachSuport.kontakt26.website')} contact={t('dachSuport.kontakt26')}
                                 head={''}/>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text153')}
                    </p>

                    <h4><b style={{color: '#243b85'}}>{t('dachSuport.headingS30')}</b></h4>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('dachSuport.text154')}
                        <a target="_blank" href={t('dachSuport.b106.url')} rel="noreferrer">
                            <b style={{color: '#243b85'}}>{t('dachSuport.b106.text')}</b>
                        </a>
                        {t('dachSuport.text155')}
                    </p>

                </Paper>
            </Container>


        </div>
    )
}
