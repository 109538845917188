import React, {useState} from "react";
import {CenteredCol} from "../../../../../components/Flex";
import {Hr, KeyWordWrapper, StyledCol, Title} from "../../../style";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import {searchEventsAsync} from "../../../../../services/api";
import {SEARCH_TYPES} from "../../../index";

export default function EventsSection({onMenuClick, inputString}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });
    const [sort, setSort] = useState({
        rowPerPage: 99999999999,
        sortBy: "createdAt,ASC",
    });

    React.useEffect(() => {
        setLoading(true);
        let parsedFilter = `?page=${page.currentPage}&sort=${sort.sortBy}&size=${sort.rowPerPage}`
        searchEventsAsync(inputString, parsedFilter).then(response => {
            setEvents(response?.data?.content);
            setLoading(false);
        }).catch(_ => {
            setLoading(false);
        });
    }, [inputString, page.currentPage, sort.rowPerPage, sort.sortBy])

    if (loading) {
        return (
            <CenteredCol>
                <CircularProgress/>
            </CenteredCol>
        );
    }

    if (events?.length === 0)
        return <></>;

    return (
        <StyledCol>
            <Title>{t('searchAutocomplete.events')}</Title>
            <Hr/>
            {events?.map((value, index) => {
                return (<KeyWordWrapper onClick={() => onMenuClick(SEARCH_TYPES.event, value?.id)}
                                        key={index}>{value?.name}</KeyWordWrapper>)
            })}
        </StyledCol>
    );
}