import React from "react";
import styled from "styled-components";
import {CenteredRow} from "../Flex";
import {makeStyles} from '@material-ui/core/styles';
import {default as MaterialPagination} from '@material-ui/lab/Pagination';

const Container = styled(CenteredRow)`
    padding: 1rem;
`;

const useStyles = makeStyles(() => ({
    ul: {
        "& .MuiPaginationItem-root": {
            color: "black"
        },
        "& .Mui-selected": {
            background: "#243B85",
            color: "white"
        },
        "& .Mui-selected:hover": {
            background: "#2f91ed",
            color: "white"
        }
    },
}));

export default function Pagination({currentPage, totalPages, handleClickPage}) {
    const classes = useStyles();
    return (
        <Container>
            <MaterialPagination
                classes={{ul: classes.ul}}
                count={totalPages}
                showFirstButton
                showLastButton
                page={currentPage}
                onChange={handleClickPage}/>
        </Container>
    );
}