import {CenteredCol, CenteredRow, FlexCol, FlexRow} from "../../components/Flex";
import Pagination from "../../components/Pagination";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {NormalText} from "../../components/Text";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TimerIcon from "@mui/icons-material/Timer";
import User from "../EventPage/user";
import Button from "@mui/material/Button";
import ConnectModal from "../UserPage/connectModal";
import {AvatarGroup} from "@mui/material";
import {searchEventsAsync} from "../../services/api";
import {useLocation} from "react-router-dom";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 4rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF;
    min-height: 80vh;
    @media (max-width: 648px) {
        padding: 0.2rem;
    }
`

const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 480px;
    width: 400px;
    padding: 2rem;
    background-color: #fff;
    
    &:hover{
        cursor: pointer;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
    
     @media (max-width: 648px) {
        width: 100%;
        
    }
    
`

const BText = styled.p`
    font-weight: 700;
    padding: 0;
    line-height: 24px; //for not webkit browser
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 50px;
`

const Text = styled(NormalText)`
    width: 100%;
    line-height: 20px; //for not webkit browser
    max-height: 120px; //for not webkit browser
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`
const BootstrapButton = styled(Button)({
    width: '140px',
})

async function getAllEvents(input, params) {
    return await searchEventsAsync(input, params)
}

export default function AllSearchedEvents({t}) {
    const location = useLocation();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });
    const [sort, setSort] = useState({
        rowPerPage: 10,
        sortBy: "createdAt,ASC",
    });

    useEffect(() => {
        let params = location.state.params;
        let filter = {
            input: null,
            cityIds: [],
            sectorIds: [],
            range: [0, 9999999999999999999999]
        }
        if (params) {
            filter = {
                input: params.input,
                cityIds: params.cityIds,
                sectorIds: params.sectorIds,
                range: params.range
            }
        }
        setLoading(true);
        let parsedFilter = `?page=${page.currentPage}&sort=${sort.sortBy}&size=${sort.rowPerPage}`
        getAllEvents(filter.input, parsedFilter).then(value => {
            setEvents(value.data.content);
            setPage({...page, totalPages: value?.data.totalPages})
        }).catch(error => {
            console.error(error, 'err')
            setLoading(false)
        });
    }, [location.state.params, page.currentPage, sort.rowPerPage, sort.sortBy]);


    const handleClickPage = (event, value) => {
        setPage({...page, currentPage: parseInt(value) - 1});
    };

    const [openConnect, setOpenConnect] = useState(false)

    function handleConnect() {
        setOpenConnect(true)
    }

    return (
        <Container>
            {openConnect &&<ConnectModal openConnect={openConnect} setOpenConnect={setOpenConnect} event={events}/>}
            <CenteredCol>
                <CenteredRow style={{gap: '1rem', flexWrap: "wrap"}}>
                    {events.map(event => (
                        <Card>
                            <BText
                                style={{
                                    fontSize: '24px',
                                    marginBottom: '1rem',
                                    textAlign: 'center',

                                }}>{event?.name === "" ? "Nema naziv" : event?.name}</BText>
                            <FlexCol style={{alignItems: 'center', height: '300px'}}>
                                <FlexRow style={{gap: '0.5rem'}}>
                                    <FlexRow style={{alignItems: 'center', gap: '0.5rem'}}><DateRangeIcon
                                        color="disabled"/><Text>{event?.date.substr(0, 10)}.</Text></FlexRow>
                                    <FlexRow style={{alignItems: 'center', gap: '0.5rem'}}><TimerIcon
                                        color="disabled"/><Text>{event?.durationMin} min.</Text></FlexRow>
                                </FlexRow>
                                <FlexRow
                                    style={{marginBottom: '0.5rem'}}>
                                    <Text>{event?.description}</Text>
                                </FlexRow>
                                <FlexRow style={{marginBottom: '0.5rem'}}>
                                    <Text>Učesnici:</Text>
                                </FlexRow>
                                {event.eventInvitationList.length !== 0 ?
                                    <FlexRow style={{gap: '0.5rem'}}>
                                        <AvatarGroup max={6}>
                                            {event.eventInvitationList.map(user => (
                                                <User user={user}/>
                                            ))}</AvatarGroup>
                                    </FlexRow> : <FlexRow><Text>Nema učesnika</Text></FlexRow>}
                                <FlexRow style={{marginTop: '0.5rem'}}> <Text>Tip
                                    eventa: {event?.eventType.name}</Text></FlexRow>
                                <FlexRow> <Text>Zoom link: <a href="#">{event?.zoomLink}</a></Text></FlexRow>
                            </FlexCol>
                            <CenteredRow style={{marginTop: '0.5rem'}}>
                                <BootstrapButton onClick={handleConnect}
                                                 variant="contained">{t('linkUpButton')}</BootstrapButton>
                                {/*<Text>{project?.projectLanguages[0].description === "" ? "Nema opis" : project?.projectLanguages[0]?.description}</Text>*/}
                            </CenteredRow>
                        </Card>
                    ))}

                </CenteredRow>
                {events.length !== 0 && <Pagination
                    totalPages={page.totalPages}
                    handleClickPage={handleClickPage}
                    currentPage={parseInt(page.currentPage) + 1}/>
                }
            </CenteredCol>
        </Container>
    )
}
