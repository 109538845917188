import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import React from "react";

const Banner = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    background-color: #243b85;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export default function RRACordination() {
    const {t} = useTranslation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>

            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('rraCoordination.title')}</h1>
            </Banner>
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%", whiteSpace: 'pre-wrap'}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraCoordination.a1')}</b>{t('rraCoordination.b1')}<b
                        style={{color: '#243b85'}}>{t('rraCoordination.b2')}</b>{t('rraCoordination.a3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraCoordination.b3')}</b>{t('rraCoordination.a4')}
                        <a href={"https://dijaspora.pks.rs/circular-economy/"}><b style={{color: '#243b85'}}>{t('rraCoordination.linkHere')}</b></a>).
                        <b style={{color: '#243b85'}}>{t('rraCoordination.b3')}</b>{t('rraCoordination.p3e')}
                        <a href={"https://dijaspora.pks.rs/crowdfunding/"}><b style={{color: '#243b85'}}>{t('rraCoordination.p3Link')}</b></a>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraCoordination.b3')}</b>{t('rraCoordination.a5')}
                        {/*TODO ovde da se doda pdf koji je prirucnik*/}
                        <b style={{cursor: 'pointer', textDecoration: 'underline', color: '#243b85'}}>{t('rraCoordination.p4Link')}</b>).

                    </p>
                    <h3 style={{color: '#243b85', textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('rraCoordination.title2')}
                    </h3>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('rraCoordination.a6')}
                        <a href={"https://dijaspora.pks.rs/regions/"}><b style={{color: '#243b85'}}>{t('rraCoordination.a7LinkProfileLocal')}</b></a>).
                        {t('rraCoordination.a7ex')}
                        <a href={"https://dijaspora.pks.rs/regions/"}><b style={{color: '#243b85'}}>{t('rraCoordination.a7linkRegionProfile')}</b></a>).
                        {t('rraCoordination.a7ex2')}
                        <a href={"#"}><b style={{color: '#243b85'}}>{t('rraCoordination.a7linkDays')}</b></a>).
                        {t('rraCoordination.a7ex3')}
                    </p>
                    <h3 style={{color: '#243b85', textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('rraCoordination.title3')}
                    </h3>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraCoordination.program')} 1</b><br/>
                        {t('mentorship.program1')}<br/>
                        <b style={{color: '#243b85'}}></b>{t('rraCoordination.more')}<br/>
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraCoordination.program')} 2</b><br/>
                        {t('mentorship.program2')}<br/>
                        <b style={{color: '#243b85'}}></b>{t('rraCoordination.more')}<br/>
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraCoordination.program')} 3</b><br/>
                        {t('mentorship.program3')}<br/>
                        <b style={{color: '#243b85'}}></b>{t('rraCoordination.more')}<br/>
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraCoordination.b5')}</b>{t('rraCoordination.a7')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('rraCoordination.b6')}</b>{t('rraCoordination.a8')}
                    </p>


                </Paper>
            </Container>


        </div>
    )
}
