import {configureStore} from '@reduxjs/toolkit'
import {userSliceReducer} from "../redux/user";
import {projectSliceReducer} from "../redux/projects";
import {citiesSliceReducer} from "../redux/citySlice";
import thunk from "redux-thunk";

export const store = configureStore({
    reducer: {
        user: userSliceReducer,
        projects: projectSliceReducer,
        city: citiesSliceReducer
    },
    middleware: [thunk]
})

