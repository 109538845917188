import Home from '../pages/Home';
import Search from '../pages/Search';
import Profile from '../pages/Profile'
import CityPage from "../pages/CityPage";
import RegionPage from "../pages/RegionPage";
import MentorshipPage from "../pages/static/MentorshipPage";
import WomenEnterpreneursPage from '../pages/static/WomenEntrepreneurs';
import FarmerPage from '../pages/static/FarmerPage';
import DisabilitiesPage from '../pages/static/DisabilitiesPage';
import CitiesPage from "../pages/Cities";
import RegionMapPage from "../pages/RegionsMapPage";
import SearchResult from "../pages/SearchResult";
import ProjectDescription from "../pages/static/ProjectDescriptionPage";
import RraProjectDescription from "../pages/static/RraProjectDescriptionPage";
import UserPage from '../pages/UserPage';
import EventPage from "../pages/EventPage";
import CircularEconomy from "../pages/static/CircularEconomy";
import DachSupport from "../pages/static/DachSupport";
import AboutPage from "../pages/static/AboutPage";
import PageAndProgram from "../pages/static/PageAndProgram";
import InvestorSupport from "../pages/static/InvestorSupport";
import Crowdfunding from "../pages/static/Crowdfunding";
import BrokersAndRepresentatives from "../pages/static/BrokersAndRepresentatives";
import SuccessfulModelDdl from "../pages/SuccessfulModelDdl";
import ProfileEditPage from "../pages/ProfileEditPage";
import AdminAprovePage from "../pages/admin/AdminApprove";
import GrantsRs from "../pages/GrantsRs";
import RegionalDachNetwork from "../pages/static/RegionalDachNetwork";
import BusinessAdviceForDiaspora from "../pages/static/BusinessAdviceForDiaspora";
import RRACordination from "../pages/static/RRACordination";
import ForumsInformation from "../pages/static/ForumsInformation";
import ConferenceVienna from "../pages/static/ConferenceVienna"
import UsersDefaultPage from "../pages/UsersFindPage";
import usersAprovePage from "../pages/admin/UsersApprove";
import AdminEventApprove from "../pages/admin/AdminEventApprove";
import RRAPage from "../pages/RRAPage";
import ProjectPage from "../pages/ProjectPage";
import MainPage from "../pages/MainPage";
import FAQPage from "../pages/FAQPage";
import AllSearchResults from "../pages/AllSearchResults";
import UserProfile from "../pages/UserProfile";
import ReviewPage from "../pages/ReviewPage";
import SuccessfulInvestmentsPage from "../pages/SuccessfulInvestmentsPage";
import DiasoraDaysMicroRegions from "../pages/static/DiasoraDaysMicroRegions";

export const LOGIN_ROUTE = "/login"
export const SEARCH_ROUTE = "/search"
export const PROFILE_ROUTE = "/profile"

export const RRA_CORDINATION = "/rra-cordination"
export const FORUMS_INFORMATION = "/forums-information"
export const CONFERENCE_VIENNA = "/conference-vienna"
export const DIASPORA_DAYS_MICRO_REGIONS = "/diaspora-days-micro-regions"

export const SEARCH_RESULTS = "/search-results"
export const ALL_SEARCH_RESULTS = "/all-search-results"

export const CITY_PAGE_ROUTE = "/city-page"

export const MENTORSHIP_ROUTE = "/mentorship"
export const PAGE_AND_PROGRAM = "/page-and-program"
export const INVESTOR_SUPPROT = "/investor-support"
export const CIRCULAR_ECONOMY = "/circular-economy"
export const DACH_SUPPORT = "/dach-supprt"
export const ABOUT_PAGE = "/about-page"
export const WOMEN_ENTERPRENEURS_ROUTE = "/women-enterpreneurs"
export const PROJECT_WIZARD_PAGE = "/project-wizard"
export const RRA_PROJECT_WIZARD_PAGE = "/rra-project-wizard"
export const FARMERS_ROUTE = "/farmers"
export const DISABILITIES_ROUTE = "/disabilities"
export const REGION_PAGE_ROUTE = "/region-page"
export const CROWDFUNDING = "/crowdfunding"
export const BROKERS_AND_REPRESENTATIVES = "/brokers-and-representatives"
export const ALL_SUCCESSFUL_INVESTMENTS = "/all-successful-investments"
export const SUCCESSFUL_MODEL_DDL = "/successful_model_ddl"
export const GRANTS_RS = "/grants-rs"
export const PROFILE_EDIT_PAGE = "/profile-edit"
export const REGIONAL_DACH_NETWORK = "/regional-dach-network"
export const BUSINESS_ADVICE_FOR_DIASPORA = "/business-advice-diaspora"

export const CITIES_PAGE = "/cities"
export const REGIONS_PAGE = "/regions"

export const USER_PAGE = "/user-page"
export const USER_PROFILE = "/user-profile"
export const RRA_PAGE = "/rra-page"
export const PROJECT_PAGE = "/project-page"
export const EVENT_PAGE = "/event-page"
export const ADMIN_APPROVE_PAGE = "/approve"
export const ADMIN_APPROVE_EVENT_PAGE = "/event-approve"
export const USER_APPROVE_PAGE = "/users-approve"
export const REVIEW_PAGE = "/review"

export const SEARCHED_USER_PAGE = "/all-users"
export const ZOOM_VALIDATE = "/zoomverify"

export const FAQS_PAGE = "/faq"

export const ROOT_PATH = "/"
const routes = () => [
    {
        path: REVIEW_PAGE,
        component: ReviewPage,
        isPrivate: true,
    },
    {
        path: USER_PAGE,
        component: UserPage,
        isPrivate: false,
    },
    {
        path: USER_PROFILE,
        component: UserProfile,
        isPrivate: false,
    },
    {
        path: ALL_SEARCH_RESULTS,
        component: AllSearchResults,
        isPrivate: false,
    },
    {
        path: FAQS_PAGE,
        component: FAQPage,
        isPrivate: false,
    },
    {
        path: RRA_PAGE,
        component: RRAPage,
        isPrivate: false,
    },
    {
        path: PROJECT_PAGE,
        component: ProjectPage,
        isPrivate: false,
    },
    {
        path: ADMIN_APPROVE_EVENT_PAGE,
        component: AdminEventApprove,
        isPrivate: true,
    },
    {
        path: SEARCHED_USER_PAGE,
        component: UsersDefaultPage,
        isPrivate: true,
    },
    {
        path: REGIONAL_DACH_NETWORK,
        component: RegionalDachNetwork,
        isPrivate: false,
    },
    {
        path: FORUMS_INFORMATION,
        component: ForumsInformation,
        isPrivate: false,
    },
    {
        path: CONFERENCE_VIENNA,
        component: ConferenceVienna,
        isPrivate: false,
    },
    {
        path: DIASPORA_DAYS_MICRO_REGIONS,
        component: DiasoraDaysMicroRegions,
        isPrivate: false,
    },
    {
        path: RRA_CORDINATION,
        component: RRACordination,
        isPrivate: false,
    },
    {
        path: BUSINESS_ADVICE_FOR_DIASPORA,
        component: BusinessAdviceForDiaspora,
        isPrivate: false,
    },
    {
        path: PROFILE_EDIT_PAGE,
        component: ProfileEditPage,
        isPrivate: true
    },
    {
        path: ADMIN_APPROVE_PAGE,
        component: AdminAprovePage,
        isPrivate: true
    },
    {
        path: USER_APPROVE_PAGE,
        component: usersAprovePage,
        isPrivate: true
    },
    {
        path: EVENT_PAGE,
        component: EventPage,
        isPrivate: false,
    },
    {
        path: SEARCH_ROUTE,
        component: Search,
        isPrivate: false,
    },
    {
        path: SEARCH_RESULTS,
        component: SearchResult,
        isPrivate: true,
    },
    {
        path: CITIES_PAGE,
        component: CitiesPage,
        isPrivate: false,
    },
    {
        path: REGIONS_PAGE,
        component: RegionMapPage,
        isPrivate: false,
    },
    {
        path: PROJECT_WIZARD_PAGE,
        component: ProjectDescription,
        isPrivate: false,
    },
    {
        path: RRA_PROJECT_WIZARD_PAGE,
        component: RraProjectDescription,
        isPrivate: false,
    },
    {
        path: CITY_PAGE_ROUTE,
        component: CityPage,
        isPrivate: false,
    },
    {
        path: REGION_PAGE_ROUTE,
        component: RegionPage,
        isPrivate: false,
    },
    {

        path: PROFILE_ROUTE,
        component: Profile,
        isPrivate: true,
    },
    {
        path: MENTORSHIP_ROUTE,
        component: MentorshipPage,
        isPrivate: false,
    },
    {
        path: CIRCULAR_ECONOMY,
        component: CircularEconomy,
        isPrivate: false,
    },
    {
        path: DACH_SUPPORT,
        component: DachSupport,
        isPrivate: false,
    },
    {
        path: ABOUT_PAGE,
        component: AboutPage,
        isPrivate: false,
    },
    {
        path: WOMEN_ENTERPRENEURS_ROUTE,
        component: WomenEnterpreneursPage,
        isPrivate: false,
    },
    {
        path: FARMERS_ROUTE,
        component: FarmerPage,
        isPrivate: false,
    },
    {
        path: DISABILITIES_ROUTE,
        component: DisabilitiesPage,
        isPrivate: false,
    },
    {
        path: PAGE_AND_PROGRAM,
        component: PageAndProgram,
        isPrivate: false,
    },
    {
        path: INVESTOR_SUPPROT,
        component: InvestorSupport,
        isPrivate: false,
    },
    {
        path: CROWDFUNDING,
        component: Crowdfunding,
        isPrivate: false,
    },
    {
        path: BROKERS_AND_REPRESENTATIVES,
        component: BrokersAndRepresentatives,
        isPrivate: false,
    },
    {
        path: ALL_SUCCESSFUL_INVESTMENTS,
        component: SuccessfulInvestmentsPage,
        isPrivate: false,
    },
    {
        path: SUCCESSFUL_MODEL_DDL,
        component: SuccessfulModelDdl,
        isPrivate: false,
    },
    {
        path: GRANTS_RS,
        component: GrantsRs,
        isPrivate: false,
    },

    {
        path: ZOOM_VALIDATE,
        component: () => window.open("/verifyzoom.html", "_self"),
        isPrivate: false,
    },
    {
        path: LOGIN_ROUTE,
        component: Home,
        isPrivate: false,
    },
    {
        exact: true,
        path: ROOT_PATH,
        component: MainPage,
        isPrivate: true,
    },
];

export default routes;
