import React, {useState} from "react";
import {NormalText} from "../Text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {CheckBoxS} from "../Checkbox";
import {TextArea} from "../TextArea";
import {Inp} from "../Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {getAllCompanySizes, getAllCompanyTypes} from "../../services/api";
import {Row} from "./stepThree";
import {useTranslation} from "react-i18next";

export default function OtherRegistration({businessSectors, user, changeUserParam, countries}) {
    const {t} = useTranslation();
    const [companyTypes, setCompanyTypes] = useState([]);
    const [companySizes, setCompanySizes] = useState([]);
    React.useEffect(() => {
        getAllCompanyTypes().then(response => {
            setCompanyTypes(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllCompanySizes().then(response => {
            setCompanySizes(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, []);


    return (
        <>
            <NormalText>{t('registration.entrepreneur.question')}</NormalText>
            <Inp type="text" value={user?.company} onChange={(e) => {
                changeUserParam("company", e.target.value)
            }}/>
            <NormalText>{t('registration.entrepreneur.question1')}</NormalText>
            <Inp type="text" value={user?.identificationNumber} onChange={(e) => {
                changeUserParam("identificationNumber", e.target.value)
            }}/>
            <NormalText>{t('registration.entrepreneur.question2')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    native
                    value={user.companyTypeId}
                    onChange={(e) => {
                        changeUserParam("companyTypeId", e.target.value)
                    }}>
                    <option aria-label="None" value=""/>
                    {companyTypes?.map((value, index) => (
                        <option key={index} value={value?.id}>{value?.name}</option>
                    ))}
                </Select>
            </FormControl>
            <NormalText>{t('registration.entrepreneur.question3')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    native
                    value={user?.companySizeId}
                    onChange={(e) => {
                        changeUserParam("companySizeId", e.target.value)
                    }}
                >
                    <option aria-label="None" value=""/>
                    {companySizes?.map((value, index) => (
                        <option key={index} value={value?.id}>{value?.name}</option>
                    ))}
                </Select>
            </FormControl>
            <NormalText>{t('bussinesSector')}</NormalText>
            <Autocomplete
                options={businessSectors}
                value={user?.businessSectorId}
                defaultValue={user?.businessSectorId}
                onChange={(event, newValue) => {
                    changeUserParam("businessSectorId", newValue)
                }}
                getOptionLabel={(option) => option?.name}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
            />
            <NormalText>{t('interestedToConnect')}</NormalText>
            <Row>
                <CheckBoxS value={user?.mentor} type="checkbox" onChange={() => {
                    changeUserParam("mentor", !user?.mentor)
                }}/>
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS value={user?.broker} type="checkbox" onChange={() => {
                    changeUserParam("broker", !user?.broker)
                }}/>
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS value={user?.investor} type="checkbox" onChange={() => {
                    changeUserParam("investor", !user?.investor)
                }}/>
                <NormalText>{t('withInvestor')}</NormalText>
            </Row>

            <NormalText style={{marginTop: '1.5rem'}}>{t('poslovniPlanovi')}</NormalText>
            <TextArea value={user?.plans} type="text" onChange={(e) => {
                changeUserParam("plans", e.target.value)
            }}/>
            <NormalText>{t('registration.entrepreneur.question4')}</NormalText>
            <Autocomplete
                multiple
                value={user?.countryIds}
                defaultValue={user?.countryIds}
                options={countries}
                onChange={(event, newValue) => {
                    changeUserParam("countryIds", newValue)
                }}
                getOptionLabel={(option) => option?.name}
                style={{width: 300}}
                renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
            />
            <NormalText>{t('registration.entrepreneur.question5')}</NormalText>
            <TextArea value={user?.export_chances} onChange={(e) => {
                changeUserParam("export_chances", e.target.value)
            }} type="text"/>
            {/*<CheckBoxS value={user?.foreignInvestment} type="checkbox" onChange={(e) => {*/}
            {/*    changeUserParam("foreignInvestment", !user?.foreignInvestment)*/}
            {/*}}/>*/}
        </>
    );
}
//countryIds ili countryId
