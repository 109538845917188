import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllBusinessActivities, getAllDachgroups, getAllMentorTypes, getBrokerType} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Dach({userInfo, setUserInfo, countries}) {
    const {t} = useTranslation();
    const [dachgroups, setDachgroups] = useState([])

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    useEffect(() => {
        getAllDachgroups().then(response => {
            setDachgroups(response?.data)
        }).catch(reason => {
            console.error(reason, "getAllDachgroups")
        });
    }, []);

    return (
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.dach.question1')}</NormalText>
                <TextField
                    fullWidth
                    id="associationName"
                    multiline
                    maxRows={4}
                    value={userInfo?.associationName || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.dach.question2')}</NormalText>
                <FormControl fullWidth variant="outlined">
                    {/*<InputLabel>{t('registration.entrepreneur.question2')}</InputLabel>*/}
                    <Select
                        native
                        id="dachGroupId"
                        value={userInfo?.dachGroupId || ''}
                        onChange={handleChange}>
                        {dachgroups?.map((value, index) => (
                            <option key={index} value={value?.id}>{value?.name}</option>
                        ))}
                    </Select>
                </FormControl>
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.dach.question3')}</NormalText>
                <TextField
                    fullWidth
                    id="success"
                    multiline
                    maxRows={4}
                    value={userInfo?.success || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

