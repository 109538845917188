import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useSnackbar} from "notistack";
import {getAllCitiesAsync, getAllDistrictsAsync, getPagePlaceBigRegionName, getPagePlaceCity} from "../../services/api";
import {CITY_PAGE_ROUTE, REGION_PAGE_ROUTE} from "../../config/routes";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import {CenteredCol, CenteredRow} from "../Flex";
import RadioButton from "../RadioButton";
import {useTranslation} from "react-i18next";
import {Spinner, SpinnerWrapper} from "../Spinner";
import {ButtonL} from "../Button";
import {getCityNameForLanguage} from "../../helper/language";

const ModalBody = styled(CenteredCol)`
    padding: 20px;
`;

const selectOption = {
    city: 1,
    district: 2
}
export default function RegionCityModal({showModalRegion, setShowModalRegion}) {
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [isAutocompleteOpen, setAutocompleteOpen] = useState(false);
    const [autocompleteValue, setAutocompleteValue] = useState(null);
    const [selectedRadioOption, setSelectedRadioOption] = useState(selectOption.city);
    const [isSubmitting, setSubmitting] = useState(false);
    const isAutocompleteLoading = isAutocompleteOpen && autocompleteOptions.length === 0;

    const handleSubmit = () => {
        setSubmitting(true);
        if (selectedRadioOption === selectOption.city) {
            getPagePlaceCity(autocompleteValue?.name)
                .then(response => {
                    setSubmitting(false);
                    history.push({
                        state: {detail: response.data},
                        pathname: CITY_PAGE_ROUTE + "?name=" + response?.data?.city?.name
                    })
                    window.location.reload()
                }).catch(_ => {
                setSubmitting(false);
                enqueueSnackbar(t('cityPageNotFoundInfo'), {variant: "info"})
            });
        } else if (selectedRadioOption === selectOption.district) {
            getPagePlaceBigRegionName(autocompleteValue?.name)
                .then(response => {
                    setSubmitting(false);
                    history.push({
                        pathname: REGION_PAGE_ROUTE,
                        search: "?name=" + autocompleteValue?.name,
                        state: {detail: response.data}
                    })
                })
                .catch(_ => {
                    setSubmitting(false);
                    enqueueSnackbar(t('districtPageNotFoundInfo'), {variant: "info"})
                });
        }
    };

    // getPagePlaceBigRegionName

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedRadioOption(parseInt(value));
    };

    useEffect(() => {
        if (!isAutocompleteLoading) {
            return undefined;
        }

        if (selectedRadioOption === selectOption.district) {
            getAllDistrictsAsync().then(response => {
                setAutocompleteOptions(response?.data)
            }).catch(reason => {
                enqueueSnackbar((t("districtGetError")), {
                    variant: 'error'
                })
            });
        } else {
            getAllCitiesAsync().then(response => {
                setAutocompleteOptions(response?.data)
            }).catch(reason => {
                enqueueSnackbar((t("citiesGetError")), {
                    variant: 'error'
                })
            });
        }

    }, [isAutocompleteLoading, selectedRadioOption])

    useEffect(() => {
        if (!isAutocompleteOpen) {
            setAutocompleteOptions([]);
        }
    }, [isAutocompleteOpen]);

    return (
        <Dialog
            open={showModalRegion}
            onClose={() => setShowModalRegion(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <ModalBody>
                <CenteredRow>
                    <RadioButton
                        id="districtRadio"
                        value={selectOption.district}
                        checked={selectedRadioOption === selectOption.district}
                        onChange={(event) => handleSelectChange(event)}
                        label={t('region')}
                    />
                    <RadioButton
                        id="cityRadio"
                        value={selectOption.city}
                        checked={selectedRadioOption === selectOption.city}
                        onChange={(event) => handleSelectChange(event)}
                        label={t('city')}
                    />
                </CenteredRow>
                <Autocomplete
                    open={isAutocompleteOpen}
                    onOpen={() => {
                        setAutocompleteOpen(true);
                    }}
                    onClose={() => {
                        setAutocompleteOpen(false);
                    }}
                    options={autocompleteOptions}
                    value={autocompleteValue}
                    defaultValue={autocompleteValue}
                    loading={isAutocompleteLoading}
                    onChange={(event, newValue) => setAutocompleteValue(newValue)}
                    getOptionLabel={(option) => getCityNameForLanguage(option,i18n.language)}
                    style={{minWidth: '200px'}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={selectedRadioOption === selectOption.city ? t('chooseCity') : t('chooseRegion')}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isAutocompleteLoading ?
                                            <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
                {isSubmitting ?
                    <SpinnerWrapper><Spinner/></SpinnerWrapper> :
                    <ButtonL onClick={handleSubmit}>{t('find')}</ButtonL>}
            </ModalBody>
        </Dialog>
    )
}
