import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createRRAProject, getMyProjectsWithParams, getMyRRaProjects} from "../../services/api";

export const fetchRraProjects = createAsyncThunk("fetchRraProjects", async () => {
    return await getMyRRaProjects();
})

export const createRra = createAsyncThunk("createRraProject", async (data) => {
    return await createRRAProject(data)
})

export const fetchProjects = createAsyncThunk("fetchProjects", async (filter) => {
    return await getMyProjectsWithParams(filter)
})

const initialState = {projectList: [], projectRRAList: [], loadingProject: false, loadingRRA: false, error: {}, step2Project: null, step3Project: null, step4Project:null, step5Project: null, step6Project:null}

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        stepTwo: (state, {payload}) => {
            state.step2Project = payload

        },
        stepThree: (state, {payload}) => {
            state.step3Project = payload
        },
        stepFour: (state, {payload}) => {
            state.step4Project = payload
        },
        stepFive: (state, {payload}) => {
            state.step5Project = payload
        },
        stepSix: (state, {payload}) => {
            state.step6Project = payload
        },
    },
    extraReducers: {
        [fetchProjects.pending]: (state) => {

            state.loadingProject = true;
            state.projectList = []
        },
        [fetchProjects.fulfilled]: (state, {payload}) => {
            state.projectList = payload.data.content;
            state.loadingProject = false;
        },
        [fetchProjects.rejected]: (state, {payload}) => {
            state.error = payload;
            state.projectList = []
            state.loadingProject = false;
        },
        [fetchRraProjects.pending]: (state) => {

            state.projectRRAList = []
            state.loadingRRA = true;
        },
        [fetchRraProjects.fulfilled]: (state, {payload}) => {
            state.projectRRAList = payload.data.content;
            state.loadingRRA = false;
        },
        [fetchRraProjects.rejected]: (state, {payload}) => {
            state.error = payload;
            state.loadingRRA = false;
        },
        [createRra.pending]: state => {
            state.loading = true;
        },
        [createRra.fulfilled]: (state, {payload}) => {
            state.loading = false;
        },
        [createRra.rejected]: (state, {payload}) => {
            state.loading = false;
        }
    },
})

export const { stepTwo, stepThree, stepFour, stepFive, stepSix } = projectSlice.actions
export const projectSliceReducer = projectSlice.reducer
export const userSelector = (state) => state.projects

