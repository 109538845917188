import {CenteredRow, FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllPksGroups, getAllVulnerableGroupTypes} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {CheckBoxS} from "../../components/Checkbox";

export default function Special({userInfo, setUserInfo}) {
    const {t} = useTranslation();
    const [vulnerableGroups, setVulnerableGroups] = useState()

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChangeVulnerableGroupType = (event) => {
        const {value} = event.target
        setUserInfo(prevState => ({
            ...prevState,
            vulnerableGroupType: {...prevState.vulnerableGroupType, id: value}
        }))
    }

    const handleChangeCheckBox = (e) => {
        const {id, checked} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: checked
        }))
    }

    useEffect(() => {
        getAllVulnerableGroupTypes().then(response => {
            setVulnerableGroups(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, []);


    return (
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.vulnerableGroups.question1')}</NormalText>
                <TextField
                    associationName
                    id="associationName"
                    multiline
                    maxRows={4}
                    value={userInfo?.associationName || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.pks.question2')}</NormalText>
                <FormControl fullWidth variant="outlined">
                    <Select
                        native
                        id="id"
                        value={userInfo?.vulnerableGroupType.id || ''}
                        onChange={(event, newValue) => {
                            handleChangeVulnerableGroupType(event, newValue)
                        }}>
                        {vulnerableGroups?.map((value, index) => (
                            <option key={index} value={value?.id}>{value?.name}</option>
                        ))}
                    </Select>
                </FormControl>
            </FlexCol>

            <NormalText style={{textAlign: 'center'}}>{t('interestedToConnect')}</NormalText>
            <CenteredRow style={{gap: 10}}>
                <CheckBoxS id="mentor" defaultChecked={userInfo?.mentor} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS id="broker" defaultChecked={userInfo?.broker} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS id="investor" defaultChecked={userInfo?.investor} type="checkbox"
                           onChange={handleChangeCheckBox}/>
                <NormalText>{t('withInvestor')}</NormalText>
            </CenteredRow>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('poslovniPlanovi')}</NormalText>
                <TextField
                    associationName
                    id="description"
                    multiline
                    maxRows={4}
                    value={userInfo?.description || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

        </FlexCol>
    )
}

