import React, {useEffect, useState} from "react";
import {NormalText} from "../Text";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {TextArea} from "./stepThree";
import {useTranslation} from "react-i18next";
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import styled from "styled-components";
import {getBrokerType} from "../../services/api";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    `
export default function BrokerRegistration({user, changeUserParam, businessActivities}) {
    const { t } = useTranslation();
    const [brokerType, setBrokerType] = useState([]);

    useEffect(() => {
        getBrokerType().then(response => {
            setBrokerType(response.data)
        }).catch(error => {
            console.error(error, "BrokerTypeError")
        })
    }, []);
    return (
        <>
            <NormalText>{t('registration.broker.question1')}</NormalText>
            <Row>
                <Autocomplete
                    multiple
                    value={user?.businessActivityList}
                    defaultValue={user?.businessActivityList}
                    options={businessActivities}
                    onChange={(event, newValue) => {
                        changeUserParam("businessActivityList", newValue)
                    }}
                    getOptionLabel={(option) => option?.name}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                />
                <Tooltip title={<h2>{t('clasificationToolTipText')}</h2>} aria-label="add" style={{ height: "100%" }}>
                    <HelpIcon />
                </Tooltip>
            </Row>

            <NormalText>{t('registration.broker.question2')}</NormalText>
            <FormControl variant="outlined">
                <Select
                    native
                    id="brokerTypeId"
                    value={user?.brokerTypeId || ''}
                    onChange={(e) => {changeUserParam("brokerTypeId", e?.target?.value)}}>
                    {brokerType?.map((value, index) => (
                        <option key={index} value={parseInt(value?.id)}>{value?.name}</option>
                    ))}
                </Select>
            </FormControl>
            <NormalText>{t('registration.broker.question3')}</NormalText>
            <TextArea value={user?.description} onChange={(e) => {
                changeUserParam("description", e?.target?.value)
            }} type="text" />
            <NormalText>{t('registration.broker.question4')}</NormalText>
            <TextArea value={user?.description1} onChange={(e) => {
                changeUserParam("description1", e?.target?.value)
            }} type="text" />
            <NormalText>{t('registration.broker.question5')}</NormalText>
            <TextArea value={user?.foreignFirms} onChange={(e) => {
                changeUserParam("foreignFirms", e?.target?.value)
            }} type="text" />
        </>
    );
}
