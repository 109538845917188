import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllBusinessActivities, getAllDachgroups, getAllMentorTypes, getBrokerType} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Dach({userInfo, t, lng}) {

    return (
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="associationName"
                    label={t('registration.dach.question1')}
                    multiline
                    maxRows={4}
                    value={userInfo?.associationName || ''}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="dachGroupId"
                    label={t('registration.dach.question2')}
                    multiline
                    maxRows={4}
                    value={userInfo?.dachGroupId || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="success"
                    label={t('registration.dach.question3')}
                    multiline
                    maxRows={4}
                    value={userInfo?.success || ''}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

