import React, {useEffect} from 'react'
import styled from "styled-components"
import {Image, WrapperCenter} from "./index.js"
import Logo from '../../assets/images/logo.png'
import {useTranslation} from "react-i18next";
import {ButtonL, ButtonR} from "../Button";
import {ABOUT_PAGE, FAQS_PAGE, PROFILE_EDIT_PAGE, SEARCH_ROUTE} from "../../config/routes";
import {useHistory, useLocation} from "react-router-dom";
import ServicesMenu from "./Menu/Services";
import InformationsMenu from "./Menu/Informations";
import {getUserProfileImageAsync} from "../../services/api";
import {LanguageDropdown} from "../LanguageDropdown";
import {FlexCol} from "../Flex";

const Text = styled.p`
    font-weight: 500;
    color: #000;
    margin-right: 0;
    cursor: pointer;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    &:hover{
        background: gray;
    }
 
`

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #E5E7EB;
  transform: ${({open}) => open ? 'translateX(0)' : 'translateX(100%)'};
  height: 100vh;
  text-align: center;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
      width: 100%;
    }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0D0C1D;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`

const SearchButton = styled(ButtonR)`
    @media only screen and (max-width: 1050px) {
        font-size: 12px;
        width: 100px;
        height: 45px;
    }
`


const options = {
    services: 1,
    information: 2,
}

const Menu = ({
                  open,
                  user,
                  handleImageClick,
                  handleRegistration,
                  handleLogin,
                  handleLogout,
                  setOpen,
                  setShowModalSendMail
              }) => {

    const history = useHistory();
    const location = useLocation();

    const [anchorElService, setAnchorElService] = React.useState(null);
    const [anchorElInformation, setAnchorElInformation] = React.useState(null);
    const [showSearchButton, setShowSearchButton] = React.useState(true);
    const [userImage, setUserImage] = React.useState();
    const [loadingImage, setLoadingImage] = React.useState(false);

    async function loadUserImage(userId) {
        setLoadingImage(true);
        getUserProfileImageAsync(userId).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(_ => {
            setLoadingImage(false);

        });
    }

    useEffect(() => {
        setShowSearchButton(!(location.pathname === SEARCH_ROUTE))
        if (user) {
            loadUserImage(user?.id);
        }
    }, [location.pathname, user, user?.id]);

    const handleClick = (event, type) => {
        setAnchorElService(null);
        setAnchorElInformation(null);
        switch (parseInt(type)) {
            case options.services:
                setAnchorElService(event.currentTarget)
                break;
            case options.information:
                setAnchorElInformation(event.currentTarget)
                break;
            default:
                setAnchorElInformation(event.currentTarget)
                break;
        }
    };

    const handleClose = () => {
        setAnchorElService(null);
        setAnchorElInformation(null);
    };

    const onMenuClick = (path) => {
        setAnchorElService(null);
        setAnchorElInformation(null);
        setOpen(false);
        history.push(path);
    }

    const {t} = useTranslation();
    return (
        <StyledMenu open={open}>
            <WrapperCenter style={{flexDirection: "column"}}>
                <Image style={{marginRight: '0', width: "100px", height: "100px"}} src={Logo} alt="Logo"
                       onClick={(e) => {
                           setAnchorElService(null);
                           setAnchorElInformation(null);
                           setOpen(false);
                           handleImageClick(e)
                       }
                       }/>
                <Text onClick={() => onMenuClick(ABOUT_PAGE)}>{t('about')}</Text>
                <Text onClick={(event) => handleClick(event, options.services)}>{t('services')}</Text>
                <ServicesMenu handleClose={handleClose} anchorEl={anchorElService} onMenuClick={onMenuClick}
                              isMobile={true}/>
                <Text onClick={(event) => handleClick(event, options.information)}>{t('information')}</Text>
                <InformationsMenu handleClose={handleClose} anchorEl={anchorElInformation} onMenuClick={onMenuClick}
                                  isMobile={true}/>
                <Text onClick={() => onMenuClick(FAQS_PAGE)}>{t('faq')}</Text>
                {user && <Text onClick={() => onMenuClick(PROFILE_EDIT_PAGE)}>{t('editProfile')}</Text>}
                <LanguageDropdown isMobile={true}/>
                {showSearchButton &&
                <SearchButton bg f
                              onClick={() => onMenuClick(SEARCH_ROUTE)}>
                    {t('searchEnt')}
                </SearchButton>}
                {user ? (
                    <FlexCol style={{display: 'flex', alignItems: 'center'}}>

                        {/*{loadingImage ? <CircularProgress /> :*/}
                        {/*  <ProfileDropdown image={userImage} isMobile={true} setOpen={setOpen} />}*/}
                        {/*// <ProfileImage onClick={handleProfileClick} src={userImage || UserImage}/>}*/}
                        {/* <img src={ProfileIcon} alt="profile" style={{width: '25px', height: '25px', cursor: "pointer"}}
               onClick={handleProfileClick}/> */}
                        <ButtonL width="auto" style={{fontSize: '13px', minWidth: '90px'}} m f
                                 onClick={() => handleLogout()}>{t('logout')}</ButtonL>
                    </FlexCol>

                ) : <div style={{display: 'flex', alignItems: 'center'}}>
                    <ButtonL width="auto" style={{fontSize: '13px', minWidth: '90px'}} m f
                             onClick={handleLogin}>{t('login')}</ButtonL>
                    <ButtonR width="auto" style={{fontSize: '13px', minWidth: '90px'}} bg f
                             onClick={handleRegistration}>{t('register')}</ButtonR>
                </div>}
            </WrapperCenter>

        </StyledMenu>
    )
}

const StyledBurger = styled.button`
  position: fixed;
  top: 2%;
  z-index: 102;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 3rem;
  height: 3rem;
  border: none;
  cursor: pointer;
  padding: ${({open}) => open ? "0.5rem 0.2rem 0.5rem 0.8rem" : "0.5rem 0.5rem 0.5rem 0.5rem"};
  background: rgba(255, 255, 255, 0.32);
  border-radius: 0.5rem;
  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({open}) => open ? '#0D0C1D' : '#EFFFFA'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
       background: ${({open}) => open ? '#FFF' : '#243B85'};
        transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
        background: ${({open}) => open ? '#FFF' : '#243B85'};
      opacity: ${({open}) => open ? '0' : '1'};
      transform: ${({open}) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
    background: ${({open}) => open ? '#FFF' : '#243B85'};
      transform: ${({open}) => open ? 'rotate(-45deg);' : 'rotate(0)'};
    }
  }
`

const Burger = ({open, setOpen}) => {
    return (
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div/>
            <div/>
            <div/>
        </StyledBurger>
    )
}

export default function MobileMenu({
                                       user,
                                       handleImageClick,
                                       handleRegistration,
                                       handleLogin,
                                       language,
                                       handleLogout,
                                       handleProfileClick,
                                       setShowModalSendMail
                                   }) {
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();
    return (
        <div ref={node}>
            <Burger open={open} setOpen={setOpen}/>
            <Menu
                setShowModalSendMail={setShowModalSendMail}
                user={user}
                Language={language}
                handleProfileClick={handleProfileClick}
                handleImageClick={handleImageClick}
                handleLogin={handleLogin}
                handleLogout={handleLogout}
                handleRegistration={handleRegistration} open={open} setOpen={setOpen}/>
        </div>
    );
}
