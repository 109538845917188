import React, {useState} from "react";
import {CenteredCol} from "../../../../../components/Flex";
import {Hr, KeyWordWrapper, StyledCol, Title} from "../../../style";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import {searchRRAProjectsAsync} from "../../../../../services/api";
import {getIndexOfRRAProjectLanguage} from "../../../../../utils/language";
import {SEARCH_TYPES} from "../../../index";

export default function RRAProjectSection({onMenuClick, inputString, sectorIds}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [rraProjects, setRRAProjects] = useState([]);
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });
    const [sort, setSort] = useState({
        rowPerPage: 99999999999,
        sortBy: "createdAt,ASC",
    });

    React.useEffect(() => {
        let data = {
            type:null,
            businesSectorIds:null
        }
        data.name = inputString;
        if (sectorIds.length > 0) {
            data.businesSectorIds = sectorIds
        }
        let parsedFilter = `?page=${page.currentPage}&sort=${sort.sortBy}&size=${sort.rowPerPage}`
        searchRRAProjectsAsync(data,parsedFilter).then(response => {
            setRRAProjects(response?.data?.content);
            setLoading(false);
        }).catch(_ => {
            setLoading(false);
        });
    }, [inputString, sectorIds, page.currentPage, sort.rowPerPage, sort.sortBy])

    if (loading) {
        return (
            <CenteredCol>
                <CircularProgress/>
            </CenteredCol>
        );
    }
    if (rraProjects?.length === 0)
        return <></>;
    return (
        <StyledCol>
            <Title>{t('searchAutocomplete.rraProjects')}</Title>
            <Hr/>
            {rraProjects?.map((value, index) => {
                return (<KeyWordWrapper
                    onClick={() => onMenuClick(SEARCH_TYPES.rra, value?.id, value)}
                    key={index}>{value?.rraProjectLanguages[getIndexOfRRAProjectLanguage(value, 1)]?.name}</KeyWordWrapper>)
            })}
        </StyledCol>
    );
}
