import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {animated, useSpring} from "react-spring";
import RegPhoto from "../../assets/images/sazvezdja-01.png";
import {BigText, NormalText} from "../Text";
import {ButtonL} from "../Button";
import styled from "styled-components";
import Bg from "../../assets/images/sazvezdjebg.png";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getAllBusinessSector, getAllRegions, searchSuccessfulInvestmentAsync} from "../../services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {ALL_SUCCESSFUL_INVESTMENTS, REGION_PAGE_ROUTE} from "../../config/routes";
import {useHistory} from "react-router-dom";
import {FlexRow} from "../../style/Flex";

export default function SuccessfulModal({showSuccessfulModal, setShowSuccessfulModal}) {
    const history = useHistory();
    const {t} = useTranslation();
    const [windowSize, setWindowSize] = useState(null);
    const [regionsList, setRegionsList] = useState([]);
    const [businessSectors, setBusinessSectors] = useState([]);
    const [disable, setDisabled] = useState(true)
    const [searchValue, setSearchValue] = useState(
        {
            businessSectorId: [
                null
            ],
            description: null,
            endAmount: null,
            name: null,
            regionId: [
                null
            ],
            startAmount: null
        })
    const [selectedCriteria, setSelectedCriteria] = useState();

    useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    useEffect(() => {
        getAllRegions().then(response => {
            setRegionsList(response.data)
        }).catch(e => {
        })
        getAllBusinessSector().then(res => {
            setBusinessSectors(res?.data);
        }).catch(err =>
            console.error(err))
    }, []);
    const handleSelectCriteria = (e) => {
        setDisabled(true)
        setSelectedCriteria(e.target.value)
    }
    const handleSelectAmount = (e) => {
        setDisabled(false)
        let str = e.target.value
        let before, after
        if(str.includes('-')){
            before = Number(str.split('-')[0])*1000;
            after =  Number(str.split('-')[1])*1000;
        } else if(str.includes('+')) {
            before = 1000000;
        } else before = Number(e.target.value)*1000;

        setSearchValue(
            {
                businessSectorId: [
                    null
                ],
                description: null,
                endAmount: after || 0,
                name: null,
                regionId: [
                    null
                ],
                startAmount: before || 0
            })
    }
    const handleSelectRegion = (e) => {
        setDisabled(false)
        setSearchValue(
            {
                businessSectorId: [
                    null
                ],
                description: null,
                endAmount: null,
                name: null,
                regionId: [
                    e.target.value
                ],
                startAmount: null
            })
    }
    const handleChangeBussinesSector = (e, v) => {
        setDisabled(false)
        if (v !== null) {
            setSearchValue(
                {
                    businessSectorId: [
                        v.id
                    ],
                    description: null,
                    endAmount: null,
                    name: null,
                    regionId: [
                        null
                    ],
                    startAmount: null
                })
        }
    }
    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showSuccessfulModal ? 1 : 0,
        width: windowSize?.width > 800 ? '60%' : '90%',
        transform: showSuccessfulModal ? `translateY(0%)` : `translateY(-100%)`
    });
    // console.log(localStorage.getItem('language') || 'lat')
    let view;
    switch (selectedCriteria) {
        case t('region'):
            view = <FormControl variant="outlined">
                <Select
                    value={searchValue.region}
                    onChange={handleSelectRegion}>
                    {regionsList?.map((option, key) => {
                        return (<MenuItem key={key} value={option.id}>{option.name}</MenuItem>)
                    })}
                </Select>
            </FormControl>
            break;
        case t('industry'):
            view = <Autocomplete
                fullWidth
                options={businessSectors}
                value={searchValue.businessSectors}
                onChange={handleChangeBussinesSector}
                getOptionLabel={(option) => option?.name || ''}
                renderInput={(params) => <TextField {...params} label="" variant="outlined"/>}
            />
            break;
        case t('amountS'):
            view = <FormControl variant="outlined">
                <Select
                    value={searchValue.amount}
                    onChange={handleSelectAmount}>
                    {['100.000', '100.000 - 500.000', '500.000 - 1.000.000', '1.000.000+'].map((option, key) => {
                        return (<MenuItem key={key} value={option}>{option}</MenuItem>)
                    })}
                </Select>
            </FormControl>
            break;
        default:
            view = <></>;
    }
    const handleFind = () => {
        history.push({
            pathname: ALL_SUCCESSFUL_INVESTMENTS,
            state: searchValue
        })
    }
    return (
        <>
            <Background>
                <animated.div style={animation}>
                    <DivRopw>
                        {windowSize?.width > 800 &&
                        <ContainerWithBackground>
                            {<img src={RegPhoto} alt="logo" style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}/>}
                        </ContainerWithBackground>}
                        <ContainerItem primary>
                            <Close onClick={() => {
                                setShowSuccessfulModal(false)}}>✖</Close>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                paddingTop: '3rem',
                                paddingRight: '1rem',
                                paddingLeft: '1rem'
                            }}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <BigText>{t('successfulInvestment')}</BigText>
                                </div>
                                <NormalText>{t('selectCriteria')}</NormalText>
                                <FormControl variant="outlined">
                                    <Select
                                        value={selectedCriteria}
                                        onChange={handleSelectCriteria}>
                                        {[t('region'), t('industry'), t('amountS')]?.map((option, key) => {
                                            return (<MenuItem key={key} value={option}>{option}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                                {selectedCriteria && <NormalText>{t('selectModel')}</NormalText>}
                                {view}
                                <div style={{
                                    display: 'flex',
                                    flex: 1,
                                    marginTop: '1.5rem',
                                    justifyContent: "center"
                                }}>
                                    {!disable && <ButtonL style={{width: '300px'}} onClick={() => handleFind()}>{t('findSucces')}</ButtonL> }
                                </div>
                            </div>
                        </ContainerItem>
                    </DivRopw>
                </animated.div>
            </Background>
        </>
    )
}

const Background = styled.div`
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 104;
    width: 100%;
    height: 100%;
`

const ContainerItem = styled.div`
    position: relative;
    width: 100%;
    height:100%;
    background: ${({primary}) =>
    primary ? 'white' : 'grey'};
    overflow: auto;
`

const ContainerWithBackground = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
  
    background: ${({primary}) =>
    primary ? 'white' : 'grey'};
    overflow: auto;
    background: no-repeat center;
    background-image: url(${Bg});
    background-size: 100% 100%;
`

const Close = styled.button`
    width: 30px;
    font-size: 20px;
    color: '#000';
    position:absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;
`

export const DivRopw = styled(FlexRow)`
    max-height: 500px;
    height: 100vh;
`

