import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {login, meWithToken, registration} from "../../services/api";

export const loginUser = createAsyncThunk('login', async (payload,) => {
    const response = await login(payload);
    return response.data;
})

export const signUpUser = createAsyncThunk('signup', async (data) => {
    return await registration(data)
})

export const getToken = createAsyncThunk(
    'meWithToken',
    async (data) => {
        const response = await meWithToken(data);
        return response.data;
    }
)
const user = JSON.parse(localStorage.getItem("user"));
const token = localStorage.getItem("token")

const initialState = user
    ? {user, token, error: null, loading: false, isLoggedIn: true, step1: {}, step2: {}}
    : {user: null, token: null, error: null, loading: false, isLoggedIn: false, step1: {}, step2: {}}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logOut: (state) => {
            state.user = null
            state.token = null
            state.isLoggedIn = false;
            localStorage.removeItem("user")
            localStorage.removeItem("token")
            return state;
        },
        stepOne: (state, {payload}) => {
            state.step1 = payload
        },
        stepTwo: (state, {payload}) => {
            state.step2 = payload
        },
    },
    extraReducers: {
        [loginUser.fulfilled]: (state, action) => {
            localStorage.setItem("token", action.payload.token)
            state.isLoggedIn = true;
            state.loading = false;
            state.token = action.payload.token;
        },
        [loginUser.pending]: (state) => {
            state.loading = true;
        },
        [loginUser.rejected]: (state, {payload}) => {
            state.error = payload;
            state.user = null;
            state.loading = false;
        },
        [getToken.fulfilled]: (state, {payload}) => {
            localStorage.setItem("user", JSON.stringify(payload))
            state.loading = false;
            state.user = payload
            state.isLoggedIn = true;
        },
        [getToken.pending]: (state) => {
            state.loading = true;

        },
        [getToken.rejected]: (state, {payload}) => {
            state.error = payload;
            state.user = null;
            state.loading = false;
        },
        [signUpUser.pending]: (state) => {
            state.loading = true;

        },
        [signUpUser.fulfilled]: (state) => {
            state.loading = false;
            state.isLoggedIn = false
        },
        [signUpUser.rejected]: (state, {payload}) => {
            state.error = payload;
            state.loading = false;
            state.isLoggedIn = false;
        },
    }
});

export const {logOut, stepOne, stepTwo} = userSlice.actions;
export const userSliceReducer = userSlice.reducer
export const userSelector = (state) => state.user;
