import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllBusinessActivities, getAllBusinessSector, getAllInvestmentTypes} from "../../services/api";

export default function Investor({userInfo, setUserInfo}) {
    const {t} = useTranslation();

    const [businessSectors, setBusinessSectors] = useState([]);
    const [businessActivities, setBusinessActivities] = useState([]);
    const [investments, setInvestments] = useState([]);

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChangeActivities = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState, businessActivityList: newValue
        }))
    }

    const handleChangeInvestments = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState, investmentTypes: newValue
        }))
    }

    const handleChangeBusinessSector = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState, businessSectors: newValue
        }))
    }

    useEffect(() => {
        getAllBusinessSector().then(response => {
            setBusinessSectors(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllBusinessActivities().then(response => {
            setBusinessActivities(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getAllInvestmentTypes().then(response => {
            setInvestments(response.data)
        }).catch(error => console.log(error, "setInvestments"))
    }, []);

    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <TextField
                fullWidth
                id="investments"
                label={t('registration.investor.question1')}
                multiline
                maxRows={4}
                value={userInfo?.investments || ''}
                onChange={handleChange}
                variant="outlined"
            />
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.investor.question2')}</NormalText>
                <Autocomplete
                    multiple
                    value={userInfo?.businessActivityList || ''}
                    options={businessActivities}
                    onChange={(e, newValue) => {
                        handleChangeActivities(e, newValue)
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    style={{minWidth: 'auto'}}
                    renderInput={(params) => <TextField {...params} variant="outlined"/>}
                />
            </FlexCol>

            <FlexCol>
                <NormalText>{t('registration.investor.question4')}</NormalText>
                <Autocomplete
                    multiple
                    value={userInfo?.businessSectors || ''}
                    options={businessSectors}
                    onChange={(e, newValue) => {
                        handleChangeBusinessSector(e, newValue)
                    }}
                    getOptionLabel={option => option?.name || ''}
                    style={{minWidth: 'auto'}}
                    renderInput={(params) => <TextField {...params} variant="outlined"/>}
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.investor.question3')}</NormalText>
                <Autocomplete
                    multiple
                    value={userInfo?.investmentTypes}
                    options={investments}
                    onChange={(e, newValue) => {
                        handleChangeInvestments(e, newValue)
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    style={{minWidth: 'auto'}}
                    renderInput={(params) => <TextField {...params} variant="outlined"/>}
                />
            </FlexCol>

        </FlexCol>
    )
}

