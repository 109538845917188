import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllPksGroups} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Pks({userInfo, setUserInfo}) {
    const {t} = useTranslation();
    const [pksGroup, setPksGroups] = useState()

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChangepksGroup = (event, newValue) => {

        const {value} = event.target

        setUserInfo(prevState => ({
            ...prevState,
            pksGroup: {...prevState.pksGroup, id: value}
        }))
    }

    useEffect(() => {
        getAllPksGroups().then(response => {
            setPksGroups(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
    }, []);


    return (
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.pks.question1')}</NormalText>
                <TextField
                    fullWidth
                    id="associationName"
                    multiline
                    maxRows={4}
                    value={userInfo?.associationName || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.pks.question2')}</NormalText>
                <FormControl fullWidth variant="outlined">
                    <Select
                        native
                        id="id"
                        value={userInfo?.pksGroup.id || ''}
                        onChange={(event, newValue) => {

                            handleChangepksGroup(event, newValue)
                        }}>
                        {pksGroup?.map((value, index) => (
                            <option key={index} value={value?.id}>{value?.name}</option>
                        ))}
                    </Select>
                </FormControl>
            </FlexCol>

        </FlexCol>
    )
}

