import Image from '../../../assets/forums.jpg';
import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {CONFERENCE_VIENNA, DIASPORA_DAYS_MICRO_REGIONS} from "../../../config/routes";
import FeedBack from "../../../components/FeedBack";

const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
 height: calc(100vh - 250px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
`

export default function ForumsInformation() {
    const {t} = useTranslation();
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>

            <Banner>
                <h1 style={{
                    fontSize: '48px',
                    color: 'white',
                    padding: "15px",
                    backgroundColor: 'rgba(0, 0, 0, 0.55)',
                    borderRadius: '25px'
                }}>{t('forumsInformation.h1')}</h1>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%", whiteSpace: 'pre-wrap'}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('forumsInformation.p1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('forumsInformation.b1')}</b>{t('forumsInformation.p2')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "4%", marginTop: "4%",  fontSize: "20px"}}>
                        <a href={CONFERENCE_VIENNA}><b style={{color: '#243b85'}}>{t('forumsInformation.l1')}</b></a>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('forumsInformation.b2')} </b>{t('forumsInformation.p3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "4%", marginTop: "4%",  fontSize: "20px"}}>
                        <a href={DIASPORA_DAYS_MICRO_REGIONS}><b style={{color: '#243b85'}}>{t('forumsInformation.l2')}</b></a>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('forumsInformation.p4')}<b style={{color: '#243b85'}}>{t('forumsInformation.b3')}</b>{t('forumsInformation.p5')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('forumsInformation.b4')}</b>
                        {t('forumsInformation.p6')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b  onClick={() => setShowModalSendMail(true)} style={{color: '#243b85'}}>{t('forumsInformation.b5')}</b>
                        {t('forumsInformation.p7')}
                    </p>
                    <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>

                </Paper>
            </Container>


        </div>
    )
}
