import {CenteredRow, FlexCol} from "../../components/Flex";
import {NormalText} from "../../components/Text";
import {Autocomplete, TextField} from "@mui/material";
import {CheckBoxS} from "../../components/Checkbox";
import React from "react";

export default function Individual({userInfo, t, lng}) {
    return(
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <NormalText>{t('registration.investor.question4')}</NormalText>
                <TextField
                    id="businessSectorId"
                    label={t('bussinesSector')}
                    multiline
                    disabled={true}
                    maxRows={4}
                    value={userInfo?.businessSector || ''}
                    variant="outlined"
                />
            </FlexCol>

            <NormalText style={{textAlign: 'center'}}>{t('interestedToConnect')}</NormalText>
            <CenteredRow style={{gap: 10}}>
                <CheckBoxS disabled={true} id="mentor" defaultChecked={userInfo?.mentor} type="checkbox"
                           />
                <NormalText>{t('withMentor')}</NormalText>
                <CheckBoxS disabled={true} id="broker" defaultChecked={userInfo?.broker} type="checkbox"
                           />
                <NormalText>{t('withBroker')}</NormalText>
                <CheckBoxS disabled={true} id="investor" defaultChecked={userInfo?.investor} type="checkbox"
                          />
                <NormalText>{t('withInvestor')}</NormalText>
            </CenteredRow>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('poslovniPlanovi')}</NormalText>
                <TextField
                    fullWidth
                    id="plans"
                    disabled={true}
                    multiline
                    maxRows={4}
                    value={userInfo?.plans || ''}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}
