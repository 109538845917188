import React, {useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {LOGIN_ROUTE, ROOT_PATH} from "./routes";
import Sidebar from "../components/Sidebar";
import Veze from "../components/Veze";
import {FlexRow} from "../components/Flex";
import MainPage from "../pages/MainPage";
import {getMenuItem, setMenuItem} from "../utils/session";

const AppRoutes = ({component: Component, path, isPrivate, ...rest}) => {
    const {user} = rest
    const [selectedMenu, setSelectedMenu] = useState(getMenuItem);

    const handleMenuChange = (menuItem) => {
        setMenuItem(menuItem)
        setSelectedMenu(menuItem)
    }

    if (Boolean(user)) {
        if (path === LOGIN_ROUTE) {
            return <Redirect to={ROOT_PATH}/>
        }
    } else {
        if (isPrivate) {
            return <Redirect to={LOGIN_ROUTE}/>
        }
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                path !== ROOT_PATH ? (
                    <>
                        {Boolean(user) && <Sidebar handleMenuChange={handleMenuChange}
                                                   isPublicRoute={true}
                                                   selectedMenu={selectedMenu} isFromStatic={true}/>}
                        <Component {...rest} />
                    </>
                ) : (
                    <FlexRow>
                        <Sidebar
                            handleMenuChange={handleMenuChange}
                            selectedMenu={selectedMenu}/>
                        <MainPage
                            selectedMenu={selectedMenu}
                            {...props} />
                        <Veze/>
                    </FlexRow>
                )
            }
            {...rest}
        />
    );
};

export default AppRoutes;

