import Image from '../../../assets/pticsa.png';
import ImageLinkUp from '../../../assets/linkup.png';
import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import React from 'react'

const Banner = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%;
  height: calc(110vh - 180px);
  background-image: url(${Image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export default function AboutPage() {
    const {t} = useTranslation();
    const [windowSize, setWindowSize] = React.useState(null);

    React.useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('aboutPage.header')}</h1>
                {windowSize?.width >= 800 && <p style={{
                    position: 'absolute',
                    right: '20px',
                    left: '50%',
                    bottom: '20px',
                    padding: "15px",
                    backgroundColor: 'rgba(250, 249, 249, 0.55)',
                    borderRadius: '25px'
                }}>{t('aboutPage.imageDescription')}</p>}
            </Banner>
            {windowSize?.width < 800 && <p style={{
                right: '20px',
                bottom: '20px',
                padding: "15px",
                backgroundColor: 'rgba(250, 249, 249, 0.55)',
                borderRadius: '25px'
            }}>{t('aboutPage.imageDescription')}</p>}

            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%", whiteSpace: 'pre-wrap'}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b1')}</b>
                        {t('aboutPage.description1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b2')}</b>
                        {t('aboutPage.description1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b3')}</b>
                        {t('aboutPage.description3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b4')}</b>
                        {t('aboutPage.description4')}

                        <b style={{color: '#243b85'}}>{t('aboutPage.b5')}</b>
                        {t('aboutPage.description5')}

                        <b style={{color: '#243b85'}}>{t('aboutPage.b6')}</b>
                        {t('aboutPage.description6')}

                        <b style={{color: '#243b85'}}>{t('aboutPage.b7')}</b>
                        {t('aboutPage.description7')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b9')}</b>
                        {t('aboutPage.description9')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b10')}</b>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b11')}</b>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b12')}</b>
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('aboutPage.description10')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b13')}</b>
                        {t('aboutPage.description11')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b14')}</b>
                        {t('aboutPage.description12')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b15')}</b>
                        {t('aboutPage.description13')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('aboutPage.description14')}
                        <a href={"https://linkupserbia.icmpd.org/"}><b
                            style={{color: '#243b85'}}>{t('aboutPage.b15-1')}</b></a>
                        {t('aboutPage.description14-0')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.description14-1')}</b>
                        {t('aboutPage.description14-2')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.description14-3')}</b>
                        {t('aboutPage.description14-4')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.description14-5')}</b>
                        {t('aboutPage.description14-6')}

                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('aboutPage.description15')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b16')}</b>
                        {t('aboutPage.description16')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('aboutPage.description17')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b17')}</b>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('aboutPage.description18')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('aboutPage.b18')}</b>
                        {t('aboutPage.description19')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b19')}</b>
                        {t('aboutPage.description20')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b20')}</b>
                        {t('aboutPage.description21')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b21')}</b>
                        {t('aboutPage.description22')}
                        <b style={{color: '#243b85'}}>{t('aboutPage.b22')}</b>
                        {t('aboutPage.description23')}
                    </p>

                    <p style={{marginBottom: "1%", marginLeft: "45%", marginRight: "45%"}}>
                        <img src={ImageLinkUp} alt="LinkUp"/>
                    </p>

                </Paper>
            </Container>


        </div>
    )
}
