import React from "react";
import {BigText, NormalText, SmallText} from "../Text";
import {InputDiv, InputS} from "../Input";
import {CheckBoxS} from "../Checkbox";
import {Spinner, SpinnerWrapper} from "../Spinner";
import {ButtonL} from "../Button";
import {Row} from "./index";
import {useTranslation} from "react-i18next";

export default function LoginSection({setUsername, setPassword, setResetPw, loading, handleLogin}) {
    const { t } = useTranslation();

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <BigText>{t('welcome')}</BigText>
            </div>
            <NormalText marginBottom='0.5rem'>{t('email')}</NormalText>
            <InputDiv>
                <InputS id="username"
                        label="username"
                        name="username"
                        autoComplete="email"
                        onChange={(e) => setUsername(e.target.value)}/>

            </InputDiv>
            <NormalText marginTop='1.5rem' marginBottom="1.5rem">{t('password')}</NormalText>
            <InputDiv>
                <InputS name="password"
                        label="password"
                        type="password"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}/>
            </InputDiv>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '1.5rem'
            }}>
                <Row>
                    <CheckBoxS type="checkbox"/>
                    <SmallText style={{marginRight: '0.5rem'}}>{t('rememberMe')}</SmallText>
                    <button style={{cursor: 'pointer', background: 'none', border: 'none', color: 'blue'}}
                            onClick={() => setResetPw(true)}>{t('forgotPassword')}
                    </button>
                </Row>
            </div>
            {loading ?
                <SpinnerWrapper><Spinner/></SpinnerWrapper> :
                <ButtonL width="100%" onClick={() => handleLogin()}>{t('login')}</ButtonL>}
        </>
    );
}
