import {FlexCol} from "../../components/Flex";
import React from "react";
import {TextField} from "@mui/material";
import {NormalText} from "../../components/Text";

export default function RRA({userInfo, t, lng}) {
    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.rra.question')}</NormalText>
                <TextField
                    fullWidth
                    disabled={true}
                    id="rraName"
                    multiline
                    value={userInfo?.rraName || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.rra.question1')}</NormalText>
                <TextField
                    fullWidth
                    disabled={true}
                    id="supportMechanism"
                    multiline
                    maxRows={4}
                    value={userInfo?.supportMechanism || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.rra.question2')}</NormalText>
                <TextField
                    fullWidth
                    disabled={true}
                    id="description"
                    multiline
                    maxRows={4}
                    value={userInfo?.description || ''}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

