import React from 'react';

export const LanguageContext = React.createContext()

export const LanguageProvider = (props) => {

    const [lng, setLng] = React.useState('sr')

    return (
        <LanguageContext.Provider value={[lng, setLng]}>
            {props.children}
        </LanguageContext.Provider>
    )
}