import React, {useState} from "react";
import {isInProgressShowed} from "../../utils/session";
import styled from "styled-components";
import {CenteredRow, FlexCol, FlexRow} from "../Flex";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import BirdImage from "../../assets/images/ptica_no_background_small.png"

const ModalWrapper = styled(CenteredRow)`
    position: absolute;
    top: 0;
    bottom:0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.5);
`;

const Modal = styled(FlexCol)`
    background: #243b85;
    padding: 10px;
    border-radius: 10px;
`;

const ModalHeader = styled(FlexRow)`
    justify-content: flex-end;
`;

const ModalBody = styled(FlexCol)`
    padding: 1rem 2rem 2rem 2rem;
`;

const CloseButton = styled(CenteredRow)`
    cursor: pointer;
    padding: 5px;
    width: 30px;
    color: white;
    border-radius: 50%;
    &:hover{
        color: black;
        background: white;
    }
`;

const ProgressWrapper = styled(FlexRow)`
    background: rgba(0,0,0,0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 40px;
    width: 100%;
`;

const ProgressBar = styled(FlexRow)`
    animation: load 3s normal forwards;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #fff;
    height: 30px;
    width: 0;
    
    @keyframes load {
        0% { width: 0; }
        100% { width: 85%; }
    }
`;

const StyledText = styled(Typography)`
    padding: 10px 0 10px 0;
    color: white;
    text-align: center;
`;

const Image = styled.img`
    width: 100%;
    height: auto;
`;

export default function WorkInProgressModal() {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(!isInProgressShowed());

    const handleCloseModal = () => {
        setShowModal(false);
        localStorage.setItem("inProgress", true);
    }

    if (!showModal)
        return <></>

    return (
        <ModalWrapper>
            <Modal>
                <ModalHeader>
                    <CloseButton onClick={handleCloseModal}>X</CloseButton>
                </ModalHeader>
                <ModalBody>
                    <CenteredRow>
                        <Image src={BirdImage}/>
                    </CenteredRow>
                    <StyledText>{t('workInProgressModal.text')}</StyledText>
                    <ProgressWrapper>
                        <ProgressBar/>
                    </ProgressWrapper>
                </ModalBody>
            </Modal>
        </ModalWrapper>
    );
}