import Image from '../../../assets/pticsa.png'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import React from "react";

const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
  height: calc(110vh - 180px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
`

const useStyles = makeStyles({
    root: {
        maxWidth: "100%"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function PageAndProgram() {
    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('pageandprogram.header')}</h1>

            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('pageandprogram.t1')}
                        <b style={{color: '#243b85'}}>{t('pageandprogram.b1')}</b>
                        {t('pageandprogram.t2')}
                        <b style={{color: '#243b85'}}>{t('pageandprogram.b2')}</b>
                        {t('pageandprogram.t3')}
                        <b style={{color: '#243b85'}}>{t('pageandprogram.b3')}</b>
                        {t('pageandprogram.t4')}
                        <b style={{color: '#243b85'}}>{t('pageandprogram.b4')}</b>
                        {t('pageandprogram.t5')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('pageandprogram.b5')}</b>
                        {t('pageandprogram.t6')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('pageandprogram.t7')}
                    </p>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h4" component="h4" style={{color: '#243b85'}}>
                                {t('pageandprogram.head1')}
                            </Typography>
                            <Typography variant="h4" className={classes.title} color="textSecondary" gutterBottom>
                                {t('pageandprogram.t8')}
                            </Typography>
                        </CardContent>
                    </Card>
                    <br/>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h4" component="h4" style={{color: '#243b85'}}>
                                {t('pageandprogram.head2')}
                            </Typography>
                            <Typography variant="h4" className={classes.title} color="textSecondary" gutterBottom>
                                {t('pageandprogram.t9')}
                            </Typography>
                        </CardContent>
                    </Card>
                    <br/>

                    <h1>{t('pageandprogram.head3')}</h1>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('pageandprogram.t10')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('pageandprogram.t11')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('pageandprogram.t12')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('pageandprogram.t13')}
                        <b style={{color: '#243b85'}}>{t('pageandprogram.b6')}</b>
                        {t('pageandprogram.t14')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('pageandprogram.t15')}
                        <b style={{color: '#243b85'}}>{t('pageandprogram.b7')}</b>
                        {t('pageandprogram.t16')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('pageandprogram.t17')}
                    </p>
                    <br/>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h4" className={classes.title} color="textSecondary" gutterBottom>
                                <b style={{color: '#243b85'}}>{t('pageandprogram.b8')}</b>
                                {t('pageandprogram.t18')}<br/>
                                <b style={{color: '#243b85'}}>{t('pageandprogram.b9')}</b>
                                {t('pageandprogram.t19')}<br/>
                                <b style={{color: '#243b85'}}>{t('pageandprogram.b10')}</b>
                                {t('pageandprogram.t20')}<br/>
                                <b style={{color: '#243b85'}}>{t('pageandprogram.b11')}</b>
                                {t('pageandprogram.t21')}<br/>
                                <b style={{color: '#243b85'}}>{t('pageandprogram.b12')}</b>
                                {t('pageandprogram.t22')}<br/>
                                <b style={{color: '#243b85'}}>{t('pageandprogram.b13')}</b>
                                {t('pageandprogram.t23')}<br/>
                            </Typography>
                        </CardContent>
                    </Card>
                    <br/>
                    <a style={{textDecoration: "none"}} href={"/"}><h4><b style={{color: '#243b85'}}>{t('pageandprogram.b14')}</b>{t('pageandprogram.t24')}</h4>
                    <h4><b style={{color: '#243b85'}}>{t('pageandprogram.b15')}</b>{t('pageandprogram.t25')}</h4></a>
                </Paper>
            </Container>


        </div>
    )
}
