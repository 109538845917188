import styled from "styled-components";

export const CheckBoxS = styled.input`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 4px;
  margin-right: 0.5rem;
  
  &:hover{
  cursor: pointer;
  }
`;
