import Image from '../../../assets/grboviGrada/SWSW.png'
import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import FeedBack from "../../../components/FeedBack";

const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
 height: calc(100vh - 250px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;

`

export default function WomenEnterpreneursPage() {
    const {t} = useTranslation();
    const [showModalSendMail, setShowModalSendMail] = useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('women.header')}</h1>

            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('women.project')}</b>{t('women.description1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('women.b1')}{t('women.description1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('women.project')}</b>{t('women.description1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('women.b2')}</b>{t('women.description2')}
                        <a target={"_blank"} href={"https://preduzetnistvo.rs/"} style={{textDecoration: "none"}} rel="noreferrer"><b style={{color: '#243b85'}}>{t('women.b3')}</b></a>
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('women.description3')}
                    </p>
                    <h4 style={{color: '#243b85'}}>
                        {t('women.description4')}
                    </h4>
                    <ul style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%", marginLeft: "5%"}}>
                        <li>{t('women.li1')}</li>
                        <li>{t('women.li2')}</li>
                        <li>{t('women.li3')}</li>
                    </ul>
                    <h4>
                        {t('women.h1')}<b style={{color: '#243b85'}}>{t('women.b5')}</b>{t('women.description5')}
                    </h4>
                    <ul style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%", marginLeft: "5%"}}>
                        <li>{t('women.li5')}</li>
                        <li>{t('women.li6')}</li>
                        <li>{t('women.li7')}</li>
                    </ul>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('women.contact')}
                        <b onClick={() => setShowModalSendMail(true)} style={{color: '#243b85'}}>{t('women.businessHub')}</b>
                        {t('women.toLinkUp')}
                    </p>
                    <h4 style={{color: '#243b85'}}>{t('women.view')} <b style={{color: '#243b85'}}>{t('women.b6')}</b>
                    </h4>
                    <h4 style={{color: '#243b85'}}>{t('women.impressions')}</h4>
                    <FeedBack showModalSendMail={showModalSendMail} setShowModalSendMail={setShowModalSendMail}/>

                </Paper>
            </Container>


        </div>
    )
}
