import React, {useEffect, useState} from "react";
import Avatar from '@mui/material/Avatar';
import {getRating, getUserProfileImageAsync} from "../../services/api";
import UserProfilePhoto from "../../assets/images/user.svg"
import CircularProgress from "@material-ui/core/CircularProgress";
import {CenteredRow, FlexRow} from "../../components/Flex";
import ReactStars from "react-stars";
import styled from "styled-components";
import {NormalText} from "../../components/Text";

const Card = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 320px;
  padding: 2rem;
  background-color: #fff;
    
  @media (max-width: 648px) {
    width: 100%;

  }

`

const Text = styled(NormalText)`
  width: 100%;
  //line-height: 20px; //for not webkit browser
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: center;
`

export function UserCard({user}) {

    const [rating, setRating] = useState(0);

    useEffect(() => {
        getRating(user.id).then((res) => {
            setRating(res.data);
        })
    }, [user])

    return (
        <>

            <CenteredRow><Text>{user.name} {user.lastName}</Text></CenteredRow>
            <CenteredRow><Text>{user.company}</Text></CenteredRow>
            <CenteredRow><ReactStars
                edit={false}
                value={rating}
                count={5}
                size={14}
                color2={'#ffd700'}/></CenteredRow>
        </>
    )
}

export default function Users({user, style}) {
    const [userImage, setUserImage] = React.useState();
    const [loadingImage, setLoadingImage] = React.useState(false);
    useEffect(() => {
        setLoadingImage(true)
        if (user.pictureUrl !== "") {
            getUserProfileImageAsync(user?.id).then(response => {
                setUserImage(URL.createObjectURL(response.data));
                setLoadingImage(false);
            }).catch(_ => {
                setLoadingImage(false);
            });
        } else setLoadingImage(false)
    }, [user]);

    return (
        <>
            {loadingImage ? <CircularProgress/> :
                <>
                    <Card>
                        <CenteredRow><Avatar style={{height: '80px', width: '80px', objectFit: "contain", ...style}} alt="Remy Sharp"
                                             src={userImage === undefined ? UserProfilePhoto : userImage}/>
                        </CenteredRow>
                        <UserCard user={user}/>
                    </Card>

                </>
            }
        </>
    )
}
