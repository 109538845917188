import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";
import {FlexCol, FlexRow} from "../../../Flex";
import styled from "styled-components";
import {
    BROKERS_AND_REPRESENTATIVES,
    BUSINESS_ADVICE_FOR_DIASPORA,
    CIRCULAR_ECONOMY,
    CROWDFUNDING,
    DISABILITIES_ROUTE,
    FARMERS_ROUTE,
    INVESTOR_SUPPROT,
    MENTORSHIP_ROUTE,
    PAGE_AND_PROGRAM,
    PROJECT_WIZARD_PAGE,
    REGIONAL_DACH_NETWORK,
    RRA_PROJECT_WIZARD_PAGE,
    WOMEN_ENTERPRENEURS_ROUTE
} from "../../../../config/routes";
import {makeStyles} from "@material-ui/styles";

const Title = styled(FlexRow)`
    padding: 20px 20px 5px 20px;
    font-weight: bold;
`;

const StyledCol = styled(FlexCol)`
    padding: 20px 30px 20px 20px;
`;

const Hr = styled.hr`
    padding: 0 20px 0 20px;
    border-top: 10px #8c8b8b;
    margin-bottom: 10px;
`

const useStyles = makeStyles({
    paper: {
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
    },
});

export default function ServicesMenu({ anchorEl, handleClose, onMenuClick, isMobile }) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            classes={{
                paper: classes.paper
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <FlexRow>
                {isMobile &&
                    <StyledCol>
                        <Title>{t('serviceMenuHeader1')}</Title>
                        <Hr />
                        <MenuItem onClick={() => onMenuClick(PROJECT_WIZARD_PAGE)}>{t('serviceMenuItem1')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(RRA_PROJECT_WIZARD_PAGE)}>{t('serviceMenuItem2')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(PAGE_AND_PROGRAM)}>{t('serviceMenuItem2-1')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(CROWDFUNDING)}>{t('serviceMenuItem3')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(CIRCULAR_ECONOMY)}>{t('serviceMenuItem4')}</MenuItem>
                        <Title>{t('serviceMenuHeader2')}</Title>
                        <Hr />
                        <Title>{t('serviceMenuSubHeader1')}</Title>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(REGIONAL_DACH_NETWORK)}>{t('serviceMenuItem13')}</MenuItem>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(BUSINESS_ADVICE_FOR_DIASPORA)}>{t('serviceMenuItem14')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(INVESTOR_SUPPROT)}>{t('serviceMenuItem5')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(BROKERS_AND_REPRESENTATIVES)}>{t('serviceMenuItem6')}</MenuItem>
                        <MenuItem onClick={() => onMenuClick(MENTORSHIP_ROUTE)}>{t('serviceMenuItem7')}</MenuItem>
                        <Title>{t('serviceMenuSubHeader2')}</Title>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(FARMERS_ROUTE)}>{t('serviceMenuItem10')}</MenuItem>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(WOMEN_ENTERPRENEURS_ROUTE)}>{t('serviceMenuItem11')}</MenuItem>
                        <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(DISABILITIES_ROUTE)}>{t('serviceMenuItem12')}</MenuItem>
                    </StyledCol>
                }
                {!isMobile &&
                    <>
                        <StyledCol>
                            <Title>{t('serviceMenuHeader1')}</Title>
                            <Hr />
                            <MenuItem onClick={() => onMenuClick(PROJECT_WIZARD_PAGE)}>{t('serviceMenuItem1')}</MenuItem>
                            <MenuItem onClick={() => onMenuClick(RRA_PROJECT_WIZARD_PAGE)}>{t('serviceMenuItem2')}</MenuItem>
                            <MenuItem onClick={() => onMenuClick(PAGE_AND_PROGRAM)}>{t('serviceMenuItem2-1')}</MenuItem>
                            <MenuItem onClick={() => onMenuClick(CROWDFUNDING)}>{t('serviceMenuItem3')}</MenuItem>
                            <MenuItem onClick={() => onMenuClick(CIRCULAR_ECONOMY)}>{t('serviceMenuItem4')}</MenuItem>
                        </StyledCol>
                        <StyledCol>
                            <Title>{t('serviceMenuHeader2')}</Title>
                            <Hr />
                            <Title>{t('serviceMenuSubHeader1')}</Title>
                            <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(REGIONAL_DACH_NETWORK)}>{t('serviceMenuItem13')}</MenuItem>
                            <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(BUSINESS_ADVICE_FOR_DIASPORA)}>{t('serviceMenuItem14')}</MenuItem>
                            <MenuItem onClick={() => onMenuClick(INVESTOR_SUPPROT)}>{t('serviceMenuItem5')}</MenuItem>
                            <MenuItem onClick={() => onMenuClick(BROKERS_AND_REPRESENTATIVES)}>{t('serviceMenuItem6')}</MenuItem>
                            <MenuItem onClick={() => onMenuClick(MENTORSHIP_ROUTE)}>{t('serviceMenuItem7')}</MenuItem>
                            <Title>{t('serviceMenuSubHeader2')}</Title>
                            <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(FARMERS_ROUTE)}>{t('serviceMenuItem10')}</MenuItem>
                            <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(WOMEN_ENTERPRENEURS_ROUTE)}>{t('serviceMenuItem11')}</MenuItem>
                            <MenuItem style={{ marginLeft: "5%" }} onClick={() => onMenuClick(DISABILITIES_ROUTE)}>{t('serviceMenuItem12')}</MenuItem>
                        </StyledCol>
                    </>
                }
            </FlexRow>
        </Menu>
    );
}
