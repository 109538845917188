import React from "react";
import Banner from "../../components/Banner"

export default function Home() {
    return(
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <Banner />
        </div>
    )
}
