import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {parseDateForMessage} from "../../utils/date";
import {getUserProfileImageAsync} from "../../services/api";
import UserImage from "../../assets/images/user.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FlexAlign, FlexCol, FlexRow} from "../Flex";
import {Head3, PUserPost, Pxs} from "../../style/Fonts";
import {Checkbox} from "@mui/material";
import EnergySavingsLeafOutlinedIcon from '@icons/material/LeafIcon';
import EnergySavingsLeafIcon from '@icons/material/LeafIcon';

const FeedModal = React.lazy(() => import('../../components/FeedModal'));

const UserPostCard = styled.div`
    display: flex;
    width: 100%;
    max-height: 300px;
    height: fit-content;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 7px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
    padding: 1.5rem;
    background-color: white;
    margin-bottom: 1rem;
    transition: all 0.2s;
    &:hover {
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
    
     @media only screen and (max-width: 900px) {
        width: 100%;
    }
`

const ProfileImage = styled.img`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-right: 1rem;
    object-fit:cover;
`

export default function MessageCard({notification}) {
    const [loadingImage, setLoadingImage] = useState(false);
    const [userImage, setUserImage] = useState();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    useEffect(() => {
        if (notification?.user) {
            loadUserImage(notification?.user?.id);
        }
    }, [notification])

    async function loadUserImage(userId) {
        setLoadingImage(true);
        getUserProfileImageAsync(userId).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(_ => {
            setLoadingImage(false);

        });
    }

    return (
        <>
            <FeedModal open={open} setOpen={setOpen} notification={notification}/>
            <UserPostCard onClick={handleOpen}>
                <FlexAlign>
                    {loadingImage ? <CircularProgress style={{marginRight: '1rem'}}/> :
                        <ProfileImage src={userImage || UserImage}/>}
                    <FlexRow style={{width: '100%', justifyContent: "space-between"}}>
                        <FlexCol>
                            <Head3>{`${notification?.user?.name} ${notification?.user?.lastName}`}</Head3>
                            <Pxs>{notification?.user?.city?.name}</Pxs>
                        </FlexCol>

                        <Pxs>{parseDateForMessage(notification?.date)}</Pxs>

                    </FlexRow>
                </FlexAlign>
                <FlexCol>
                    <Head3 style={{color: "gray", margin: '2rem 0 1rem 0'}}>{notification?.title}</Head3>
                    <FlexRow>
                        <PUserPost style={{flex: 10}}>{notification?.description}</PUserPost>
                        <Checkbox style={{zIndex: 0, flex: 1}} name="isImportant" checked={notification?.isImportant}
                                  contentEditable={false}
                                  sx={{
                                      color: "#545252",
                                      '&.Mui-checked': {
                                          color: "#287428",
                                      },
                                  }}
                                  icon={<EnergySavingsLeafOutlinedIcon />}
                                  checkedIcon={<EnergySavingsLeafIcon />}
                        />
                    </FlexRow>

                </FlexCol>
            </UserPostCard>
        </>
    )
}
