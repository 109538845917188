import styled from "styled-components";
import React, {useContext, useEffect, useRef, useState} from "react";

import {LanguageContext} from "../../context/context";
import {useTranslation} from 'react-i18next';
import {CenteredRow} from "../Flex";
import LanguageIcon from '@material-ui/icons/Language';
import {setSessionLanguage} from "../../utils/session";

const DropDownContainer = styled("div")`
    //width: 5.5em;
    text-align: left;
    display: inline-block;
    margin: 0;
    list-style: none;
    top:0;
`;

const DropDownHeader = styled(CenteredRow)`
    
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  z-index: 999;
  cursor: pointer;
  position: absolute;
  display: inline-block;
  margin-top: 1rem;
  width: 55px;
  padding: 1em;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;

  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`

  list-style: none;
  margin-bottom: 0.8em;
  
  &:hover{
    background-color: #f0f0f0;
  }
`;

export function LanguageDropdown({isMobile}) {

    const [, setLanguage] = useContext(LanguageContext);

    const {i18n} = useTranslation();

    const changeLanguage = (event) => {
        setLanguage(event);
        i18n.changeLanguage(event);
        setIsActive(false);
        setSessionLanguage(event);
    };

    const [options,] = useState(["cir", "de", "lat", "en"]);
    const [isActive, setIsActive] = useState(false);
    const ref = useRef()
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isActive && ref.current && !ref.current.contains(e.target)) {
                setIsActive(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive])


    return (
        <DropDownContainer>
            <DropDownHeader onClick={() => setIsActive(true)}>
                <LanguageIcon style={{color: 'grey', margin: 10}}/>
                <span/>
            </DropDownHeader>
            {isActive && (
                <DropDownListContainer  ref={ref}>
                    <DropDownList style={isMobile && {marginTop: "-200px"}}>
                        {options.map((option, index) => (
                            <ListItem
                                key={index}
                                onClick={() => {
                                    changeLanguage(option)
                                }}>
                                {option}
                            </ListItem>
                        ))}
                    </DropDownList>
                </DropDownListContainer>
            )}
        </DropDownContainer>
    );
}




