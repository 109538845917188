import React, {useState} from "react";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import styled from "styled-components";

import CloseIcon from '../../assets/close.svg'
import {Image} from "../Header";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {
    BROKERS_AND_REPRESENTATIVES,
    BUSINESS_ADVICE_FOR_DIASPORA,
    CIRCULAR_ECONOMY,
    CITIES_PAGE, CONFERENCE_VIENNA,
    CROWDFUNDING,
    DACH_SUPPORT, DIASPORA_DAYS_MICRO_REGIONS,
    DISABILITIES_ROUTE,
    FARMERS_ROUTE,
    FORUMS_INFORMATION,
    GRANTS_RS,
    INVESTOR_SUPPROT,
    MENTORSHIP_ROUTE,
    PAGE_AND_PROGRAM,
    PROJECT_WIZARD_PAGE,
    REGIONAL_DACH_NETWORK,
    REGIONS_PAGE, ROOT_PATH,
    RRA_CORDINATION,
    RRA_PROJECT_WIZARD_PAGE,
    SEARCH_RESULTS,
    SUCCESSFUL_MODEL_DDL,
    WOMEN_ENTERPRENEURS_ROUTE
} from "../../config/routes";
import HomeIcon from "../../assets/sidebar/home-outlineW.svg"
import MessageIcon from "../../assets/sidebar/message-square-outlineW.svg"
import InteractionsIcon from "../../assets/sidebar/flash-outlineW.svg"
import ProjectsIcon from "../../assets/sidebar/project-outlineW.svg"
import PagesIcon from "../../assets/sidebar/pages-outlineW.svg"
import ApproveIcon from "../../assets/sidebar/approve-outlineW.svg"
import EventsIcon from "../../assets/sidebar/events-outlineW.svg"
import {getUserInfo} from "../../utils/session";
import {useHistory} from "react-router-dom";
import {SEARCH_TYPES} from "../../pages/Search";
import {ROLES_TYPE} from "../../utils/roles";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {sidebar_menu} from "../../helper/sidebar";

const StyledBurger = styled.button`
  position: fixed;
  top: 20%;
  z-index: 2;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({open}) => open ? '#0D0C1D' : '#EFFFFA'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`


const StyledMenu = styled.div`
  display: flex;
  width: 60%;
  height: 100hv;
  overflow-y: scroll;
  bottom: 0;
  box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25);
  flex-direction: column;
  background: #E5E7EB;
  transform: ${({open}) => open ? 'translateX(0)' : 'translateX(-100%)'};
  text-align: left;
  padding: 0.5rem 0.2rem 0.2rem 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
  
@media only screen and (max-width: 600px) {
    width: 100%;
  }
`
const Text = styled.p`
    font-size: .875rem; 
    color: #000;
    color: ${props => props.color ? '#545454' : '#000'};
    font-weight: 700;
    letter-spacing: .07rem;
`


const LineDiv = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    height: 35px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    
    &:hover {
        cursor: pointer;
        background-color: #fff;
    }
`

const Burger = ({open, setOpen}) => {
    return (
        <StyledBurger open={open} onClick={() => setOpen(true)}>
            {open ? null : <DoubleArrowIcon/>}
        </StyledBurger>
    )
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    test: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root": {
            backgroundColor: "transparent"
        }
    },
    MuiAccordionDetails: {
        "&.MuiAccordionDetails-root": {
            padding: "0px 16px"
        }
    },

}));

export default function MobileSidebar({handleMenuChange, isPublicRoute}) {
    const {t} = useTranslation();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();
    let user = getUserInfo();
    const [isAdmin, setIsAdmin] = useState(false);
    React.useEffect(() => {

        if (user !== null) {
            if (user.role?.id === 11) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    }, []);

    const handleNavUserClick = (id) => {
        history.push({
            pathname: SEARCH_RESULTS,
            search: "?menuType=" + SEARCH_TYPES.user + "&userType=" + id
        })
    }

    const handleLocalGovernmentsClick = () => {
        history.push(CITIES_PAGE)
    }

    const handleRegionsClick = () => {
        history.push(REGIONS_PAGE)
    }

    const handleProjectsClick = () => {
        history.push({
            pathname: SEARCH_RESULTS,
            search: "?menuType=" + SEARCH_TYPES.project
        })
    }

    const handleSelectPath = (value) => {
        handleMenuChange(value, isPublicRoute)
        setOpen(false)
        if (isPublicRoute) {
            history.push(ROOT_PATH)
        }
    }
    const classes = useStyles();
    return (
        <div ref={node} style={{zIndex: 100}}>
            <Burger open={open} setOpen={setOpen}/>
            <StyledMenu open={open}>
                <>{user ?
                    <>
                        <Image src={CloseIcon} onClick={() => setOpen(false)} style={{marginBottom: '1.5rem'}}/>
                        <LineDiv onClick={() => handleSelectPath(sidebar_menu.home)}>
                            <Image marginRight="0.1rem" src={HomeIcon} alt="homePage"/><Text>{t('homePage')}</Text>
                        </LineDiv>
                        <LineDiv onClick={() => handleSelectPath(sidebar_menu.messages)}>
                            <Image marginRight="0.1rem" src={MessageIcon} alt="messages"/><Text> {t('messages')}</Text>
                        </LineDiv>
                        <LineDiv onClick={() => handleSelectPath(sidebar_menu.interactions)}>
                            <Image marginRight="0.1rem" src={InteractionsIcon}
                                   alt="interactions"/><Text>{t('interactions')}</Text>
                        </LineDiv>
                        <LineDiv onClick={() => handleSelectPath(sidebar_menu.myProjects)}>
                            <Image marginRight="0.1rem" src={ProjectsIcon}
                                   alt="myProjects"/><Text>{t('myProjects')}</Text>
                        </LineDiv>
                        <LineDiv onClick={() => handleSelectPath(sidebar_menu.myPages)}>
                            <Image marginRight="0.1rem" src={PagesIcon} alt="myPages"/><Text>{t('myPages')}</Text>
                        </LineDiv>
                        {isAdmin && <LineDiv onClick={() => handleSelectPath(sidebar_menu.adminApprove)}>
                            <Image marginRight="0.1rem" src={ApproveIcon}
                                   alt="createPages"/><Text>{t('createPages')}</Text>
                        </LineDiv>}
                        {isAdmin && <LineDiv onClick={() => handleSelectPath(sidebar_menu.myEvents)}>
                            <Image marginRight="0.1rem" src={EventsIcon} alt="myEvents"/><Text>{t('myEvents')}</Text>
                        </LineDiv>}
                    </>
                    :
                    null
                }

                </>


                <div style={{marginBottom: '0.5rem', marginTop: '2.5rem', height: '35px'}}>
                    <Text style={{
                        fontWeight: 500,
                        letterSpacing: '1.9px',
                        color: '#757575'
                    }}>{t('services')}</Text>
                </div>

                <div>
                    <Accordion
                        elevation={0}
                        classes={{
                            root: classes.MuiAccordionroot
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('serviceMenuHeader1')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(PROJECT_WIZARD_PAGE)}>
                                <Text color>{t('serviceMenuItem1')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(RRA_PROJECT_WIZARD_PAGE)}>
                                <Text color>{t('serviceMenuItem2')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(PAGE_AND_PROGRAM)}>
                                <Text color>{t('serviceMenuItem3')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(CROWDFUNDING)}>
                                <Text color>{t('serviceMenuItem4')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails>
                            <LineDiv onClick={() => history.push(CIRCULAR_ECONOMY)}>
                                <Text color>{t('serviceMenuItem5')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        elevation={0}
                        classes={{
                            root: classes.MuiAccordionroot
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('serviceMenuSubHeader1')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(REGIONAL_DACH_NETWORK)}>
                                <Text color>{t('serviceMenuItem13')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(BUSINESS_ADVICE_FOR_DIASPORA)}>
                                <Text color>{t('serviceMenuItem14')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(INVESTOR_SUPPROT)}>
                                <Text color>{t('serviceMenuItem5')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(BROKERS_AND_REPRESENTATIVES)}>
                                <Text color>{t('serviceMenuItem6')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails>
                            <LineDiv onClick={() => history.push(MENTORSHIP_ROUTE)}>
                                <Text color>{t('serviceMenuItem7')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        elevation={0}
                        classes={{
                            root: classes.MuiAccordionroot
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('serviceMenuSubHeader2')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(FARMERS_ROUTE)}>
                                <Text color>{t('serviceMenuItem10')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(WOMEN_ENTERPRENEURS_ROUTE)}>
                                <Text color>{t('serviceMenuItem11')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(DISABILITIES_ROUTE)}>
                                <Text color>{t('serviceMenuItem12')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                    </Accordion>
                </div>


                <div style={{marginBottom: '0.5rem', marginTop: '2.5rem', height: '35px'}}>
                    <Text style={{
                        fontWeight: 500,
                        letterSpacing: '1.9px',
                        color: '#757575'
                    }}>{t('information')}</Text>
                </div>

                <div>
                    <Accordion
                        elevation={0}
                        classes={{
                            root: classes.MuiAccordionroot
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('informationMenuHeader1')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(RRA_CORDINATION)}>
                                <Text color>{t('informationMenuItem1')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        elevation={0}
                        classes={{
                            root: classes.MuiAccordionroot
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('informationMenuSubHeader1')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(FORUMS_INFORMATION)}>
                                <Text color>{t('informationMenuItem9')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(CONFERENCE_VIENNA)}>
                                <Text color>{t('informationMenuItem10')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(DIASPORA_DAYS_MICRO_REGIONS)}>
                                <Text color>{t('informationMenuItem11')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        elevation={0}
                        classes={{
                            root: classes.MuiAccordionroot
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('profiles')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(CITIES_PAGE)}>
                                <Text color>{t('informationMenuItem2')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(REGIONS_PAGE)}>
                                <Text color>{t('informationMenuItem3')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        {/*<AccordionDetails*/}
                        {/*    classes={{root: classes.MuiAccordionDetails}}>*/}
                        {/*    <LineDiv>*/}
                        {/*        <Text color>{t('informationMenuItem4')}</Text>*/}
                        {/*    </LineDiv>*/}
                        {/*</AccordionDetails>*/}
                    </Accordion>


                    <Accordion
                        elevation={0}
                        classes={{
                            root: classes.MuiAccordionroot
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('informationMenuHeader2')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(SUCCESSFUL_MODEL_DDL)}>
                                <Text color>{t('informationMenuItem5')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(DACH_SUPPORT)}>
                                <Text color>{t('informationMenuItem6')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv onClick={() => history.push(GRANTS_RS)}>
                                <Text color>{t('informationMenuItem7')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                        <AccordionDetails
                            classes={{root: classes.MuiAccordionDetails}}>
                            <LineDiv>
                                <Text color>{t('informationMenuItem8')}</Text>
                            </LineDiv>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div style={{marginBottom: '0.5rem', marginTop: '2.5rem', height: '35px'}}>
                    <Text style={{
                        fontWeight: 500,
                        letterSpacing: '1.9px',
                        color: '#757575'
                    }}>{t('entitySearch').toUpperCase()}</Text>
                </div>

                <LineDiv onClick={() => handleNavUserClick(ROLES_TYPE.investor)}>
                    <Text color>{t('investor')}</Text>
                </LineDiv>
                <LineDiv onClick={() => handleNavUserClick(ROLES_TYPE.broker)}>
                    <Text color>{t('broker')}</Text>
                </LineDiv>
                <LineDiv onClick={() => handleNavUserClick(ROLES_TYPE.entrepreneur)}>
                    <Text color>{t('entrepreneur')}</Text>
                </LineDiv>
                <LineDiv onClick={handleLocalGovernmentsClick}>
                    <Text color>{t('localGovernments')}</Text>
                </LineDiv>
                <LineDiv onClick={handleRegionsClick}>
                    <Text color>{t('regions')}</Text>
                </LineDiv>
                <LineDiv onClick={handleProjectsClick}>
                    <Text color>{t('projects')}</Text>
                </LineDiv>
            </StyledMenu>
        </div>
    );
}
