import React, {useEffect, useState} from 'react'
import Logo from '../../assets/images/logo.png'
import {Head, Image, WrapperCenter} from "./index.js"
import {useTranslation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import {FlexRow} from "../Flex";
import ServicesMenu from "./Menu/Services";
import InformationsMenu from "./Menu/Informations";
import {useHistory, useLocation} from "react-router-dom";
import {ABOUT_PAGE, FAQS_PAGE, SEARCH_ROUTE} from "../../config/routes";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getUserProfileImageAsync} from "../../services/api";
import {ButtonR} from "../Button";
import SearchIcon from '@material-ui/icons/Search';
import LanguagePopover from "../LanguagePopover";
import ProfilePopover from "../ProfilePopover";
import NotificationsPopover from "../NotificationsPopover";

const options = {
    services: 1,
    information: 2,
    faq: 3,
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    `
const Text = styled.span`
    font-size: 16px;
    
    @media only screen and (max-width: 1050px) {
        padding: 0;
        font-size: 14px
    }
`

const SearchButton = styled(ButtonR)`
    @media only screen and (max-width: 1050px) {
        font-size: 12px;
        width: 100px;
        height: 45px;
    }
`

const LoginButton = styled.button`
    background: none;
    color: grey;
    font-weight: 700;
    width: auto;
    border: none;
    padding: 15px;
    
    &:hover{
        cursor: pointer;
        background-color: #dbdbdb;
        color: '#fff';
    }

     @media only screen and (max-width: 1150px) {
        display: none
    } 
`

const Regbutton = styled.button`
    color: white;
    background: ${({bg}) => (bg ? '#DC2626' : '#243b85')};
    margin-right: ${({m}) => (m ? '1.5rem' : '0px')};
    font-weight: ${({f}) => (f ? '400' : '700')};
    width: auto;
    border: none;
    padding: 15px;
    
    &:hover{
        cursor: pointer;
        background-color: #2f91ed;
    }
    
    @media only screen and (max-width: 1150px) {
        display: none
    }
`
export default function NormalMenu({
                                       user,
                                       handleImageClick,
                                       handleRegistration,
                                       handleLogin
                                   }) {
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [windowSize, setWindowSize] = useState({width: 0, height: 0});
    const [anchorElService, setAnchorElService] = React.useState(null);
    const [anchorElInformation, setAnchorElInformation] = React.useState(null);
    const [anchorElFAQ, setAnchorElFAQ] = React.useState(null);
    const [userImage, setUserImage] = React.useState();
    const [loadingImage, setLoadingImage] = React.useState(false);
    const [showSearchButton, setShowSearchButton] = React.useState(true);

    useEffect(() => {
        if (user) {
            loadUserImage(user?.id);
        }
        setShowSearchButton(!(location.pathname === SEARCH_ROUTE))
    }, [location.pathname, user, user?.id]);

    async function loadUserImage(userId) {
        setLoadingImage(true);
        getUserProfileImageAsync(userId).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(_ => {
            setLoadingImage(false);

        });
    }

    const handleClick = (event, type) => {
        setAnchorElService(null);
        setAnchorElInformation(null);
        switch (parseInt(type)) {
            case options.services:
                setAnchorElService(event.currentTarget)
                break;
            case options.information:
                setAnchorElInformation(event.currentTarget)
                break;
            case options.faq:
                setAnchorElFAQ(event.currentTarget)
                break;
            default:
                setAnchorElService(event.currentTarget)
                break;
        }
    };

    const handleClose = () => {
        setAnchorElService(null);
        setAnchorElInformation(null);
    };

    const onMenuClick = (path) => {
        history.push(path);
        setAnchorElService(null);
        setAnchorElInformation(null);
    }

    useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    return (
        <Head title="">
            <Image style={{width: '50px', height: '50px'}} src={Logo} alt="Logo" onClick={handleImageClick}/>
            <WrapperCenter>
                <FlexRow>
                    <MenuItem
                        onClick={() => {
                            history.push(ABOUT_PAGE)
                        }}>
                        <Text>{t('about')}</Text>
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => handleClick(event, options.services)}><Text>{t('services')}</Text></MenuItem>
                    <MenuItem
                        onClick={(event) => handleClick(event, options.information)}><Text>{t('information')}</Text></MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.push(FAQS_PAGE)
                        }}><Text>{t('faq')}</Text></MenuItem>
                    {/*<MenuItem onClick={()=>setShowModalSendMail(true)}><Text>{t('sendMail')}</Text></MenuItem>*/}
                </FlexRow>
                {showSearchButton &&
                <SearchButton bg f
                              onClick={() => {
                                  history.push(SEARCH_ROUTE)
                              }}>
                    <Row>
                        {t('searchEnt')}
                        {windowSize?.width > 1050 && <SearchIcon/>}
                    </Row>
                </SearchButton>}
                {user ? (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <NotificationsPopover />
                            {loadingImage ?
                                <CircularProgress/> :
                                <ProfilePopover/>
                            }
                            <LanguagePopover/>
                        </div>) :
                    (<div style={{display: 'flex', alignItems: 'center'}}>
                        <LoginButton
                            style={{marginRight: '0.5rem'}}
                            onClick={handleLogin}>
                            {t('login')}
                        </LoginButton>
                        <Regbutton
                            onClick={handleRegistration}>
                            {t('register')}
                        </Regbutton>
                        <LanguagePopover/>
                    </div>)}

            </WrapperCenter>
            <ServicesMenu
                handleClose={handleClose}
                anchorEl={anchorElService}
                onMenuClick={onMenuClick}
                isMobile={false}/>
            <InformationsMenu
                handleClose={handleClose}
                anchorEl={anchorElInformation}
                onMenuClick={onMenuClick}
                isMobile={false}/>
        </Head>
    )
}
