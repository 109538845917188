import * as React from 'react';
import Popper from '@mui/material/Popper';
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {NormalText} from "../Text";
import {Inp} from "../Input";
import {ButtonS} from "../Button";
import styled from "styled-components";
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import {sendFeedback} from "../../services/api";

const Row = styled.div`
flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const Win = styled.div`
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 9;
    `

export const TextArea = styled.textarea`
    padding: 15px 20px;
    background: #fff;
    color: #333;
    display: flex;
    font-size: 16px;
    width: auto;
    border: 1px solid #cfd1d0;
    resize: vertical;
     min-height: 90px;
    max-height: 150px;
    margin-bottom: 1.5rem;
`

export default function FeedBack({showModalSendMail, setShowModalSendMail}) {
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const inputREf = React.useRef(null);
    const [mail, setMail] = React.useState({
        email: "",
        name: "",
        text: ""
    });

    React.useEffect(() => {
        if(showModalSendMail){
            inputREf.current.click();
            setShowModalSendMail(false);
        }
    }, [showModalSendMail]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const sendEmail = () => {
        if (!mail.email || mail.email.length < 3) {
            enqueueSnackbar(t('invalidEmail'), {
                variant: 'error'
            });
        } else {
            sendFeedback(mail).then(_ => {
                enqueueSnackbar(t('feedBack.success'), {
                    variant: 'success'
                });
                setMail({
                    email: "",
                    name: "",
                    text: ""
                });
                setAnchorEl(null);
            }).catch(err => {
                console.error(err)
                enqueueSnackbar(t('invalidEmail'), {
                    variant: 'error'
                });
            })
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Win>
            <ButtonS style={{padding: "5%", fontSize: "14px", borderRadius: "12px"}} ref={inputREf} aria-describedby={id} variant="contained" onClick={handleClick}>
                {t('feedBack.sendFeedBack')}
            </ButtonS>
            <Popper
            style={{zIndex: 999999999}}
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'top'}
            >
                <Paper style={{ padding: "5%" }}>
                    <h4>
                        {t('feedBack.sendFeedBack1')}
                    </h4>
                    <CloseIcon style={{ position: "absolute", right: "5%", top: "5%" }} onClick={handleClose} />
                    <NormalText required>{t('feedBack.email')}</NormalText>
                    <Inp onChange={(e) =>
                        setMail({
                            ...mail, email: e.target.value
                        })
                    } name={"email"} value={mail.email} type="text" />

                    <NormalText required>{t('feedBack.name')}</NormalText>
                    <Inp type="email" required onChange={(e) =>
                        setMail({
                            ...mail, name: e.target.value
                        })
                    } name={"name"} value={mail.name} type="text" />
                    <NormalText required>{t('feedBack.message')}</NormalText>
                    <TextArea onChange={(e) =>
                        setMail({
                            ...mail, text: e.target.value
                        })
                    } name={"text"} value={mail.text} type="text" />
                    <Row>
                        <ButtonS onClick={sendEmail}>{t('send')}</ButtonS>
                    </Row>
                </Paper>
            </Popper>
        </Win>
    );
}
