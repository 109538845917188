import React, {useState} from 'react';
import styled from 'styled-components'
import doc1en from '../../assets/pdf/podsticaj dvolist eng nov 2023.pdf';
import doc1ge from '../../assets/pdf/grants-rs-ge.pdf';
import doc2rs from '../../assets/pdf/Pogodnosti za ulaganja 15.avgust 2023 (1).pdf';
import doc2en from '../../assets/pdf/grants-rs-1-en.pdf';
import {useTranslation} from "react-i18next";
import {Container, Paper} from '@material-ui/core';

const Col = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    max-width: 500px;
    width: 100%;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    
`
const Banner = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    background-color: #243b85;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
`;


export default function GrantsRs() {
    const [numPages, setNumPages] = useState(null);
    const {t} = useTranslation();
    const {i18n} = useTranslation('home');
    const [windowSize, setWindowSize] = React.useState(null);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    let doc1 = doc1en;
    let doc2 = doc2rs;

    // if (i18n.language === "en") {
    //     doc1 = doc1en;
    //     doc2 = doc2rs;
    // } else if (i18n.language === "de") {
    //     doc1 = doc1ge;
    //     doc2 = doc2en;
    // }
    React.useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    let forRender;
    if (windowSize?.width < 700) {
        forRender = 300
    } else forRender = 500
    return (


        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Banner>
                {/* <h1 style={{ fontSize: '48px', color: 'white' }}>{t('dijasporaCroudFounding.header')}</h1> */}
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('grantsRs.header')}</h1>

            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>
                    <h4><b style={{color: '#243b85'}}>{t('grantsRs.t1')}</b></h4>

                    <div style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        paddingTop: '2rem',
                        gap: '1rem'
                    }}>
                    </div>
                </Paper>
                <FlexRow>
                    <Col><iframe style={{height : '800px', width: '100%'}} src={doc1} /></Col>
                    <Col><iframe style={{height : '100%', width: '100%'}} src={doc2} /></Col>
                </FlexRow>
            </Container>


        </div>

    );
}
