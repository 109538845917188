import styled from 'styled-components';


export const Inp = styled.input`

    padding: 15px 20px;
    background: #fff;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
    color: #333;
    display: flex;
    align-items: center;
    border: 1px solid #cfd1d0;
    z-index: 1;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 17px;
    width: ${props => props.width ? props.width : 'auto'};
    height: ${props => props.height ? props.height : 'auto'};
    margin-bottom: ${props => props.margin ? props.margin : '1.5rem'};
    &:focus {
        box-shadow: none;
        background: white;
        border: solid 1px #C3C3C3;
        box-sizing: border-box;
    }
    ::placeholder {
        left: 22px;
        top: calc(50% - 17px / 2 + 12px);
        font-size: 24px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: grey;
    }
    
  //   @media screen and (max-width: 900px) {
  //       width: 300px;
  //       height: 150px;
  //       margin-top: 50px;
  //
  // }
    `;
export const FileChooser = styled.label`
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 0px;
    -webkit-appearance: none;
    color: #99A3BA;
    border: 1px solid #CDD9ED;
    background: #fff;
    transition: border .3s ease;
    &::placeholder {
        color: #CBD1DC;
    }
    &:focus {
        outline: none;
        border-color: #275EFE;
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
        
    }
`;
export const InputS = styled.input`
    display: block;
    width: ${props => props.width ? props.width : '100%'};
    margin-top: ${props => props.marginTop ? props.marginTop : '0'};
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 0px;
    -webkit-appearance: none;
    color: #99A3BA;
    border: 1px solid #CDD9ED;
    background: #fff;
    transition: border .3s ease;
    &::placeholder {
        color: #8c8c8c;
    }
    &:focus {
        outline: none;
        border-color: #275EFE;
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
        
    }
`



export const InputDiv = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    & > span,
    input${InputS} {
        white-space: nowrap;
        display: block;
        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
        &:first-child {
            border-radius: 0px 0 0 0px;
        }
        &:last-child {
            border-radius: 0 0px 0px 0;
        }
        &:not(:first-child) {
            margin-left: -1px;
        }
    }
    .form-field {
        position: relative;
        z-index: 1;
        flex: 1 1 auto;
        width: 1%;
        margin-top: 0;
        margin-bottom: 0;
    }
    & > span {
        text-align: center;
        padding: 8px 12px;
        font-size: 16px;
  font-weight: 600;
        line-height: 25px;
        color: #99A3BA;
        background:#EEF4FF;
        border: 1px solid #CDD9ED;
        transition: background .3s ease, border .3s ease, color .3s ease;
    }
    &:focus-within {
        & > span {
            color: #fff;
            background: #678EFE;
            border-color: #275EFE;
        }
    }

`
