import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllBusinessActivities, getBrokerType} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Broker({userInfo, setUserInfo}) {
    const {t} = useTranslation();
    const [businessActivities, setBusinessActivities] = useState([]);
    const [brokerType, setBrokerType] = useState([]);

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChangeActivities = (e, newValue) => {
        setUserInfo(prevState => ({
            ...prevState, businessActivityList: newValue
        }))
    }

    useEffect(() => {
        getAllBusinessActivities().then(response => {
            setBusinessActivities(response?.data)
        }).catch(reason => {
            console.error(reason)
        });
        getBrokerType().then(response => {
            setBrokerType(response.data)
        }).catch(error => {
            console.error(error, "BrokerTypeError")
        })
    }, []);

    return (
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.broker.question1')}</NormalText>
                <Autocomplete
                    multiple
                    value={userInfo?.businessActivityList || ''}
                    options={businessActivities}
                    onChange={(e, newValue) => {
                        handleChangeActivities(e, newValue)
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    style={{minWidth: 'auto'}}
                    renderInput={(params) => <TextField {...params} variant="outlined"/>}
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.broker.question2')}</NormalText>
                <FormControl fullWidth variant="outlined">
                    <Select
                        native
                        id="brokerTypeId"
                        value={userInfo?.brokerTypeId || ''}
                        onChange={handleChange}>
                        {brokerType?.map((value, index) => (
                            <option key={index} value={parseInt(value?.id)}>{value?.name}</option>
                        ))}
                    </Select>
                </FormControl>
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.broker.question3')}</NormalText>
                <TextField
                    fullWidth
                    id="description"
                    multiline
                    maxRows={4}
                    value={userInfo?.description || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.broker.question4')}</NormalText>
                <TextField
                    fullWidth
                    id="description1"
                    multiline
                    maxRows={4}
                    value={userInfo?.description1 || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.broker.question5')}</NormalText>
                <TextField
                    fullWidth
                    id="foreignFirms"
                    multiline
                    maxRows={4}
                    value={userInfo?.foreignFirms || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

