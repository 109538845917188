import {CenteredCol, CenteredRow, FlexRow} from "../../components/Flex";
import Pagination from "../../components/Pagination";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {NormalText} from "../../components/Text";
import Button from "@mui/material/Button";
import {USER_PAGE} from "../../config/routes";
import {useHistory, useLocation} from "react-router-dom";
import {postParams, getRating} from "../../services/api";
import ReactStars from "react-stars";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 4rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF;
    min-height: 80vh;
    @media (max-width: 648px) {
        padding: 0.2rem;
    }
`

const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 220px;
    width: 300px;
    padding: 2rem;
    background-color: #fff;
    
     @media (max-width: 648px) {
        width: 100%;
        
    }
    
`

const Text = styled(NormalText)`
    width: 100%;
    line-height: 20px; //for not webkit browser
    height: 80px; //for not webkit browser
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`
const BootstrapButton = styled(Button)({
    width: '220px',
})

async function getAllUsers(input, cityIds) {
    let data = {}
    data.type = input;
    if (cityIds.length > 0) {
        data.cityIds = cityIds
    }
    return await postParams(data)
}

export function UserCard({user, t, handleConnect}){

    const [rating, setRating] = useState(0);

    useEffect(() =>{
        getRating(user.userId).then((res) => {
            setRating(res.data);
        })
    }, [user])

    return (
        <Card>
            <CenteredCol>
                {/*<User user={user}/>*/}
                {user.companyName === null ?
                    <FlexRow><Text>{user.name} {user.lastName}</Text></FlexRow>:<Text>{user.companyName}</Text>}
                <ReactStars
                    edit={false}
                    value={rating}
                    count={5}
                    size={24}
                    color2={'#ffd700'}/>
                <BootstrapButton onClick={()=>handleConnect(user.userId)}>{t('seeProfile')}</BootstrapButton>
            </CenteredCol>
        </Card>
    )
}

export default function AllSearchedUsers({t}) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(false);
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });
    const [sort, setSort] = useState({
        rowPerPage: 10,
        sortBy: "createdAt,ASC",
    });
    const history = useHistory();

    useEffect(() => {
        let params = location.state.params;
        let filter = {
            input: null,
            cityIds: [],
            sectorIds: [],
            range: [0, 9999999999999999999999]
        }
        if (params) {
            filter = {
                input: params.input,
                cityIds: params.cityIds,
                sectorIds: params.sectorIds,
                range: params.range
            }
        }
        setLoading(true);
        let parsedFilter = `?page=${page.currentPage}&sort=${sort.sortBy}&size=${sort.rowPerPage}`
        getAllUsers(filter.input, filter.cityIds).then(value => {
            setUsers(value.data)
        }).catch(error => {
            console.error(error, 'err')
            setLoading(false)
        });
    }, [page.currentPage, sort.rowPerPage, sort.sortBy]);

    const handleClickPage = (event, value) => {
        setPage({...page, currentPage: parseInt(value) - 1});
    };

    const [openConnect, setOpenConnect] = useState(false)

    function handleConnect(id) {
        history.push({
            pathname: USER_PAGE,
            search: "?menuType=2&id=" + id
        });
    }

    return (
        <Container>
            {/*<ConnectModal openConnect={openConnect} setOpenConnect={setOpenConnect} event={events}/>*/}
            <CenteredCol>
                    {users.length > 0 ?  <>
                        <CenteredRow style={{gap: '1rem', flexWrap: "wrap"}}>
                    {users.map(user => (
                        <UserCard user={user} handleConnect={handleConnect} t={t} />
                    ))}
                        </CenteredRow>
                    <Pagination
                        totalPages={page.totalPages}
                        handleClickPage={handleClickPage}
                        currentPage={parseInt(page.currentPage) + 1}/>
                        </> :
                        <p style={{textAlign: 'center'}}>{t('noResults')}</p>}
            </CenteredCol>
        </Container>
    )
}
