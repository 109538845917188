import React, {useEffect, useRef, useState} from 'react';
import UserIcon from "../../assets/sidebar/person-outline.svg"
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../redux/user";
import {useTranslation} from "react-i18next";
// material
import {alpha} from '@mui/material/styles';
import {Avatar, Box, Divider, IconButton, MenuItem, Typography} from '@mui/material';
// components
import MenuPopover from '../LanguagePopover/MenuPopover';
import {PROFILE_EDIT_PAGE, SEARCH_ROUTE} from "../../config/routes";
import {getUserProfileImageAsync} from "../../services/api";
import UserImage from "../../assets/images/user.svg";
import {ButtonL} from "../Button";
//


export default function ProfilePopover() {
    const {t} = useTranslation();
    const location = useLocation();

    const MENU_OPTIONS = [
        {
            label: t('profileEdit'),
            icon: UserIcon,
            linkTo: PROFILE_EDIT_PAGE
        },
    ];

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const {user} = useSelector(state => state.user)
    const [userImage, setUserImage] = useState();
    const [showSearchButton, setShowSearchButton] = useState(true);
    const [loadingImage, setLoadingImage] = useState(false);

    const handleLogout = () => {
        dispatch(logOut());
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        if (user) {
            loadUserImage(user?.id);
        }
        setShowSearchButton(!(location.pathname === SEARCH_ROUTE))
    }, [location.pathname, user, user?.id]);

    async function loadUserImage(userId) {
        setLoadingImage(true);
        getUserProfileImageAsync(userId).then(response => {
            setUserImage(URL.createObjectURL(response.data));
            setLoadingImage(false);
        }).catch(_ => {
            setLoadingImage(false);
        });
    }

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                <Avatar src={userImage || UserImage} alt="photoURL"/>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{width: 220}}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle1" noWrap>
                        {user?.name}
                    </Typography>
                    <Typography variant="body2" sx={{p: 0, m: 0, color: 'text.secondary'}} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{my: 1}}/>

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{typography: 'body2', py: 1, px: 2.5}}
                    >
                        <img src={option.icon} style={{width: 24, height: 24, marginRight: '.5rem'}}
                             alt={option.label}/>
                        {option.label}
                    </MenuItem>
                ))}

                <Box sx={{p: 2, pt: 1.5}}>
                    <ButtonL onClick={handleLogout} fullWidth color="inherit" variant="outlined">
                        {t('logout')}
                    </ButtonL>
                </Box>
            </MenuPopover>
        </>
    );
}
