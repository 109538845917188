import React, {useEffect, useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {FlexCol, FlexRow} from "../../../components/Flex";
import {Inp} from '../../../components/Input'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnRoundedIcon from '@mui/icons-material/DoNotDisturbOnRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import {useSnackbar} from "notistack";

const StyledRow = styled(FlexRow)`
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
`;

const StyledCol = styled(FlexCol)`
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
`;


export default function EventPreviewModal({showModal, setShowModal, data, createEventOnZoom}) {
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const descriptionElementRef = useRef(null);
    const [razlog, setReason] = React.useState("")
    useEffect(() => {

        if (showModal) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [showModal]);

    const handleClose = () => {
        setShowModal(false);
    };

    function handlePrihvati() {
        if (data.zoomLink && data.zoomLink.length > 0) {

        } else {
            enqueueSnackbar(t('eventPreview.noZoomLink'), {
                variant: 'error'
            });
        }
    }

    function handleOdbij() {
        if (razlog && razlog.length > 0) {

        } else {
            enqueueSnackbar(t('eventPreview.reasonEmpty'), {
                variant: 'error'
            });
        }
    }

    return (
        <>
            {showModal &&
            <div>
                <Dialog
                    maxWidth="xl"
                    open={showModal}
                    onClose={handleClose}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description">
                    <DialogTitle id="scroll-dialog-title">{data.name}
                        {parseInt(data.eventStatus.id) === 1 &&
                        <CheckCircleIcon color="success" style={{position: "absolute", right: "5%"}}/>}
                        {parseInt(data.eventStatus.id) === 2 &&
                        <DoNotDisturbOnRoundedIcon color="error" style={{position: "absolute", right: "5%"}}/>}
                        {parseInt(data.eventStatus.id) === 3 &&
                        <FiberManualRecordRoundedIcon style={{position: "absolute", right: "5%"}}/>}
                    </DialogTitle>
                    <DialogContent dividers={'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}>
                            <StyledRow>
                                <Typography>{t('eventPreview.user')}</Typography>
                                <Typography>{data.user.email}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.name')}</Typography>
                                <Typography>{data.name}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.description')}</Typography>
                                <Typography>{data.description}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.type')}</Typography>
                                <Typography>{data.eventType.name}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.day')}</Typography>
                                <Typography>{new Date(data.date).getDate()}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.mesec')}</Typography>
                                <Typography>{new Date(data.date).getMonth()}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.godina')}</Typography>
                                <Typography>{new Date(data.date).getFullYear()}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.sat')}</Typography>
                                <Typography>{new Date(data.date).getHours()}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.minut')}</Typography>
                                <Typography>{new Date(data.date).getMinutes()}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.durationInMinutes')}</Typography>
                                <Typography>{data.durationMin}</Typography>
                            </StyledRow>
                            <StyledRow>
                                <Typography>{t('eventPreview.numberOfAttendents')}</Typography>
                                <Typography>{data.eventInvitationList.length}</Typography>
                            </StyledRow>
                            <StyledCol>
                                <FlexRow>
                                    <Typography>{t('eventPreview.zoomLink')}</Typography>
                                    <Typography>{data.zoomLink}</Typography>
                                </FlexRow>
                                <Button onClick={createEventOnZoom}
                                        variant="contained">{t('eventPreview.generateZoomLink')}</Button>
                            </StyledCol>
                            <StyledCol>
                                <Typography>{t('eventPreview.reason')}</Typography><br/>
                                <Inp value={razlog} onChange={(event) => setReason(event.target.value)}></Inp>
                                <FlexRow>
                                    <Button onClikc={handlePrihvati} variant="contained"
                                            color="success">{t('eventPreview.potvrdi')}</Button>
                                    <p></p>
                                    <Button onClick={handleOdbij} variant="contained"
                                            color="error">{t('eventPreview.odbij')}</Button>
                                </FlexRow>
                            </StyledCol>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
            }
        </>

    );
}
