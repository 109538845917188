import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from "styled-components";
import NoImage from "../../../assets/images/noImage.svg";
import {getInvestImageAsync} from "../../../services/api";
import {CenteredRow} from "../../../style/Flex";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function SuccessfullModal({open, setOpen, investDetails}){

    const handleClose = () => setOpen(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [investmentImages, setInvestImages] = useState();
    const [investmentImages2, setInvestImages2] = useState();
    const [investmentImages3, setInvestImages3] = useState();

    useEffect(() => {

        if (investDetails?.successfulInvestmentImagePath || investDetails?.successfulInvestmentImagePath2 ||
            investDetails?.successfulInvestmentImagePath3) {
            getInvestImage()
        }
    }, [])

    async function getInvestImage() {
        setLoadingImage(true)
        getInvestImageAsync(investDetails.successfulInvestmentImagePath).then(value => {
            if(value.status === 200){
                setInvestImages(URL.createObjectURL(value.data))
                setLoadingImage(false)
            } else {
                setInvestImages(undefined)
                setLoadingImage(false)
            }
        }).catch(reason => {
            setLoadingImage(false)
        });

        getInvestImageAsync(investDetails.successfulInvestmentImagePath2).then(value => {
            if(value.status === 200){
                setInvestImages2(URL.createObjectURL(value.data))
                setLoadingImage(false)
            } else {
                setInvestImages2(undefined)
                setLoadingImage(false)
            }
        }).catch(reason => {
            setLoadingImage(false)
        });

        getInvestImageAsync(investDetails.successfulInvestmentImagePath3).then(value => {
            if(value.status === 200){
                setInvestImages3(URL.createObjectURL(value.data))
                setLoadingImage(false)
            } else {
                setInvestImages3(undefined)
                setLoadingImage(false)
            }
        }).catch(reason => {
            setLoadingImage(false)
        });
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>

                <Typography id="modal-modal-description" sx={{ mb: 2, fontWeight: 700, fontSize: 24, textAlign: 'center'}}>
                    {investDetails.city.name.toUpperCase()}
                </Typography>
                <Typography id="modal-modal-description" >
                   Vrednost investicije: {investDetails.amount} EUR
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2}}>
                    Delatnost: {investDetails.businessActivity.name}
                </Typography>


                <CenteredRow style={{gap: 2}}>
                    {investmentImages !== undefined && <ProfileImage src={investmentImages}/>}
                    {investmentImages2 !== undefined &&<ProfileImage src={investmentImages2 }/>}
                    {investmentImages3 !== undefined &&<ProfileImage src={investmentImages3}/>}
                </CenteredRow>



                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {investDetails.videoLink.toString().includes("https") ? <iframe width="640" height="360" src={investDetails.videoLink} ></iframe> : <Typography sx={{color: 'red', textAlign: 'center'}}>Video sadržaj nije dostupan</Typography> }

                </Typography>
            </Box>
        </Modal>
    )
}


const ProfileImage = styled.img`
  margin-right: 10px;
  border-radius: 5px;
  height: 175px;
  width: 175px;
  object-fit: cover;
  border: 1px solid lightGrey;
`
