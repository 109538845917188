import {customAxios} from "./customAxios";

export function login(payload) {
    return customAxios.post("login", payload);
}

export function registration(registrationPayload) {
    return customAxios.post("registration", registrationPayload);
}

export function logout() {
    return localStorage.clear();
}

export function me() {
    return customAxios.get("users/me");
}

export function resetPassword(email){
    return customAxios.post(`/users/forgotPassword?userEmail=${email}`)
}

export function meWithToken(token) {

    return customAxios.get("users/me", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        }
    });
}

const contentSet = {
    responseType: 'blob',
    ContentType: "image/jpeg"
}

export function getUserProfileImageAsync(id) {
    return customAxios.get(`users/image/${id}`, contentSet);
}

export function getAllActiveNotifications(params) {
    return customAxios.get(`notifications/active${params}`);
}

export function getAllInactiveNotifications(params) {
    return customAxios.get(`notifications/inactive${params}`);
}

export function readNotifications(notificationIds){
    return customAxios.post(`notifications`, notificationIds)
}

export function getAllUserPosts(params) {
    return customAxios.get(`/posts/byUser${params}`);
}

export function insertNewPost(post) {
    return customAxios.post(`/posts`,post);
}

export function getAllCountries() {
    return customAxios.get("countries");
}

export function getAllCities() {
    return customAxios.get('cities')
}

export async function getMyProjectsWithParams(params) {
    return await customAxios.get(`projects/user${params}`);
}

export async function getMyRRaProjects(params) {
    return await customAxios.get(`rraProjects/user${params}`);
}

export async function createRRAProject(project) {
    return await customAxios.post('rraProjects', project)
}

export function getAllBusinessSector() {
    return customAxios.get("businessSector");
}

export function getAllBusinessActivities() {
    return customAxios.get("businessActivities");
}

export function getAllInteractionReasons() {
    return customAxios.get("interaction/reasons")
}

export function insertInteraction(data) {
    return customAxios.post("interaction", data)
}

export function getInteractions(params) {
    return customAxios.get(`interaction/pending${params}`)
}

export function acceptInteraction(interactionId) {
    return customAxios.get(`/interaction/${interactionId}/accept`)
}

export function rejectInteraction(interactionId) {
    return customAxios.get(`/interaction/${interactionId}/reject`)
}

export function getMyConnection(params) {
    return customAxios.get(`interaction/active${params}`)
}

export async function getProjectTypes() {
    return await customAxios.get("projectTypes");
}

export async function getFInancingTypes() {
    return await customAxios.get("financingTypes");
}

const options = {
    responseType: 'arraybuffer',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
    }
}

const options2 = {
    responseType: 'blob',
    ContentType: "image/jpeg"
}

export function loginUserSync(loginPayload) {
    return customAxios.post("login", loginPayload);
}

export function createProject(project) {
    return customAxios.post('projects', project)
}

export function editProject(project) {
    return customAxios.put('projects', project)
}

export function editRRAProject(project) {
    return customAxios.put('rraProjects', project)
}

export function getAllBigRegions() {
    return customAxios.get('/bigRegions')
}

export async function getAllCitiesAsync() {
    return await customAxios.get('cities')
}

export function getAllCompanyTypes() {
    return customAxios.get("companyTypes");
}

export function getAllCompanySizes() {
    return customAxios.get("companySizes");
}

export function getAllInvestmentTypes() {
    return customAxios.get("investmentType");
}

export function getAllIndustries() {
    return customAxios.get("industry");
}

export function getAllDachgroups() {
    return customAxios.get("dachgroups");
}

export function getAllPksGroups() {
    return customAxios.get("pksgroup");
}

export function getAllVulnerableGroupTypes() {
    return customAxios.get("vulnerableGroupType");
}

export function getAllMentorTypes() {
    return customAxios.get("mentorType");
}

export function saveEvent(event) {
    return customAxios.post("events", event);
}

export function editEvent(event) {
    return customAxios.put("events", event);
}

export function getAllDistricts(event) {
    return customAxios.get("/districts", event);
}

export async function getAllDistrictsAsync(event) {
    return await customAxios.get("/districts", event);
}

export async function getAllBigRegionsAsync(event) {
    return await customAxios.get("/bigRegions", event);
}

export function getWebPageById(id) {
    return customAxios.get(`webpages/${id}`);
}

export function saveWebPage(data) {
    return customAxios.post("webpages", data);
}

export function getMyEvents() {
    return customAxios.get("events/user");
}

export function getEventById(id) {
    return customAxios.get(`events/${id}`);
}


export function getProjects() {
    return customAxios.get("projects/user");
}

export function searchUsersForEvent(params, searchObject) {
    return customAxios.post(`users/search${params}`, searchObject);
}

export function getAllApprovedUsers() {
    return customAxios.get(`users/approved`);
}

export function getRating(usrId){
    return customAxios.get(`/reviews/getUserRating/${usrId}`)
}

export function createRRA(obj) {
    return customAxios.post("rraProjects", obj)
}

export function uploadUserImage(form) {
    return customAxios.put("users/image", form);
}

export function getPdf(id, language) {
    return customAxios.get(`projects/load-pdf/${id}/${language}`, options);
}

export function getPdfLocation(id, language) {
    return customAxios.get(`projectLocation/load-pdf-location/${id}/${language}`, options);
}

export function getRRAPdf(id, language) {
    return customAxios.get(`rraProjects/load-pdf-rra/${id}/${language}`, options);
}

export function getProjectLocationPdf(id, language) {
    return customAxios.get(`projectLocation/load-pdf-location/${id}/${language}`, options);
}

export function checkIfEmailExist(email) {
    return customAxios.post("email/check", email);
}

export function getPdfRRa(id, language) {
    return customAxios.get(`rraProjects/load-pdf-rra/${id}/${language}`, options);
}

export function sendPagePlace(objectForPost) {
    return customAxios.post(`pageplaces`, objectForPost);
}

export function sendSuccessfullInvestment(objectForPost) {
    return customAxios.post(`successfulInvestment`, objectForPost);
}

export function  postSuccessfulInvestment(image, data){

    return customAxios.post(`successfulInvestment/insertImage?extension=${data.extension}&imageNumber=${data.imageNumber}&successfulInvestmentId=${data.successfulInvestmentId}`, image)
}
export function getAllSuccessInvesmtnets(){
    return customAxios.get('successfulInvestment')
}

export function updatePagePlace(pagePlace) {
    return customAxios.put(`pageplaces`, pagePlace);
}

export function updateSuccessfullInvestment(data) {
    return customAxios.put(`successfulInvestment`, data);
}

export function getPageByCityName(name) {
    return customAxios.get(`/pageplaces/city/${name}`);
}

export function getPagePlaceBigRegionName(name) {
    return customAxios.get(`/pageplaces/bigRegion/${name}`);
}

export function getPageByRegionName(name) {
    return customAxios.get(`/pageplaces/region/${name}`);
}

export async function upladPlacesSymbol(objectForPost) {
    return await customAxios.post(`pageplaces/symbol/image`, objectForPost);
}

export async function upladPlacesImage(objectForPost) {
    return await customAxios.post(`pageplaces/place/image`, objectForPost);
}

export async function uploadInvestmentImage(data) {
    return await customAxios.post(`successfulInvestment/insertImage`, data);
}

export function getPagePlaceCity(name) {
    return customAxios.get(`pageplaces/city/${name}`);
}

export function getPagePlaceSymbol(id) {
    return customAxios.get(`pageplaces/${id}/symbol`, options2);
}

export async function getPagePlaceSymbolAsync(id) {
    return await customAxios.get(`pageplaces/${id}/symbol`, options2);
}

export async function getPagePlaceImageAsync(imagePath) {
    return await customAxios.post(`pageplaces/image`, {
        imagePath: imagePath
    }, options2);
}

export async function getInvestmentImageAsync(imagePath) {
    return await customAxios.post(`pageplaces/image`, {
        imagePath: imagePath
    }, options2);
}

export function getPagePlaceImage(image) {
    return customAxios.post(`pageplaces/image`, image, options2);
}

export function getUserProfileImage(id) {
    return customAxios.get(`users/image/${id}`);
}

export function deleteProject(projectId) {
    return customAxios.delete(`/projects/${projectId}`);
}

export function deleteRraProject(projectId) {
    return customAxios.delete(`/rraProjects/${projectId}`);
}

export function getEventTypes() {
    return customAxios.get("/eventTypes");
}

export function getSupportedLanguages() {
    return customAxios.get("/language")
}

export function getAllConversations() {
    return customAxios.get("/conversations");
}

export function getAllMesagesForConversation(id, params) {
    return customAxios.get(`/conversations/${id}/messages${params}`)
}

export async function searchUserAsync(name) {
    return await customAxios.post("/users/searchGuest",
        {
            name: name
        }
    );
}

export async function searchProjectsAsync(data, params) {
    return await customAxios.post(`/projects/searchGuest${params}`,
        data
    );
}

export async function searchLocationsAsync(data, params) {
    return await customAxios.post(`/projectLocation/searchGuest${params}`,
        data
    );
}

export async function searchRRAProjectsAsync(data, params) {
    return await customAxios.post(`/rraProjects/searchGuest${params}`,
        data
    );
}

export async function searchSuccessfulInvestmentAsync(data, params) {
    return await customAxios.post(`/successfulInvestment/searchGuest${params}`,
        data
    );
}

export async function searchEventsAsync(name, params) {
    return await customAxios.post(`/events/search${params}`,
        {
            name: name
        }
    );
}

export function insertNewMessage(message, conversationId, recivierId) {
    return customAxios.post("/conversations",
        {
            "content": message,
            "conversationId": parseInt(conversationId),
            "receiverId": parseInt(recivierId)
        }
    );
}

export async function getInvestImageAsync(imagePath) {
    return await customAxios.post(`successfulInvestment/loadImage`, {
        imagePath: imagePath
    }, options2);
}

export function getUserById(id) {
    return customAxios.get(`users/${id}`)
}

export function getAllSuccessfulInvestments(){
    return customAxios.get(`/successfulInvestment`)
}

export async function insertDocumentAsync(data) {
    return await customAxios.post("/projects/documents", data)
}

export async function updateInsertDocumentAsync(data) {
    return await customAxios.post("/documents/update", data)
}

export async function insertRraDocumentAsync(data) {
    return await customAxios.post("rraProjects/documents", data)
}

const options3 = {
    responseType: 'blob'
}
export function getDocument(documentName) {
    return customAxios.get(`/documents/new/${documentName}`, options3);
}
export function removeDocument(documentId) {
    return customAxios.get(`/documents/remove?documentId=${documentId}`);
}

export function deleteEventById(id) {
    return customAxios.delete(`/events/${id}`)
}

export function getEventsApprovement() {
    return customAxios.get(`/events/status/1`)
}

export function organizationUnitType() {
    return customAxios.get(`/organizationUnitType`)
}

export function getApprovement() {
    return customAxios.get(`/projects/approve`)
}

export function getApproved(params) {
    return customAxios.get(`projects/approved${params}`)
}

export function getRRAApproved(params) {
    return customAxios.get(`rraProjects/approved${params}`)
}

export function updateProjectStatus(projectId, statusId) {
    return customAxios.get(`/projects/${projectId}/status/${statusId}`)
}

export function updateProjectLocationStatus(projectLocationId, statusId) {
    return customAxios.get(`/projectLocation/${projectLocationId}/status/${statusId}`)
}

export function getProjectStatus() {
    return customAxios.get(`/projectStatus/getAll`)
}

export function updateRRAProjectStatus(projectId, statusId) {
    return customAxios.get(`/rraProjects/${projectId}/status/${statusId}`)
}

export function getProjectPreviewById(projectId) {
    return customAxios.get(`/projects/preview/${projectId}`)
}

export function getProjectRRAPreviewById(projectId) {
    return customAxios.get(`/rraProjects/preview/${projectId}`)
}

export function updateUser(userInfo) {
    return customAxios.put(`users`, userInfo)
}


export function updateIndividualDto(userInfo) {
    return customAxios.put('individual/withDto', userInfo)
}

export function changePassword(changePasswordDto) {
    return customAxios.post('/users/changePassword', changePasswordDto)
}

export function updateEntrepreneurDto(userInfo) {
    return customAxios.put('entrepreneur/withDto', userInfo)
}

export function updateInvesstorDto(userInfo) {
    return customAxios.put('investor/withDto', userInfo)
}

export function updateBrokerDto(userInfo) {
    return customAxios.put('broker/withDto', userInfo)
}

export function updateMentorDto(userInfo) {
    return customAxios.put('mentor/withDto', userInfo)
}

export function updateDachDto(userInfo) {
    return customAxios.put('dach/withDto', userInfo)
}

export function updateRRADto(userInfo) {
    return customAxios.put('rra/withDto', userInfo)
}

export function updateMediaDto(userInfo) {
    return customAxios.put('media/withDto', userInfo)
}

export function updatePksDto(userInfo) {
    return customAxios.put('pks/withDto', userInfo)
}

export function updateSpecialDto(userInfo) {
    return customAxios.put('vulnerableGroup/withDto', userInfo)
}

export function updatePksNewDto(userInfo) {
    return customAxios.put('pksNew/withDto', userInfo)
}

export function addEventInvitation(invitationDto) {
    return customAxios.post(`/events/addInvitations`, invitationDto)
}

export function zoomTokenApi(code) {
    return customAxios.post(`/zoom/token`, {"code": code})
}

export function getAllSearchUsersByRoleId(roleId, params) {
    return customAxios.get(`/users/getByRoleId/${roleId}${params}`)
}

export function getAllRegions(){
    return customAxios.get(`regions`)
}

export function sendFeedback(email) {
    return customAxios.post(`/feedback`, email)
}

export function postParams(data) {
    return customAxios.post(`/searchPage/params`, data)
}

export function getResults(userId) {
    return customAxios.get(`/searchPage/results/${userId}`)
}

export function getProjectById(id) {
    return customAxios.get(`projects/${id}`)
}

export function getBasicRRAProject(id) {
    return customAxios.get(`rraProjects/basicRraProject`, {
        params: {
            id: id
        }
    })
}

export function getBasicProject(id) {
    return customAxios.get(`projects/basicRraProject`, {
        params: {
            id: id
        }
    })
}

export function getUserApprovment() {
    return customAxios.get('users/approvement')
}

export function updateUserStatus(userId, statusId) {
    return customAxios.get(`/users/${userId}/status/${statusId}`)
}

export function getSearchPageNumbers() {
    return customAxios.get(`searchPage/numbers`)
}

export function getCitiesForPagePlace() {
    return customAxios.get(`cities/forPagePlace`)
}

export function getHasConnection(userId) {
    return customAxios.get(`interaction/${userId}/hasConnection`)
}

export function getBrokerType() {
    return customAxios.get(`brokerType`)
}

export function uploadDocumentForMessage(form) {
    return customAxios.post("/documents/message", form);
}

export function getMessageDocument(fileName) {
    return customAxios.get(`/documents/${fileName}`, contentSet);
}

export function getFilter() {
    return customAxios.get(`searchPage/getFilter`);
}

export function downloadMessageFile(fileName, headers) {
    const contentSet = {
        responseType: 'blob',
        ContentType: "application/octet-stream"
    }
    return customAxios.get(`/documents/${fileName}`, contentSet);
}

export function insertProjectLocation(data) {
    return customAxios.post("projectLocation", data);
}

export function editProjectLocation(data) {
    return customAxios.put('projectLocation', data)
}

export async function getMyProjectsLocations(parsedFilter) {
    return await customAxios.get(`projectLocation/user${parsedFilter}`);
}

export function deleteProjectLocation(projectId) {
    return customAxios.delete(`projectLocation/${projectId}`);
}

export function getPdfProjectLocation(id, language) {
    return customAxios.get(`projectLocation/load-pdf-location/${id}/${language}`, options);
}

export function getApprovementLocation() {
    return customAxios.get(`projectLocation/approve`)
}

export function updateLocationsStatus(projectLocationId, statusId) {
    return customAxios.get(`projectLocation/${projectLocationId}/status/${statusId}`)
}


export function insertProjectLocationDocument(data) {
    return customAxios.post(`projectLocation/documents`, data)
}

export function postUserReview(data){
    return customAxios.post(`reviews`, data)
}

export function createReviewForUsers(data){
    return customAxios.post(`reviews/createReview`, data)
}

export function createSurveyForUsers(data){
    return customAxios.post(`reviews/surveyInvitation`, data)
}
