import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useHistory} from "react-router-dom";
import RegPhoto from "../../assets/images/sazvezdja-01.png"
import Bg from "../../assets/images/sazvezdjebg.png"
import {animated, useSpring} from 'react-spring';
import {useSnackbar} from "notistack";
import {FlexRow} from "../Flex";
import LoginSection from "./loginSection";
import ResetPasswordSection from "./resetPwSection";
import {useDispatch, useSelector} from "react-redux";
import {getToken, loginUser} from "../../redux/user";
import {ROOT_PATH} from "../../config/routes";
import {loadAxiosData} from "../../services/customAxios";
import {resetPassword} from "../../services/api";
import {useTranslation} from "react-i18next";

const Background = styled.div`
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 104;
    width: 100%;
    height: 100%;
`

const ContainerItem = styled.div`
    position: relative;
    width: 100%;
    height:100%;
    background: ${({primary}) =>
    primary ? 'white' : 'grey'};
    overflow: auto;
`

const ContainerWithBackground = styled.div`
       position: relative;
    height: 100%;
    width: 100%;
  
    background: ${({primary}) =>
    primary ? 'white' : 'grey'};
    overflow: auto;
    background: no-repeat center;
    background-image: url(${Bg});
    background-size: 100% 100%;
`

const Close = styled.button`
    width: 30px;
    font-size: 20px;
    color: '#000';
    position:absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;
`

export const DivRopw = styled(FlexRow)`
    max-height: 500px;
    height: 100vh;
`
export default function LoginModal({showModal, setShowModal}) {
    const dispatch = useDispatch();
    const {t}=useTranslation();
    const {error, loading} = useSelector(state => state.user);
    const history = useHistory();
    const [windowSize, setWindowSize] = React.useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const [resetPw, setResetPw] = useState(false)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (error) {
            enqueueSnackbar('Pogrešno korisničko ime ili lozinka!', {
                variant: 'error'
            });
        }
    }, [error])


    useEffect(() => {
        function updateSize() {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    const handleLogin = () => {
        dispatch(loginUser({username: username, password: password})).then(response => {
            try {
                loadAxiosData();
                dispatch(getToken(response?.payload?.data?.token)).then(_ => {
                    history.push(ROOT_PATH)
                })
            } catch {
                console.error("LOGIN ERROR")
            }
        })
    };

    const keyPress = React.useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
                setResetPw(false);
            }
        },
        [setShowModal, showModal]
    );

    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    const handleMailReset = () => {
        resetPassword(username).then(value => {
            enqueueSnackbar(t("checkEmail"),{variant:"success"})
            setResetPw(false)
            setShowModal(false)
        }).catch(reason => {
            enqueueSnackbar(t("resetPasswordError"),{variant:"error"})
        })

    }
    let view;


    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        width: windowSize?.width > 800 ? '60%' : '90%',
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    switch (resetPw) {
        case false:
            view = <LoginSection
                loading={loading}
                handleLogin={handleLogin}
                setPassword={setPassword}
                setResetPw={setResetPw}
                setUsername={setUsername}/>
            break;
        case true:
            view = <ResetPasswordSection setUsername={setUsername} handleMailReset={handleMailReset}/>
            break;
        default:
            view = <LoginSection loading={loading} handleLogin={handleLogin} setPassword={setPassword}
                                 setResetPw={setResetPw} setUsername={setUsername}/>
    }
    return (
        <>
            {showModal && <Background>
                <animated.div style={animation}>
                    <DivRopw>
                        {windowSize?.width > 800 &&
                        <ContainerWithBackground>
                            {<img src={RegPhoto} alt="logo" style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}/>}
                        </ContainerWithBackground>}
                        <ContainerItem primary>
                            <Close onClick={() => {
                                setShowModal(false);
                                setResetPw(false);

                            }}>✖</Close>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                paddingTop: '3rem',
                                paddingRight: '1rem',
                                paddingLeft: '1rem'
                            }}>
                                {view}
                            </div>
                        </ContainerItem>
                    </DivRopw>
                </animated.div>
            </Background>}
        </>
    )
}
