import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllBusinessActivities, getAllDachgroups, getAllMentorTypes, getBrokerType} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Mediji({userInfo, setUserInfo, countries}) {
    const {t} = useTranslation();

    const handleChange = (e) => {
        const {id, value} = e.target
        setUserInfo(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleChangeCountriesExport = (event, newValue) => {
        setUserInfo(prevState => ({
            ...prevState,
            countries: newValue
        }))
    }


    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.media.question1')}</NormalText>
                <Autocomplete
                    multiple
                    id="countries"
                    options={countries}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(event, newValue) => {
                        handleChangeCountriesExport(event, newValue)
                    }}
                    defaultValue={userInfo?.countries}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                        />)}
                />
            </FlexCol>

            <TextField
                fullWidth
                label={t('registration.media.question2')}
                id="followersNumber"
                type="number"
                min="0"
                value={userInfo?.followersNumber || 0}
                onChange={handleChange}
                variant="outlined"
            />

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.media.question3')}</NormalText>
                <TextField
                    fullWidth
                    id="explanation"
                    multiline
                    maxRows={4}
                    value={userInfo?.explanation || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.media.question4')}</NormalText>
                <TextField
                    fullWidth
                    id="showExample"
                    multiline
                    maxRows={4}
                    value={userInfo?.showExample || ''}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

