import React, {useEffect, useState} from "react"
import styled from "styled-components";

import IndustrijaIcon from '../../assets/images/City/inudstrija.svg'
import PlusRIcon from '../../assets/images/City/plusRotated.svg'
import TraktorIcon from '../../assets/images/City/traktor.svg'
import TurizamIcon from '../../assets/images/City/toursim.svg'
import SumarstvoIcon from '../../assets/images/City/sumarstvo.svg'
import Plus from '../../assets/images/City/plus.svg'
import {useTranslation} from "react-i18next";


const Section = styled.div`
  padding: 2rem 0;
  background-color: #EFEFEF;
`
const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;
const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  
`;

const Bar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #FFF;
    padding: ${props => props.padding ? props.padding : '0'};
    border: ${p => p.isOk ? "2px solid #15AFFA" : 'none'};
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    transition: width 0.2s ease-in-out;
    //background-color: ${p => p.isOk ? 'white' : 'green'};
    width:  ${p => p.isOk ? '85%' : '80%'};
    &: hover {
        cursor: pointer;
        width: 85%;
    }
    @media screen and (max-width: 768px) {
        width: 95%;
        justify-content: space-between;
        
        &: hover {
        cursor: pointer;
        width: 100%;
    }
     }
`
const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

const Text = styled.p`
    padding-left: 20px;
    text-align: justify;
    color: #575757;
    margin: ${props => props.margin ? props.margin : 'none'};
    //transition: 0.5s ease-in-out;
    @media screen and (max-width: 420px) {
        font-size: 12px;
    }
`;

const LeftWrapper = styled.div`
    width: 100%;
    padding: 2rem;
`;

const RightWrapper = styled.div`
    width: 100%;
    background-color: #FFF;
    height: 500px;
    overflow: auto;
    border-radius: 5px;
    border: 0.6px solid #E9E9E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 600;
  text-align: center;
  background-color: #15AFFA;
  color: #FFF;
  margin-top: 2rem;
`;


const Image = styled.img`
    width: ${props => props.width ? props.width : '40px'};
    height: ${props => props.height ? props.height : '40px'};
    transition: 0.2s ease-in;
     @media screen and (max-width: 420px) {
        width: ${props => props.width ? props.width : '30px'};
        height: ${props => props.height ? props.height : '30px'};
     }
    
`

const Icon = styled.img`
    width: ${props => props.width ? props.width : '40px'};
    height: ${props => props.height ? props.height : '40px'};
    transition: 0.2s ease-in;
     @media screen and (max-width: 420px) {
        width: ${props => props.width ? props.width : '30px'};
        height: ${props => props.height ? props.height : '30px'};
    }  
`

export default function SectionTwo({object, index}) {
    let {t} = useTranslation();
    const [name, setName] = useState('');
    const [description, setDescription] = useState("")
    const [active, setActive] = useState({
        i: true
    })
    useEffect(() => {
        setDescription(object.pagePlaceLanguages[index]?.reachTextIndustry)
        setName(t('industry').toUpperCase())
    }, [index, object.pagePlaceLanguages, t])


    const handleBarClick = (name) => {
        switch (name) {
            case "POLJOPRIVREDA" :
                setDescription(object.pagePlaceLanguages[index]?.reachTextAgriculture)
                setActive({p: true})
                setName(t('agriculture').toUpperCase())
                break;
            case "TURIZAM" :
                setDescription(object.pagePlaceLanguages[index]?.reachTextTourism)
                setActive({t: true})
                setName(t('tourism').toUpperCase())
                break;
            case "ŠUMARSTVO" :
                setDescription(object.pagePlaceLanguages[index]?.reachTextForestry)
                setActive({s: true})
                setName(t('forestry').toUpperCase())
                break;
            default :
                setDescription(object.pagePlaceLanguages[index]?.reachTextIndustry)
                setActive({i: true})
                setName(t('industry').toUpperCase());
                break;
        }
    }

    return (
        <Section>
            <Container>
                <InfoRow>
                    <InfoColumn>
                        <LeftWrapper>
                            <Bar isOk={active.i} padding="0.5rem 1rem 0.5rem 1rem" border="2px solid #75aaff"
                                 onClick={_ => handleBarClick("INDUSTRIJA")}>
                                <Image src={IndustrijaIcon} alt="industrija"/>
                                <Text>{t('industry').toUpperCase()}</Text>
                                {active.i ?
                                    <Icon src={PlusRIcon}/>
                                    : <Icon src={Plus} width="20px" height="20px"/>
                                }

                            </Bar>
                            <Bar isOk={active.p} padding="0.5rem 1rem 0.5rem 1rem" border="2px solid #75aaff"
                                 onClick={_ => handleBarClick("POLJOPRIVREDA")}>
                                <Image src={TraktorIcon} alt="poljoprivreda"/>
                                <Text>{t('agriculture').toUpperCase()}</Text>
                                {active.p ?
                                    <Icon src={PlusRIcon}/>
                                    : <Icon src={Plus} width="20px" height="20px"/>
                                }
                            </Bar>
                            <Bar isOk={active.t} padding="0.5rem 1rem 0.5rem 1rem" border="2px solid #75aaff"
                                 onClick={_ => handleBarClick("TURIZAM")}>
                                <Image src={TurizamIcon} alt="turizam"/>
                                <Text>{t('tourism').toUpperCase()}</Text>
                                {active.t ?
                                    <Icon src={PlusRIcon}/>
                                    : <Icon src={Plus} width="20px" height="20px"/>
                                }
                            </Bar>
                            <Bar isOk={active.s} padding="0.5rem 1rem 0.5rem 1rem" border="2px solid #75aaff"
                                 onClick={_ => handleBarClick("ŠUMARSTVO")}>
                                <Image src={SumarstvoIcon} alt="sumarstvo"/>
                                <Text>{t('forestry').toUpperCase()}</Text>
                                {active.s ?
                                    <Icon src={PlusRIcon}/>
                                    : <Icon src={Plus} width="20px" height="20px"/>
                                }
                            </Bar>
                        </LeftWrapper>
                    </InfoColumn>
                    <InfoColumn>
                        <RightWrapper>
                            <Heading>{name}</Heading>
                            <Text style={{textAlign: 'justify'}} margin="2rem 1rem 2rem 1rem" dangerouslySetInnerHTML={{__html: description}}/>
                        </RightWrapper>
                    </InfoColumn>
                </InfoRow>
            </Container>
        </Section>
    )
}
