import React, {useState} from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import routes from './config/routes.js';
import AppRoute from './config/AppRoute';
import Header from "./components/Header";
import Footer from "./components/Footer";
import WorkInProgressModal from "./components/WorkInProgress";
import styled from "styled-components";
import {FlexCol} from "./components/Flex";
import {isPwaBrowser} from "./utils/pwa";
import {useSelector} from "react-redux";
import PwaInstaller from "./components/PwaInstaller";
import {isPwaInstalled, setPwaToInstalled} from "./utils/session";

const Container = styled(FlexCol)`
    min-height: 100vh;
    justify-content: space-between;
`;

async function printInstalledApps() {
    if ('getInstalledRelatedApps' in window.navigator) {
        const relatedApps = await navigator.getInstalledRelatedApps();
        relatedApps.forEach((app) => {
            //if your PWA exists in the array it is installed
            console.log("INSTALIRANO: ", app.platform, app.url);
        });
    }
}

export default function App() {
    const [showPwaWindow, setShowPwaWindow] = useState(!isPwaInstalled());
    const {user} = useSelector((state) => state.user)
    const [isPwaApp] = useState(isPwaBrowser());


    React.useEffect(() => {
        printInstalledApps();
    }, [user])

    const handleCloseDialog = (isInstalled) => {
        setShowPwaWindow(false);
        if (isInstalled) {
            setPwaToInstalled();
        }
    }

    return (
        <Router>
            <Container>
                <Header isPwa={isPwaApp}/>
                <Switch>
                    {routes(Boolean(user)).map((route) => (
                        <AppRoute
                            exact={route.exact}
                            key={route.path}
                            path={route.path}
                            component={route.component}
                            isPrivate={route.isPrivate}
                            user={user}
                        />
                    ))}
                </Switch>
                <Footer/>
                {showPwaWindow && <PwaInstaller closeDialog={handleCloseDialog}/>}
            </Container>
        </Router>
    );
}
