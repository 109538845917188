import Image from '../../../assets/forums.jpg';
import styled from 'styled-components'
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import React from "react";
import doc1 from '../../../assets/pdf/Позивница-Дан дијаспоре Кладово.pdf';
import doc2 from '../../../assets/pdf/Позивница-Дан дијаспоре Неготин.pdf';

const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
 height: calc(100vh - 250px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    max-width: 500px;
    width: 100%;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    
`
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
`;

export default function DiasoraDaysMicroRegions() {
    const {t} = useTranslation();
    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>

            <Banner>
                <h1 style={{
                    fontSize: '48px',
                    color: 'white',
                    padding: "15px",
                    backgroundColor: 'rgba(0, 0, 0, 0.55)',
                    borderRadius: '25px'
                }}>{t('diasporaDaysAndMicroRegions.header')}</h1>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}
            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%", whiteSpace: 'pre-wrap'}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('diasporaDaysAndMicroRegions.text1')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('diasporaDaysAndMicroRegions.text2')}
                        <a href='https://dijaspora.pks.rs:8443'><b style={{color: '#243b85'}}>{t('diasporaDaysAndMicroRegions.b2')}</b></a>
                        {t('diasporaDaysAndMicroRegions.text3')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('diasporaDaysAndMicroRegions.text4')}
                        <b style={{color: '#243b85'}}>{t('diasporaDaysAndMicroRegions.b3')}</b>
                        {t('diasporaDaysAndMicroRegions.text5')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('diasporaDaysAndMicroRegions.text6')}
                        <b style={{color: '#243b85'}}>{t('diasporaDaysAndMicroRegions.b4')}</b>
                        {t('diasporaDaysAndMicroRegions.text7')}
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('diasporaDaysAndMicroRegions.text8')}
                        <b style={{color: '#243b85'}}>{t('diasporaDaysAndMicroRegions.b5')}</b>
                        {t('diasporaDaysAndMicroRegions.text9')}
                    </p>

                </Paper>
                <FlexRow>
                    <Col><iframe style={{height : '800px', width: '100%'}} src={doc1} /></Col>
                    <Col><iframe style={{height : '800px', width: '100%'}} src={doc2} /></Col>
                </FlexRow>
            </Container>


        </div>
    )
}
