import {FlexCol} from "../../components/Flex";
import React from "react";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Mentor({userInfo, t, lng}) {

    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <TextField
                    fullWidth
                    disabled={true}
                    id="countries"
                    label={t('registration.mentor.question1')}
                    multiline
                    maxRows={4}
                    value={userInfo?.countries || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    label={t('registration.mentor.question2')}
                    disabled={true}
                    fullWidth
                    id="description"
                    multiline
                    maxRows={4}
                    value={userInfo?.description || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    label={t('registration.mentor.question3')}
                    disabled={true}
                    fullWidth
                    id="mentorTypeId"
                    multiline
                    maxRows={4}
                    value={userInfo?.mentorTypeId || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    label={t('registration.investor.question2')}
                    disabled={true}
                    fullWidth
                    id="businessActivities"
                    multiline
                    maxRows={4}
                    value={userInfo?.businessActivities|| ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    label={t('registration.investor.question5')}
                    disabled={true}
                    fullWidth
                    id="description1"
                    multiline
                    maxRows={4}
                    value={userInfo?.description1|| ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <TextField
                    label={t('registration.investor.question6')}
                    disabled={true}
                    fullWidth
                    id="companyExamples"
                    multiline
                    maxRows={4}
                    value={userInfo?.companyExamples || ''}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

