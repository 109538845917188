import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CenteredCol, CenteredRow, FlexCol} from "../../components/Flex";
import {NormalText} from "../../components/Text";
import {SEARCH_TYPES} from "../Search";
import {getApproved, getPdf, getRRAApproved, getRRAPdf} from "../../services/api";
import {useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import Pagination from "../../components/Pagination";
import ConnectModal from "../UserPage/connectModal";
import Button from "@mui/material/Button";
import LanguagePdfSelect from "../../components/LanguagePdfSelect";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 4rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFF;
    
    @media (max-width: 648px) {
        padding: 0.2rem;
    }
`

const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 400px;
    width: 400px;
    padding: 2rem;
    background-color: #fff;

    
     @media (max-width: 648px) {
        width: 100%;
        
    }
    
`

const BText = styled.p`
    font-weight: 700;
    padding: 0;
    line-height: 24px; //for not webkit browser
    height: 50px; //for not webkit browser
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const Text = styled(NormalText)`
    width: 100%;
    line-height: 20px; //for not webkit browser
    max-height: 120px; //for not webkit browser
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
`
const BootstrapButton = styled(Button)({
    width: '140px',
})

export default function SearchResult() {
    const {t} = useTranslation();
    const query = new URLSearchParams(window.location.search);
    const menuType = query.get('menuType');
    const {user} = useSelector((state) => state.user)
    const {enqueueSnackbar} = useSnackbar();
    const [openConnect, setOpenConnect] = useState(false);
    const [project, setproject] = useState(false);
    const [showLang, setShowLang] = useState(false);
    const [showLangRra, setShowLangRra] = useState(false);
    const [projectForDownload, setProjecForDownload] = useState(0);
    const [filter, setFilter] = useState({
        rowPerPage: 10,
        sortBy: "createdAt,ASC",
    });
    const [page, setPage] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });

    const [pageRRA, setPageRRA] = useState({
        totalPages: 1,
        currentPage: 0,
        totalElements: 0
    });

    useState(() => {
        switch (menuType) {
            case SEARCH_TYPES.user:
                break;
            case SEARCH_TYPES.project:
                break;
            case SEARCH_TYPES.event:
                break;
            case SEARCH_TYPES.tag:
                break;
            default:
                break;
        }
    });
    const [projects, setProjects] = useState()
    const [rraprojects, setRRAProjects] = useState()

    const handleClickPage = (event, value) => {
        setPage({...page, currentPage: parseInt(value) - 1});
    };

    const handleClickPageRRA = (event, value) => {
        setPageRRA({...pageRRA, currentPage: parseInt(value) - 1});
    };
    useEffect(() => {
        let parsedFilter = `?page=${page.currentPage}&sort=${filter.sortBy}&size=${filter.rowPerPage}`
        getApproved(parsedFilter).then(response => {
            setProjects(response.data)
            setPage({...page, totalPages: response?.data.totalPages, totalElements: response.data.totalElements})
        }).catch(error => {

        })
    }, [ page.currentPage])

    useEffect(()=> {
        let parsedFilter = `?page=${pageRRA.currentPage}&sort=${filter.sortBy}&size=${filter.rowPerPage}`
        getRRAApproved(parsedFilter).then(response => {
            setRRAProjects(response.data)
            setPageRRA({...pageRRA, totalPages: response?.data.totalPages, totalElements: response.data.totalElements})
        }).catch(error => {

        })
    },[ pageRRA.currentPage])

    function handlePreviewProjectClick(lang) {
        if (user) {
            getPdf(projectForDownload, lang).then((response) => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                const url = window.URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(url);
            }).catch(_ => {
                enqueueSnackbar(t('pdfNotExist'), {
                    variant: 'error'
                });
            })
        } else {
            enqueueSnackbar(t('youHaveToBeLoggedIn'), {
                variant: 'error'
            });
        }
        setShowLang(false)
    }

    function handlePreviewRraProjectClick(lan) {
        if (user) {
            getRRAPdf(projectForDownload, lan).then((response) => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                const url = window.URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(url);
            }).catch(_ => {
                enqueueSnackbar(t('pdfNotExist'), {
                    variant: 'error'
                });
            })
        } else {
            enqueueSnackbar(t('youHaveToBeLoggedIn'), {
                variant: 'error'
            });
        }
        setShowLangRra(false)
    }

    const handleConnect = (project) => {
        setproject(project)
        setOpenConnect(true)
    }

    return (
        <Container>
            <LanguagePdfSelect t={t} show={showLang}  handlePreviewProjectClick={handlePreviewProjectClick}
                               handleClose={()=>{setShowLang(false)}}/>
            <LanguagePdfSelect t={t} show={showLangRra}  handlePreviewProjectClick={handlePreviewRraProjectClick}
                               handleClose={()=>{setShowLangRra(false)}}/>
            <ConnectModal openConnect={openConnect} setOpenConnect={setOpenConnect} project={project}/>
            <h3 style={{textAlign: 'center', marginBottom: '1.5rem'}}>{t('projectViewTitle')}</h3>
            <div style={{margin: '2rem', backgroundColor: "#F7FAFD", padding: '1rem', borderRadius: '5px'}}>
                <FlexCol>
                    <h1 style={{marginBottom: '1.5rem', color: "#5c5c5c"}}>{t('investOpportunity')}
                        ({page.totalElements})</h1>
                    <CenteredRow style={{gap: '1rem', flexWrap: "wrap"}}>
                        {projects?.content?.map(project => (
                            <Card>
                                <CenteredRow>
                                    {/*<Image style={{height: '60px', width: 'auto'}} src={Logo}/>*/}
                                    <FlexCol style={{height: '100px'}}>
                                        <BText
                                            style={{fontSize: '24px'}}>{project?.projectLanguages[0].name === "" ? t('noName') : project?.projectLanguages[0].name}</BText>
                                        <Text style={{textAlign: 'center'}}>{project?.municipality?.name === "" ? t('noCityName') : project?.municipality?.name}</Text>
                                    </FlexCol>

                                </CenteredRow>
                                <FlexCol style={{height: '190px',marginTop: '0.5rem'}}>
                                    <BText>{project?.businessSector?.name == null ? t('noName') : project?.businessSector.name}</BText>
                                    <Text>{project?.projectLanguages[0].description === "" ? t('noDescription') : project?.projectLanguages[0]?.description}</Text>
                                </FlexCol>
                                <CenteredRow style={{marginTop: '.5rem', gap: '.5rem'}}>
                                    <BootstrapButton onClick={()=>{handleConnect(project)}}
                                                     variant="contained">{t('linkUpButton')}</BootstrapButton>
                                    <BootstrapButton onClick={()=>{
                                        setProjecForDownload(project.id);
                                        setShowLang(true)
                                    }}
                                                     variant="contained">{t('seePdf')}</BootstrapButton>
                                    {/*<Text>{project?.projectLanguages[0].description === "" ? "Nema opis" : project?.projectLanguages[0]?.description}</Text>*/}
                                </CenteredRow>
                            </Card>
                        ))}

                    </CenteredRow>
                    <Pagination
                        totalPages={page.totalPages}
                        handleClickPage={handleClickPage}
                        currentPage={parseInt(page.currentPage) + 1}/>
                    <h1 style={{marginBottom: '1.5rem', color: "#5c5c5c"}}>RRA {t('investOpportunity')}
                        ({pageRRA.totalElements})</h1>
                    <CenteredRow style={{gap: '1rem', flexWrap: "wrap"}}>

                        {rraprojects?.content?.map(project => (
                            <Card>
                                <CenteredRow>
                                    {/*<Image style={{height: '60px', width: 'auto'}} src={Logo}/>*/}
                                    <CenteredCol>
                                        <BText
                                            style={{fontSize: '20px'}}>{project?.rraProjectLanguages[0].name === "" ? t('noName') : project?.rraProjectLanguages[0].name}</BText>
                                        <Text>{project?.city?.name === "" ? t('noName') : project?.city?.name}</Text>
                                    </CenteredCol>
                                </CenteredRow>
                                <FlexCol style={{marginTop: '0.5rem'}}>
                                    <BText>{project?.businessActivity?.name}</BText>
                                    <Text>{project?.rraProjectLanguages[0].investmentDescription === "" ? t('noDescription') : project?.rraProjectLanguages[0].investmentDescription}</Text>
                                </FlexCol>
                                <CenteredRow style={{marginTop: '.5rem', gap: '.5rem'}}>
                                    <BootstrapButton onClick={()=>{handleConnect(project)}}
                                                     variant="contained">{t('linkUpButton')}</BootstrapButton>
                                    <BootstrapButton onClick={()=>{
                                            setProjecForDownload(project.id);
                                            setShowLangRra(true)
                                    }}
                                                     variant="contained">{t('seePdf')}</BootstrapButton>
                                    {/*<Text>{project?.projectLanguages[0].description === "" ? "Nema opis" : project?.projectLanguages[0]?.description}</Text>*/}
                                </CenteredRow>
                            </Card>
                        ))}

                    </CenteredRow>
                    <Pagination
                        totalPages={pageRRA.totalPages}
                        handleClickPage={handleClickPageRRA}
                        currentPage={parseInt(pageRRA.currentPage) + 1}/>
                </FlexCol>

            </div>
        </Container>
    )
}
