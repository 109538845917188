import styled from "styled-components";

export const ProjectContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 7px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
    padding: 1rem;
    background-color: white;
    margin-bottom: 1rem;
`

export const PageContainer = styled.div`
     display: flex;
     background-color: white;
     flex-direction: column;
     width: 100%;
`

