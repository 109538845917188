import React, {useEffect, useState} from "react";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useTranslation} from "react-i18next";
import {PRIVATE_ROLES, PUBLIC_ROLES} from "../../utils/roles";

export function AccountType({selectedSktor, roleString, setRoleString, changeUserParam}) {
    const {t} = useTranslation();
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);
    const privateOption = PRIVATE_ROLES(t);
    const publicOption = PUBLIC_ROLES(t);

    useEffect(() => {
        if (selectedSktor === t('publicSector')) {
            setOptions(publicOption);
            setSelectedOption(publicOption.filter(value => value.name === roleString)[0])
        } else {
            setOptions(privateOption);
            setSelectedOption(privateOption.filter(value => value.name === roleString)[0])
        }
    }, [selectedSktor, roleString])
    return (
        <>
            <FormControl variant="outlined">
                <Select
                    value={selectedOption}
                    defaultValue={selectedOption}
                    onChange={(e) => {
                        const {value} = e.target;
                        changeUserParam("roleId", value.id);
                        setRoleString(value.name);
                    }}>
                    {options?.map((option, index) =>
                        <MenuItem
                            key={`menuItem${index}`}
                            value={option}>
                            {option.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </>
    );
}
