import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {GridToolbar} from '@mui/x-data-grid';
import {getEventById, getEventsApprovement, getProjectStatus, zoomTokenApi} from "../../../services/api";
import {Redirect} from "react-router-dom";
import EventPreviewModal from "./EventPreviewModal";
import {CenteredRow, FlexCol} from "../../../components/Flex";
import styled from "styled-components";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useTranslation} from "react-i18next";

const Wrapper = styled(FlexCol)`
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
`;

export default function AdminEventApprovePage() {
    const {t} = useTranslation()
    const [eventsCounts, setEventsCounts] = useState([]);
    const column = [
        {
            field: 'view', headerName: 'VIDI', width: 20, renderCell: (params) => (
                <strong onClick={handleViewEvent(params?.row.id)}>
                    <VisibilityIcon/>
                </strong>
            ),
        },
        {field: 'name', headerName: 'Naziv', width: 150},
        {
            field: 'date', headerName: 'Datum', width: 150, renderCell: (params) => (
                <span>{params?.row.date?.split('T')[0]}</span>
            )
        },
        {field: 'description', headerName: 'Opis', width: 150},
        {field: 'durationMin', headerName: 'Trajanje u minutima', width: 200},
        {
            field: 'user.email', headerName: 'Korisnik', width: 200, renderCell: (params) => (
                <span>{params?.row.user.email}</span>
            )
        },
        {
            field: 'eventInvitationList.length', headerName: 'Broj zvanica', width: 200, renderCell: (params) => (
                <span>{params?.row.eventInvitationList.length}</span>

            )
        },


    ];
    let user = JSON.parse(localStorage.getItem('user'))

    const [events, setEvents] = useState([])
    const [previewData, setPreviewData] = useState({})
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code')
    const state = query.get('state')

    const handleViewEvent = useCallback(
        (id) => () => {
            return getEventById(id)
                .then(res => {
                    setPreviewData(res?.data)
                    setShowModal(true)
                })
                .catch(err => {
                    console.log(err, 'preview err')
                })
        },
        [],
    );

    function createEventOnZoom() {
        // https://zoom.us/oauth/authorize?response_type=code&client_id=F5EbeuEbRDyRN4fiBvrXzQ&redirect_uri=https://dijaspora.pks.rs/newEvents
        window.location.replace(`https://zoom.us/oauth/authorize?response_type=code&client_id=KQsrdbX5SeKjxBJmPekiRA&redirect_uri=https%3A%2F%2Fdijaspora.pks.rs%2Fevent-approve&state=${previewData.id}`)
    }

    useEffect(() => {
        if (code) {
            if (state) {
                handleViewEvent(state)
                getEventById(state)
                    .then(res => {
                        setPreviewData(res?.data)
                        setShowModal(true)
                    })
                    .catch(err => {
                        console.log(err, 'preview err')
                    })

            }
            zoomTokenApi(code).then(() => {
            }).catch(reason => {
                console.error(reason)
            });
        }
        setLoading(true)
        getProjectStatus().then(res => {
            setStatus(res.data)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
        getEventsApprovement().then(response => {
            setEvents(response?.data)
            let approved = response.data.filter(e => e.eventStatus.id === 1);
            let rejected = response.data.filter(e => e.eventStatus.id === 2);
            let onWaiting = response.data.filter(e => e.eventStatus.id === 3);
            let finished = response.data.filter(e => e.eventStatus.id === 4);
            setEventsCounts([approved.length, rejected.length, onWaiting.length, finished.length])
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

    }, [])

    if (user.role.id !== 11) return <Redirect to="/"/>

    console.log("eventsCounts", eventsCounts)

    return (
        <Wrapper>
            <CenteredRow style={{margin: '2rem 0', gap: '1rem'}}>
                <label>{t('numOfAccepted')}: {eventsCounts[0]}</label>
                <label>{t('numOfRejectd')}: {eventsCounts[1]}</label>
                <label>{t('onWaiting')}: {eventsCounts[2]}</label>
                <label>{t('finished')}: {eventsCounts[3]}</label>
            </CenteredRow>
            <div style={{height: 800}}>
                <EventPreviewModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    data={previewData}
                    createEventOnZoom={createEventOnZoom}/>
                <div style={{display: 'flex', height: '100%'}}>
                    <div style={{flexGrow: 1}}>
                        <DataGrid
                            rows={events}
                            columns={column}
                            loading={loading}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}



