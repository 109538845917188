import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllPksGroups} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Pks({userInfo, lng, t}) {
    return (
        <FlexCol style={{width: '100%', gap: 25}}>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.pks.question1')}</NormalText>
                <TextField
                    fullWidth
                    id="associationName"
                    multiline
                    maxRows={4}
                    value={userInfo?.associationName || ''}
                    variant="outlined"
                />
            </FlexCol>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.pks.question2')}</NormalText>
                <TextField
                    fullWidth
                    id="id"
                    multiline
                    maxRows={4}
                    value={userInfo?.pksGroup.id || ''}
                    variant="outlined"
                />
            </FlexCol>

        </FlexCol>
    )
}

