import styled from "styled-components";

export const NormalText = styled.p`
    font-size: 15px;
    padding: 0;
    color: ${props => props.color ? '#000' : '#616161'};
    
    //color: ${props => props.color ? '#000' : '#737373'};
    margin-bottom: ${props => props.margin ? props.margin : '0.5rem'};
    margin-top: ${props => props.marginTop ? props.marginTop : '0.5rem'};
    
`

export const SideBarText = styled.p`
    font-weight: 600;
    font-size: ${props => props.fontSize ? props.fontSize : '15px'};
    padding: 0;
    color: ${props => props.color ? props.color : '#616161'};
    margin-top: auto;
    margin-bottom: auto;
`

export const SmallText = styled.p`
    color: grey;
    font-size: 12px;
    padding: 0;
`
export const BigText = styled.p`
    font-weight: 700;
    font-size: 24px;
    height: 20px;
    padding: 0;
    color: black;
   margin-bottom: ${props => props.margin ? props.margin : '2rem'};
`
