import Image from '../../assets/images/uspesni_modeli_ddl.jpg'
import styled from 'styled-components'
import React, {useEffect, useState} from 'react';
import {Container, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {ALL_SUCCESSFUL_INVESTMENTS, SEARCHED_USER_PAGE} from "../../config/routes";
import {SEARCH_TYPES} from "../Search";
import {ROLES_TYPE} from "../../utils/roles";
import {useHistory} from "react-router-dom";
import SuccessfulModal from "../../components/SuccessfulModal";

const Banner = styled.div`
 display:flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: black;
 width: 100%;
 height: calc(100vh - 250px);
 background-image: url(${Image});
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
`

export default function SuccessfulModelDdl() {
    const {t} = useTranslation();
    const [showSuccessfulModal, setShowSuccessfulModal] = useState(false);
    const handleSearchSuccessfulInvestments = () => {
        setShowSuccessfulModal(true)
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            {showSuccessfulModal && <SuccessfulModal
                showSuccessfulModal={showSuccessfulModal}
                setShowSuccessfulModal={setShowSuccessfulModal} />}
            <Banner>
                <h1 style={{fontSize: '48px', color: 'white'}}>{t('successfulModelsDdl.header')}</h1>
                <p style={{
                    position: 'absolute',
                    right: '20px',
                    bottom: '20px',
                    backgroundColor: 'rgba(250, 249, 249, 0.55)',
                    borderRadius: '25px'
                }}>{t('successfulModelsDdl.imageDescription')}</p>
            </Banner>
            {/* {windowSize?.width < 740 && <Sidebar isMobile={true} />} */}

            <Container maxWidth="xl" style={{marginTop: "2%", marginBottom: "2%"}}>
                <Paper elevation={3} style={{padding: "2%"}}>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('successfulModelsDdl.b1')}</b>
                        {t('successfulModelsDdl.t1')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('successfulModelsDdl.t2')}
                        <b style={{color: '#243b85'}}>{t('successfulModelsDdl.b2')}</b>
                        {t('successfulModelsDdl.t3')}

                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('successfulModelsDdl.t4')}
                        <b style={{color: '#243b85'}}>{t('successfulModelsDdl.b3')}</b>
                        {t('successfulModelsDdl.t5')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        <b style={{color: '#243b85'}}>{t('successfulModelsDdl.b4')}</b>
                        {t('successfulModelsDdl.t6')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('successfulModelsDdl.t7')}
                        <a href={"https://dijaspora.pks.rs/login/"}>
                            <b style={{color: '#243b85'}}>{t('successfulModelsDdl.b5')}</b>
                        </a>
                        {t('successfulModelsDdl.t8')}
                    </p>

                    <p style={{textAlign: "justify", textJustify: "inter-word", marginBottom: "1%"}}>
                        {t('successfulModelsDdl.t9')}
                        <b style={{color: '#243b85',
                            textDecoration: 'underline',
                            textDecorationColor: '#243b85',
                            cursor: 'pointer'}} onClick={handleSearchSuccessfulInvestments}>{t('successfulModelsDdl.b6')}</b>
                    </p>
                </Paper>
            </Container>


        </div>
    )
}
