import {FlexCol} from "../../components/Flex";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import {NormalText} from "../../components/Text";
import {getAllBusinessActivities, getAllDachgroups, getAllMentorTypes, getBrokerType} from "../../services/api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function Mediji({userInfo, t, lng}) {

    return (
        <FlexCol style={{width: '100%', gap: 25}}>
            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.media.question1')}</NormalText>
                <TextField
                    fullWidth
                    disabled={true}
                    id="countries"
                    multiline
                    value={userInfo?.countries || ''}
                    variant="outlined"
                />
            </FlexCol>

            <TextField
                fullWidth
                disabled={true}
                label={t('registration.media.question2')}
                id="followersNumber"
                type="number"
                min="0"
                value={userInfo?.followersNumber || 0}
                variant="outlined"
            />

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.media.question3')}</NormalText>
                <TextField
                    fullWidth
                    id="explanation"
                    multiline
                    maxRows={4}
                    value={userInfo?.explanation || ''}
                    variant="outlined"
                />
            </FlexCol>

            <FlexCol>
                <NormalText style={{marginTop: '1.5rem'}}>{t('registration.media.question4')}</NormalText>
                <TextField
                    fullWidth
                    id="showExample"
                    multiline
                    maxRows={4}
                    value={userInfo?.showExample || ''}
                    variant="outlined"
                />
            </FlexCol>
        </FlexCol>
    )
}

