import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton
} from '@mui/x-data-grid';
import {GridToolbar} from '@mui/x-data-grid';
import {GridToolbarExport} from '@mui/x-data-grid';
import {
    getApprovement,
    getPdf, getProjectLocationPdf,
    getProjectStatus, getRRAPdf, updateProjectLocationStatus,
    updateProjectStatus,
    updateRRAProjectStatus
} from "../../../services/api";
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Redirect} from "react-router-dom";
import PreviewProjectModal from "./previwProjectModal";
import PreviewRRAModal from "./previewRRAModal";
import {CenteredRow, FlexCol} from "../../../components/Flex";
import styled from "styled-components";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import LanguagePdfSelect from "../../../components/LanguagePdfSelect";
import {CenteredCol} from "../../../style/Flex";

const Wrapper = styled(FlexCol)`
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
`;

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={{
                fileName: 'ProjektiDijaspora',
                delimiter: ';',
                utf8WithBom: true,
            }} />
        </GridToolbarContainer>
    );
}

export default function AdminApprovePage() {
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const column = [
        {field: 'id', headerName: 'ID', width: 10},
        {field: 'projectStatus', headerName: 'STATUS', width: 150},
        {
            field: 'view', headerName: 'VIDI', width: 20, renderCell: (params) => (
                <strong onClick={() => handleClick(params?.row.projectId, params?.row.projectType)}>
                    <VisibilityIcon/>
                </strong>
            ),
        },
        {
            field: 'reject', width: 100, headerName: 'O', renderCell: (params) => (
                <strong>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={handleReject(params.id, params?.row.projectId, params?.row.projectType)}>
                        {t('reject')}
                    </Button>
                </strong>
            ),
        },
        {
            field: 'accept', width: 100, headerName: 'P', renderCell: (params) => (
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleAccept(params.id, params?.row.projectId, params?.row.projectType)}>
                        {t('accept')}
                    </Button>
                </strong>
            ),
        },
        {field: 'projectName', headerName: 'PROJEKAT', width: 150},
        {field: 'chamberOfCommerceName', headerName: 'KOMORA', width: 150},
        {field: 'projectType', headerName: 'TIP', width: 150},
        {field: 'userType', headerName: 'GRUPA', width: 150},
        {field: 'nameSurname', headerName: 'KORISNIK', width: 150},
        {field: 'userEmail', headerName: 'USER', width: 150},
        {field: 'firmName', headerName: 'KOMPANIJA', width: 150},
        {field: 'country', headerName: 'ZEMLJA', width: 150},
        {field: 'createdAt', headerName: 'DATUM', width: 150},
        {field: 'projectName', headerName: 'PROJEKAT', width: 150},
        {field: 'projectType', headerName: 'TIP', width: 150},
        {field: 'userType', headerName: 'GRUPA', width: 150},
        {field: 'nameSurname', headerName: 'KORISNIK', width: 150},
        {field: 'userEmail', headerName: 'EMAIL', width: 150},
        {field: 'firmName', headerName: 'KOMPANIJA', width: 150},
        {field: 'country', headerName: 'ZEMLJA', width: 150},
        {field: 'createdAt', headerName: 'DATUM', width: 150},
        {field: 'projectStatus', headerName: 'STATUS', width: 150},
        {field: 'amount', headerName: 'IZNOST', width: 150},
        {field: 'description', headerName: 'OPIS', width: 150},

    ];
    let user = JSON.parse(localStorage.getItem('user'))

    const [projects, setProjects] = useState([])
    const [status, setStatus] = useState([])
    const [previewData, setPreviewData] = useState({})
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [showModalR, setShowModalR] = useState(false);
    const [showLang, setShowLang] = useState(false);
    const [forDownload, setForDownload] = useState(0);
    const [projectTypeDownload, setProjectTypeDownload] = useState(0);
    const [projectTypeStatusNumber, setProjectTypeStatusNumber] = useState({});
    const [projectTypeStatusNumber1, setProjectTypeStatusNumber1] = useState([]);
    const handleReject = useCallback(
        (id, projectId, projectType) => () => {
            enqueueSnackbar("Odbačen projekat", {
                variant: 'success'
            });
            setProjects((prevRows) =>
                prevRows.map((row) =>
                    row.id === id ? {...row, projectStatus: 'Odbijen'} : row,
                ),
            );
            if (projectType === "PROJECT") return updateProjectStatus(projectId, 5)
            else if (projectType === "INVESTMENT-LOCATION") return updateProjectLocationStatus(projectId, 3)
            else return updateRRAProjectStatus(projectId, 5)

        },
        [],
    );

    const handleClick = useCallback(
        (projectId, projectType) => {
            setForDownload(projectId);
            setProjectTypeDownload(projectType);
            setShowLang(true);
        }
    )

    const handleAccept = useCallback(
        (id, projectId, projectType) => () => {
            enqueueSnackbar("Odobren projekat", {
                variant: 'success'
            });
            setProjects((prevRows) =>
                prevRows.map((row) =>
                    row.id === id ? {...row, projectStatus: 'Odobren'} : row,
                ),
            );
            if (projectType === "PROJECT") return updateProjectStatus(projectId, 4)
            else if (projectType === "INVESTMENT-LOCATION") return updateProjectLocationStatus(projectId, 2)
            else return updateRRAProjectStatus(projectId, 4)

        },
        [],
    );

    function handleDownloadPdf(lang) {
            switch (projectTypeDownload) {
                case 'RRA-PROJECT':
                    getRRAPdf(forDownload, lang).then((response) => {
                        const file = new Blob(
                            [response.data],
                            {type: 'application/pdf'});
                        const url = window.URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(url);
                    }).catch((error) => console.log(error, 'pdf'));
                    break;
                case 'INVESTMENT-LOCATION':
                    getProjectLocationPdf(forDownload, lang).then((response) => {
                        const file = new Blob(
                            [response.data],
                            {type: 'application/pdf'});
                        const url = window.URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(url);
                    }).catch((error) => console.log(error, 'pdf'));
                    break;
                default: {
                    getPdf(forDownload, lang).then((response) => {
                        const file = new Blob(
                            [response.data],
                            {type: 'application/pdf'});
                        const url = window.URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(url);
                    }).catch((error) => console.log(error, 'pdf'));
                }
            }
            setShowLang(false);
        }

    useEffect(() => {
        setLoading(true)
        getProjectStatus().then(res => {
            setStatus(res.data)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
        getApprovement().then(response => {
            setProjects(response?.data)

            const projectStatusType = response?.data
                .map(dataItem => dataItem.projectStatus)
                .filter((projectStatus, index, array) => array.indexOf(projectStatus) === index);

            const counts = projectStatusType
                .map(projectType => ({
                    type: projectType,
                    projectStatus: projectType,
                    count: response?.data.filter(item => item.projectStatus === projectType).length
                }));

            let projectTypes = ["PROJECT", "RRA-PROJECT", "INVESTMENT-LOCATION"];
            let projectStautsTypes1 = ["Na čekanju", "Odobren", "Odbijen"];

            let counts1 = []
            for(let statusI = 0; statusI < projectStautsTypes1?.length; statusI++){
                let status = projectStautsTypes1[statusI];
                for(let projectTypeI = 0; projectTypeI < projectTypes?.length; projectTypeI++){
                    let projectType = projectTypes[projectTypeI];
                    let count = response?.data.filter(item => item.projectStatus === status
                        && item.projectType === projectType).length;
                    counts1.push({
                        projectTypeName: projectType,
                        projectStatusName: status,
                        countValue: count
                    })
                }
            }

            setProjectTypeStatusNumber(counts)
            setProjectTypeStatusNumber1(counts1)

            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    if (user.role.id !== 11) return <Redirect to="/"/>

    return (
        <Wrapper>
            <LanguagePdfSelect t={t} show={showLang}  handlePreviewProjectClick={handleDownloadPdf}
                               handleClose={()=>{setShowLang(false)}}/>
            <CenteredRow style={{margin: '2rem 0', gap: '1rem'}}>
                <label>{t('numOfAccepted')}: {projectTypeStatusNumber[1]?.count}</label>
                <label>{t('numOfRejected')}: {projectTypeStatusNumber[0]?.count}</label>
                <label>{t('onWaiting')}: {projectTypeStatusNumber[2]?.count}</label>
            </CenteredRow>
            <CenteredRow style={{margin: '2rem 0', gap: '1rem'}}>
                <CenteredCol>
                    {projectTypeStatusNumber1?.map((p) => {
                        if(p?.projectTypeName === "PROJECT") return <label>{p?.projectTypeName} {p?.projectStatusName}: {p?.countValue}</label>
                        else return <label/>
                    })}
                </CenteredCol>
                <CenteredCol>
                    {projectTypeStatusNumber1?.map((p) => {
                        if(p?.projectTypeName === "RRA-PROJECT") return <label>{p?.projectTypeName} {p?.projectStatusName}: {p?.countValue}</label>
                        else return <label/>
                    })}
                </CenteredCol>
                <CenteredCol>
                    {projectTypeStatusNumber1?.map((p) => {
                        if(p?.projectTypeName === "INVESTMENT-LOCATION") return <label>{p?.projectTypeName} {p?.projectStatusName}: {p?.countValue}</label>
                        else return <label/>
                    })}
                </CenteredCol>
            </CenteredRow>
            <div style={{height: 800}}>
                <PreviewProjectModal showModal={showModal} setShowModal={setShowModal} data={previewData}/>
                <PreviewRRAModal showModal={showModalR} setShowModal={setShowModalR} data={previewData}/>
                <div style={{display: 'flex', height: '100%'}}>
                    <div style={{flexGrow: 1}}>
                        <DataGrid rows={projects} columns={column} loading={loading}
                                  components={{Toolbar: CustomToolbar}}>
                        </DataGrid>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}


